import { passwordValidator } from '../../../../shared/components/Input/validators';

export const StepEnum = {
  password: 'password',
  registerMethods: 'registerMethods',
};

export const passwordErrorsConfig = {
  noPassword: 'setPassword.noPassword',
  passwordRequired: 'setPassword.passwordRequired',
  passwordMustBeAtLeast8CharactersLong: 'setPassword.passwordMustBeAtLeast8CharactersLong',
  passwordMustContainOneCapitalLetter: 'setPassword.passwordMustContainOneCapitalLetter',
  passwordIsOk: 'setPassword.passwordIsOk',
  passwordsAreDifferent: 'setPassword.passwordsAreDifferent',
  passwordsAreOk: 'setPassword.passwordsAreOk',
  confirmPassword: 'setPassword.confirmPassword',
};

export const getErrorMessage = ({ password }) => {
  const isPasswordEmpty = !password;
  const passwordError = passwordValidator(password);

  if (isPasswordEmpty || passwordError === passwordErrorsConfig.passwordRequired) {
    return {
      message: passwordErrorsConfig.noPassword,
      errorPasswordConfirmation: false,
    };
  }

  return {
    message: !passwordError ? passwordErrorsConfig.passwordIsOk : passwordError,
    errorPasswordConfirmation: false,
  };
};

export const getConfirmationPasswordErrorMessage = ({ password, passwordConfirmation }) => {
  const isPasswordConfirmationDifferent = passwordConfirmation && passwordConfirmation !== password;
  const passwordError = passwordValidator(password);
  if (isPasswordConfirmationDifferent) {
    return {
      message: passwordErrorsConfig.passwordsAreDifferent,
      errorPasswordConfirmation: false,
    };
  }
  if (!passwordConfirmation?.length) {
    return {
      message: passwordErrorsConfig.confirmPassword,
      errorPasswordConfirmation: false,
    };
  }

  return {
    message: !passwordError ? passwordErrorsConfig.passwordsAreOk : passwordErrorsConfig.passwordIsOk,
    errorPasswordConfirmation: false,
  };
};

export const isSignUpButtonDisabled = ({ errMessage, errConfirmationPasswordMessage }) => errMessage.message !== passwordErrorsConfig.passwordIsOk
  || errConfirmationPasswordMessage.message !== passwordErrorsConfig.passwordsAreOk;

export const passwordInputValid = (errMessage, form, password) => {
  const isPasswordValid = errMessage.message === passwordErrorsConfig.passwordIsOk && password;
  const isPasswordConfirmationValid = form.password === form.passwordConfirmation && Boolean(form.passwordConfirmation?.length);
  return { isPasswordValid, isPasswordConfirmationValid };
};
