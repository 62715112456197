export const FormTypeEnum = {
  update: 'update',
  create: 'create'
};
export const getLabelFromUrl = (location) => {
  const { hash, search } = location;
  const [postId, formType] = search.split('type=');
  const actionType = hash.replace('#', '');
  const formTypeCapitalized = formType ? formType.charAt(0).toUpperCase() + formType.slice(1) : '"';
  return `posts.${actionType}${formTypeCapitalized}`;
};
