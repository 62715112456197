import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useApi } from '../../../../shared/helpers/api';

const Reauthorization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { api } = useApi();

  const result = Object.fromEntries(new URLSearchParams(location.search).entries());
  useEffect(async () => {
    if (result.code) {
      await Promise.all([await api.post('/auth/reauthorization', { code: result.code })]);
    }
    navigate('/login');
  }, []);
};

export default Reauthorization;
