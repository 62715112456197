import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import Input from '../../../../../shared/components/Input';
import Button from '../../../../../shared/components/Button';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import AdminLoader from '../../../components/AdminLoader/index';
import { trimSpaceFromObject } from '../../../../../shared/helpers/strings';
import { createAdminModalFields } from './utils';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/addCircle.svg';

const AddAdminModalForm = ({ onClose, fetchList }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ name: '', lastName: '', login: '' });
  const [error, setError] = useState({ name: true, lastName: true, login: true });

  const isReadOnlyButton = Object.values(error).some((value) => value);

  const onFormInputChange = (key) => (value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };
  const onErrorChange = (key) => (value) => {
    setError((prev) => ({ ...prev, [key]: value }));
  };

  const saveUserHandler = async () => {
    setIsLoading(true);
    try {
      const parsedFormWithNoWhiteSpace = trimSpaceFromObject(form);
      await api.post('/acp/user/addUser', parsedFormWithNoWhiteSpace);
      fetchList();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      modalTitle={t('adminControlPanel.addAdministrator')}
      titleStyle={classes.titleStyle}
      customIconStyle={classes.customCloseStyle}
      onCancel={onClose}
      contentStyle={classes.content}
    >
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <AdminLoader />
        </div>
      ) : (
        <>
          <div className={classes.modalContentWrapper}>
            {createAdminModalFields(t).map(({ value, label, placeholder, validator, transformer, maxLength }) => (
              <div key={value}>
                <div className={classes.inputName}>{t(label)}</div>
                <Input
                  admin
                  labelRised
                  showErrorAfterBlur
                  placeholder={t(placeholder)}
                  value={form[value]}
                  validator={validator}
                  transformer={transformer}
                  onChange={onFormInputChange(value)}
                  onError={onErrorChange(value)}
                  maxLength={maxLength}
                />
              </div>
            ))}
          </div>
          <Button
            readOnly={isReadOnlyButton}
            className={classes.addAdminBtn}
            Icon={PlusIcon}
            label={t('global.add')}
            onClick={saveUserHandler}
          />
        </>
      )}
    </Modal>
  );
};

export default AddAdminModalForm;
