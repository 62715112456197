import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../Button';
import Modal from '../Modal';

const DeleteCardModal = ({ onClose, deleteCardHandler, client }) => {
  const { t } = useTranslation();

  const deleteHandler = async () => {
    await deleteCardHandler();
    onClose();
  };

  return (
    <Modal
      onCancel={onClose}
      modalTitle={t('adminControlPanel.deleteCardModalTitle')}
      contentStyle={classes.modalContent}
    >
      <div className={classes.modalBody}>
        {t('adminControlPanel.deleteCardModalDescription')}
        <div className={classes.actions}>
          <Button
            className={clsx(classes.cancelBtn, client && classes.clientCancelBtn)}
            label={t('global.cancel')}
            onClick={onClose}
          />
          <Button className={client && classes.clientConfirmBtn} label={t('global.confirm')} onClick={deleteHandler} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteCardModal;
