import React from 'react';
import classes from './styles.module.css';
import ProfileCompletion from '../ProfileCompletion';
import BusinessStatus from '../BusinessStatus';

const BusinessCardsMetadata = ({ status, data, placeId, adminPreview }) => (
  <div className={classes.wrapper}>
    <BusinessStatus status={status} placeId={placeId} adminPreview={adminPreview} />
    <ProfileCompletion data={data} />
  </div>
);

export default BusinessCardsMetadata;
