import React from 'react';
import { useTranslation } from 'react-i18next';
import { formEnum } from '../ModalForm/utils/utils';
import classes from './styles.module.css';
import { ReactComponent as Arrow } from '../../../../../shared/assets/arrowAlternative.svg';

const OfferLink = ({ data, localPost, openOfferLinkHandler }) => {
  const { t } = useTranslation();

  return (
    data.type === formEnum.OFFER
    && localPost?.offer?.redeemOnlineUrl && (
      <div onClick={() => openOfferLinkHandler(localPost?.offer?.redeemOnlineUrl)} className={classes.offerLink}>
        {t('posts.redeemOnline')}
        <Arrow className={classes.arrowRightIcon} />
      </div>
    )
  );
};

export default OfferLink;
