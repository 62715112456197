import { STEPPER_ENUM } from '../../../views/BusinessProfileCreate/utils';

export const ACTIONS_BUTTON_ENUM = {
  resign: 'businessProfileCreate.resignToCreateBusinessProfile',
  goBack: 'global.goBack',
  confirm: 'global.confirm',
  submit: 'businessProfileCreate.submit',
};

export const formActions = ({
  onResign,
  goPreviousStepHandler,
  goNextStepHandler,
  readOnly,
  activeStepName,
  submitHandler,
  t
}) => {
  const isLastStep = activeStepName === STEPPER_ENUM.contactDataForm;

  const actions = [
    {
      onClick: onResign,
      label: t(ACTIONS_BUTTON_ENUM.resign),
      className: 'resignBtn',
    },
    {
      onClick: goPreviousStepHandler,
      label: t(ACTIONS_BUTTON_ENUM.goBack),
      className: 'goBackBtn',
    },
    {
      onClick: isLastStep ? submitHandler : goNextStepHandler,
      label: isLastStep ? t(ACTIONS_BUTTON_ENUM.submit) : t(ACTIONS_BUTTON_ENUM.confirm),
      className: 'confirmBtn',
      readOnlyBtn: readOnly,
    },
  ];

  return actions;
};
