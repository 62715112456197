import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import { SelectEnum, parseAccountDetails, selectList } from '../KeywordsSelect/utils';
import KeywordDistance from '../KeywordDistance';
import KeywordsArea from '../KeywordsArea';
import KeywordNet from '../KeywordNet';
import GenerateReportOptions from '../GenerateReportOptions';
import Button from '../../../../../shared/components/Button';
import ExpandableSection from '../../../../../shared/components/ExpandableSection';
import FiltersDescriptionModal from '../../SetBusinessKeyWords/FiltersModal';
import LoaderComponent from '../LoaderComponent';
import { useNotification } from '../../../../../shared/helpers/notification';
import CharCounter from '../../../../../shared/components/CharCounter';
import AddressForm from '../../SetBusinessKeyWords/AddressForm';
import { adressProvidedCheck } from './utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const KEYWORD_MAX_LENGTH = 50;
const FiltersModal = ({ modalHandler, values, accountDetails, handleValues, onGenerateReport }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const focusedElement = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [focusedKey, setFocusedKey] = useState(null);
  const { addressProvided, fullAdress } = adressProvidedCheck(values);
  const listWithNoAddress = selectList.filter((el) => el.id !== SelectEnum.address);
  const list = addressProvided ? selectList : listWithNoAddress;
  const { businessProfiles, selectedBusinessProfile } = useMemo(
    () => parseAccountDetails({ accountDetails, values }),
    [accountDetails, values],
  );

  const changeAddressHandler = (addressValue) => {
    Object.entries(addressValue).forEach(([key, value]) => {
      handleValues({ name: key, value });
    });
  };

  const handleFocus = (key) => (e) => {
    setFocusedKey(key);
    focusedElement.current = e.target;
  };
  const handleRWDFilters = (name) => (value) => {
    handleValues({ name, value });
  };
  const handleKeywordChange = (e) => {
    handleRWDFilters(SelectEnum.keyword)(e.target.value);
  };
  useEffect(() => {
    const controller = new AbortController();

    const hideModal = (event) => {
      if (focusedKey && !focusedElement.current.contains(event.target)) {
        controller.abort();
        setFocusedKey(null);
      }
    };

    if (focusedKey) {
      document.addEventListener('click', hideModal, { signal: controller.signal });
    }

    const cleanup = () => {
      document.removeEventListener('click', hideModal);
      controller.abort();
    };

    return cleanup();
  }, [focusedKey]);

  const generateReport = async () => {
    setIsLoading(true);
    try {
      await onGenerateReport();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      modalHandler();
    }
  };

  return (
    <Modal contentStyle={classes.content} rwdBackTitle={t('modalMobile.goBackToReport')} onCancel={modalHandler}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <LoaderComponent message={t('generateReport.generatingReport')} />
        </div>
      )}
      <div className={classes.modalWrapper}>
        <div className={classes.wrapper}>
          <div className={classes.title}>{t('generateReport.yourFilters')}</div>
          <div className={classes.form}>
            {list.map((el, index) => (
              <div
                tabIndex={0}
                role="button"
                onFocus={handleFocus(el.id)}
                key={el.id}
                className={clsx(
                  classes.field,
                  focusedKey === el.id && classes.focused,
                  focusedKey === selectList[index - 1]?.id && classes.leftFocused,
                )}
              >
                <div className={classes.label}>{t(el.name)}</div>
                {el.id === SelectEnum.businessProfileId && (
                  <>
                    <div className={classes.value}>{selectedBusinessProfile}</div>
                    {el.id === focusedKey && (
                      <div className={classes.changeComponentWrapper}>
                        <GenerateReportOptions
                          label={t('generateReport.selectCompany')}
                          options={businessProfiles}
                          onChange={handleRWDFilters(el.id)}
                          value={values[SelectEnum.businessProfileId]}
                        />
                      </div>
                    )}
                  </>
                )}
                {el.id === SelectEnum.keyword && (
                  <div className={classes.keywordInputWrapper}>
                    <input
                      maxLength={KEYWORD_MAX_LENGTH}
                      onChange={handleKeywordChange}
                      value={values[SelectEnum.keyword]}
                      className={classes.keywordInput}
                    />
                    <CharCounter value={values[SelectEnum.keyword].length} maxLength={50} className={classes.counter} />
                  </div>
                )}
                {el.id === SelectEnum.distance && (
                  <>
                    <div className={classes.value}>
                      {values[SelectEnum.distance] ? `${values[SelectEnum.distance]} km` : ''}
                    </div>
                    {el.id === focusedKey && (
                      <div className={classes.changeComponentWrapper}>
                        <KeywordDistance value={values[SelectEnum.distance]} rwd onChange={handleRWDFilters(el.id)} />
                      </div>
                    )}
                  </>
                )}
                {el.id === SelectEnum.net && (
                  <>
                    <div className={classes.value}>
                      {values[SelectEnum.net] ? `${values[SelectEnum.net]} x ${values[SelectEnum.net]}` : ''}
                    </div>
                    {el.id === focusedKey && (
                      <div className={classes.changeComponentWrapper}>
                        <KeywordNet value={values[SelectEnum.net]} rwd onChange={handleRWDFilters(el.id)} />
                      </div>
                    )}
                  </>
                )}
                {el.id === SelectEnum.area && (
                  <>
                    <div className={classes.value}>
                      {values[SelectEnum.area] ? `${values[SelectEnum.area]} km` : ''}
                    </div>
                    {el.id === focusedKey && (
                      <div className={classes.changeComponentWrapper}>
                        <KeywordsArea value={values[SelectEnum.area]} rwd onChange={handleRWDFilters(el.id)} />
                      </div>
                    )}
                  </>
                )}
                {el.id === SelectEnum.address && (
                  <>
                    <div className={classes.value}>{addressProvided && fullAdress}</div>
                    {el.id === focusedKey && (
                      <div className={clsx(classes.changeComponentWrapper, classes.addressForm)}>
                        <AddressForm
                          rwdFilters
                          reportLocation={fullAdress}
                          keywordSelect
                          onLocationChange={changeAddressHandler}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          <ExpandableSection
            sectionName={t('generateReport.showFiltersDefinition')}
            sectionNameStyle={classes.expandableSectionTitle}
          >
            <FiltersDescriptionModal />
          </ExpandableSection>
        </div>
        <div className={classes.actionBox}>
          <Button
            className={classes.generateBtn}
            label={t('generateReport.generateNewReport')}
            onClick={generateReport}
          />
          <Button className={classes.cancelBtn} label={t('global.cancel')} onClick={modalHandler} />
        </div>
      </div>
    </Modal>
  );
};
export default FiltersModal;
