import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import ViewTitle from '../../components/ViewTitle';
import SeoCardsTool from '../../modules/SeoCards/SeoCardsTool';
import SeoCardList from '../../modules/SeoCards/SeoCardList';
import { useApi } from '../../../../shared/helpers/api';
import { FILTERS_ENUM } from '../AdministratorList/utils';
import { seoCardDataParser } from '../../modules/SeoCards/SeoCardList/utils';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import { SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';

const { ASC, DESC } = SORT_DIRECTION_ENUM;

const SeoCards = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [seoCardList, setSeoCardList] = useState([]);
  const [cardsTotalAmount, setCardsTotalAmount] = useState(0);
  const [orderBy, setOrderBy] = useState({ column: 'card_code', direction: ASC });
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0 }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const fetchSeoCardList = async (controller = new AbortController()) => {
    setIsLoading(true);
    const payloadWithNoNullishValues = removeNullValues({ ...filters, orderBy });

    try {
      const {
        data: { results, total },
      } = await api.post(
        '/acp/card/cardList',
        {
          filters: payloadWithNoNullishValues,
        },
        { signal: controller.signal },
      );
      setSeoCardList(seoCardDataParser(results, t));
      setCardsTotalAmount(total);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    fetchSeoCardList(controller);

    return () => controller.abort();
  }, [filters, orderBy]);

  return (
    <>
      <ViewTitle title={t('adminControlPanel.opinieo')} />
      <div className={classes.wrapper}>
        <SeoCardsTool onListFetch={fetchSeoCardList} handleFiltersChange={handleFiltersChange} filters={filters} />
        <SeoCardList
          handleFiltersChange={handleFiltersChange}
          sortHandler={sortHandler}
          isLoading={isLoading}
          cardsTotalAmount={cardsTotalAmount}
          filters={filters}
          orderBy={orderBy}
          seoCardList={seoCardList}
        />
      </div>
    </>
  );
};

export default SeoCards;
