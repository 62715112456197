import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as Close } from '../../../../../shared/assets/close.svg';

const RWDDeleteBox = ({ modalHandler, deleteItemHandler }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.backdrop} />
      <div className={classes.rowContainer}>
        <div className={classes.title}>{t('posts.deletePost')}</div>
        <Close onClick={modalHandler} className={classes.closeIcon} />
      </div>
      <div className={classes.modalBody}>{t('posts.deletePostConfirmation')}</div>
      <div className={classes.modalActions}>
        <Button
          onClick={modalHandler}
          className={clsx(classes.modalButton, classes.cancel)}
          label={t('global.cancel')}
        />
        <Button onClick={deleteItemHandler} className={classes.modalButton} label={t('posts.deletePost')} />
      </div>
    </div>
  );
};

export default RWDDeleteBox;
