import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as Chevron } from '../../assets/chevronv2.svg';
import Calendar from '../Calendar';

const DateInput = ({ label, placeholder, className, onChange, userId }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);

  const handleDateChange = (value) => {
    setSelectedDate(value);
    onChange(value.date);
    setShowCalendar(false);
  };

  const onClose = () => {
    onChange(null);
    setSelectedDate(null);
    setShowCalendar(false);
  };
  const handleCalendarClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div onClick={() => setShowCalendar(true)} className={clsx(classes.wrapper, className)}>
      <div className={classes.inputWrapper}>
        <div className={classes.label}>{label}</div>
        <div className={classes.placeholder}>{selectedDate?.date ? selectedDate.date : placeholder}</div>
      </div>
      <Chevron className={classes.icon} />
      {showCalendar && (
        <div onClick={handleCalendarClick} className={classes.calendarWrapper}>
          <Calendar
            oneDayChoose
            isSingleMonth
            onClose={onClose}
            onChange={handleDateChange}
            selectedDate={selectedDate?.date}
            className={clsx(userId && classes.calendarWithUser)}
          />
        </div>
      )}
    </div>
  );
};

export default DateInput;
