import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CreateSeoCardModal from '../CreateSeoCardModal';
import Button from '../../../../../shared/components/Button';
import SearchInput from '../../../../../shared/components/SearchInput';
import { useModal } from '../../../../../shared/helpers/hooks';
import CardListFilterModal from '../CardListFilterModal';
import { debounce } from '../../../../../shared/helpers/debounce';
import { MIN_SEARCH_LENGTH } from '../../../views/AdministratorList/utils';
import { geFilterBtnLabel } from './utils';
import { ReactComponent as AddBtnIcon } from '../../../../../shared/assets/addCircle.svg';
import { ReactComponent as FilterBtnIcon } from '../../../../../shared/assets/filter.svg';

const SeoCardsTool = ({ onListFetch, handleFiltersChange, filters }) => {
  const { t } = useTranslation();
  const [showCreateCardModal, setShowCreateCardModal, toggleCreateCardModal] = useModal();
  const [showFilters, setShowFilters, filtersHandler] = useModal();

  const filtersBtnLabel = geFilterBtnLabel(filters, t);

  const applyFiltersHandler = (value) => {
    Object.keys(value)
      .filter((el) => !['limit', 'offset'].includes(el))
      .forEach((key) => {
        handleFiltersChange(key)(value[key]);
      });
    filtersHandler();
  };

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload) {
        handleFiltersChange('search')(payload);
      }
      if (payload.length < MIN_SEARCH_LENGTH && payload.length !== 0) {
        return [];
      }
      handleFiltersChange('search')(payload);

      return [];
    }, 500),
    [],
  );
  return (
    <div className={classes.wrapper}>
      {showCreateCardModal && <CreateSeoCardModal onClose={toggleCreateCardModal} onListFetch={onListFetch} />}
      {showFilters && (
        <CardListFilterModal onClose={filtersHandler} onApplyFilters={applyFiltersHandler} listFilters={filters} />
      )}
      <div className={classes.leftSide}>
        <SearchInput
          value={filters?.search}
          className={classes.searchInput}
          label={t('global.search')}
          onSearch={handleSearchbar}
        />
        <Button Icon={FilterBtnIcon} label={filtersBtnLabel} className={classes.filterBtn} onClick={filtersHandler} />
      </div>
      <Button
        Icon={AddBtnIcon}
        className={classes.addCardBtn}
        label={t('adminControlPanel.createNewCard')}
        onClick={toggleCreateCardModal}
      />
    </div>
  );
};

export default SeoCardsTool;
