import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Stars from '../../../../../shared/components/Stars';
import Pill from '../../../../../shared/components/Pill';
import { getColorByPosition } from '../../../helpers/colors';
import classes from './styles.module.css';
import { getVisibilityMessage } from './utils';
import PlacePhoto from '../PlacePhoto';

const PlaceElement = ({
  className,
  highlighted,
  data,
  displayPosition = true,
  photo,
  noDisplayCheckIcon = false,
  selectedPoint,
}) => {
  const { t } = useTranslation();
  const { name, types, rating, ratingCount, order, state, placeOverallPosition } = data;
  const displayedTypes = types.slice(0, 2).join(', ');
  const businessTypesLeft = types.length > 2 ? `  +${types.length - 2}` : '';
  const properPosition = selectedPoint ? order + 1 : placeOverallPosition;
  const pillLabel = selectedPoint
    ? t('generateReport.positionInPoint', { position: properPosition })
    : t('generateReport.positionGeneral', { position: properPosition });

  const { color, message } = useMemo(() => {
    const positionMessage = getVisibilityMessage({ position: properPosition });
    const positionColor = getColorByPosition({ position: properPosition });
    return { color: positionColor, message: positionMessage };
  }, [properPosition]);

  return (
    <div className={clsx(classes.wrapper, className, highlighted && !noDisplayCheckIcon && classes.highlighted)}>
      <div className={classes.rowContainer}>
        <PlacePhoto state={state} photo={photo} name={name} />
        <div className={classes.info}>
          {displayPosition && (
            <div className={classes.positionWrapper}>
              <Pill className={classes.pill} label={pillLabel} color={color}/>
            </div>
          )}
          <div className={classes.rating}>
            {rating.toFixed(1)}
            <Stars className={classes.stars} rating={rating} />
            {ratingCount}
          </div>
          <div className={classes.name}>{name}</div>
          <div className={classes.row}>
            <div className={classes.types}>{displayedTypes}</div>
            <span className={classes.length}>{businessTypesLeft}</span>
          </div>
          {!highlighted && (
            <div className={classes.visibility}>
              {t('generateReport.companyVisibility')}
              <span className={classes.visibilityText} style={{ color }}>
                {message}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={classes.visibilityRWD}>
        <div className={classes.visibilityRWDPlacement}>
          <div className={classes.visibilityRWDText}>{t('generateReport.companyVisibility')}</div>
          <span style={{ color }}>{message}</span>
        </div>
        <Pill className={clsx(classes.pill, classes.mobilePill)} label={pillLabel} color={color} />
      </div>
    </div>
  );
};

export default PlaceElement;
