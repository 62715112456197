import { removeNullValues } from '../../../shared/helpers/parsers';
import { handleApiError } from '../../SEO/modules/Settings/ChangePassword/utils';
import { FILTERS_ENUM } from '../views/AdministratorList/utils';

const { LIMIT, OFFSET } = FILTERS_ENUM;

export const exportCSVDataHandler = async ({ response, fileName, showNotification, t }) => {
  try {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    handleApiError({ err, showNotification, t });
  }
};

export const prepareExportFilters = (filters) => removeNullValues(
  Object.keys(filters)
    .filter((key) => ![LIMIT, OFFSET].includes(key))
    .reduce((acc, key) => {
      acc[key] = filters[key];
      return acc;
    }, {}),
);
