import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import { optionsConfig } from './utils';

const VerificationOption = ({ option, onSelect, selectedOption }) => {
  const { t } = useTranslation();

  const phoneNumber = `****${option?.phoneNumber?.slice(-4)}`;
  const emailDomain = option.emailData?.domain;
  const optionDetail = optionsConfig(t, option.verificationMethod, phoneNumber, emailDomain);

  return (
    <div
      onClick={() => onSelect(option)}
      className={clsx(classes.option, selectedOption === option && classes.selectedOption)}
    >
      <div className={classes.rowContainer}>
        <Checkbox
          className={classes.checboxWrapper}
          checkboxStyle={classes.checkboxStyle}
          label={optionDetail?.verificationMethod}
          labelPosition="right"
          value={selectedOption === option}
        />
      </div>
      {selectedOption === option && <div className={classes.subInfo}>{optionDetail.subInfo}</div>}
    </div>
  );
};

export default VerificationOption;
