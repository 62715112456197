import { ReactComponent as ViewsIcon } from '../../../../../shared/assets/dashboardBox1.svg';
import { ReactComponent as InterActionsIcon } from '../../../../../shared/assets/dashboardbox2.svg';
import { ReactComponent as AverageRate } from '../../../../../shared/assets/dashboardbox3.svg';
import { ReactComponent as DesktopSearch } from '../../../../../shared/assets/desktopSearch.svg';
import { ReactComponent as DesktopMapSearch } from '../../../../../shared/assets/desktopMap.svg';
import { ReactComponent as MobileSearch } from '../../../../../shared/assets/mobileSearch.svg';
import { ReactComponent as MobileSearchMap } from '../../../../../shared/assets/mobileMaps.svg';
import { ReactComponent as DirectionRequest } from '../../../../../shared/assets/businessDirectionRequestIcon.svg';
import { ReactComponent as CallClick } from '../../../../../shared/assets/callClickIncon.svg';
import { ReactComponent as WebsiteClick } from '../../../../../shared/assets/websiteClick.svg';
import { ReactComponent as BusinessConversations } from '../../../../../shared/assets/businessConversationsIcon.svg';
import { ReactComponent as BusinessBookings } from '../../../../../shared/assets/businessBooking.svg';
import { ReactComponent as BusinessFoodMenu } from '../../../../../shared/assets/businessFoodMenu.svg';
import { ReactComponent as BusinessFoodOrder } from '../../../../../shared/assets/businessFoodOrders.svg';

export const statisticBoxes = [
  {
    id: 1,
    title: 'dashboard.viewsNumber',
    value: null,
    comparison: null,
    Icon: ViewsIcon,
    toolTipText: 'dashboard.tooltipViewsNumber',
    toolTipTitle: 'dashboard.viewsToolTipTitle',
  },
  {
    id: 2,
    title: 'dashboard.interactionsNumber',
    value: null,
    comparison: null,
    Icon: InterActionsIcon,
    toolTipText: 'dashboard.tooltipInterActionsNumber',
    toolTipTitle: 'dashboard.interactionsToolTipTile',
  },
  { id: 3, title: 'dashboard.averageRate', value: null, Icon: AverageRate },
  { id: 4, title: 'dashboard.postsNumber', value: null, Icon: InterActionsIcon },
];

export const detailSearchBoxes = [
  { title: 'dashboard.desktop', value: null, comparison: null, Icon: DesktopSearch },
  { title: 'dashboard.desktopMap', value: null, comparison: null, Icon: DesktopMapSearch },
  { title: 'dashboard.mobile', value: null, comparison: null, Icon: MobileSearch },
  { title: 'dashboard.mobileMap', value: null, comparison: null, Icon: MobileSearchMap },
];

export const detailInteractionsBoxes = [
  { title: 'dashboard.businessDirectionRequests', value: null, comparison: null, Icon: DirectionRequest },
  { title: 'dashboard.callClicks', value: null, comparison: null, Icon: CallClick },
  { title: 'dashboard.websiteClicks', value: null, comparison: null, Icon: WebsiteClick },
  { title: 'dashboard.businessConversations', value: null, comparison: null, Icon: BusinessConversations },
  { title: 'dashboard.businessBookings', value: null, comparison: null, Icon: BusinessBookings },
  { title: 'dashboard.businessFoodMenuClicks', value: null, comparison: null, Icon: BusinessFoodMenu },
  { title: 'dashboard.businessFoodOrders', value: null, comparison: null, Icon: BusinessFoodOrder },
  { title: 'dashboard.dailyMetricUnknown', value: null, comparison: null, Icon: MobileSearchMap },
];
const boxesEnum = {
  views: 'dashboard.viewsNumber',
  interactions: 'dashboard.interactionsNumber',
  averageRate: 'dashboard.averageRate',
  posts: 'dashboard.postsNumber',
};
const titles = [
  'dashboard.desktop',
  'dashboard.desktopMap',
  'dashboard.mobile',
  'dashboard.mobileMap',
  'dashboard.businessDirectionRequests',
  'dashboard.callClicks',
  'dashboard.websiteClicks',
  'dashboard.businessBookings',
  'dashboard.businessConversations',
  'dashboard.businessFoodMenuClicks',
  'dashboard.businessFoodOrders',
  'dashboard.dailyMetricUnknown',
];

export const transformData = (data, boxes) => {
  if (!data) return boxes;
  return boxes.map((box, index) => {
    if (titles.includes(box.title)) {
      return {
        ...box,
        value: data[index]?.value || 0,
        comparison: data[index]?.changePercent || 0,
      };
    }
    return box;
  });
};

export const transformSearchData = (data) => transformData(data, detailSearchBoxes, titles);
export const transformInteractionData = (data) => transformData(data, detailInteractionsBoxes);

const boxValues = {
  [boxesEnum.averageRate]: 'averageRating',
  [boxesEnum.views]: 'totalViews',
  [boxesEnum.interactions]: 'totalInteractions',
  [boxesEnum.posts]: 'totalPosts',
};

const boxComparisons = {
  [boxesEnum.views]: 'viewsChange',
  [boxesEnum.interactions]: 'interactionsChange',
};

export const transformStatisticBoxes = (data) => {
  if (!data) return statisticBoxes;

  return statisticBoxes.map((box) => {
    const valueKey = boxValues[box.title];
    const comparisonKey = boxComparisons[box.title];

    return {
      ...box,
      ...(valueKey && { value: data[valueKey] || '-' }),
      ...(comparisonKey && { comparison: data[comparisonKey] || '-' }),
    };
  });
};

export const transformStatisticData = ({ data, searchDataDetails, additionalInteractions }) => {
  const statisticBoxesWithData = transformStatisticBoxes(data);
  const searchDetailBoxesWithData = transformSearchData(searchDataDetails);
  const interactionBoxesWithData = transformInteractionData(additionalInteractions);

  return {
    statisticBoxesWithData,
    searchDetailBoxesWithData,
    interactionBoxesWithData,
  };
};
