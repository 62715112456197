import ReportImage from '../../../../shared/assets/generateReport.svg';
import LocalPostImage from '../../../../shared/assets/localPostFeatureImage.svg';
import BusinessCardStatistics from '../../../../shared/assets/businessCardStatisticFeature.svg';
import SeoCardImage from '../../../../shared/assets/seoCardsFeatureImage.svg';
import AiAssistant from '../../../../shared/assets/aiAssistant.svg';

export const features = (t, mobile) => {
  const desktopFeatureList = [
    {
      title: t('landingPage.featuresSection.generatingReport'),
      description: t('landingPage.featuresSection.generatingReportDescription'),
      image: ReportImage,
    },
    {
      title: t('landingPage.featuresSection.businessCardStatistics'),
      description: t('landingPage.featuresSection.businessCardStatisticsDescription'),
      image: BusinessCardStatistics,
    },
    {
      title: t('landingPage.featuresSection.localPostPlanning'),
      description: t('landingPage.featuresSection.localPostPlanningDescription'),
      image: LocalPostImage,
    },
    {
      title: t('landingPage.featuresSection.aiAssistant'),
      description: t('landingPage.featuresSection.aiAssistantDescription'),
      image: AiAssistant,
    },
    // TODO: Add this feature when it's ready
    // {
    //   title: t('landingPage.featuresSection.positioningAssistant'),
    //   description: t('landingPage.featuresSection.positioningAssistantDescription'),
    //   image: ReportImage,
    // },
    {
      title: t('landingPage.featuresSection.seoCards'),
      description: t('landingPage.featuresSection.seoCardsDescription'),
      image: SeoCardImage,
    },
  ];

  const mobileFeatureList = [
    {
      title: t('landingPage.featuresSection.aiAssistant'),
      description: t('landingPage.featuresSection.aiAssistantDescription'),
      image: AiAssistant,
    },
    {
      title: t('landingPage.featuresSection.generatingReport'),
      description: t('landingPage.featuresSection.generatingReportDescription'),
      image: ReportImage,
    },
    {
      title: t('landingPage.featuresSection.seoCards'),
      description: t('landingPage.featuresSection.seoCardsDescription'),
      image: SeoCardImage,
    },
    {
      title: t('landingPage.featuresSection.businessCardStatistics'),
      description: t('landingPage.featuresSection.businessCardStatisticsDescription'),
      image: BusinessCardStatistics,
    },
  ];
  return mobile ? mobileFeatureList : desktopFeatureList;
};
