import i18next from 'i18next';
import { CHANGE_KEY_CONFIG } from '../../../views/BusinessProfileChangeHistory/utils';

export const CHANGE_KEY_ENUM = {
  additionalCategories: 'additional_categories',
  additionalPhones: 'additional_phones',
  serviceItems: 'service_items',
  regularHours: 'regular_hours',
  title: 'title',
  primaryPhone: 'primary_phone',
  address: 'address',
  websiteUri: 'website_uri',
  description: 'description',
  labels: 'labels',
  openingDate: 'opening_date',
  serviceArea: 'service_area',
  moreHours: 'more_hours',
};

export const keyChangeList = [{ id: 'businessProfileChangeHistory.all', name: 'businessProfileChangeHistory.all' }]
  .concat(
    Object.keys(CHANGE_KEY_CONFIG).map((key) => ({
      id: key,
      name: CHANGE_KEY_CONFIG[key],
    })),
  )
  .map((item) => {
    if (item.id === CHANGE_KEY_ENUM.moreHours) {
      return { ...item, name: 'businessProfileChangeHistory.moreHoursWithoutType' };
    }
    return item;
  });

export const actionTypeList = [
  { id: 'ALL', name: 'businessProfileChangeHistory.all' },
  {
    id: 'CREATE',
    name: 'businessProfileChangeHistory.create',
  },
  {
    id: 'UPDATE',
    name: 'businessProfileChangeHistory.update',
  },
  {
    id: 'DELETE',
    name: 'businessProfileChangeHistory.delete',
  },
];

export const getTranslatedCollection = (collection) => collection.map((item) => ({ ...item, name: i18next.t(item.name) }));
