import dayjs from 'dayjs';
import { getDateFromObject } from '../../../../../shared/helpers/strings';
import { ErrorCodes } from '../EventForm/utils';
import { DEFAULT_DATE_FORMAT } from '../../../helpers/constants';

export const parseDateForInput = (post) => {
  if (!post || !post.event || !post.event.schedule || !post.event.schedule.startDate || !post.event.schedule.endDate) {
    return '';
  }
  const { startDate, endDate } = post.event.schedule;
  const formatedStartDate = dayjs(getDateFromObject(startDate)).format(DEFAULT_DATE_FORMAT);
  const formatedEndDate = dayjs(getDateFromObject(endDate)).format(DEFAULT_DATE_FORMAT);
  const startEqualEnd = dayjs(formatedStartDate).isSame(formatedEndDate);
  const endDateIsToday = dayjs(formatedEndDate).isSame(dayjs().format(DEFAULT_DATE_FORMAT));
  const isOneDayOffer = startEqualEnd || endDateIsToday;
  return { startDate: formatedStartDate, endDate: formatedEndDate, isOneDayOffer };
};
