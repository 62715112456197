import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as EmptyListIcon } from '../../assets/emptyState.svg';

const EmptyList = ({ title, admin }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      {admin ? <EmptyListIcon /> : null}
      <div className={classes.title}>{title}</div>
    </div>
  );
};

export default EmptyList;
