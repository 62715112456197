import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { formEnum } from '../ModalForm/utils/utils';
import { getSchedule } from '../../../views/AddPost/utils';

const OfferCouponCodeBox = ({ localPost, data }) => {
  const { t } = useTranslation();
  const displayCouponCode = data.type === formEnum.OFFER && localPost?.offer?.couponCode;

  return (
    displayCouponCode && (
    <div className={classes.couponCodeBox}>
      <div className={classes.coupon}>
        {t('posts.showCouponCode')}
        <div className={classes.code}>{localPost?.offer.couponCode}</div>
        <div className={classes.codeBoxSchedule}>{t('posts.oneDayOfferValid', { offerDate: getSchedule(data) })}</div>
      </div>
    </div>
    )
  );
};

export default OfferCouponCodeBox;
