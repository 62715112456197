import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const IconWrapper = ({ className, Icon, color, hoverColor, height, width, onClick, iconPosition = { L: '', B: '', T: '', R: '' } }) => {
  const { L, R, T, B } = iconPosition;
  return (
    <div onClick={onClick} className={clsx(classes.wrapper, className)} style={{ stroke: color, color: hoverColor, left: L, right: R, top: T, bottom: B, }}>
      <Icon height={height} width={width} className={classes.icon} />
    </div>
  );
};
export default IconWrapper;
