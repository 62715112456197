export const changePasswordFields = (t) => [
  {
    key: 'oldPassword',
    label: t('adminControlPanel.oldPassword'),
    placeholder: t('adminControlPanel.passOldPassword'),
  },
  {
    key: 'newPassword',
    label: t('adminControlPanel.newPassword'),
    placeholder: t('adminControlPanel.passNewPassword'),
  },
  {
    key: 'repeatNewPassword',
    label: t('adminControlPanel.repeatNewPassword'),
    placeholder: t('adminControlPanel.passNewPassword'),
  },
];
