import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';

const ArrowButton = ({ direction, onClick }) => {
  const { t } = useTranslation();
  return (
    <div onClick={() => onClick(direction)} className={classes.arrowWrapper}>
      <Arrow className={clsx(classes.arrow, direction === 'left' ? classes.arrowLeft : classes.arrowRight)} />
    </div>
  );
};

export default ArrowButton;
