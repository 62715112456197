import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const WhyIsWorthCard = ({ card, className }) => {
  const { t } = useTranslation();
  const { Icon, title, description } = card;

  return (
    <div className={className}>
      <Icon className={classes.icon} />
      <h3 className={classes.title}>{t(title)}</h3>
      <p className={classes.description}>{t(description)}</p>
    </div>
  );
};

export default WhyIsWorthCard;
