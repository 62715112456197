import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useAuth } from '../../../../shared/helpers/auth';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import { ReactComponent as Notification } from '../../../../shared/assets/bell.svg';
import { ReactComponent as SEOCard } from '../../../../shared/assets/creditCard.svg';
import { ReactComponent as Chevron } from '../../../../shared/assets/chevronDown.svg';
import { useDashboard } from '../../helpers/dashboard';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import Loader from '../Loader';
import SearchInput from '../../../../shared/components/SearchInput';
import Button from '../../../../shared/components/Button';
import DashboardTopbarSubmenu from '../DashboardTopbarSubmenu';
import { useCloseOnOutsideClick } from '../../../../shared/helpers/hooks';

const DashboardTopbar = ({ className }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { selectBusinessProfile, businessProfile } = useDashboard();
  const { api, onLogout } = useApi();
  const { businessProfiles, clearAuth } = useAuth();
  const navigate = useNavigate();
  const subMenuRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [userDetails, setUserDetails] = useState({ accountName: '', pictureUrl: '', email: '' });

  const userDetailsFromLocalStorage = JSON.parse(sessionStorage.getItem('userDetails'));

  useCloseOnOutsideClick(subMenuRef, () => setShowSubMenu(false));

  const showOnListHandler = (value) => value.name;

  const toggleSubMenu = (e) => {
    e.stopPropagation();
    setShowSubMenu((prevState) => !prevState);
  };

  const onSelectProfileHandler = (profile) => {
    selectBusinessProfile(profile.id);
  };

  const navigateToCardList = () => {
    navigate('/dashboard/cards');
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await api.post('/auth/logout', {});
      Object.keys(localStorage).forEach((key) => {
        if (!key.includes('admin')) {
          localStorage.removeItem(key);
        }
      });
      sessionStorage.clear();
      clearAuth();
    } catch (error) {
      handleApiError(error, showNotification);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    const fetchUserDetails = async () => {
      const firstInternalBp = businessProfiles.find((bp) => !bp.externalBusinessProfile);
      setIsLoading(true);
      try {
        const { data } = await api.post(
          '/auth/accountInfo',
          { businessProfileId: firstInternalBp.id || businessProfile.id },
          { signal: controller.signal },
        );
        sessionStorage.setItem('userDetails', JSON.stringify(data));
        setUserDetails(data);
      } catch (err) {
        handleApiError({ err, t, showNotification });
      } finally {
        setIsLoading(false);
      }
    };
    if (!userDetailsFromLocalStorage && businessProfile.id) {
      fetchUserDetails();
    } else {
      setUserDetails(userDetailsFromLocalStorage);
    }
    return () => controller.abort();
  }, [businessProfile.id]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      <Button
        label={t('dashboard.opinieoCard')}
        Icon={SEOCard}
        className={classes.seoCardsBtn}
        onClick={navigateToCardList}
      />
      <SearchInput
        showOnList={showOnListHandler}
        onSelect={onSelectProfileHandler}
        list={businessProfiles}
        label={t('settings.searchForCard')}
        className={classes.searchInput}
        showList
      />
      <div className={classes.notificationIconWrapper}>
        <Notification className={classes.icon} />
      </div>
      <div className={classes.accountDetails} onClick={toggleSubMenu}>
        <img src={userDetails?.pictureUrl} className={classes.userPicture} alt="Business profile" />
        <div className={classes.textWrapper}>
          <div className={classes.accountName}>{userDetails?.accountName}</div>
          <div className={classes.mail}>{userDetails?.email}</div>
        </div>
        <Chevron className={classes.chevron} />
      </div>
      {showSubMenu && (
        <div ref={subMenuRef} className={classes.subMenu}>
          <DashboardTopbarSubmenu onLogout={handleLogout} onClose={toggleSubMenu} />
        </div>
      )}
    </div>
  );
};

export default DashboardTopbar;
