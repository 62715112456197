import Ajv from 'ajv';

export const urlParamsConfig = {
  type: 'object',
  additionalProperties: true,
  required: ['area', 'net', 'distance'],
  properties: {
    businessProfileId: { type: 'number' },
    keywordId: { type: 'number' },
    keyword: { type: 'string' },
    area: { type: 'number', minimum: 1, maximum: 50, multipleOf: 1 },
    net: { type: 'number', enum: [3, 5, 7, 9] },
    distance: { type: 'number', minimum: 1, maximum: 10, multipleOf: 1 },
    reportId: { type: 'number' },
  },
};

export const filtersConfig = {
  required: ['businessProfileId', 'area', 'net', 'distance'],
  additionalProperties: true,
  properties: {
    businessProfileId: { type: 'number' },
    keyword: { type: 'string', minLength: 1 },
    area: { type: 'number' },
    net: { type: 'number' },
    distance: { type: 'number' },
    reportId: { type: 'number' },
    storefrontAddress: {
      type: 'object',
      properties: {
        postalCode: { type: 'string' },
        addressLines: {
          type: 'array',
          items: { type: 'string' },
        },
        locality: { type: 'string' },
      },
      required: ['postalCode', 'addressLines', 'locality'],
    },
  },
};

const ajvFilters = new Ajv({ removeAdditional: false, coerceTypes: false });

const ajvParams = new Ajv({ removeAdditional: true, coerceTypes: true });

const validateParams = ajvParams.compile(urlParamsConfig);

export const validateFilters = ajvFilters.compile(filtersConfig);

export const parseUrlPrams = ({ search }) => {
  const data = Object.fromEntries(Array.from(new URLSearchParams(search).entries()));
  return validateParams(data) ? data : false;
};
