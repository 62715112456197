import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';

const TabNavigator = ({ activeTab, onTabClick, tabs }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      {tabs.map((tab) => (
        <div
          key={tab.label}
          className={clsx(classes.tab, activeTab === tab.label && classes.active)}
          onClick={() => onTabClick(tab)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default TabNavigator;
