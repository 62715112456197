import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { opinionsReplyActionsArray } from '../OpinionItem/utils';
import { useWindowResize } from '../../../../../shared/helpers/hooks';
import { MAX_MOBILE_WIDTH } from '../../../helpers/constants';
import { useDashboard } from '../../../helpers/dashboard';

const OpinionActions = ({ opinion, onReplyClick, modalHandler, aiModalHandler }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { subscription },
  } = useDashboard();

  const isFreeSubPlan = subscription === 'free';

  const actions = opinionsReplyActionsArray(onReplyClick, modalHandler, aiModalHandler, t, subscription);
  const innerWidth = useWindowResize();
  const showBtnLabel = innerWidth >= MAX_MOBILE_WIDTH;
  return (
    <div className={classes.opinionActions}>
      {!isFreeSubPlan
        && actions.map(({ id, onClick, text, Icon }) => (
          <div
            key={id}
            onClick={() => onClick(opinion.id)}
            className={clsx(classes.actionIcon, id === 3 && classes.withAI)}
          >
            <Icon className={classes.icon} />
            {showBtnLabel && text}
          </div>
        ))}
    </div>
  );
};
export default OpinionActions;
