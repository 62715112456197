export const ERROR_ENUM = {
  badRequest: 'ERR_BAD_REQUEST',
  forbiddenLoginPassword: 'FORBIDDEN_LOGIN_OR_PASSWORD',
};

export const ADMIN_PATH_ENUM = {
  getBusinessProfile: 'acp/client/getBusinessProfile',
  localPostStatistic: 'acp/client/localPostStatistic',
  getReviews: 'acp/client/getReviews',
  getMedia: '/acp/client/mediaList',
  businessProfilePerformance: 'acp/client/getPerformance',
  businessProfileKeywords: 'acp/client/getKeywords',
  changeContent: 'acp/client/getBusinessProfileChangeContent',
};
