import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import SearchInput from '../../../../../shared/components/SearchInput';
import { FILTERS_ENUM, MIN_SEARCH_LENGTH } from '../../../views/AdministratorList/utils';
import UserSeoCard from '../UserSeoCard';
import EmptyList from '../../../../../shared/components/EmptyList';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import AdminPagination from '../../../components/AdminPagination';
import AdminLoader from '../../../components/AdminLoader';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { debounce } from '../../../../../shared/helpers/debounce';
import { removeNullValues } from '../../../../../shared/helpers/parsers';

const UserSeoCardList = ({ userData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });
  const [cardList, setCardList] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0 }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const fetchCardData = async () => {
    const filtersWithNoNullValue = removeNullValues(filters);
    try {
      setIsLoading(true);
      const { data } = await api.post('/acp/card/cardList', {
        userId: userData.id,
        filters: filtersWithNoNullValue,
      });
      setCardList(data.results);
      setTotal(data.total);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCardData();
  }, [filters]);

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <AdminLoader />
        </div>
      )}
      {cardList?.length ? (
        <>
          <SearchInput
            className={classes.searchInput}
            onSearch={handleFiltersChange(FILTERS_ENUM.SEARCH)}
            label={t('global.search')}
            value={filters.search}
          />
          <div className={classes.listWrapper}>
            {cardList.map((card) => (
              <UserSeoCard key={card.id} card={card} />
            ))}
          </div>
          <AdminPagination
            noExport
            total={total}
            limit={filters.limit}
            offset={filters.offset}
            disableExport
            className={classes.pagination}
            onLimitChange={handleFiltersChange(FILTERS_ENUM.LIMIT)}
            onPageChange={handleFiltersChange(FILTERS_ENUM.OFFSET)}
          />
        </>
      ) : (
        <EmptyList title={t('adminControlPanel.noCardsToDisplay')} />
      )}
    </div>
  );
};

export default UserSeoCardList;
