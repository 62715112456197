import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Map from '../Map';
import PlaceList from '../PlaceList';
import classes from './styles.module.css';

const Report = ({ className, data, businessProfiles, filters, adminPreview }) => {
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [highlighted, setHighlighted] = useState(null);

  const overAllPosition = data.placeOverallPosition;

  const resetReportHandler = () => {
    setSelectedPoint(null);
    setSelectedPlace(data.placeReferenceId);
  };
  useEffect(() => {
    if (data.placeReferenceId) {
      setSelectedPlace(data.placeReferenceId);
    }
  }, [data.placeReferenceId, data.placeOverallPosition]);

  const shouldRenderResetBtn = selectedPlace !== data.placeReferenceId || selectedPoint;

  return (
    <div className={clsx(classes.wrapper, className, adminPreview && classes.adminPreview)}>
      <div className={classes.report}>
        <PlaceList
          className={classes.list}
          selectedPlace={selectedPlace}
          data={data.points}
          selectedPoint={selectedPoint}
          onPlaceSelect={setSelectedPlace}
          businessProfiles={businessProfiles}
          setHighlighted={setHighlighted}
          overAllPositions={overAllPosition}
          onReset={resetReportHandler}
          shouldRenderResetBtn={shouldRenderResetBtn}
          profileReferencePlaceId={Boolean(data?.placeReferenceId)}
          adminPreview={adminPreview}
        />
        <Map
          className={classes.map}
          highlighted={highlighted}
          area={data.area}
          coordinates={data.points}
          fileName={data.mapUrl}
          date={data.createdAt}
          selectedPlace={selectedPlace}
          selectedPoint={selectedPoint}
          setSelectedPoint={setSelectedPoint}
          filters={filters}
        />
      </div>
    </div>
  );
};

export default Report;
