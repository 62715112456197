import clsx from 'clsx';
import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as NoImage } from '../../assets/noImage.svg';
import { useDashboard } from '../../../app/SEO/helpers/dashboard';

const ProfileList = ({ props }) => {
  const { businessProfile } = useDashboard();
  const { profileListToDisplay, handleBusinessProfile } = props;

  const isSelected = (id) => id === businessProfile.id;

  return profileListToDisplay?.map(({ id, image }) => (
    <div key={id} className={classes.profile} onClick={() => handleBusinessProfile(id)}>
      {image ? (
        <img src={image} alt="business-profile" className={clsx(classes.image, isSelected(id) && classes.selected)} />
      ) : (
        <NoImage className={clsx(classes.image, classes.noImage, isSelected(id) && classes.selected)} />
      )}
    </div>
  ));
};

export default ProfileList;
