import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { getReviewerInitials, generateRandomColor } from './utils';
import Stars from '../../../../../shared/components/Stars';

const BusinessOpinionItem = ({ review: { rating, reviewerPhoto, reviewText, createdAt, reviewerName } }) => {
  const { t } = useTranslation();

  const reviewerInitials = useMemo(() => getReviewerInitials(reviewerName), [reviewerName]);
  const randomColor = useMemo(() => generateRandomColor(), []);

  return (
    <div className={classes.opinion}>
      <div className={classes.opinionHeader}>
        <div className={classes.reviewerBox}>
          {reviewerPhoto ? (
            <img src={reviewerPhoto} alt="reviewer" className={classes.reviewerPhoto} />
          ) : (
            <div style={{ background: randomColor }} className={classes.reviewerPhoto}>
              {reviewerInitials}
            </div>
          )}
          <span className={classes.reviewerName}>{reviewerName}</span>
        </div>
        <div className={classes.opinionAndStarsBox}>
          <Stars rating={rating} />
          <span className={classes.opinionDate}>{t('dashboard.opinionAdded', { date: createdAt })}</span>
        </div>
      </div>
      <div className={classes.opinionText}>{reviewText}</div>
      <div className={classes.opinionValue} />
    </div>
  );
};

export default BusinessOpinionItem;
