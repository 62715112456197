import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import classes from './styles.module.css';
import AdminNavigationSections from '../AdminNavigationSections';
import { ReactComponent as LogoSeo } from '../../../../shared/assets/logo.svg';
import TopbarMenu from '../TopbarMenu';
import ViewTitle from '../ViewTitle';
import { NotificationContext } from '../../../../shared/helpers/notification';
import Notification from '../../../../shared/components/Notification';

const AdminLayout = () => {
  const [notificationQueue, setNotificationQueue] = useState([]);

  const handleClose = () => {
    setNotificationQueue((prev) => {
      const [first, ...rest] = prev;
      return rest;
    });
  };

  const providerValue = useMemo(() => {
    const showNotification = (notification) => {
      setNotificationQueue((prev) => [...prev, { ...notification, key: Math.random() }]);
    };
    return { showNotification };
  }, []);

  return (
    <NotificationContext.Provider value={providerValue}>
      <div className={classes.wrapper}>
        <div className={classes.sideNavigator}>
          <LogoSeo className={classes.logoIcon} />
          <AdminNavigationSections />
        </div>
        <div className={classes.mainSection}>
          <div className={classes.topbar}>
            <TopbarMenu />
          </div>
          <Outlet />
        </div>
      </div>
      {notificationQueue[0] && (
        <Notification
          label={notificationQueue[0].label}
          message={notificationQueue[0].message}
          type={notificationQueue[0].type}
          payload={notificationQueue[0].payload}
          key={notificationQueue[0].key}
          onClose={handleClose}
        />
      )}
    </NotificationContext.Provider>
  );
};

export default AdminLayout;
