import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as Magnifier } from '../../../../shared/assets/magnifier.svg';

const NoAccessInformation = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.iconWrapper}>
        <Magnifier />
      </div>
      {t('global.noAccessSubTitle')}
      <p>{t('global.noAccessSubInformation')}</p>
    </div>
  );
};

export default NoAccessInformation;
