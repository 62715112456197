import { ReactComponent as Reply } from '../../../../../shared/assets/chat.svg';
import { ReactComponent as ReplyWithTemplate } from '../../../../../shared/assets/templateAnswer.svg';
import { ReactComponent as Delete } from '../../../../../shared/assets/bin.svg';
import { ReactComponent as Edit } from '../../../../../shared/assets/editPost.svg';
import { ReactComponent as ReplyWithAI } from '../../../../../shared/assets/aiReply.svg';

export const opinionsReplyActionsArray = (onReply, modalHandler, aiModalHandler, t, subscription) => {
  const actions = [
    {
      id: 1,
      Icon: Reply,
      text: t('opinions.reply'),
      onClick: onReply,
    },
    {
      id: 2,
      Icon: ReplyWithTemplate,
      text: t('opinions.replyWithTemplate'),
      onClick: modalHandler,
    },
  ];

  if (subscription !== 'mini') {
    actions.push({
      id: 3,
      Icon: ReplyWithAI,
      text: t('opinions.replyWithAI'),
      onClick: aiModalHandler,
    });
  }

  return actions;
};

export const opinionsNativeReplyActionsArray = (onEditClick, deleteReplyHandler, t) => [
  {
    id: 1,
    Icon: Edit,
    text: t('global.edit'),
    onClick: onEditClick,
  },
  {
    id: 2,
    Icon: Delete,
    text: t('global.delete'),
    onClick: deleteReplyHandler,
  },
];
