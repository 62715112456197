import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';

const BusinessProfileModalHeader = ({ profileImage, title, address, onClose }) => (
  <div className={classes.wrapper}>
    <div className={classes.leftSide}>
      <img src={profileImage} alt="business profile logo" className={classes.businessImage} />
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.address}>{address}</div>
      </div>
    </div>
    <CloseIcon onClick={onClose} className={classes.closeIcon} />
  </div>
);

export default BusinessProfileModalHeader;
