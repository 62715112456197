import i18next from 'i18next';
import { getSchedule } from '../../../views/AddPost/utils';
import { buttonActions } from '../ModalForm/utils/changeDataFieldHandles';
import { formEnum } from '../ModalForm/utils/utils';

export const getCallToActionData = (callToAction) => {
  const btnLabel = i18next.t(buttonActions.find((button) => button.googleID === callToAction?.actionType)?.name) || null;
  return { btnLabel, btnUrl: callToAction?.url || '' };
};
const isPostOnline = (post) => post.state === 'LIVE';
const isPostWithMedia = (post) => Boolean(post?.media?.length);
const getDisplayDate = (post) => post.parsedPublishDate || post.parsedDate;

const getStandardPostData = (post, t) => {
  const { localPost } = post;
  const isOnline = isPostOnline(localPost);
  const postWithMedia = isPostWithMedia(localPost);
  const callToActionBtn = buttonActions.find((button) => button.googleID === localPost?.callToAction?.actionType) || null;

  const data = {
    type: post.topicType,
    summary: localPost.summary,
    owner: localPost.owner,
    state: localPost.state,
    postID: localPost.postID,
    displayDate: getDisplayDate(post),
  };

  if (callToActionBtn) {
    data.btnLabel = t(callToActionBtn.name);
    data.btnUrl = localPost.callToAction.url;
  }

  if (postWithMedia) {
    data.media = post.media.map((el) => ({ ...el, mediaUrl: el.googleUrl }));
  }

  if (isOnline) {
    data.googleUrl = post.searchUrl;
  }
  return data;
};

const getOfferPostData = (post) => {
  const isOnline = isPostOnline(post);
  const postWithMedia = isPostWithMedia(post);

  const data = {
    type: post.topicType,
    title: post.event.title,
    postValid: getSchedule(post),
    summary: post.summary,
    offerReedemOnline: post.offer?.redeemOnlineUrl,
    offerCouponCode: post.offer?.couponCode,
    termsConditions: post.offer?.termsConditions,
    state: post.state,
    postID: post.postID,
    displayDate: getDisplayDate(post),
    schedule: post.event.schedule,
  };

  if (isOnline) {
    data.googleUrl = post.searchUrl;
  }
  if (postWithMedia) {
    data.media = post.media.map((el) => ({ ...el, mediaUrl: el.googleUrl }));
  }
  return data;
};

const getEventData = (post, t) => {
  const isOnline = isPostOnline(post);
  const postWithMedia = isPostWithMedia(post);
  const callToActionBtn = buttonActions.find((button) => button.googleID === post?.callToAction?.actionType) || null;
  const data = {
    type: post.topicType,
    title: post.event.title,
    summary: post.summary,
    postValid: getSchedule(post),
    owner: post.owner,
    state: post.state,
    postID: post.postID,
    displayDate: getDisplayDate(post),
    schedule: post.event.schedule,
  };
  if (callToActionBtn) {
    data.btnLabel = t(callToActionBtn.name);
    data.btnUrl = post.callToAction.url;
  }
  if (isOnline) {
    data.googleUrl = post.searchUrl;
  }
  if (postWithMedia) {
    data.media = post.media.map((el) => ({ ...el, mediaUrl: el.googleUrl }));
  }
  return data;
};

const dataByType = (post, t) => {
  const data = {
    [formEnum.STANDARD]: () => getStandardPostData(post, t),
    [formEnum.OFFER]: () => getOfferPostData(post, t),
    [formEnum.EVENT]: () => getEventData(post, t),
    [formEnum.ALERT]: () => getEventData(post, t),
  };
  return data[post?.type || post?.topicType]();
};

export const preparePostItemData = (post, t) => dataByType(post, t);
