import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const PillWithIcon = ({ className, children, onClick }) => (
  <div
    onClick={onClick}
    className={clsx(className)}
  >
    {children}
  </div>
);

export default PillWithIcon;
