import classes from './styles.module.css';
import { ReactComponent as AlertCircle } from '../../assets/alertCircle.svg';
import { ReactComponent as CheckCircle } from '../../assets/checkCircle.svg';
import { ReactComponent as Bell } from '../../assets/bell.svg';

export const notificationConfig = {
  success: {
    type: 'success',
    label: 'Success',
    message: 'Success',
    payload: {},
    className: classes.wrapperSuccess,
    bodyClassName: classes.bodySuccess,
    Icon: CheckCircle,
  },
  error: {
    type: 'error',
    label: 'Error',
    message: 'Error',
    payload: {},
    className: classes.wrapperError,
    bodyClassName: classes.bodyError,
    Icon: AlertCircle,
  },
  warning: {
    type: 'warning',
    label: 'Warning',
    message: 'Warning',
    payload: {},
    className: classes.wrapperWarning,
    bodyClassName: classes.bodyWarning,
    Icon: Bell,
  },
};
