import dayjs from 'dayjs';

export const getParsedDateString = (filters) => {
  const { dateFrom, dateTo } = filters;
  const dateToFomatted = dateTo ? dayjs(dateTo).format('DD.MM.YY') : '';
  const dateFromFomatted = dateFrom ? dayjs(dateFrom).format('DD.MM.YY') : '';
  if (!filters.dateFrom && !filters.dateTo) {
    return '';
  }

  if (filters.dateFrom === filters.dateTo) {
    return dateToFomatted;
  }
  return `${dateFromFomatted} - ${dateToFomatted}`;
};
