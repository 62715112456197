import { ReactComponent as SuccessIcon } from '../../assets/check.svg';
import { ReactComponent as ErrorIcon } from '../../assets/close.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';

export const FormStatusEnum = {
  success: 'success',
  error: 'error',
  warning: 'warning',
};

export const formSuccessIndicatorConfig = {
  success: SuccessIcon,
  error: ErrorIcon,
  warning: InfoIcon,
};
