import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../Button';
import Modal from '../Modal';
import { ReactComponent as BurgerMenu } from '../../assets/menu.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';
import { ReactComponent as SEOCard } from '../../assets/creditCard.svg';
import { ReactComponent as Notification } from '../../assets/bell.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { handleApiError } from '../../../app/SEO/modules/Settings/ChangePassword/utils';
import { useApi } from '../../helpers/api';
import { useAuth } from '../../helpers/auth';
import { useNotification } from '../../helpers/notification';
import SidebarUserMenu from '../SidebarUserMenu';

const SidebarHeader = ({ businessProfile, onMobileMenuClick, showBusinessCards, showList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api, onLogout } = useApi();
  const { clearAuth } = useAuth();
  const { showNotification } = useNotification();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { address, image, name } = businessProfile;

  const navigateToCardList = () => {
    navigate('/dashboard/cards');
  };
  const toggleUserMenu = () => {
    setShowUserMenu((prev) => !prev);
  };

  return (
    <div className={classes.wrapper}>
      {showUserMenu && <SidebarUserMenu toggleUserMenu={toggleUserMenu} />}
      <div className={classes.rowContainer}>
        <Button
          label={t('dashboard.opinieoCard')}
          Icon={SEOCard}
          className={classes.seoCardsBtn}
          onClick={navigateToCardList}
        />
        <div className={classes.rightSide}>
          <div className={classes.notificationIconWrapper}>
            <Notification className={classes.icon} />
          </div>
          <div onClick={toggleUserMenu} className={classes.userIconWrapper}>
            <User className={classes.userIcon} />
          </div>
        </div>
      </div>
      <div className={classes.header}>
        <BurgerMenu onClick={onMobileMenuClick} className={classes.menu} />
        <div className={classes.info}>
          {image && <img src={image} alt="business profile" className={classes.image} />}
          <div className={classes.businessDetails}>
            <div className={classes.name}>{name}</div>
            <div className={classes.address}>{address}</div>
          </div>
        </div>
        <ChevronDown
          onClick={showList}
          className={clsx(classes.chevronIconTopBar, showBusinessCards && classes.invertedIcon)}
        />
      </div>
    </div>
  );
};

export default SidebarHeader;
