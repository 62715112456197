import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import { ServicesTypeEnum } from '../ServicesForm/utils';
import InformationTextBox from '../../../../../shared/components/InformationTextBox';
import CustomService from '../CustomService';
import Radio from '../../../../../shared/components/Radio';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';

const ModalServicesForCategory = ({ onClose, onSave, selectedCategory }) => {
  const [category, setCategory] = useState(selectedCategory || null);
  const { t } = useTranslation();
  const [customServices, setCustomServices] = useState([]);
  const [serviceAlreadyExist, setServiceAlreadyExist] = useState(false);
  useEffect(() => {
    const services = category.serviceTypes.map((service) => service.displayName.toLowerCase());
    const serviceExist = customServices.some((el) => services.includes(el.displayName.toLowerCase()));
    setServiceAlreadyExist(serviceExist);
  }, [customServices, category]);

  const onAddCustomService = () => {
    setCustomServices((prev) => [
      ...prev,
      { serviceTypeId: Math.random(), displayName: '', type: ServicesTypeEnum.FREE_FORM_SERVICE_ITEM },
    ]);
  };

  const deleteCustomInputHandler = (id) => setCustomServices((prev) => prev.filter((el) => el.serviceTypeId !== id));

  const onAddHandler = () => {
    const newCategory = {
      ...category,
      serviceTypes: [...category.serviceTypes, ...customServices.filter((el) => el.displayName !== '')],
    };
    onSave(newCategory);
    onClose();
  };

  const onCustomServiceChangeHandler = (id, value) => {
    if (customServices.some((el) => el.displayName === value && !!value)) {
      setServiceAlreadyExist(true);
      return;
    }

    setCustomServices((prev) => {
      if (prev.some((el) => el.serviceTypeId === id)) {
        return prev.map((el) => (el.serviceTypeId === id
          ? { ...el, displayName: value, selected: true, category: selectedCategory.categoryReferenceId }
          : el),);
      }
      return [
        ...prev,
        {
          id,
          serviceTypeId: Math.random(),
          displayName: value,
          type: ServicesTypeEnum.FREE_FORM_SERVICE_ITEM,
          category: selectedCategory.categoryReferenceId,
        },
      ];
    });
  };

  const serviceClickHandler = (id) => {
    setCategory((prev) => ({
      ...prev,
      serviceTypes: prev.serviceTypes.map((el) => (el.serviceTypeId === id ? { ...el, selected: !el.selected } : el)),
    }));
  };

  const onModalClose = () => {
    setCategory(null);
    onClose();
  };
  const radioClickHandler = (id) => {
    serviceClickHandler(id);
  };
  const onCloseIconClickHandler = (id) => {
    const clickedService = category.serviceTypes.find((el) => el.serviceTypeId === id);
    if (clickedService.type === ServicesTypeEnum.FREE_FORM_SERVICE_ITEM) {
      setCategory((prev) => ({ ...prev, serviceTypes: prev.serviceTypes.filter((el) => el.serviceTypeId !== id) }));
      setCustomServices((prev) => prev.filter((el) => el.serviceTypeId !== id));
    }
  };
  const structuredServicesLength = category.serviceTypes.filter(
    (el) => el.type !== ServicesTypeEnum.FREE_FORM_SERVICE_ITEM,
  ).length;
  const readOnlyButton = !category || serviceAlreadyExist;
  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.categoryType}>{selectedCategory.type}</div>
        <div className={classes.categoryTitle}>{selectedCategory.name}</div>
      </div>
      {Boolean(selectedCategory.serviceTypes.length) && (
        <>
          <div className={classes.addServices}>{t('businessProfileEdit.addOfferedServices')}</div>
          <div className={classes.servicesList}>
            {category.serviceTypes.map((el) => (
              <div key={el.serviceTypeId} onClick={() => serviceClickHandler(el.serviceTypeId)}>
                <PillWithIcon className={clsx(classes.servicePill, el.selected && classes.selectedService)}>
                  <Radio onClick={() => radioClickHandler(el.serviceTypeId)} value={el.selected} />
                  <div className={classes.displayName}>{el.displayName}</div>
                  <CloseIcon onClick={() => onCloseIconClickHandler(el.serviceTypeId)} className={classes.closeIcon} />
                </PillWithIcon>
              </div>
            ))}
          </div>
        </>
      )}
      <div className={classes.horizontalLine} />
      {Boolean(!category.serviceTypes.length) && (
        <div className={classes.noServices}>
          <InformationTextBox
            noIcon
            customStyle={classes.noServices}
            text={t('businessProfileEdit.noServicesForCategory')}
          />
        </div>
      )}
      <CustomService
        alreadyExist={serviceAlreadyExist}
        onCustomServiceChangeHandler={onCustomServiceChangeHandler}
        customServices={customServices}
        deleteCustomInputHandler={deleteCustomInputHandler}
        onAddCustomService={onAddCustomService}
        structuredServicesLength={structuredServicesLength}
      />

      <div className={classes.actionsWrapper}>
        <Button onClick={onModalClose} className={classes.cancelButton} label={t('businessProfileEdit.cancel')} />
        <Button
          onClick={onAddHandler}
          className={classes.saveChangesButton}
          readOnly={readOnlyButton}
          label={t('businessProfileEdit.addServices')}
        />
      </div>
    </div>
  );
};

export default ModalServicesForCategory;
