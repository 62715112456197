const attachPhotos = ({ places, images }) => places.map((place) => ({
  ...place,
  photo: images.find((image) => image.id === place.id)?.image ?? null,
  state: images.find((image) => image.id === place.id)?.state ?? 'loading',
}));

export const parsePlaces = ({ data, selectedPoint, images, businessProfiles, overAllPositions }) => {
  const allPlaces = selectedPoint
    ? data.find((el) => el.id === selectedPoint)?.places
    : data.flatMap((el) => el.places);
  const allParsed = [...new Map(allPlaces.map((place) => [place.id, place])).values()];
  if (!selectedPoint) {
    const allParsedWithOverAllPosition = allParsed.map((place) => ({
      ...place,
      placeOverallPosition: overAllPositions.find((el) => el.placeReferenceId === place.placeReferenceId)
        .overallOrder,
    }));
    const sorted = allParsedWithOverAllPosition.sort((a, b) => a.placeOverallPosition - b.placeOverallPosition);
    return attachPhotos({ places: sorted, images });
  }
  const allParsedWithOwner = allParsed.map((place) => ({
    ...place,
    owner: businessProfiles.some((profile) => place.name.includes(profile.name)),
  }));
  return attachPhotos({ places: allParsedWithOwner, images });
};

export const sortList = [
  { id: 1, name: 'setFilters.fromBest' },
  { id: 2, name: 'setFilters.fromWorst' },
];
