import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';

const VerificationActions = ({ handleVerification, standAlone }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className={clsx(classes.container, standAlone && classes.standAloneContainer)}>
      <Button
        onClick={navigateToDashboard}
        className={classes.verifyLaterBtn}
        label={t('businessProfileCreate.verifyLater')}
      />
      <Button onClick={handleVerification} className={classes.verifyBtn} label={t('businessProfileCreate.verify')} />
    </div>
  );
};

export default VerificationActions;
