import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { shouldColorStepper, stepperContent } from './utils';

const Stepper = ({ activeStep }) => {
  const { t } = useTranslation();

  const translatedSteperContent = stepperContent(t);
  const { isActiveStep, lineInColor } = shouldColorStepper(activeStep);

  return (
    <div className={classes.horizontalStepper}>
      <div className={classes.stepperTitle}>{t('businessProfileCreate.stepperTitle')}</div>
      <div className={classes.stepsBox}>
        {translatedSteperContent.map(({ stepValue, title }) => (
          <div key={title} className={classes.stepWrapper}>
            <div
              className={clsx(
                classes.stepNumber,
                isActiveStep(stepValue) && classes.activeStep,
                lineInColor(stepValue) && classes.activeLine,
              )}
            >
              {stepValue}
            </div>
            <div className={classes.stepTitle}>{title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
