import React, { useState } from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';

const ExpandableSection = ({ sectionName, children, Icon, iconWidth, iconHeight, sectionNameStyle, wrapperStyle }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded((prevState) => !prevState);

  return (
    <div className={clsx(classes.expandableSection, wrapperStyle, !isExpanded && classes.shortBox)}>
      <div
        onClick={toggleExpand}
        className={clsx(classes.sectionTitle, sectionNameStyle, isExpanded && classes.expandedSectionName)}
      >
        <div className={clsx(classes.sectionName)}>
          {Icon && <Icon width={iconWidth} height={iconHeight} />}
          {sectionName}
        </div>
        <div className={clsx(classes.expandIconWrapper)}>
          <ChevronDown className={clsx(classes.expandIcon, isExpanded && classes.reversed)} />
        </div>
      </div>
      {isExpanded && <div>{children}</div>}
    </div>
  );
};

export default ExpandableSection;
