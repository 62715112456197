import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Input from '../../../../../shared/components/Input';
import classes from './styles.module.css';

const KeywordsInput = ({ onAdd, className, disabled, inputLabel, inputClassName, maxLength, onChange, selectedKeyword }) => {
  const [value, setValue] = useState('');
  const inputChangeHandler = (val) => {
    setValue(val);
    onChange(val);
  };
  const handleAdd = () => {
    onAdd(value);
    setValue('');
    onChange('');
  };
  useEffect(() => {
    setValue(selectedKeyword);
  }, [selectedKeyword]);
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !disabled) {
      handleAdd();
    }
  };
  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.inputWithCounter}>
        <Input label={inputLabel} onKeyDown={handleKeyDown} className={clsx(classes.input, inputClassName)} onChange={inputChangeHandler} value={value} maxLength={maxLength} />
      </div>

    </div>
  );
};

export default KeywordsInput;
