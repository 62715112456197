import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/paginationArrow.svg';
import classes from './styles.module.css';
import Button from '../Button';

const limitList = [10, 30, 50];

const Pagination = ({
  total,
  page,
  onPageChange,
  onLimitChange,
  limit,
  className,
  paginationLimitList,
  withExport,
  exportHandler,
}) => {
  const ref = useRef();
  const limitListPagination = paginationLimitList || limitList;
  const [showLimitList, setShowLimitList] = useState(false);
  const { t } = useTranslation();
  const hideList = useCallback((event) => {
    if (!ref.current.contains(event.target)) {
      setShowLimitList(false);
      document.removeEventListener('click', hideList);
    }
  }, []);

  const handleClick = () => {
    if (showLimitList) {
      setShowLimitList(false);
      document.removeEventListener('click', hideList);
    } else {
      setShowLimitList(true);
      document.addEventListener('click', hideList);
    }
  };

  useEffect(
    () => () => {
      document.removeEventListener('click', hideList);
    },
    [],
  );

  const handleSelectLimit = (value) => () => {
    onLimitChange(value);
  };

  const handlePageChange = (value) => () => {
    if (page + value > 0 && (page - 1 + value) * limit < total) {
      onPageChange(page + value);
    }
  };
  const pageScore = Number(`${page * limit}`) > total ? total : `${(page - 1) * limit + 1}-${page * limit}`;

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.limit} ref={ref} onClick={handleClick}>
        {showLimitList && (
          <div className={classes.list}>
            {limitListPagination.map((el) => (
              <div key={`limit-option-${el}`} className={classes.option} onClick={handleSelectLimit(el)}>
                {el}
              </div>
            ))}
          </div>
        )}
        {`${t('reportList.paginationItems')}`}
        <div className={classes.limitAmountBox}>
          {limit}
          <ArrowIcon className={clsx(classes.arrow, classes.down, showLimitList && classes.up)} width="10px" />
        </div>
      </div>
      {!withExport ? (
        <div className={classes.total}>{`${pageScore} ${t('reportList.paginationOf')} ${total}`}</div>
      ) : (
        <div className={classes.arrows}>
          <ArrowIcon className={clsx(classes.arrow, classes.left)} onClick={handlePageChange(-1)} />
          <div className={classes.total}>{`${pageScore} ${t('reportList.paginationOf')} ${total}`}</div>
          <ArrowIcon className={clsx(classes.arrow, classes.right)} onClick={handlePageChange(1)} />
        </div>
      )}
      {!withExport ? (
        <div className={classes.arrows}>
          <ArrowIcon className={clsx(classes.arrow, classes.left)} onClick={handlePageChange(-1)} />
          <ArrowIcon className={clsx(classes.arrow, classes.right)} onClick={handlePageChange(1)} />
        </div>
      ) : (
        <Button label={t('global.export')} className={classes.exportDataBtn} onClick={exportHandler} />
      )}
    </div>
  );
};

export default Pagination;
