import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import NavigationBar from '../NavigationBar';
import classes from './styles.module.css';

const TermsOfService = () => {
  const { t } = useTranslation();

  const sections = [
    { key: 'definitions', data: t('termsOfService.definitions', { returnObjects: true }) },
    { key: 'electronicServices', data: t('termsOfService.electronicServices', { returnObjects: true }) },
    { key: 'clientAccount', data: t('termsOfService.clientAccount', { returnObjects: true }) },
    { key: 'newsLetter', data: t('termsOfService.newsLetter', { returnObjects: true }) },
    {
      key: 'deliveryElectronicServices',
      data: t('termsOfService.deliveryElectronicServices', { returnObjects: true }),
    },
    { key: 'couponCode', data: t('termsOfService.couponCode', { returnObjects: true }) },
    { key: 'prices', data: t('termsOfService.prices', { returnObjects: true }) },
    { key: 'payments', data: t('termsOfService.payments', { returnObjects: true }) },
    { key: 'delivery', data: t('termsOfService.delivery', { returnObjects: true }) },
    { key: 'complaint', data: t('termsOfService.complaint', { returnObjects: true }) },
    { key: 'judgementSolution', data: t('termsOfService.judgementSolution', { returnObjects: true }) },
    { key: 'withdrawalContract', data: t('termsOfService.withdrawalContract', { returnObjects: true }) },
    { key: 'personalData', data: t('termsOfService.personalData', { returnObjects: true }) },
    { key: 'finalProvisions', data: t('termsOfService.finalProvisions', { returnObjects: true }) },
  ];
  const renderTitle = (title) => <h3 key={title}>{title}</h3>;

  const renderSection = (data) => Object.keys(data).map((key) => {
    const isList = Array.isArray(data[key]);
    const isTitle = key.includes('title');
    const isObject = typeof data[key] === 'object' && !isList;
    const descriptionKey = `${key}Description`;
    const definitionsTitle = t('termsOfService.definitions.title');

    const renderList = (items) => (
      <ul>
        {items.map((item) => (
          <li className={classes.noDecor} key={item.key}>
            {typeof item === 'object' ? renderSection(item) : item}
          </li>
        ))}
      </ul>
    );
    if (data.title === definitionsTitle) {
      if (isTitle) {
        return renderTitle(data[key]);
      }
      if (!data[descriptionKey]) {
        return null;
      }
      return (
        <div key={key}>
          <p>
            <b>{data[key]}</b>
            {data[descriptionKey]}
          </p>
        </div>
      );
    }

    if (isTitle) {
      return renderTitle(data[key]);
    }

    if (isList) {
      return renderList(data[key]);
    }

    if (isObject) {
      return <div key={key}>{renderSection(data[key])}</div>;
    }

    return <p key={key}>{data[key]}</p>;
  });

  return (
    <div>
      <NavigationBar hideSectionList className={classes.bgNavbar} />
      <div className={classes.wrapper}>
        <h2>{t('termsOfService.title')}</h2>
        <h3>{t('termsOfService.1.title')}</h3>
        <p>{t('termsOfService.1.1.1')}</p>
        <p>{t('termsOfService.1.1.2.title')}</p>
        <ul>
          <li>{t('termsOfService.1.1.2.1.2.1')}</li>
          <li>{t('termsOfService.1.1.2.1.2.2')}</li>
          <li>{t('termsOfService.1.1.2.1.2.3')}</li>
        </ul>
        <p>{t('termsOfService.1.1.3')}</p>
        <p>{t('termsOfService.1.1.4')}</p>
        <p>{t('termsOfService.1.1.5')}</p>
        <p>{t('termsOfService.1.1.6')}</p>
        <p>{t('termsOfService.1.1.7')}</p>
        <p>{t('termsOfService.1.1.8')}</p>

        {sections.map(({ key, data }) => (
          <div key={key}>{renderSection(data)}</div>
        ))}
      </div>
    </div>
  );
};

export default TermsOfService;
