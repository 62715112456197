import dayjs from 'dayjs';
import { buttonActionsEnum } from '../ModalForm/utils/changeDataFieldHandles';
import { POST_STATE_ENUM, formEnum } from '../ModalForm/utils/utils';

export const getPostDetails = (post, t) => {
  const previewSubInfo = post.state === POST_STATE_ENUM.PLANNED
    ? t('posts.plannedDateRelease', { date: dayjs(post.publishDate).format('DD.MM.YYYY') })
    : t('posts.postAdded', { date: dayjs(post.createTime).format('DD.MM.YYYY') });

  const shouldRenderUrl = post?.callToAction
    && ![buttonActionsEnum.ACTION_TYPE_UNSPECIFIED, buttonActionsEnum.CALL].includes(post.callToAction.actionType);

  const offerIsValid = t('posts.oneDayOfferValid', { offerDate: post.offerScheduleInformation });
  const eventIsValid = t('posts.oneDayEventValid', { eventDate: post.offerScheduleInformation });
  const isValid = post.topicType === formEnum.OFFER ? offerIsValid : eventIsValid;

  return { previewSubInfo, shouldRenderUrl, isValid };
};
