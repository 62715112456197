export const TABLE_HEADERS_ENUM = {
  lastName: 'lastName',
  name: 'name',
  login: 'login',
  nip: 'nip',
  phoneNumber: 'phoneNumber',
  lastLogin: 'lastLogin',
  seo: 'seo',
  subscriptionDate: 'subscriptionDate'
};

export const SORT_ADMIN_COLUMNS = {
  login: 'login',
  lastLogin: 'last_login',
  lastName: 'last_name',
  seo: 'seo',
  nip: 'nip',
  phoneNumber: 'phone',
  businessProfiles: 'business_profiles',
};
