export const getVisibilityMessage = ({ position }) => {
  if (position < 10) {
    return 'Wysoka';
  }
  if (position < 20) {
    return 'Średnia';
  }
  return 'Niska';
};

export const stateEnum = {
  LOADING: 'loading',
  LOADED: 'loaded',
  REJECTED: 'rejected',
};
