import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';

const DesactivateUserConfirmationModal = ({ onClose, desactivateAccountHandler }) => {
  const { t } = useTranslation();

  return (
    <Modal
      onCancel={onClose}
      modalTitle={t('adminControlPanel.desactivateAccount')}
      contentStyle={classes.modalWrapper}
      titleStyle={classes.modalTitleStyle}
      customIconStyle={classes.customIconStyle}
    >
      <div className={classes.contentWrapper}>{t('adminControlPanel.descactivateAccountModalInfo')}</div>
      <Button label={t('global.confirm')} className={classes.confirmBtn} onClick={desactivateAccountHandler} />
    </Modal>
  );
};

export default DesactivateUserConfirmationModal;
