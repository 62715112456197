import React, { useState } from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const Tooltip = ({ text, children, isEdit, className, tooltipStyle, toolTipTitle }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  return (
    <div
      className={clsx(classes.tooltipWrapper, className)}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onTouchStart={showTooltip}
      onTouchEnd={hideTooltip}
    >
      {children}
      {isTooltipVisible && !isEdit && (
      <div className={tooltipStyle}>
        <div className={classes.title}>{toolTipTitle}</div>
        <p>{text}</p>
      </div>
      )}
    </div>
  );
};

export default Tooltip;
