import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import Select from '../../../../../shared/components/Select';
import Loader from '../../../components/Loader';
import { makeRequired } from '../../../../../shared/components/Input/validators';
import { COUNTRIES, SMALL_MOBILE_WIDTH } from '../../../helpers/constants';
import { useApi } from '../../../../../shared/helpers/api';
import { debounce } from '../../../../../shared/helpers/debounce';
import { useNotification } from '../../../../../shared/helpers/notification';
import { formEnum } from '../WelcomeScreenCreate/utils';
import { filterCountries } from '../FormCountryLocation/utils';
import CreateBusinessProfileTextWrapper from '../CreateBusinessProfileTextWrapper';
import BusinessLocationPreviewMap from '../BusinessLocationPreviewMap';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { encodeAddress, getBusinessLocationInputs, getLatLong } from './utils';
import { ReactComponent as BusinessLocationSvg } from '../../../../../shared/assets/businessLocation.svg';
import { useWindowResize } from '../../../../../shared/helpers/hooks';

const FormBusinessLocation = ({ onChange, form, scrollOnFocus, readOnlyHandler }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const innerWidth = useWindowResize();
  const { showNotification } = useNotification();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState({
    postalCode: '',
    locality: '',
    addressLines: [],
  });
  const isNotMobileScreen = innerWidth > SMALL_MOBILE_WIDTH;
  const isMapPreviewVisible = form.latlng.latitude && isNotMobileScreen;
  const { postalCode, addressLines, locality } = location;
  const isAddressFullyProvided = postalCode && addressLines && locality;

  const translatedBusinessLocationInputs = getBusinessLocationInputs(t);

  const handleCountry = (value) => {
    if (value) {
      setSelectedCountry(value);
    }
  };
  const confirmAdrresHandler = (latlong) => {
    onChange(formEnum.latlng)(latlong);
  };

  const filterCountriesHandler = useCallback(({ list, value }) => filterCountries(list, value, t), []);

  const handleLocation = (name) => (value) => {
    if (location[name] === value) {
      return;
    }
    setLocation((prev) => {
      const current = {
        ...prev,
        [name]: name === 'addressLines' ? [value] : value,
      };

      return current;
    });
  };

  const handleGetAddress = useCallback(
    debounce(async (payload) => {
      setIsLoading(true);
      const data = await encodeAddress(payload.value, api, setIsLoading, showNotification, t);
      setIsLoading(false);
      return data;
    }, 1000),
    [],
  );

  useEffect(() => {
    const fetchLatLong = async () => {
      if (isAddressFullyProvided) {
        try {
          const data = await getLatLong(location, isAddressFullyProvided, handleGetAddress);
          if (Number(data?.lat) && Number(data?.lon)) {
            onChange(formEnum.latlng)({
              lat: data.lat,
              lng: data.lon,
            });
          } else {
            showNotification({ type: 'error', message: t('businessProfileCreate.addressNotFound') });
          }
        } catch (err) {
          showNotification({ type: 'error', message: t('errors.somethingWentWrong') });
        }
      }
    };

    fetchLatLong();
  }, [location, onChange, formEnum.latlng]);

  useEffect(() => {
    setLocation(form.storefrontAddress);
    const countryFromLanguageCode = COUNTRIES.find((country) => country.code.toLowerCase() === form.languageCode);
    setSelectedCountry(countryFromLanguageCode?.id);
  }, []);

  useEffect(() => {
    const someInputIsEmpty = Object.values(location).some((value) => !value);
    onChange(formEnum.storefrontAddress)(location);
    readOnlyHandler(someInputIsEmpty);
  }, [location]);

  useEffect(() => {
    const foundCountry = COUNTRIES.find((country) => country.id === selectedCountry);
    if (foundCountry) {
      setLocation((prev) => ({ ...prev, regionCode: foundCountry.code.toLowerCase() }));
    }
  }, [selectedCountry]);

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
          {t('businessProfileCreate.checkingAddress')}
        </div>
      )}

      <div className={classes.leftSide}>
        <div className={classes.form}>
          <div className={classes.formTitle}>
            <CreateBusinessProfileTextWrapper
              title={t('businessProfileCreate.passLocationData')}
              subTitle={t('businessProfileCreate.passLocationDataSubinfo')}
            />
          </div>
          <div className={classes.inputs}>
            <Select
              filterList={filterCountriesHandler}
              value={selectedCountry}
              list={COUNTRIES}
              onSelect={handleCountry}
              label={t('businessProfileCreate.countryOrRegion')}
            />
            {translatedBusinessLocationInputs.map(({ transformer, label, name, showErrorAfterBlur, validator }) => (
              <Input
                key={name}
                onFocus={scrollOnFocus}
                transformer={transformer}
                className={classes.input}
                value={location[name]}
                label={label}
                onChange={handleLocation(name)}
                validator={makeRequired(validator)}
                showErrorAfterBlur={showErrorAfterBlur}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        {isMapPreviewVisible ? (
          <div className={classes.mapContainer}>
            <BusinessLocationPreviewMap
              key={form.latlng.latitude}
              latitude={form.latlng.latitude}
              longitude={form.latlng.longitude}
              onChange={confirmAdrresHandler}
              className={classes.map}
              form={form}
            />
          </div>
        ) : (
          <BusinessLocationSvg className={classes.svgImage} />
        )}
      </div>
    </div>
  );
};

export default FormBusinessLocation;
