import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Input from '../../../../../shared/components/Input';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';

export const cardInformationConfig = (t, cardData) => [
  {
    key: 'cardName',
    label: t('adminControlPanel.cardTitle'),
    Component: Input,
    props: {
      admin: true,
      showErrorAfterBlur: true,
      labelRised: true,
      placeholder: '',
      maxLength: 50
    },
  },
  {
    key: 'cardCode',
    label: t('adminControlPanel.cardId'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      readOnly: true,
      placeholder: t('adminControlPanel.passLastName'),
    },
  },
  {
    key: 'createdAt',
    label: t('adminControlPanel.createdAt'),
    Component: () => (
      <div className={classes.createdAt}>{dayjs(cardData.createdAt).locale('pl').format('DD MMMM YYYY')}</div>
    ),
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      placeholder: '',
    },
  },
  {
    key: 'status',
    label: t('adminControlPanel.cardStatus'),
    Component: () => (
      <Pill
        className={clsx(classes.pill, !cardData.active && classes.nonActivePill)}
        label={cardData.active ? t('adminControlPanel.cardActive') : t('adminControlPanel.cardNotActive')}
      />
    ),
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      placeholder: t('adminControlPanel.passLastName'),
      className: classes.pill,
    },
  },
];

export const renderInputComponent = ({
  field: {
    key,
    label,
    Component,
    props: {
      readOnly,
      admin,
      placeholder,
      labelRised,
      transformer,
      validator,
      showErrorAfterBlur,
      maxLength,
      className,
    },
  },
  form,
  onInputChange,
}) => (
  <div className={classes.label} key={key}>
    {label}
    <Component
      value={form[key]}
      onChange={onInputChange(key)}
      admin={admin}
      placeholder={placeholder}
      labelRised={labelRised}
      transformer={transformer}
      validator={validator}
      showErrorAfterBlur={showErrorAfterBlur}
      maxLength={maxLength}
      className={className}
      readOnly={readOnly}
    />
  </div>
);

export const getProperActivationText = (active, t) => {
  const activationBtnLabel = active ? t('adminControlPanel.desactivateCard') : t('adminControlPanel.activateCard');
  const notificationMessage = active
    ? t('adminControlPanel.cardDesactivationSuccess')
    : t('adminControlPanel.activationSuccess');

  return { activationBtnLabel, notificationMessage };
};
