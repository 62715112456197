import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';
import { plans, priceModelBusinessCardTitles, priceModelSEOCardsMatrixConfig } from './utils';
import AvailabilityGridItems from '../AvailabilityGridItems';
import { useIsMobile } from '../../../../shared/helpers/hooks';
import { MAX_TABLET_WIDTH } from '../../../SEO/helpers/constants';

const PriceModelsFeatureMatrix = () => {
  const { t } = useTranslation();
  const comparePlansRef = useRef(null);

  const [featureListExpanded, setFeatureListExpanded] = useState(false);
  const isMobileScreen = useIsMobile(MAX_TABLET_WIDTH);
  const btnLabel = featureListExpanded
    ? t('landingPage.pricingSection.expandLessFn')
    : t('landingPage.pricingSection.expandMoreFn');
  const filterValue = isMobileScreen ? 2 : 3;

  const onExpandFeatureList = () => {
    const element = comparePlansRef.current;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setFeatureListExpanded((prev) => !prev);
  };

  return (
    <div className={classes.row}>
      <div ref={comparePlansRef} className={classes.gridContainer}>
        <div className={classes.gridItemTitle}>
          <div className={clsx(classes.gridItem, classes.comparePlans)}>
            {t('landingPage.pricingSection.comparePlans')}
          </div>
        </div>
        {priceModelBusinessCardTitles(t).map((model) => (
          <div key={model.title} className={clsx(classes.gridItem, classes.planName)}>
            <div key={model.title} className={classes.planTitle}>
              {model.title}
            </div>
            <div className={classes.planPrice}>
              {`${model.price} PLN`}
              <span>{model.period}</span>
            </div>
          </div>
        ))}
        <div className={classes.googleBusienssCards}>{t('landingPage.pricingSection.googleBusienssCards')}</div>
        {plans(t)
          .filter((el, i) => i < (featureListExpanded ? 19 : filterValue))
          .map((plan, index) => (
            <React.Fragment key={plan.featureTitle}>
              <div className={clsx(classes.gridItemTitle, classes.featureTitle, index === 0 && classes.as)}>
                {plan.featureTitle}
              </div>
              {plan.subFeatures ? (
                plan.subFeatures.map((subFeature) => (
                  <React.Fragment key={subFeature.name}>
                    <div className={classes.gridItemSubFeature}>{subFeature.name}</div>
                    <AvailabilityGridItems availability={subFeature.availability} />
                  </React.Fragment>
                ))
              ) : (
                <AvailabilityGridItems availability={plan.availability} />
              )}
            </React.Fragment>
          ))}
        {featureListExpanded && (
          <>
            <div className={classes.seoCards}>{t('landingPage.pricingSection.seoCards')}</div>
            {priceModelSEOCardsMatrixConfig(t).map((plan) => (
              <React.Fragment key={plan.featureTitle}>
                <div className={clsx(classes.gridItemTitle, classes.featureTitle, classes.cardTitle)}>
                  {plan.featureTitle}
                </div>
                <AvailabilityGridItems availability={plan.availability} extraClasses={classes.gridCardItem} />
              </React.Fragment>
            ))}
          </>
        )}
      </div>
      <Button className={classes.expandBtn} label={btnLabel} onClick={onExpandFeatureList} />
    </div>
  );
};

export default PriceModelsFeatureMatrix;
