import React from 'react';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../../../shared/components/ErrorMessage';
import { ReactComponent as PinIcon } from '../../../../../shared/assets/pin.svg';
import classes from './styles.module.css';
import { StateEnum } from '../../../../../shared/helpers/state';

const mapStateComponent = (t) => ({
  [StateEnum.loading]: <Loader className={classes.indicator} />,
  [StateEnum.error]: <ErrorMessage className={classes.indicator} message={t('global.error')} />,
  [StateEnum.success]: (
    <div className={classes.pin}>
      <PinIcon width="24px" height="24px" />
    </div>
  ),
});

export const renderMapStateComponent = ({ mapState, mapPreview, t }) => (
  <div className={classes.mapWrapper}>
    {mapStateComponent(t)[mapState]}
    {mapState !== StateEnum.initializing && mapPreview && <img src={mapPreview} className={classes.map} alt="map" />}
  </div>
);
