import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import ImagePreview from '../MediaPreview';
import CircleIconText from '../CircleIconText';
import { ReactComponent as ImageIcon } from '../../assets/image.svg';
import { useNotification } from '../../helpers/notification';
import { getUniqueFiles, moreInfoHandler, validateFiles } from './utils';
import CharCounter from '../CharCounter';

const DragAndDrop = ({
  onChange,
  className,
  maxFilesNumber,
  files,
  supportingText,
  onFileRemove = async () => {},
  btnLabel,
  withVideo = false,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const inputRef = useRef(null);
  const showUploadBtn = uploadedFiles.length !== maxFilesNumber && !isEdit;
  const showCounter = uploadedFiles.length > 0 && maxFilesNumber !== 1 && !isEdit;
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (['dragenter', 'dragover'].includes(e.type)) {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleUploadFiles = async (data) => {
    const { invalidFiles, validFiles } = await validateFiles(data, t, withVideo);
    if (invalidFiles.length > 0) {
      invalidFiles.forEach((el) => showNotification({ message: el, type: 'error' }));
      return;
    }
    setUploadedFiles((prev) => {
      if (prev.length + validFiles.length > maxFilesNumber) {
        return prev;
      }
      onChange([...prev, ...validFiles]);
      return [...prev, ...validFiles];
    });
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.from(e.target.files);
    const uniqueFiles = getUniqueFiles(chosenFiles, uploadedFiles);
    handleUploadFiles(uniqueFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const chosenFiles = Array.from(e.dataTransfer.files);
    const uniqueFiles = getUniqueFiles(chosenFiles, uploadedFiles);
    handleUploadFiles(uniqueFiles);
    setDragActive(false);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const imageDeleteHandler = async (media) => {
    const filteredImages = uploadedFiles.filter((file) => file.id !== media.id);
    setUploadedFiles(filteredImages);
    onChange(filteredImages);
    await onFileRemove(media);
  };

  useEffect(() => {
    if (files) {
      setUploadedFiles(files);
    }
  }, [files]);

  return (
    <>
      <div className={className}>
        <label
          className={clsx(classes.labelFileUpload, dragActive && classes.dragActive)}
          htmlFor="input-file-upload"
          onDragEnter={handleDrag}
          onDrop={handleDrop}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
        >
          <input multiple ref={inputRef} onChange={handleFileEvent} type="file" className={classes.inputFileUpload} />
          <div className={classes.previewContainer}>
            <div className={clsx(classes.previewImages, !uploadedFiles.length && classes.center)}>
              {uploadedFiles?.map((file) => (
                <ImagePreview
                  key={file.id}
                  file={file}
                  onDelete={imageDeleteHandler}
                  dimension={140}
                  source={file.mediaSource}
                  isEdit={isEdit}
                />
              ))}
              {showUploadBtn && (
                <button type="button" onClick={onButtonClick} className={classes.uploadButton}>
                  <CircleIconText
                    headingStyle={classes.dragAndDropTitle}
                    icon={ImageIcon}
                    heading={btnLabel}
                    supportingText={!uploadedFiles.length && supportingText}
                    supportingTextStyle={classes.dragAndDropSupportingText}
                    supportingTextClickHandler={moreInfoHandler}
                  />
                </button>
              )}
            </div>
          </div>
        </label>
      </div>
      {showCounter && (
        <CharCounter className={classes.imageCounter} maxLength={maxFilesNumber} value={uploadedFiles.length} />
      )}
    </>
  );
};

export default DragAndDrop;
