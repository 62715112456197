import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import classes from './styles.module.css';
import { checkboxClickHandler as checkboxClickHandlerUtil, paginationLimitList } from './utils';
import BusinessCardSettingsActionsWrapper from '../BusinessCardSettingsActionsWrapper';
import { useAuth } from '../../../../../shared/helpers/auth';
import { useApi } from '../../../../../shared/helpers/api';
import { useDashboard } from '../../../helpers/dashboard';
import { useNotification } from '../../../../../shared/helpers/notification';
import Loader from '../../../components/Loader';
import Pagination from '../../../../../shared/components/Pagination';
import NoBusinessProfiles from '../NoBusinessProfiles';
import BusinessCardSettingsHeader from '../BusinessCardSettingsHeader';
import Checkbox from '../../../../../shared/components/Checkbox';
import BusinessItem from '../BusinessItem';
import { handleApiError } from '../ChangePassword/utils';

const BusinessCardsSettings = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { businessProfiles } = useAuth();
  const { businessProfile } = useDashboard();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [selectAllowed, setSelectAllowed] = useState(false);
  const [importedProfiles, setImportedProfiles] = useState([]);
  const [selectedBusinessProfiles, setSelectedBusinessProfiles] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    limit: 5,
    offset: importedProfiles.length,
    orderBy: 'name asc',
    businessProfileId: businessProfile.id,
    page: 1,
  });

  const loaderHandler = () => {
    setIsLoading((prev) => !prev);
  };
  const updateFilters = useCallback((name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  }, []);

  const pageChangeHandler = useCallback(
    (value) => {
      setSelectAllowed(false);
      updateFilters('page', value);
      updateFilters('offset', value * filters.limit - filters.limit);
    },
    [filters.limit, updateFilters],
  );

  const limitChangeHandler = useCallback(
    (value) => {
      setSelectAllowed(false);
      updateFilters('page', 1);
      updateFilters('limit', value);
      updateFilters('offset', 0);
    },
    [updateFilters],
  );

  const toggleSelectAll = () => {
    setSelectAllowed((prev) => !prev);
  };

  const onDetachHandler = (id) => {
    setImportedProfiles((prev) => prev.filter((item) => item.id !== id));
  };

  const selectAllHandler = () => {
    const profilesID = importedProfiles.map((el) => el.id);
    setSelectedBusinessProfiles((prev) => (prev.length ? [] : profilesID));
  };

  const detachSelectedHandler = useCallback(async () => {
    try {
      const newSelectedBusinessProfiles = [...selectedBusinessProfiles];
      newSelectedBusinessProfiles.map((el) => onDetachHandler(el.id));
      loaderHandler();
      await api.post('/business_profile/detachBusinessProfiles', { businessProfilesId: newSelectedBusinessProfiles });
      setSelectAllowed(false);
      setSelectedBusinessProfiles([]);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      loaderHandler();
    }
  }, [selectedBusinessProfiles, onDetachHandler, loaderHandler, api, t, showNotification]);

  const debouncedSetImportedProfiles = _.debounce((value) => {
    setImportedProfiles(() => {
      if (!value.length) {
        return businessProfiles.slice(0, filters.limit);
      }
      return businessProfiles.filter((el) => el.name.toLowerCase().includes(value.toLowerCase()));
    });
    setFilters((prev) => ({ ...prev, name: value }));
  }, 500);

  const checkboxClickHandler = useMemo(
    () => (id) => checkboxClickHandlerUtil(
      id,
      selectedBusinessProfiles,
      setSelectedBusinessProfiles,
      selectAllowed,
      toggleSelectAll,
    ),
    [selectedBusinessProfiles, selectAllowed, setSelectedBusinessProfiles, toggleSelectAll],
  );

  useEffect(() => {
    const start = (filters.page - 1) * filters.limit;
    const end = start + filters.limit;
    const newList = businessProfiles.slice(start, end);
    setImportedProfiles(newList);
  }, [businessProfiles, filters.limit, filters.page]);

  useEffect(() => {
    setImportedProfiles(businessProfiles.slice(0, filters.limit));
  }, [businessProfiles, filters.limit]);

  if (isLoading) {
    return (
      <div className={classes.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <BusinessCardSettingsHeader />
      <div className={classes.body}>
        <BusinessCardSettingsActionsWrapper
          readOnlyBtn={!selectedBusinessProfiles.length}
          businessProfiles={importedProfiles}
          onDetachSelected={detachSelectedHandler}
          selectAllowed={selectAllowed}
          selectAllowedHandler={toggleSelectAll}
          searchHandler={debouncedSetImportedProfiles}
        />
        <div className={classes.listWrapper}>
          {selectAllowed && (
            <div className={classes.checkboxWrapper}>
              <Checkbox
                onClick={selectAllHandler}
                className={classes.checkbox}
                label={t('settings.selectAllThisPage')}
                checkboxStyle={classes.checkboxStyle}
                labelPosition="right"
                value={selectedBusinessProfiles.length === importedProfiles.length}
              />
            </div>
          )}
          {!importedProfiles.length ? (
            <NoBusinessProfiles />
          ) : (
            importedProfiles.map((profile) => (
              <BusinessItem
                key={profile.id}
                profile={profile}
                onDetachHandler={onDetachHandler}
                onCheckBoxClick={checkboxClickHandler}
                selectedBusinessProfiles={selectedBusinessProfiles}
                selectAllowed={selectAllowed}
                loaderHandler={loaderHandler}
              />
            ))
          )}
        </div>
        {filters.name === '' && (
          <Pagination
            className={classes.pagination}
            page={filters.page}
            total={businessProfiles.length}
            limit={filters.limit}
            paginationLimitList={paginationLimitList}
            onPageChange={pageChangeHandler}
            onLimitChange={limitChangeHandler}
          />
        )}
      </div>
    </div>
  );
};
export default BusinessCardsSettings;
