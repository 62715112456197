export const navLinks = (t) => [
  {
    title: t('landingPage.about'),
    url: '#about',
  },
  {
    title: t('landingPage.whyIsWorth'),
    url: '#whyIsworth',
  },
  {
    title: t('landingPage.features'),
    url: '#features',
  },
  {
    title: t('landingPage.pricing'),
    url: '#pricing',
  },
  {
    title: t('landingPage.contact'),
    url: '#contact',
  },
];
