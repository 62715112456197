export const OPEN_FOR_BUSINESS = {
  openForBusinessUnspecified: 'OPEN_FOR_BUSINESS_UNSPECIFIED',
  open: 'OPEN',
  closedPermanently: 'CLOSED_PERMANENTLY',
  closedTemporarily: 'CLOSED_TEMPORARILY'
};

export const config = [
  {
    id: OPEN_FOR_BUSINESS.open,
    value: OPEN_FOR_BUSINESS.open,
    name: 'Otwarte, podane główne godziny otwarcia',
    description: 'Pokazuj godziny otwarcia firmy.'
  },
  {
    id: OPEN_FOR_BUSINESS.openForBusinessUnspecified,
    value: OPEN_FOR_BUSINESS.open,
    name: 'Otwarte, brak głównych godzin otwarcia',
    description: 'Nie pokazuj godzin otwarcia firmy.'
  },
  {
    id: OPEN_FOR_BUSINESS.closedTemporarily,
    value: OPEN_FOR_BUSINESS.closedTemporarily,
    name: 'Tymczasowo zamknięte',
    description: 'Poinformuj, że w przyszłości Twoja firma będzie ponownie otwarta.'
  },
  {
    id: OPEN_FOR_BUSINESS.closedPermanently,
    value: OPEN_FOR_BUSINESS.closedPermanently,
    name: 'Zamknięte na stałe',
    description: 'Poinformuj, że Twoja firma już nie istnieje.'
  },
];

export const weekDays = [
  { id: 'MONDAY', name: 'Poniedziałek' },
  { id: 'TUESDAY', name: 'Wtorek' },
  { id: 'WEDNESDAY', name: 'Środa' },
  { id: 'THURSDAY', name: 'Czwartek' },
  { id: 'FRIDAY', name: 'Piątek' },
  { id: 'SATURDAY', name: 'Sobota' },
  { id: 'SUNDAY', name: 'Niedziela' },
];

const parseTime = ({ hours, minutes }) => {
  const parsedHours = String(hours || 0);
  const parsedMinutes = String(minutes || 0);
  return `${parsedHours.padStart(2, '0')}:${parsedMinutes.padStart(2, '0')}`;
};

export const parseRegularHours = (regularHours) => (
  weekDays.map((el) => {
    const hours = regularHours.filter((item) => item.openDay === el.id);
    if (!hours.length) {
      return { id: el.id, name: el.name, value: true, hours: [] };
    }
    const parsedHours = hours.map((item) => ({ from: parseTime(item.openTime), to: parseTime(item.closeTime) }));
    return { id: el.id, name: el.name, value: false, hours: parsedHours };
  })
);

export const parseBackRegularHours = (regularHours, openType) => {
  const filtered = regularHours.filter((el) => !el.value);
  if ([
    OPEN_FOR_BUSINESS.openForBusinessUnspecified,
    OPEN_FOR_BUSINESS.closedPermanently
  ].includes(openType)) {
    return [];
  }

  const parsed = filtered.reduce((acc, el) => {
    const parsedHours = el.hours.map((item) => {
      const closeTime = item.to.split(':').map(Number).filter(Boolean);
      const openTime = item.from.split(':').map(Number).filter(Boolean);
      return {
        openDay: el.id,
        closeDay: el.id,
        closeTime: { hours: closeTime[0], minutes: closeTime[1] },
        openTime: { hours: openTime[0], minutes: openTime[1] }
      };
    });
    return [...acc, ...parsedHours];
  }, []);
  return parsed;
};
