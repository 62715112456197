import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BusinessProfileSelect from '../../modules/PickBusinessProfiles/BusinessProfileSelect';
import Select from '../../../../shared/components/Select';
import Loader from '../../components/Loader';
import Button from '../../../../shared/components/Button';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import Checkbox from '../../../../shared/components/Checkbox';
import { useNotification } from '../../../../shared/helpers/notification';
import { useApi } from '../../../../shared/helpers/api';
import classes from './styles.module.css';
import {
  StateEnum,
  fetchAccountsAndProfiles,
  fetchBusinessProfiles,
  attachProfilesHandler,
  handleChangeAccount,
  getCheckboxValue,
} from './utils';
import { useAuth } from '../../../../shared/helpers/auth';

const MAX_BUSIENSS_PROFILES_AT_ONCE = 100;
const PickBusinessProfiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfiles: importedBusinessProfiles } = useAuth();

  const [status, setStatus] = useState(StateEnum.loading);
  const [accountUserList, setUserAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [list, setList] = useState([]);
  const [selectedBusinessProfiles, setSelectedBusinessProfiles] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');

  const importLimitReached = list?.length > MAX_BUSIENSS_PROFILES_AT_ONCE;
  const checkboxValue = getCheckboxValue({ list, selectedBusinessProfiles, MAX_BUSIENSS_PROFILES_AT_ONCE });

  const selectMoreLabel = importLimitReached
    ? t('pickBusinessProfiles.selectFirstHundred')
    : t('pickBusinessProfiles.selectAll');

  const { loading } = StateEnum;
  const readOnly = status === loading || !selectedBusinessProfiles.length;

  const handleCheckAll = (value) => {
    if (importLimitReached) {
      setSelectedBusinessProfiles(() => (value
        ? list
          .slice(0, MAX_BUSIENSS_PROFILES_AT_ONCE)
          .map((el) => ({ googleReferenceId: el.id, googleAccountName: selectedAccount }))
        : []));
    } else {
      setList((prev) => prev.map((el) => ({ ...el, checked: value })));
      setSelectedBusinessProfiles(() => (value ? list.map((el) => ({ googleReferenceId: el.id, googleAccountName: selectedAccount })) : []),);
    }
  };

  const createBusinessCardNavigator = () => {
    navigate(importedBusinessProfiles.length ? '/dashboard/createBusinessProfile' : '/createBusinessProfile');
  };
  const goBackHandler = () => {
    navigate(-1);
  };

  const attachProfileWrapper = async () => {
    await attachProfilesHandler({ api, selectedBusinessProfiles, setStatus, showNotification, t });
    navigate('/dashboard');
  };

  const handleAccountWrapper = async (value) => {
    setSelectedAccount(value);
    setList([]);
    setSelectedBusinessProfiles([]);
    const { pageToken, parsed, error } = await handleChangeAccount(api, value, setStatus);
    setNextPageToken(pageToken);
    setList(parsed);
    if (error) {
      setStatus(StateEnum.error);
      if (error.message !== 'canceled') {
        showNotification({ label: 'Error', message: 'Error', type: 'error', payload: error });
      }
    }
  };
  const fetchBusinessProfilesWrapper = async (controller, token) => {
    const { error, pageToken, parsedBusinessProfiles } = await fetchBusinessProfiles(
      api,
      controller,
      token,
      selectedAccount,
    );
    if (error) {
      setStatus(StateEnum.error);
      if (error.message !== 'canceled') {
        showNotification({ label: 'Error', message: 'Error', type: 'error', payload: error });
      }
    } else {
      setList((prev) => [...prev, ...parsedBusinessProfiles]);
      setNextPageToken(pageToken);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetch = async () => {
      const { defaultAccountId, userLocations, error, pageToken, parsedBusinessProfiles } = await fetchAccountsAndProfiles(api, controller, setStatus, setStatus);
      setUserAccountList(userLocations);
      setList(parsedBusinessProfiles);
      setSelectedAccount(defaultAccountId);
      setNextPageToken(pageToken);
      if (error) {
        setStatus(StateEnum.error);
        if (error.message !== 'canceled') {
          showNotification({ label: 'Error', message: 'Error', type: 'error', payload: error });
        }
      }
    };

    fetch();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (importLimitReached) {
      showNotification({
        message: t('pickBusinessProfiles.youCanAttachUpTo'),
        type: 'warning',
      });
    }
  }, [importLimitReached]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>{t('pickBusinessProfiles.header')}</div>
      <div className={classes.rwdHeader}>{t('pickBusinessProfiles.rwdHeader')}</div>
      {status === StateEnum.loading && (
        <div className={classes.center}>
          <Loader />
        </div>
      )}
      {status === StateEnum.success && (
        <div className={classes.form}>
          <div className={classes.row}>
            <Select
              label={t('pickBusinessProfiles.selectLabel')}
              className={classes.select}
              list={accountUserList}
              onSelect={handleAccountWrapper}
              value={selectedAccount}
            />
            <Button
              className={classes.createCardBtn}
              label={t('pickBusinessProfiles.create')}
              onClick={createBusinessCardNavigator}
            />
          </div>
          <div className={classes.horizontalLine} />

          <BusinessProfileSelect
            list={list}
            setList={setList}
            setSelected={setSelectedBusinessProfiles}
            account={selectedAccount}
            selected={selectedBusinessProfiles}
            onFetch={fetchBusinessProfilesWrapper}
            nextPageToken={nextPageToken}
          />

          <div className={classes.actionsBox}>
            {Boolean(list.length) && (
              <div className={classes.all}>
                <Checkbox checkboxStyle={classes.checkbox} value={checkboxValue} onClick={handleCheckAll} />
                <div className={classes.allText}>{selectMoreLabel}</div>
              </div>
            )}
            <div className={classes.row}>
              <Button className={classes.backButton} label={t('global.goBack')} onClick={goBackHandler} />
              <Button
                readOnly={readOnly}
                className={classes.button}
                label={t('pickBusinessProfiles.import')}
                onClick={attachProfileWrapper}
              />
            </div>
          </div>
        </div>
      )}
      {status === StateEnum.error && (
        <div className={classes.center}>
          <ErrorMessage message={t('global.errorOccurred')} />
        </div>
      )}
    </div>
  );
};

export default PickBusinessProfiles;
