import dayjs from 'dayjs';

export const ROLE_ENUM = {
  superAdmin: 'super_admin',
  moderator: 'moderator',
};
const roleConfig = (t) => ({
  moderator: t('adminControlPanel.moderator'),
  super_admin: t('adminControlPanel.superAdmin'),
});

export const getUserRole = (role, t) => roleConfig(t)[role];
export const initUserData = {
  name: '',
  lastName: '',
  fullName: '',
  roleType: '',
  id: null,
  active: null,
  lastLogin: '',
  createdAt: '',
  lastActiveStatusChangeDate: '',
};

const parseDateWithTranslation = (date, t) => (dayjs(date).locale('pl').format('DD MMMM YY') === 'Invalid Date'
  ? t('adminControlPanel.noData')
  : dayjs(date).locale('pl').format('DD MMMM YY'));
export const parseUserData = (userData, t) => ({
  ...userData,
  roleType: getUserRole(userData.roleType, t),
  fullName: `${userData.name} ${userData.lastName}`,
  lastLogin: parseDateWithTranslation(userData.lastLogin, t),
  createdAt: parseDateWithTranslation(userData.createdAt, t),
  lastActiveStatusChangeDate: dayjs(userData.activeChangedAt).format('DD/MM/YYYY'),
});

export const getButtonProp = ({ active, t, modalHandler, desactivateAccountHandler }) => {
  const buttonLabel = active ? t('adminControlPanel.desactivateAccount') : t('adminControlPanel.activateAccount');
  const onButtonClick = active ? modalHandler : desactivateAccountHandler;
  return { buttonLabel, onButtonClick };
};
