import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';

const BusinessProfileRow = ({ checked, name, address, className, onCheck, alreadyImported }) => (
  <div className={clsx(classes.wrapper, className)}>
    <Checkbox className={classes.checkbox} value={checked} onClick={onCheck} />
    <div className={classes.info}>
      <div className={clsx(classes.name, alreadyImported && classes.imported)}>{name}</div>
      <div className={clsx(classes.address, alreadyImported && classes.imported)}>{address}</div>
    </div>
  </div>
);

export default BusinessProfileRow;
