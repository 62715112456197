import { POLISH_CURRENCY } from '../../../helpers/constants';
import { parseStringsWithWhiteSpaces } from '../../../../../shared/helpers/strings';

export const StateEnum = {
  loading: 'loading',
  error: 'error',
  success: 'success',
};
export const PriceEnums = {
  noPrice: 'Brak ceny',
  freePrice: 'Bezpłatnie',
  constantPrice: 'Cena stała',
  priceFrom: 'Cena od',
};
export const Prices = [
  { id: PriceEnums.noPrice, name: 'Brak ceny' },
  { id: PriceEnums.constantPrice, name: 'Cena stała' },
];
export const ModalEnum = {
  backdrop: 'backdrop',
};
export const CategoryEnum = {
  primary: 'Kategoria podstawowa',
  additional: 'Kategoria dodatkowa',
};

export const ServicesTypeEnum = {
  FREE_FORM_SERVICE_ITEM: 'freeFormServiceItem',
  STRUCTURED_SERVICE_ITEM: 'structuredServiceItem',
  FREE_FORM_SERVICE_ITEM_WITHOUT_PRICE: 'freeFormServiceItemWithoutPrice',
  FREE_FORM_SERVICE_ITEM_WITH_PRICE: 'freeFormServiceItemWithPrice',
  STRUCTURED_SERVICE_ITEM_WITHOUT_PRICE: 'structuredServiceItemWithoutPrice',
  STRUCTURED_SERVICE_ITEM_WITH_PRICE: 'structuredServiceItemWithPrice',
};
export const parseAdditionalCategories = (additionalCategories) => {
  if (!additionalCategories) {
    return [];
  }
  return additionalCategories.map((category) => {
    if (!category.serviceTypes?.length) {
      return { ...category, serviceTypes: [], type: CategoryEnum.additional };
    }
    return {
      ...category,
      serviceTypes: category.serviceTypes?.map((el) => ({
        ...el,
        selected: false,
        type: ServicesTypeEnum.STRUCTURED_SERVICE_ITEM,
      })),
      type: CategoryEnum.additional,
    };
  });
};
const descriptionCondition = (service) => !service.description || service.description.trim().length === 0;

const parseServiceFreeFormNoPrice = (service, category) => {
  if (descriptionCondition(service)) {
    return {
      freeFormServiceItem: {
        category,
        label: {
          displayName: parseStringsWithWhiteSpaces(service.displayName).trim(),
          languageCode: 'pl',
        },
      },
    };
  }
  return {
    freeFormServiceItem: {
      category,
      label: {
        displayName: parseStringsWithWhiteSpaces(service.displayName).trim(),
        languageCode: 'pl',
        description: parseStringsWithWhiteSpaces(service.description).trim(),
      },
    },
  };
};
const parseServiceFreeFormWithPrice = (service, category) => {
  const [units, remainder] = service.price.split('.');

  if (descriptionCondition(service)) {
    return {
      freeFormServiceItem: {
        category,
        label: {
          displayName: parseStringsWithWhiteSpaces(service.displayName),
          languageCode: 'pl',
        },
      },
      price: {
        currencyCode: POLISH_CURRENCY,
        units,
        nanos: Number(`0.${remainder || 0}`) * 1000000000,
      },
    };
  }
  return {
    freeFormServiceItem: {
      category,
      label: {
        displayName: parseStringsWithWhiteSpaces(service.displayName),
        description: parseStringsWithWhiteSpaces(service.description).trim(),
        languageCode: 'pl',
      },
    },
    price: {
      currencyCode: POLISH_CURRENCY,
      units,
      nanos: Number(`0.${remainder || 0}`) * 1000000000,
    },
  };
};
const parseStructuredServiceWithPrice = (service) => {
  const [units, remainder] = service.price.split('.');
  if (descriptionCondition(service)) {
    return {
      structuredServiceItem: {
        serviceTypeId: service.serviceTypeId,
      },
      price: {
        currencyCode: POLISH_CURRENCY,
        units,
        nanos: Number(`0.${remainder || 0}`) * 1000000000,
      },
    };
  }
  return {
    structuredServiceItem: {
      serviceTypeId: service.serviceTypeId,
      description: parseStringsWithWhiteSpaces(service.description).trim(),
    },
    price: {
      currencyCode: POLISH_CURRENCY,
      units,
      nanos: Number(`0.${remainder || 0}`) * 1000000000,
    },
  };
};
const parseStructuredServiceWithNoPrice = (service) => {
  if (descriptionCondition(service)) {
    return {
      structuredServiceItem: {
        serviceTypeId: service.serviceTypeId,
      },
    };
  }
  return {
    structuredServiceItem: {
      serviceTypeId: service.serviceTypeId,
      description: parseStringsWithWhiteSpaces(service.description),
    },
  };
};
export const parseServices = (services) => {
  const parsedServices = services.map((service) => {
    if (service.type === ServicesTypeEnum.FREE_FORM_SERVICE_ITEM) {
      if (service.price) {
        return parseServiceFreeFormWithPrice(service, service.category);
      }
      return parseServiceFreeFormNoPrice(service, service.category);
    }
    if (service.price) {
      return parseStructuredServiceWithPrice(service);
    }
    return parseStructuredServiceWithNoPrice(service);
  });
  return parsedServices;
};
const parseIncomingStructuredWithPrice = (service) => ({
  serviceTypeId: service.structuredServiceItem.serviceTypeId,
  description: parseStringsWithWhiteSpaces(service.structuredServiceItem.description).trim(),
  price: `${service.price.units}.${Math.round(service.price.nanos / 10000000).toString()}`,
  priceType: PriceEnums.constantPrice,
  currencyCode: service.price.currencyCode,
  selected: true,
  type: ServicesTypeEnum.STRUCTURED_SERVICE_ITEM,
});
const parseIncomingStructuredWithNoPrice = (service) => ({
  serviceTypeId: service.structuredServiceItem.serviceTypeId,
  description: parseStringsWithWhiteSpaces(service.structuredServiceItem.description),
  selected: true,
  type: ServicesTypeEnum.STRUCTURED_SERVICE_ITEM,
});
const parseIncomingFreeFormWithPrice = (service) => ({
  displayName: service.freeFormServiceItem.label.displayName,
  serviceTypeId: service.freeFormServiceItem.label.displayName,
  description: parseStringsWithWhiteSpaces(service.freeFormServiceItem.label.description),
  price: `${service.price.units}.${Math.round(service.price.nanos / 10000000).toString()}`,
  currencyCode: service.price.currencyCode,
  priceType: PriceEnums.constantPrice,
  category: service.freeFormServiceItem.category,
  selected: true,
  type: ServicesTypeEnum.FREE_FORM_SERVICE_ITEM,
});
const parseIncomingFreeFormWithNoPrice = (service) => ({
  displayName: service.freeFormServiceItem.label.displayName,
  serviceTypeId: service.freeFormServiceItem.label.displayName,
  description: parseStringsWithWhiteSpaces(service.freeFormServiceItem.label.description),
  category: service.freeFormServiceItem.category,
  selected: true,
  type: ServicesTypeEnum.FREE_FORM_SERVICE_ITEM,
});

export const parseStructured = (services) => {
  const parsedServices = services.map((service) => {
    if (service.price) {
      return parseIncomingStructuredWithPrice(service);
    }
    return parseIncomingStructuredWithNoPrice(service);
  });
  return parsedServices;
};
export const parseFreeForm = (services) => {
  const parsedServices = services.map((service) => {
    if (service.price) {
      return parseIncomingFreeFormWithPrice(service);
    }
    return parseIncomingFreeFormWithNoPrice(service);
  });
  return parsedServices;
};

export function parseAdditionalCategoriesServices({ parsedAdditionalCategories, parsedFreeForm, parsedStructured }) {
  return parsedAdditionalCategories.map((category) => {
    const freeFormServicesFound = parsedFreeForm.filter((el) => el.category === category.categoryReferenceId) || [];
    const categoryServices = category.serviceTypes.map((el) => {
      const found = parsedStructured.find((item) => item.serviceTypeId === el.serviceTypeId);
      if (found) {
        return { ...el, ...found };
      }
      return el;
    });

    return { ...category, serviceTypes: [...categoryServices, ...freeFormServicesFound] };
  });
}

export function parsePrimaryCategoryWithService({ parsedPrimaryCategory, parsedFreeForm, parsedStructured }) {
  const primaryCategoryFreeFormServicesFound = parsedFreeForm.filter((el) => el.category === parsedPrimaryCategory.categoryReferenceId) || [];
  const primaryCategoryStructuredFormServices = parsedPrimaryCategory.serviceTypes?.map((el) => {
    const found = parsedStructured.find((item) => item.serviceTypeId === el.serviceTypeId);
    if (found) {
      return { ...el, ...found };
    }
    return el;
  });
  if (!primaryCategoryStructuredFormServices) {
    return {
      ...parsedPrimaryCategory,
      serviceTypes: [...parsedPrimaryCategory.serviceTypes, ...primaryCategoryFreeFormServicesFound],
    };
  }
  return {
    ...parsedPrimaryCategory,
    serviceTypes: [...primaryCategoryStructuredFormServices, ...primaryCategoryFreeFormServicesFound],
  };
}
export const serviceDetailSetter = (prev, service, category) => {
  if (category === prev.primaryCategory.categoryReferenceId) {
    const primaryCategoryServices = prev.primaryCategory.serviceTypes.map((serviceItem) => {
      if (serviceItem.serviceTypeId === service.serviceTypeId) {
        return service;
      }
      return serviceItem;
    });

    const newPrimaryCategory = { ...prev.primaryCategory, serviceTypes: primaryCategoryServices };

    return {
      ...prev,
      primaryCategory: newPrimaryCategory,
    };
  }
  const newAdditionalCategories = prev.additionalCategories.map((categoryItem) => {
    if (categoryItem.categoryReferenceId === category) {
      const additionalCategoryServices = categoryItem.serviceTypes.map((serviceItem) => {
        if (serviceItem.serviceTypeId === service.serviceTypeId) {
          return service;
        }
        return serviceItem;
      });
      const newCategory = { ...categoryItem, serviceTypes: additionalCategoryServices };
      return newCategory;
    }
    return categoryItem;
  });
  return {
    ...prev,
    additionalCategories: newAdditionalCategories,
  };
};
export const serviceDeleteSetter = (prev, serviceID, categoryID) => {
  if (categoryID === prev.primaryCategory.categoryReferenceId) {
    const primaryServices = prev.primaryCategory.serviceTypes.filter((service) => service.serviceTypeId !== serviceID);
    const newPrimaryCategory = { ...prev.primaryCategory, serviceTypes: primaryServices };
    return {
      ...prev,
      primaryCategory: newPrimaryCategory,
    };
  }
  const newAdditionalCategories = prev.additionalCategories.map((category) => {
    if (category.categoryReferenceId === categoryID) {
      const additionalCategories = category.serviceTypes.filter((service) => service.serviceTypeId !== serviceID);
      const newCategory = { ...category, serviceTypes: additionalCategories };
      return newCategory;
    }
    return category;
  });
  return {
    ...prev,
    additionalCategories: newAdditionalCategories,
  };
};
export const saveServicesSetter = (prev, data) => {
  if (data.categoryReferenceId === prev.primaryCategory.categoryReferenceId) {
    const newPrimaryCategory = { ...prev.primaryCategory, serviceTypes: data.serviceTypes };
    return {
      ...prev,
      primaryCategory: newPrimaryCategory,
    };
  }
  const newAdditionalCategories = prev.additionalCategories.map((category) => {
    if (category.categoryReferenceId === data.categoryReferenceId) {
      const newCategory = { ...category, serviceTypes: data.serviceTypes };
      return newCategory;
    }
    return category;
  });
  return {
    ...prev,
    additionalCategories: newAdditionalCategories,
  };
};
export const SERVICE_NAME_MAX_LENGTH = 120;
