export const PROFILE_STATUS_ENUM = {
  verified: 'verified',
  notVerified: 'notVerified',
  pending: 'pending',
  duplicate: 'duplicate',
  suspended: 'suspended',
};

export const DATA_NAME_ENUM = {
  performance: 'performance',
  keywords: 'keywords',
  reviews: 'reviews',
  statistic: 'statistic',
  form: 'form',
  media: 'mediaCount',
  changeContent: 'changeContent',
};

export const METRIC_TYPE_ENUM = {
  default: 'default',
  bookings: 'BUSINESS_BOOKINGS',
  directionRequests: 'BUSINESS_DIRECTION_REQUESTS',
  mobileImpressionsMaps: 'BUSINESS_MOBILE_IMPRESSIONS_MOBILE_MAPS',
  businessConversations: 'BUSINESS_CONVERSATIONS',
  desktopImpressionsMaps: 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS',
  mobileImpressionsSearch: 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH',
  desktopImpressionsSearch: 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH',
};

export const AI_MODEL_ENUM = {
  gpt3: 'gpt-3.5-turbo',
  gpt4: 'gpt-4',
  gpt4Turbo: 'gpt-4-turbo-preview',
};

export const KEYBOARD_KEYS_ENUM = {
  escape: 'Escape',
  enter: 'Enter',
};

export const ACTION_ENUM = {
  CREATE: 'dashboard.create',
  UPDATE: 'dashboard.update',
  DELETE: 'dashboard.delete',
};

export const CLIENT_PATH_ENUM = {
  getBusinessProfile: 'business_profile/getBusinessProfile',
  localPostStatistic: 'local_post/localPostStatistic',
  getReviews: 'review/getReviews',
  getMedia: 'media/mediaList',
  businessProfilePerformance: 'performance/getPerformance',
  businessProfileKeywords: 'performance/getKeywords',
  changeContent: 'business_profile/getBusinessProfileChangeContent',
};
