import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../../../../shared/helpers/debounce';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import AsyncSelect from '../../../../../shared/components/AsyncSelect';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import Input from '../../../../../shared/components/Input';
import { useDashboard } from '../../../helpers/dashboard';

const CategoriesSelect = ({
  className,
  onCategoryChange,
  isEdit,
  value,
  categoriesList = [],
  label,
  validator,
  onError,
  adminPreview,
  inputValue,
}) => {
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { t } = useTranslation();
  const {
    businessProfile: { id },
  } = useDashboard();

  const handleGetCategoryList = useCallback(
    debounce(async (payload) => {
      if (adminPreview) return [];
      try {
        const { data } = await api.post('/place_category/getPlaceCategoryList', {
          limit: 10,
          offset: payload.offset,
          filters: { name: payload.value },
          orderBy: 'name',
          order: 'ASC',
        });
        return data.filter(({ categoryReferenceId }) => !categoriesList.includes(categoryReferenceId));
      } catch (err) {
        handleApiError({ err, t, showNotification });
        throw err;
      }
    }, 250),
    [],
  );

  const handleGetCategory = useCallback(
    debounce(async (payload) => {
      if (adminPreview) return [];
      try {
        const categoryReferenceId = typeof payload.value === 'string' ? payload.value : payload.value.categoryReferenceId;
        const { data } = await api.post('/place_category/getPlaceCategory', {
          categoryReferenceId,
          businessProfileId: id,
        });
        return data;
      } catch (err) {
        handleApiError({ err, t, showNotification });
        throw err;
      }
    }, 500),
    [],
  );

  return adminPreview ? (
    <Input value={inputValue} readOnly className={className} />
  ) : (
    <AsyncSelect
      className={className}
      onSelect={onCategoryChange}
      label={label}
      readOnly={!isEdit}
      value={value}
      onElementGet={handleGetCategory}
      onListRead={handleGetCategoryList}
      infiniteScroll
      limit={10}
      showErrorAfterBlur
      validator={validator}
      onError={onError}
    />
  );
};

export default CategoriesSelect;
