import { ReactComponent as Card1Icon } from '../../../../shared/assets/card1Icon.svg';
import { ReactComponent as Card2Icon } from '../../../../shared/assets/card2Icon.svg';
import { ReactComponent as Card3Icon } from '../../../../shared/assets/card3Icon.svg';
import { ReactComponent as Card4Icon } from '../../../../shared/assets/card4Icon.svg';
import { ReactComponent as Card5Icon } from '../../../../shared/assets/card5Icon.svg';

export const cardListCollection = (t) => [
  {
    title: t('landingPage.whyIsWorthSection.cardList.firstCard.title'),
    description: t('landingPage.whyIsWorthSection.cardList.firstCard.description'),
    Icon: Card1Icon,
  },
  {
    title: t('landingPage.whyIsWorthSection.cardList.secondCard.title'),
    description: t('landingPage.whyIsWorthSection.cardList.secondCard.description'),
    Icon: Card2Icon,
  },
  {
    title: t('landingPage.whyIsWorthSection.cardList.thirdCard.title'),
    description: t('landingPage.whyIsWorthSection.cardList.thirdCard.description'),
    Icon: Card3Icon,
  },
  {
    title: t('landingPage.whyIsWorthSection.cardList.fourthCard.title'),
    description: t('landingPage.whyIsWorthSection.cardList.fourthCard.description'),
    Icon: Card4Icon,
  },
  {
    title: t('landingPage.whyIsWorthSection.cardList.fifthCard.title'),
    description: t('landingPage.whyIsWorthSection.cardList.fifthCard.description'),
    Icon: Card5Icon,
  },
];
