import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';

const BetaSign = ({ className }) => {
  const { t } = useTranslation();
  return <div className={clsx(classes.betaSign, className)}>BETA</div>;
};

export default BetaSign;
