import React from 'react';
import BusinessCardsSettings from '../../modules/Settings/BusinessCardsSettings';
import ChangePassword from '../../modules/Settings/ChangePassword';
import MyAccount from '../../modules/Settings/MyAccount';

export const PATH_ENUM = {
  PROFILE: '/settings/profile',
  PASSWORD: '/settings/password',
  MY_BUSINESS_CARDS: '/settings/my_business_cards',
};

export const translatedTabs = (t) => [
  {
    label: t('settings.myAccount'),
    path: PATH_ENUM.PROFILE,
  },
  {
    label: t('settings.password'),
    path: PATH_ENUM.PASSWORD,
  },
  {
    label: t('settings.myBusinessCards'),
    path: PATH_ENUM.MY_BUSINESS_CARDS,
  },
];

export const TAB_COMPONENTS = {
  '/settings/my_business_cards': <BusinessCardsSettings />,
  '/settings/profile': <MyAccount />,
  '/settings/password': <ChangePassword />,
};
