import React from 'react';
import classes from './styles.module.css';
import ProgressBar from '../../../../../shared/components/ProgressBar';

const KeywordItem = ({ keyword, maxValue }) => {
  const { keyword: keywordText, value, displayValue } = keyword;

  return (
    <div className={classes.keyword} key={keywordText}>
      <div className={classes.rowContainer}>
        <div title={keywordText} className={classes.keywordName}>{keywordText}</div>
        <div className={classes.keywordValue}>{displayValue}</div>
      </div>
      <ProgressBar maxValue={maxValue} value={value} />
    </div>
  );
};

export default KeywordItem;
