import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import VerificationActions from '../VerificationActions';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const VerificationPin = ({ verificationName, id, standAlone }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const [pin, setUserPin] = useState(null);

  const changePinHandler = (value) => {
    setUserPin(value);
  };

  const handleCompleteVerification = async () => {
    try {
      const {
        data: { verification },
      } = await api.post('/business_profile/verifyCompleteBusinessProfile', {
        businessProfileId: id,
        name: verificationName,
        pin,
      });
      if (verification) {
        showNotification({ type: 'success', message: t('businessProfileCreate.verificationSuccess') });
        navigate('/dashboard');
      }
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  return (
    <div className={clsx(classes.verificationNameWrapper, standAlone && classes.standAloneWrapper)}>
      <div className={classes.body}>
        <div className={classes.title}>{t('businessProfileCreate.finishVerification')}</div>
        <div className={classes.subTitle}>{t('businessProfileCreate.finishVerificationSubtitle')}</div>
        <Input
          className={classes.pinInput}
          label={t('businessProfileCreate.typeGivenCode')}
          onChange={changePinHandler}
        />
      </div>
      <VerificationActions handleVerification={handleCompleteVerification} />
    </div>
  );
};
export default VerificationPin;
