import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { stringCharactersTransformer } from '../../../../../shared/components/Input/transformers';
import { INPUT_MAX_LENGTH } from '../../AdministratorList/AddAdminModalForm/utils';

const UserData = ({ userData, onChange }) => {
  const { t } = useTranslation();
  const { roleType, login, name, lastName } = userData;

  return (
    <div className={classes.userDataWrapper}>
      <div className={classes.dataTitle}>{t('adminControlPanel.userData')}</div>
      <div className={classes.basicDataWrapper}>
        <div>
          <div className={classes.inputDataLabel}>{t('global.email')}</div>
          <Input admin className={classes.input} readOnly value={login} />
        </div>
        <div>
          <div className={classes.inputDataLabel}>{t('adminControlPanel.lastName')}</div>
          <Input
            admin
            className={classes.input}
            value={lastName}
            maxLength={INPUT_MAX_LENGTH}
            onChange={onChange('lastName')}
            transformer={stringCharactersTransformer}
          />
        </div>
        <div>
          <div className={classes.inputDataLabel}>{t('adminControlPanel.name')}</div>
          <Input
            admin
            className={classes.input}
            value={name}
            maxLength={INPUT_MAX_LENGTH}
            onChange={onChange('name')}
            transformer={stringCharactersTransformer}
          />
        </div>
        <div>
          <div className={classes.dataLabel}>{t('adminControlPanel.role')}</div>
          <div className={classes.dataValue}>{roleType}</div>
        </div>
      </div>
    </div>
  );
};

export default UserData;
