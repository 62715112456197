import { stepperContent } from '../Stepper/utils';

const OFFSTET = '50px';
export const getStepperDetails = (activeStep, t) => {
  const stepList = stepperContent(t);
  const activeStepData = stepList[activeStep - 1] || {};

  const activeStepTitle = activeStepData.title || '';
  const progress = activeStep / stepList.length;
  const progressInPercent = `${progress * 100}%`;
  const textTranslateX = `calc(${progressInPercent} - ${OFFSTET})`;

  return {
    activeStepTitle,
    progress,
    progressInPercent,
    textTranslateX,
  };
};
