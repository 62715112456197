import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import FilterTools from '../../modules/ClientSeoCardList/FilterTools';
import List from '../../modules/ClientSeoCardList/List';
import Pagination from '../../../../shared/components/Pagination';
import Loader from '../../components/Loader';
import EmptyState from '../../modules/ClientSeoCardList/EmptyState';
import { clinetSeoCardListParser } from './utils';
import { EXTERNAL_ADDRESSES, SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';
import { SORT_CARD_COLUMNS } from '../../../Admin/modules/SeoCards/SeoCardList/utils';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import Button from '../../../../shared/components/Button';
import { ReactComponent as CartIcon } from '../../../../shared/assets/shoppingCart.svg';
import { useModal } from '../../../../shared/helpers/hooks';
import RedirectModal from '../../modules/ClientSeoCardList/RedirectModal';
import { exportCSVDataHandler } from '../../../Admin/helpers/utils';

const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { active } = SORT_CARD_COLUMNS;

const ClientSeoCardList = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [showModal, setShowModal, toggleModal] = useModal();
  const [showConfirmRedirectModal, setShowConfirmRedirectModal, toggleConfirmRedirectModal] = useModal();
  const [totalResult, setTotalResult] = useState(0);
  const [cardListData, setListData] = useState([]);
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });
  const [orderBy, setOrderBy] = useState({ column: active, direction: ASC });
  const [isLoading, setIsLoading] = useState(false);

  const renderList = Boolean(cardListData.length) || Boolean(filters.dateFrom);

  const handleFiltersChange = (name) => (value) => {
    if (name === 'date') {
      const { dateFrom, dateTo } = value;
      setFilters((prev) => ({ ...prev, dateFrom, dateTo }));
      return;
    }
    if (name === 'offset') {
      setFilters((prev) => ({ ...prev, offset: prev.limit * (value - 1) }));
      return;
    }
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleRedirectConfirm = (name) => () => {
    window.open(name, '_blank');
    if (name === EXTERNAL_ADDRESSES.HELP_FORM) {
      toggleConfirmRedirectModal();
    } else {
      toggleModal();
    }
  };

  const fetchCardList = async () => {
    setIsLoading(true);
    const parsedFilters = removeNullValues(filters);
    try {
      const {
        data: { results, total },
      } = await api.post('/card/cardList', { filters: { ...parsedFilters, orderBy } });
      setTotalResult(total);
      setListData(clinetSeoCardListParser(results, t));
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const handleExportStatistic = async () => {
    const parsedFilters = removeNullValues({
      search: filters.search,
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      orderBy,
    });
    try {
      const response = await api.post(
        '/card/cardListExport',
        { filters: { ...parsedFilters, orderBy } },
        { responseType: 'blob' },
      );
      const fileName = 'card_list';
      exportCSVDataHandler({ response, fileName, showNotification, t });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  const page = useMemo(
    () => (filters.offset === 0 ? 1 : Math.round(filters.offset / filters.limit) + 1),
    [filters.offset, filters.limit],
  );

  useEffect(() => {
    fetchCardList();
  }, [filters, orderBy]);

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      {showModal && (
        <RedirectModal
          information={t('seoCard.redirectModalInformation')}
          onClose={toggleModal}
          onConfirm={handleRedirectConfirm(EXTERNAL_ADDRESSES.OPINIEO)}
        />
      )}
      <div className={classes.viewHeaderBar}>
        {t('seoCard.title')}
        <Button
          Icon={CartIcon}
          label={t('seoCard.purchaseCard')}
          className={classes.orderCartBtn}
          onClick={toggleModal}
        />
      </div>
      {renderList ? (
        <div className={classes.body}>
          <FilterTools onChange={handleFiltersChange} filters={filters} />
          <List data={cardListData} sortHandler={sortHandler} orderBy={orderBy} />
          <Pagination
            limit={filters.limit}
            total={totalResult}
            page={page}
            withExport
            exportHandler={handleExportStatistic}
            onLimitChange={handleFiltersChange('limit')}
            onPageChange={handleFiltersChange('offset')}
          />
        </div>
      ) : (
        <EmptyState
          buttonLabel={t('seoCard.contactForm')}
          withIcon={false}
          buttonOnClick={toggleConfirmRedirectModal}
          title={t('seoCard.noCard')}
          subTitle={t('seoCard.noCardSubtitle')}
        />
      )}
      {showConfirmRedirectModal && (
        <RedirectModal
          onClose={toggleConfirmRedirectModal}
          information={t('seoCard.redirtectHelpInformation')}
          onConfirm={handleRedirectConfirm(EXTERNAL_ADDRESSES.HELP_FORM)}
        />
      )}
    </div>
  );
};

export default ClientSeoCardList;
