import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as StarIcon } from '../../assets/star.svg';

const stars = [...(new Array(5))].map((el, index) => index + 1);

const Stars = ({ className, rating }) => {
  const roundedRating = Math.round(rating);
  return (
    <div className={clsx(classes.wrapper, className)}>
      {stars.map((el) => (
        <StarIcon key={el} className={clsx(classes.star, el <= roundedRating && classes.filled)}/>
      ))}
    </div>
  );
};

export default Stars;
