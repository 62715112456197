import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const LoaderSpinner = ({ className }) => (
  <div className={clsx(classes.wrapper, className)}>
    <div className={classes.spinner} />
  </div>
);

export default LoaderSpinner;
