export const filterTranslations = (list, value, t) => {
  if (!list) {
    return [];
  }
  if (!value) {
    return list;
  }
  const regex = new RegExp(value, 'i');
  return list.filter((el) => regex.test(t(el.name)));
};
