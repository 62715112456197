import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { plannedPostValidator } from '../../../../../shared/components/Input/validators';
import { getCorrectDate } from './utils';
import { formEnum } from '../ModalForm/utils/utils';

const PlannedPost = ({
  onPublicationDateChange,
  data = { type: null, publishDate: null, localPost: null },
  media = false,
  plannedPublishDate,
}) => {
  const { t } = useTranslation();
  const { publishDate, type, localPost } = data;
  const isFormWithDateInput = [formEnum.EVENT, formEnum.OFFER].includes(type);
  const [plannedPublicationDate, setPlannedPublicationDate] = useState({ date: '', time: '09:00' });
  const [plannedDateError, setPlannedDateError] = useState(false);

  const isPlannedAfterEnd = isFormWithDateInput
    && dayjs(plannedPublicationDate.date).isAfter(
      dayjs(Object.values(localPost?.event?.schedule?.endDate).join('-'), 'YYYY-MM-DD'),
    );

  const displayLocalPostError = isFormWithDateInput && (isPlannedAfterEnd || plannedDateError) && !media;
  const displayUpdatePostError = !isFormWithDateInput && plannedDateError;

  const onDateChange = (name) => (value) => {
    setPlannedPublicationDate((prev) => ({ ...prev, [name]: value }));
  };

  const validateAndSetPublicationDate = () => {
    const fullDate = `${plannedPublicationDate.date} ${plannedPublicationDate.time}`;
    const dayjsFullDateIsValid = dayjs(fullDate).isValid();
    if (dayjsFullDateIsValid) {
      onPublicationDateChange(fullDate);
    }
    setPlannedDateError(plannedPostValidator(publishDate));
  };

  const setDefaultTime = () => {
    if (!plannedPublicationDate.time) {
      setPlannedPublicationDate((prev) => ({ ...prev, time: '09:00' }));
    }
  };

  const updatePlannedPublicationDate = () => {
    const plannedPublicationDateValue = media ? plannedPublishDate : publishDate;
    if (plannedPublicationDateValue && dayjs(plannedPublicationDateValue).isValid()) {
      const [date, time] = plannedPublicationDateValue.split(' ');
      const correctDate = getCorrectDate(date);
      setPlannedPublicationDate((prevState) => {
        if (prevState.date !== correctDate || prevState.time !== time) {
          return { date: correctDate, time };
        }
        return prevState;
      });
    }
  };

  useEffect(validateAndSetPublicationDate, [plannedPublicationDate]);
  useEffect(setDefaultTime, [plannedPublicationDate.time]);
  useEffect(updatePlannedPublicationDate, [publishDate, plannedPublishDate, media]);

  return (
    <div>
      <div className={classes.plannedBox}>
        <Input
          className={classes.date}
          label={t('posts.publicationDateOptional')}
          type="date"
          onChange={onDateChange('date')}
          labelRised
          value={plannedPublicationDate.date}
        />
        {plannedPublicationDate.date && (
          <Input
            className={classes.time}
            label={t('posts.publicationTimeOptional')}
            type="time"
            onChange={onDateChange('time')}
            labelRised
            value={plannedPublicationDate.time}
          />
        )}
      </div>
      {Boolean(displayLocalPostError) && <div className={classes.error}>{t('posts.publicationDateAfterEndDate')}</div>}
      {Boolean(displayUpdatePostError) && (
        <div className={classes.error}>{t('businessProfileEdit.postoponedMediaMustBeAfterToday')}</div>
      )}
    </div>
  );
};
export default PlannedPost;
