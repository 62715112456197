import clsx from 'clsx';
import { Tile as TileLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { useTranslation } from 'react-i18next';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import { Feature, Overlay } from 'ol';
import { Circle } from 'ol/geom';
import React, { useEffect, useRef } from 'react';
import NoImage from '../../../../../shared/assets/image.svg';
import classes from './styles.module.css';
import {
  addFeaturesToLayer,
  addLayerToMap,
  createLayer,
  createCircleFeature,
  getExtentOfCoordinates,
  setStyleofCircleFeature,
  returnGradientCircleStyle,
  getLayerExtent,
  fitMapToExtent,
  createCursorFeature,
} from './utils';
import Pill from '../../../../../shared/components/Pill';
import { getColorByPosition } from '../../../helpers/colors';
import { POSITION_ABOVE_20 } from '../../../helpers/constants';

const MapRender = ({
  className,
  coordinates,
  selectedPlace,
  setSelectedPoint,
  area,
  highlighted,
  filters,
  selectedPoint,
}) => {
  const { t } = useTranslation();
  const map = useRef({});
  const mapToDrawRef = useRef(null);
  const layer = useRef({});
  const featureLayer = useRef({});
  const markerLayer = useRef({});
  const layerName = 'reportLayer';
  const selectedFeature = useRef({});
  const markerFeature = useRef({});
  const overlay = useRef({});
  const overlayDiv = useRef(null);
  const placeNumber = useRef();
  const currentlyLookedPlace = useRef(null);
  const { distance } = filters;

  const photoToRender = highlighted?.photo ?? NoImage;
  const shouldRenderPositionPill = !!Object.keys(selectedFeature.current).length;

  const getSelectedPlaceByPlaceId = () => coordinates
    .map((coordinate) => (coordinate?.places ? coordinate?.places : []))
    .flat()
    .find((place) => place.placeReferenceId === selectedPlace);

  const getInitialPlace = () => getSelectedPlaceByPlaceId();

  const updateMarker = (point) => {
    const canChangeMarker = Object.keys(markerFeature.current).length && coordinates.length;
    if (canChangeMarker) {
      markerFeature.current.getGeometry().setCoordinates(fromLonLat([point.lng, point.lat]));
    } else {
      markerFeature.current = createCursorFeature(point, map.current);
      addFeaturesToLayer(markerLayer.current, [markerFeature.current]);
    }
  };
  const removeSelectedFeature = () => {
    featureLayer.current.getSource().removeFeature(selectedFeature.current);
    selectedFeature.current = {};
  };

  useEffect(() => {
    if (!selectedPoint && Object.keys(selectedFeature.current).length) {
      removeSelectedFeature();
    }
  }, [selectedPoint, selectedFeature.current]);

  const handleChangeMarker = () => {
    const initialPlace = getInitialPlace();
    if (!initialPlace) return;

    updateMarker(initialPlace);
  };

  useEffect(() => {
    const canSetStyles = Object.keys(featureLayer.current).length && coordinates.length;

    const applyStylesToFeatures = () => {
      const features = featureLayer.current
        .getSource()
        .getFeatures()
        .filter((item) => item.getProperties().properties.id);
      features.forEach((feature) => {
        const { id } = feature.getProperties().properties;
        const correspondingPoint = coordinates.filter((point) => point.id === id && !point.cursor);
        if (id) {
          setStyleofCircleFeature(feature, correspondingPoint[0], selectedPlace);
        }
      });
    };

    if (canSetStyles) {
      removeSelectedFeature();
      handleChangeMarker();
      applyStylesToFeatures();
      currentlyLookedPlace.current = selectedPlace;
    }
  }, [selectedPlace]);

  const createPoints = (arrayOfCoordinates, selectedCompany) => arrayOfCoordinates.map((place) => createCircleFeature(place, selectedCompany));

  const setPlaceNumber = (id) => {
    const correspondingPoint = coordinates.find((point) => point.id === id && !point.cursor);
    if (correspondingPoint) {
      const place = correspondingPoint.places.find((item) => item.placeReferenceId === currentlyLookedPlace.current);
      if (place && typeof place.order === 'number') {
        placeNumber.current = String(place.order + 1);
      } else {
        placeNumber.current = POSITION_ABOVE_20;
      }
    } else {
      placeNumber.current = POSITION_ABOVE_20;
    }
  };

  const updateSelectedFeatureColor = (feature) => {
    // eslint-disable-next-line no-underscore-dangle
    const color = feature.getStyle().fill_;
    selectedFeature.current.setStyle(returnGradientCircleStyle(color));
  };

  const handleSelectCircle = (clickedFeature) => {
    removeSelectedFeature();
    const { id } = clickedFeature.getProperties().properties;
    if (id) {
      setPlaceNumber(id);
      setSelectedPoint(id);
      selectedFeature.current = new Feature({
        geometry: new Circle(clickedFeature?.getGeometry()?.getCenter(), area),
        properties: {
          area: true,
        },
      });
      updateSelectedFeatureColor(clickedFeature);
      addFeaturesToLayer(featureLayer.current, [selectedFeature.current]);
      fitMapToExtent(map.current, getLayerExtent(featureLayer.current));
    }
  };

  const handleMapClick = (event) => {
    const clickedFeatures = map.current.getFeaturesAtPixel(event.pixel);
    if (clickedFeatures.length > 0 && !clickedFeatures[0].getProperties().properties.area) {
      handleSelectCircle(clickedFeatures[0]);
    } else {
      setSelectedPoint(null);
      removeSelectedFeature();
    }
  };

  const handlePointerMove = (event) => {
    const features = map.current.getFeaturesAtPixel(event.pixel, {
      layerFilter: (layerToFound) => layerToFound === markerLayer.current,
    });
    const canSetPositionToMarkerFeature = features && features.length > 0 && features.find((feature) => feature.get('id') === 'cursorFeature');
    if (canSetPositionToMarkerFeature) {
      overlay.current.setPosition(markerFeature.current.getGeometry().getCoordinates());
    } else {
      overlay.current.setPosition(undefined);
    }
  };

  const unmountMap = () => {
    if (map.current && typeof map.current.setTarget === 'function') {
      map.current.setTarget(null);
      map.current = null;
    }
  };

  useEffect(() => {
    if (!Object.keys(map.current || {}).length) {
      layer.current = new TileLayer({
        source: new OSM(),
        className: 'map-layer',
        preload: 1,
      });
      map.current = new Map({
        layers: [layer.current],
        controls: [],
        target: mapToDrawRef.current,
        view: new View({
          maxResolution: 30 * distance,
          minResolution: 0.2 * distance,
        }),
      });
      const featuresToAdd = [...createPoints(coordinates, selectedPlace)];
      featureLayer.current = createLayer(layerName);
      addFeaturesToLayer(featureLayer.current, featuresToAdd);
      addLayerToMap(map.current, featureLayer.current);

      markerLayer.current = createLayer('markerLayer');
      addLayerToMap(map.current, markerLayer.current);

      const initMarker = getSelectedPlaceByPlaceId();
      if (initMarker) {
        markerFeature.current = createCursorFeature(initMarker);
        const markerFeatures = [markerFeature.current];
        addFeaturesToLayer(markerLayer.current, markerFeatures);
        markerLayer.current.setZIndex(20); // Ensure marker layer is on top
      }

      map.current.on('click', (event) => {
        handleMapClick(event);
      });

      overlay.current = new Overlay({
        element: overlayDiv.current,
        positioning: 'bottom-center',
        offset: [0, -5],
      });
      map.current.addOverlay(overlay.current);
      map.current.on('postcompose', (event) => {
        document.querySelector('.map-layer').style.filter = 'grayscale(100%)';
      });

      const view = map.current.getView();
      const placesExtent = getExtentOfCoordinates(coordinates.map(({ lng, lat }) => fromLonLat([lng, lat])));
      view.fit(placesExtent, { padding: [100, 100, 100, 100] });
      map.current.on('pointermove', (event) => {
        handlePointerMove(event);
      });
    }
  }, [coordinates, selectedPlace, distance]);

  useEffect(() => {
    currentlyLookedPlace.current = selectedPlace;
  }, [selectedPlace]);

  useEffect(
    () => () => {
      if (map.current) {
        unmountMap();
      }
    },
    [],
  );

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div ref={overlayDiv} className={classes.popupwrapper}>
        {highlighted && (
          <>
            <img src={photoToRender} className={classes.image} alt="place cover" />
            {shouldRenderPositionPill && (
              <Pill
                color={getColorByPosition({ position: placeNumber.current })}
                className={classes.pill}
                label={t('generateReport.positionInPoint', { position: placeNumber.current.toString() })}
              />
            )}
            <div className={classes.popuptext}>
              <p className={classes.name}>{highlighted.name}</p>
              <span className={classes.vicinity}>{highlighted.vicinity}</span>
            </div>
          </>
        )}
      </div>
      <div
        ref={mapToDrawRef}
        className={clsx(classes.map, className, 'map-container')}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default MapRender;
