import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { features } from './utils';
import FeatureWrapper from '../FeatureWrapper';
import classes from './styles.module.css';
import { useIsMobile } from '../../../../shared/helpers/hooks';
import { MAX_TABLET_WIDTH } from '../../../SEO/helpers/constants';

const Features = () => {
  const { t } = useTranslation();
  const [expandedFeature, setExpandedFeature] = useState(features(t)[0]);
  const isMobileScreen = useIsMobile(MAX_TABLET_WIDTH);

  const onExpandFeatureHandler = (feature) => {
    setExpandedFeature((prev) => (prev.title === feature.title && prev.title !== t('landingPage.featuresSection.generatingReport')
      ? features(t)[0]
      : feature),);
  };

  const renderFeature = (feature) => (
    <FeatureWrapper
      key={feature.title}
      expandedFeature={expandedFeature}
      feature={feature}
      onFeatureClick={onExpandFeatureHandler}
    />
  );
  const renderMobileFeature = (feature) => (
    <FeatureWrapper
      key={feature.title}
      expandedFeature={expandedFeature}
      feature={feature}
      onFeatureClick={onExpandFeatureHandler}
      isMobileScreen
    />
  );
  return (
    <section className={classes.sectionWrapper}>
      <div className={classes.leftSide}>
        <p className={classes.sectionName} id="features">
          {t('landingPage.features')}
        </p>
        <h2 className={classes.sectionHeading}>{t('landingPage.featuresSection.title')}</h2>
        <div className={classes.featureList}>{features(t, false).map(renderFeature)}</div>
      </div>
      <img src={expandedFeature.image} alt="feature" className={classes.image} />
      {isMobileScreen && (
        <div className={classes.mobileFeatureListWrapper}>{features(t, true).map(renderMobileFeature)}</div>
      )}
    </section>
  );
};

export default Features;
