import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';
import Select from '../../../../../shared/components/Select';
import { useNotification } from '../../../../../shared/helpers/notification';

const SelectBusiness = ({ businessProfiles, handleChange, businessProfile }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { id, placeReferenceId } = businessProfile;

  const filterListFn = ({ list, value }) => {
    if (!value) return list;
    return list.filter((el) => el.name.toLowerCase().includes(value.toLowerCase()));
  };

  useEffect(() => {
    if (!placeReferenceId) {
      showNotification({
        type: 'error',
        message: t('setFilters.profileNotVerified'),
      });
    }
  }, [placeReferenceId]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionName}>{t('setFilters.selectCompany')}</div>
      <Select
        className={classes.inputSelect}
        inputClassName={classes.inputSelection}
        label={`${t('setFilters.yourBusinessCards', { number: businessProfiles.length })}`}
        list={businessProfiles}
        onSelect={handleChange('businessProfileId')}
        value={id}
        iconStyle={classes.iconStyle}
        filterList={filterListFn}
      />
      {!placeReferenceId && <div className={classes.profileNotVerified}>{t('setFilters.profileNotVerified')}</div>}
      <div className={classes.horizontalLine} />
    </div>
  );
};

export default SelectBusiness;
