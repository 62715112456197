import React from 'react';
import Loader from '../../../app/SEO/components/Loader';

const withLoading = ({ Component, className }) => {
  const WithLoadingComponent = ({ isLoading, ...props }) => {
    if (!isLoading) return <Component props={props} />;
    return <div><Loader className={className} /></div>;
  };
  return WithLoadingComponent;
};

export default withLoading;
