import React from 'react';
import classes from './styles.module.css';
import { formEnum } from '../ModalForm/utils/utils';
import { getScheduleFromSinglePost } from '../../../views/AddPost/utils';

const PostDetails = ({ data }) => {
  const { title } = data.event;
  const validSchedule = getScheduleFromSinglePost(data.event.schedule);

  return (
    data.type !== formEnum.STANDARD && (
      <>
        <div className={classes.title}>{title}</div>
        <div className={classes.schedule}>{validSchedule}</div>
      </>
    )
  );
};
export default PostDetails;
