import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';

const UserSeoCard = ({ card }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cardName, cardCode, bpName, bpAddress, active } = card;
  const connectedBpName = bpName?.length ? bpName : t('adminControlPanel.noBusinessProfile');
  const connectedBpAddress = bpAddress?.length ? bpAddress : '-';

  const cardStatus = active ? t('adminControlPanel.seoCardActive') : t('adminControlPanel.seoCardInactive');

  const showDetailsHandler = () => {
    navigate(`/admin/cards/${card.id}`);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.businessCard}>
        <div className={classes.businessCardText}>
          <div className={classes.businessCardName}>{cardCode}</div>
          <div className={classes.businessCardAddress}>{cardName}</div>
        </div>
      </div>
      <div className={classes.connectedCard}>
        <div className={classes.cardName}>{connectedBpName}</div>
        <div className={classes.cardAdress}>{connectedBpAddress}</div>
      </div>
      <div className={classes.rightSide}>
        <Pill label={cardStatus} className={clsx(classes.seoCardPill, !active && classes.inactiveCard)} />
        <Button
          label={t('adminControlPanel.details')}
          className={classes.showDetailsBtn}
          onClick={showDetailsHandler}
        />
      </div>
    </div>
  );
};

export default UserSeoCard;
