import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { OpinionListActionEnum, limitOpinionList, sortOpinionList } from '../../../views/Opinions/utils';
import SelectAlternative from '../../../../../shared/components/Select/SelectAlternative';
import Button from '../../../../../shared/components/Button';
import { useModal } from '../../../../../shared/helpers/hooks';
import AnswerWithTemplateModal from '../AnswerWithTemplateModal';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { ReactComponent as CheckIcon } from '../../../../../shared/assets/checkSquare.svg';

const OpinionSortActions = ({
  onClick,
  limitValue,
  orderValue,
  onSelect,
  selectedReviews,
  answerSelectedReviews,
  adminPreview,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile: { subscription } } = useDashboard();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal, modalHandler] = useModal();
  const buttonLabel = selectedReviews?.length ? t('opinions.useTemplate') : t('businessProfileEdit.selectFew');
  const btnClickHandler = selectedReviews?.length ? modalHandler : onSelect;
  const isFreeSubPlan = subscription === 'free';

  const templateChooseHandler = async (template) => {
    const foudnTemplate = templates.find((el) => el.content === template);
    setShowModal(false);
    await answerSelectedReviews(foudnTemplate.id);
  };

  const fetchTemplates = async () => {
    const controller = new AbortController();
    setIsLoading(true);
    try {
      const { data } = await api.post(
        '/review_reply_template/getTemplates',
        { orderBy: 'created_at', order: 'DESC' },
        { signal: controller.signal },
      );
      setTemplates(data.templates);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
    return () => controller.abort();
  };

  useEffect(() => {
    if (showModal) {
      fetchTemplates();
    }
  }, [showModal]);

  return (
    <div>
      <div className={classes.actions}>
        {showModal && !isLoading && (
          <AnswerWithTemplateModal onClick={templateChooseHandler} templates={templates} onClose={modalHandler} />
        )}
        <div className={clsx(classes.filterActions, adminPreview && classes.adminPreview)}>
          <SelectAlternative
            className={classes.selectAlternative}
            list={limitOpinionList}
            onSelect={onClick(OpinionListActionEnum.limit)}
            showOnList={(val) => t(val.name, { number: val.value })}
            value={limitValue}
          />
          <SelectAlternative
            list={sortOpinionList}
            onSelect={onClick(OpinionListActionEnum.orderBy)}
            value={orderValue}
            showOnList={(val) => t(val.name)}
          />
        </div>
        {!adminPreview && !isFreeSubPlan && (
          <Button onClick={btnClickHandler} label={buttonLabel} className={classes.selectBtn} Icon={CheckIcon} />
        )}
      </div>
      <div className={classes.horizontalLine} />
    </div>
  );
};
export default OpinionSortActions;
