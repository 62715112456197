import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';

const ActionBox = ({ readOnlyAction, handleCancel, handleSave, className }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.buttons, className)}>
      <Button
        className={classes.button}
        label={t('businessProfileEdit.save')}
        onClick={handleSave}
        readOnly={readOnlyAction}
      />
      <Button
        className={clsx(classes.button, classes.cancel)}
        label={t('businessProfileEdit.cancel')}
        onClick={handleCancel}
      />
    </div>
  );
};
export default ActionBox;
