import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { formSuccessIndicatorConfig } from './utils';

const FormSuccessIndicator = ({ className, status, disabled, radius }) => {
  const iconSize = `${radius - 6}px`;
  const wrapperSize = `${radius}px`;
  const Icon = formSuccessIndicatorConfig[status];

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={clsx(classes.icon, classes[status])} style={{ width: wrapperSize, height: wrapperSize }}>
        {Icon && <Icon className={classes[status]} width={iconSize} height={iconSize} />}
      </div>
    </div>
  );
};

export default FormSuccessIndicator;
