import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as NoImage } from '../../../../../shared/assets/noImage.svg';
import { ReactComponent as GoogleProfile } from '../../../../../shared/assets/internalGoogleProfile.svg';

const BusinessItemImage = (props) => {
  const { image, externalBusinessProfile } = props;
  return (
    <div className={classes.imageContainer}>
      {image ? (
        <img className={classes.profileImage} src={image} alt="business card" />
      ) : (
        <NoImage className={classes.noImagePlaceholder} />
      )}
      {!externalBusinessProfile && <GoogleProfile className={classes.googleIcon} />}
    </div>
  );
};
export default BusinessItemImage;
