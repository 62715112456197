import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import { ReactComponent as Add } from '../../../../../shared/assets/addCircle.svg';

const EmptyState = ({ title, subTitle, buttonLabel, buttonOnClick, withIcon = true }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.content}>
      <CircleIconText headingStyle={classes.headingStyle} heading={title} supportingTextStyle={classes.supportingText} supportingText={subTitle} icon={Magnifier} />
      {buttonLabel && (
        <Button
          onClick={buttonOnClick}
          Icon={withIcon && Add}
          iconWidth={20}
          label={t(buttonLabel)}
          className={classes.button}
        />
      )}
    </div>
  );
};

export default EmptyState;
