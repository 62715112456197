import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import Button from '../../../../../shared/components/Button';
import { debounce } from '../../../../../shared/helpers/debounce';
import Calendar from '../../../../../shared/components/Calendar';
import { useDashboard } from '../../../helpers/dashboard';
import { ReactComponent as CalendarIcon } from '../../../../../shared/assets/calendar.svg';

const Filters = ({ className, filters, onChange, adminPreview }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessProfile } = useDashboard();
  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => setShowCalendar((prev) => !prev);

  const generateReport = () => {
    navigate({
      pathname: '/set_filters',
      search: `?businessProfileId=${businessProfile.id}`,
    });
  };
  const handleDateChange = (date) => {
    const { dateFrom, dateTo } = date;
    onChange({ ...filters, dateFrom, dateTo });
    toggleCalendar();
  };
  const onCloseCalendar = () => {
    setShowCalendar(false);
    onChange({ businessProfileId: businessProfile.id });
  };
  const handleKeyword = useCallback(
    debounce((val) => onChange({ ...filters, keyword: val }), 500),
    [filters],
  );
  return (
    <div className={clsx(classes.wrapper, className)}>
      <Button onClick={generateReport} className={classes.newReportBtn} label={t('reportList.generateReport')} />
      <Input
        className={classes.input}
        value={filters.keyword}
        onChange={handleKeyword}
        label={t('reportList.searchByKeyword')}
      />
      <Button
        onClick={toggleCalendar}
        className={classes.filters}
        Icon={CalendarIcon}
        iconStyle={classes.calendarIconStyle}
        label={t('reportList.selectDate')}
      />
      {showCalendar && (
        <Calendar
          className={clsx(classes.calendar, adminPreview && classes.calendarAdminPreview)}
          onClose={onCloseCalendar}
          filters={filters}
          onChange={handleDateChange}
          quickSelect
          isSingleMonth={false}
        />
      )}
    </div>
  );
};

export default Filters;
