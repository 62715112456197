import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as Step2LocationSvg } from '../../../../../shared/assets/step2.2.svg';
import Select from '../../../../../shared/components/Select';
import { COUNTRIES } from '../../../helpers/constants';
import { filterCountries } from './utils';
import { formEnum } from '../WelcomeScreenCreate/utils';

const FormCountryLocation = ({ onChange, form, scrollOnFocus }) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState();

  const selectCountryHandler = (id) => {
    const foundCountry = COUNTRIES.find((el) => el.id === id);
    setSelectedCountry(foundCountry.id);
    onChange(formEnum.regionCode)(foundCountry.code.toLowerCase());
  };

  useEffect(() => {
    if (!selectedCountry) {
      const countryFromLanguageCode = COUNTRIES.find((country) => country.code.toLowerCase() === form.languageCode);

      if (countryFromLanguageCode) {
        onChange(formEnum.regionCode)(countryFromLanguageCode.code.toLowerCase());
        setSelectedCountry(countryFromLanguageCode.id);
      }
    }
  }, []);

  const filterCountriesFn = useCallback(({ list, value }) => filterCountries(list, value, t), []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftSide}>
        <div className={classes.form}>
          <div className={classes.formTitle}>
            <div className={classes.title}>{t('businessProfileCreate.whatIsYourCountry')}</div>
          </div>
          <div onFocus={scrollOnFocus} className={classes.form}>
            <Select
              filterList={filterCountriesFn}
              className={classes.input}
              list={COUNTRIES}
              value={selectedCountry}
              label={t('businessProfileCreate.selectCountry')}
              onSelect={selectCountryHandler}
            />
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <Step2LocationSvg />
      </div>
    </div>
  );
};

export default FormCountryLocation;
