export const informationProvidedCheck = (form) => Boolean(form.title && form.categories.primaryCategory?.categoryReferenceId);

const LOCATION_GROUP_CONFIG = {
  PERSONAL: 'businessProfileCreate.personalType',
  LOCATION_GROUP: 'businessProfileCreate.locationGroupType',
  USER_GROUP: 'businessProfileCreate.userGroupType',
  ORGANIZATION: 'businessProfileCreate.organizationType',
};
export const parseLocations = (locations, t) => locations.map((el, index) => {
  const { id, name, type } = el;
  return {
    id,
    name,
    type: t(LOCATION_GROUP_CONFIG[type]),
    selected: index === 0,
  };
});

export const showOnListLocationValue = (value) => {
  if (!value) return '';
  const { name, type } = value;
  return `${name} - ${type}`;
};
