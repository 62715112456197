import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import AdminLoader from '../../../components/AdminLoader';
import { trimValues } from '../../../../../shared/helpers/helpers';
import AsyncSelect from '../../../../../shared/components/AsyncSelect';
import { emailValidator, makeRequired } from '../../../../../shared/components/Input/validators';
import { debounce } from '../../../../../shared/helpers/debounce';
import Button from '../../../../../shared/components/Button';

const AddManagerModal = ({ onClose, onListFetch }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: '',
    manager: true,
  });
  const [emailError, setEmailError] = useState('');
  const [emailValue, setEmailValue] = useState('');

  const saveManager = async () => {
    const parsedForm = trimValues(form);
    setLoading(true);
    try {
      await api.post('/acp/client/updateUserManager', parsedForm);
      await onListFetch();
      onClose();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const onSelectEmailHander = (value) => {
    setEmailValue(value);
    setForm({ ...form, email: value });
  };

  const resetForm = () => {
    setEmailValue('');
  };

  const handleGetEmailList = useCallback(
    debounce(async (payload) => {
      if (!payload.value || payload.value.length <= 3) {
        resetForm();
        return [];
      }
      try {
        const {
          data: { emails },
        } = await api.post('/acp/client/autocompleteNotManager', {
          email: payload.value,
        });
        return emails;
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
      return [];
    }, 500),
    [],
  );

  const saveBtnReadonly = useMemo(() => !form.email || form.email.length <= 0, [form.email]);

  useEffect(() => {
    const { email } = form;
    const emailInvalid = makeRequired(emailValidator)(email);
    setEmailError(emailInvalid);
  }, [form]);

  const renderLoader = () => (
    <div className={classes.loaderWrapper}>
      <AdminLoader />
    </div>
  );

  const renderErrorState = () => {
    if (form.email && makeRequired(emailValidator)(form.email)) {
      return <span className={classes.error}>{t(emailError)}</span>;
    }
    return null;
  };

  return (
    <Modal
      contentStyle={classes.modalContent}
      titleStyle={classes.titleStyle}
      onCancel={onClose}
      modalTitle={t('adminControlPanel.addManager')}
      customIconStyle={classes.customCloseStyle}
    >
      <div>
        {loading && renderLoader()}
        <div className={classes.label}>
          {t('adminControlPanel.email')}
          *
          <AsyncSelect
            withChevron
            placeholder={t('adminControlPanel.searchEmail')}
            labelRised
            admin
            onListRead={handleGetEmailList}
            showOnList={(v) => v?.email || v}
            onSelect={onSelectEmailHander}
            value={emailValue}
            error={emailError}
          />
          {renderErrorState()}
        </div>
        <div className={classes.btnContainer}>
          <Button
            className={classes.saveCardBtn}
            label={t('adminControlPanel.addManager')}
            onClick={saveManager}
            readOnly={saveBtnReadonly}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddManagerModal;
