import dayjs from 'dayjs';
import React from 'react';
import Pill from '../../../../shared/components/Pill';
import { ReactComponent as DownloadIcon } from '../../../../shared/assets/download.svg';
import classes from './styles.module.css';
import IconWrapper from '../../../../shared/components/IconWrapper';
import { getColorByPosition } from '../../helpers/colors';

export const config = [
  {
    key: 'createdAt',
    header: 'reportList.reportDate',
    sort: 'created_at',
    render: (val) => dayjs(val).format('DD/MM/YYYY HH:mm'),
    width: 1,
  },
  {
    key: 'keyword',
    header: 'reportList.keyword',
    sort: 'keyword',
    render: (val) => <Pill className={classes.keywordWrap} label={val} color="var(--primary900)" border={false} />,
    width: 1,
  },
  {
    key: 'overallPosition',
    header: 'reportList.position',
    sort: 'min_position',
    render: (val) => (
      <Pill
        className={classes.positionPill}
        label={val || '20+'}
        color={getColorByPosition({ position: val ? val + 1 : 20 })}
        border
      />
    ),
    width: 0.5,
  },
  {
    key: 'net',
    header: 'reportList.grid',
    sort: 'net',
    render: (val) => `${val} x ${val}`,
    width: 0.5,
  },
  {
    key: 'area',
    header: 'reportList.searchArea',
    sort: 'area',
    render: (val) => `${val / 1000} km`,
    width: 1,
  },
  {
    key: 'distance',
    header: 'reportList.pointsDistance',
    sort: 'distance',
    render: (val) => `${val / 1000} km`,
    width: 1,
  },
  {
    key: 'download',
    action: true,
    render: () => (
      <IconWrapper width="20px" Icon={DownloadIcon} color="var(--grey800)" hoverColor="var(--primary500)" />
    ),
    header: '',
    width: '45px',
  },
];
