import dayjs from 'dayjs';

const mediaConfig = (t) => ({
  PROFILE: t('businessProfileEdit.profileImage'),
  ADDITIONAL: t('businessProfileEdit.additionalImages'),
  COVER: t('businessProfileEdit.coverImage'),
  MENU: t('businessProfileEdit.menuImage'),
});
const getCreateDate = (createTime) => dayjs(createTime).locale('pl').format('MMM YY');

const checkIfImage = (mediaFormat) => mediaFormat === 'PHOTO';

export const getPhotoType = (isImage, locationAssociation, t) => (isImage ? mediaConfig(t)[locationAssociation.category] : t('businessProfileEdit.video'));

const getPillLabel = (photoType, createDate) => `${photoType}\n${createDate}`;
export const getVideoUrl = (url) => {
  const [source, type] = url.split('=');
  return `${source}=m18`;
};
const getSourceUrl = (isImage, googleUrl) => (isImage ? googleUrl : getVideoUrl(googleUrl));

export const parsePostponedMedia = (t, media) => media.map((mediaItem) => {
  const createDate = dayjs(mediaItem.createTime).locale('pl').format('MMM YY');
  const [fileName, fileExtension] = mediaItem.name.split('.');
  const isImage = fileExtension !== 'mp4';
  const locationAssociation = { category: mediaItem.category };
  const photoType = getPhotoType(isImage, locationAssociation, t);
  const pillLabel = `${photoType}\n${createDate}`;
  const sourceUrl = mediaItem.link;
  const thumbnailUrl = mediaItem.link;
  const publishDate = dayjs(mediaItem.publishDate).format('DD.MM.YY HH:mm');

  return {
    ...mediaItem,
    id: mediaItem.id,
    sourceUrl,
    pillLabel,
    isImage,
    locationAssociation,
    thumbnailUrl,
    publishDate,
  };
});

export const parseToGalleryMediaItem = (t, mediaItemList = []) => mediaItemList.map((mediaItem) => {
  const createDate = getCreateDate(mediaItem.createTime);
  const isImage = checkIfImage(mediaItem.mediaFormat);
  const photoType = getPhotoType(isImage, mediaItem.locationAssociation, t);
  const pillLabel = getPillLabel(photoType, createDate);
  const sourceUrl = getSourceUrl(isImage, mediaItem.googleUrl);

  return {
    ...mediaItem,
    id: mediaItem.id,
    sourceUrl,
    pillLabel,
    isImage,
    locationAssociation: mediaItem.locationAssociation,
  };
});

export const separateItemsByIdAndName = (items) => items.reduce(
  (acc, item) => {
    if (item.publishDate) {
      acc.media_id.push(item.id);
    } else {
      acc.name.push(item.name);
    }
    return acc;
  },
  { name: [], media_id: [] },
);

export const combineMediaItems = (t, actualMediaData = [], postponedMediaData = []) => {
  const actualMedia = parseToGalleryMediaItem(t, actualMediaData);
  const postponedMedia = parsePostponedMedia(t, postponedMediaData) || [];
  return postponedMedia.concat(actualMedia);
};

export const getOnDeletePayload = (id, deleteCollection, selectedItems, file) => {
  const payload = { businessProfileId: id };

  const processItems = (items, condition, key) => {
    const filteredItems = items.filter(condition).map((item) => item[key]);
    if (filteredItems.length > 0) {
      if (key === 'id') {
        payload.mediaId = filteredItems;
      } else {
        payload[key] = filteredItems;
      }
    }
  };

  if (deleteCollection) {
    processItems(selectedItems, (item) => item.publishDate, 'id');
    processItems(selectedItems, (item) => !item.publishDate, 'name');
  } else {
    processItems([file], (item) => item.publishDate, 'id');
    processItems([file], (item) => !item.publishDate, 'name');
  }

  return payload;
};
