import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const Pill = ({ className, label, color, Icon, iconStyle, border = true }) => (
  <div
    className={clsx(classes.wrapper, className)}
    style={{ borderColor: border ? color : `${color}15`, color, backgroundColor: `${color}15` }}
  >
    {label}
    {Icon && <Icon className={iconStyle} />}
  </div>
);

export default Pill;
