import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Modal from '../Modal';
import classes from './styles.module.css';
import Button from '../Button';

const DesactivateCardModal = ({ onClose, activationHandler, client }) => {
  const { t } = useTranslation();

  const desactivateHandler = async () => {
    await activationHandler();
    onClose();
  };

  return (
    <Modal
      onCancel={onClose}
      modalTitle={t('adminControlPanel.desactivateCardModalTitle')}
      contentStyle={classes.modalContent}
    >
      <div className={classes.modalBody}>
        {t('adminControlPanel.desactivateCardModalDescription')}
        <div className={classes.actions}>
          <Button
            className={clsx(classes.cancelBtn, client && classes.clientCancelBtn)}
            label={t('global.cancel')}
            onClick={onClose}
          />
          <Button
            className={client && classes.clientConfirmBtn}
            label={t('global.confirm')}
            onClick={desactivateHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DesactivateCardModal;
