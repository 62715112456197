export const generatePayload = (businessProfileId, keywords, model, content, type) => {
  const apiPayload = {
    businessProfileId,
    keywords,
    model,
    content,
    type,
  };
  const apiPath = type ? '/local_post/generateLocalPost' : '/business_profile/generateDescription';
  return { apiPath, apiPayload };
};

export const aiModelConfig = {
  'gpt-3.5-turbo': 'GPT 3.5 Turbo',
  'gpt-4': 'GPT 4',
  'gpt-4-turbo-preview': 'GPT 4 Turbo Preview',
};
