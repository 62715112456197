import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { NotificationContext } from '../../../../shared/helpers/notification';
import Notification from '../../../../shared/components/Notification';
import Topbar from '../../../../shared/components/Topbar';
import classes from './styles.module.css';

const ReportLayout = () => {
  const [notificationQueue, setNotificationQueue] = useState([]);

  const handleClose = () => {
    setNotificationQueue((prev) => {
      const [first, ...rest] = prev;
      return rest;
    });
  };

  const providerValue = useMemo(() => {
    const showNotification = (notification) => {
      setNotificationQueue((prev) => ([...prev, { ...notification, key: Math.random() }]));
    };
    return { showNotification };
  }, []);

  return (
    <div className={classes.wrapper}>
      <NotificationContext.Provider value={providerValue}>
        <Topbar />
        <Outlet/>
        {notificationQueue[0] && (
          <Notification
            label={notificationQueue[0].label}
            message={notificationQueue[0].message}
            type={notificationQueue[0].type}
            payload={notificationQueue[0].payload}
            key={notificationQueue[0].key}
            onClose={handleClose}
          />
        )}
      </NotificationContext.Provider>
    </div>
  );
};

export default ReportLayout;
