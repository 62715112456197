import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const CharCounter = ({ className, value, maxLength }) => (
  <div className={clsx(classes.wrapper, className)}>
    {`${value}/${maxLength}`}
  </div>
);
export default CharCounter;
