import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import Button from '../../../../../shared/components/Button';

const ResignModal = ({ modalHandler, navigateToDashboard }) => {
  const { t } = useTranslation();

  return (
    <Modal
      onCancel={modalHandler}
      contentStyle={classes.modalWrapper}
      modalTitle={t('businessProfileCreate.resignToCreateBusinessProfile')}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.modalSubinfo}>
          {t('businessProfileCreate.resignToCreateBusinessProfileSubinfo')}
        </div>
        <div className={classes.modalActions}>
          <Button
            label={t('global.cancel')}
            className={clsx(classes.btn, classes.cancelBtn)}
            onClick={modalHandler}
          />
          <Button
            label={t('global.resign')}
            className={clsx(classes.btn, classes.resignBtn)}
            onClick={navigateToDashboard}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ResignModal;
