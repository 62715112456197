export const filterListFn = ({ list, value }) => {
  if (!list) {
    return [];
  }
  if (!value) {
    return list;
  }
  const regex = new RegExp(value, 'i');
  return list.filter((el) => regex.test(el.name));
};
