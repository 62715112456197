export const isSelectedMonth = (monthId, selectedPeriod) => {
  const { start, end, year: displayedYear } = selectedPeriod;

  if (start && end) {
    const { month: startMonth, year: startYear } = start;
    const { month: endMonth, year: endYear } = end;

    if (displayedYear > startYear && displayedYear < endYear) {
      return true;
    }

    if (displayedYear === startYear && displayedYear === endYear) {
      return monthId >= startMonth && monthId <= endMonth;
    }

    if (displayedYear === startYear && displayedYear !== endYear) {
      return monthId >= startMonth;
    }

    if (displayedYear !== startYear && displayedYear === endYear) {
      return monthId <= endMonth;
    }
  }

  if (start && !end) {
    const { month: startMonth, year: startYear } = start;
    return startYear === displayedYear && monthId === startMonth;
  }

  return false;
};
