import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as Arrow } from '../../../../../shared/assets/arrowAlternative.svg';
import StepperRWD from '../StepperRWD';

const NavigationMobileScreen = ({ rwdNavigationHandler, mobileNavigationText, stepperValue }) => (
  <div className={classes.wrapper}>
    <div onClick={rwdNavigationHandler} className={classes.rwdNavigation}>
      <Arrow className={classes.arrow} />
      {mobileNavigationText}
    </div>
    <StepperRWD activeStep={stepperValue} />
  </div>
);

export default NavigationMobileScreen;
