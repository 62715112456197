import React from 'react';
import clsx from 'clsx';
import classes from './style.module.css';
import IconWrapper from '../IconWrapper';

const CircleIconText = ({
  icon,
  heading,
  supportingText,
  headingStyle,
  supportingTextStyle,
  supportingTextClickHandler,
}) => (
  <div className={classes.body}>
    <IconWrapper className={classes.icon} Icon={icon} />
    <div className={`${classes.bodyHeading}, ${headingStyle}`}>{heading}</div>
    <div onClick={supportingTextClickHandler} className={clsx(classes.supportingText, supportingTextStyle)}>
      {supportingText}
    </div>
  </div>
);
export default CircleIconText;
