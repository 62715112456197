import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { activePillConfig } from '../../../views/AdministratorList/utils';
import Button from '../../../../../shared/components/Button';
import { SUBSCRIPTION_PLAN_ENUM } from '../../../../../shared/helpers/subscriptions';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { USER_LIST_NAVIGATION_KEY_ENUM } from '../../../views/UserList/utils';

const { managers } = USER_LIST_NAVIGATION_KEY_ENUM;

const ExpandedUserRow = ({ userData, setLoading, onListFetch, tab }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const { businessProfiles, status = true, cardTotalNumber, id, login, subscriptionStatus } = userData;

  const addSubscriptionToManager = async () => {
    const data = {
      userId: id,
      plan: SUBSCRIPTION_PLAN_ENUM.pro,
      months: 6,
    };
    try {
      setLoading(true);
      await api.post('/acp/subscription/addSubscription', data);
      await onListFetch();
      showNotification({ type: 'success', message: t('adminControlPanel.subscriptionAdded') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const removeSubscriptionFromManager = async () => {
    const data = {
      userId: id,
    };
    try {
      setLoading(true);
      await api.post('/acp/subscription/deleteSubscription', data);
      await onListFetch();
      showNotification({ type: 'success', message: t('adminControlPanel.subscriptionRemoved') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteManager = async () => {
    const data = {
      email: login,
      manager: false,
    };
    try {
      setLoading(true);
      await api.post('/acp/client/updateUserManager', data);
      await onListFetch();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const renderButton = useMemo(
    () => (subscriptionStatus ? (
      <Button
        className={classes.addSubBtn}
        label={t('adminControlPanel.removeSubscription')}
        onClick={removeSubscriptionFromManager}
      />
    ) : (
      <Button
        className={classes.addSubBtn}
        label={t('adminControlPanel.addSubscription')}
        onClick={addSubscriptionToManager}
      />
    )),
    [subscriptionStatus],
  );

  return (
    <tr id={classes.expandedUserRowWrapper} className={clsx(classes.wrapper, classes.expandedUserRowWrapper)}>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.businessCardsNumber')}</span>
        <span className={classes.value}>{businessProfiles}</span>
      </td>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.activeSubscriptionsNumber')}</span>
        <span className={classes.value}>{subscriptionStatus ? businessProfiles : 0}</span>
      </td>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.opinieoCardsNumber')}</span>
        <span className={classes.value}>{cardTotalNumber}</span>
      </td>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.subscritpionState')}</span>
        <Pill
          className={clsx(classes.pill, status && classes.activeStatusPill)}
          label={activePillConfig(subscriptionStatus, t)}
        />
      </td>
      {tab === managers && (
        <>
          <td className={classes.cell}>{renderButton}</td>
          <td className={classes.cell}>
            <Button
              className={classes.actionBtn}
              label={t('adminControlPanel.deleteManager')}
              onClick={handleDeleteManager}
            />
          </td>
        </>
      )}
    </tr>
  );
};

export default ExpandedUserRow;
