export const basicUserDataConfig = (t) => [
  {
    title: t('adminControlPanel.lastName'),
    key: 'lastName',
  },
  {
    title: t('adminControlPanel.name'),
    key: 'name',
  },
  {
    title: t('adminControlPanel.email'),
    key: 'login',
  },
  {
    title: t('adminControlPanel.phoneNumber'),
    key: 'phoneNumber',
  },
  {
    title: t('adminControlPanel.nip'),
    key: 'nip',
  },
];
