import dayjs from 'dayjs';

export const rowDataParser = (rowData) => rowData.map((link) => ({
  linkCode: link.linkCode || '-',
  linkName: link.linkName || '-',
  link: link.link || '-',
  linkInteractions: link.linkInteractions || '-',
  lastUsed:
      dayjs(link.lastUsed).locale('pl').format('DD/MM/YY') === 'Invalid Date'
        ? '-'
        : dayjs(link.lastUsed).locale('pl').format('DD/MM/YY'),
}));
