import React, { useContext } from 'react';

const defaultDashboard = {
  businessProfile: {
    name: null,
    address: null,
    id: null,
    image: null,
    externalBusinessProfile: null
  },
  selectBusinessProfile(id) {},
};

export const DashboardContext = React.createContext(defaultDashboard);

export const useDashboard = () => useContext(DashboardContext);
