import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import ExpandableSection from '../../../../../shared/components/ExpandableSection';
import { formEnum } from '../ModalForm/utils/utils';

const TermsConditions = ({ data, localPost }) => {
  const { t } = useTranslation();

  return (
    data.type === formEnum.OFFER
    && localPost.offer?.termsConditions && (
      <ExpandableSection sectionName={t('posts.termsOffer')}>
        <div className={classes.termsConditions}>{localPost.offer.termsConditions}</div>
      </ExpandableSection>
    )
  );
};
export default TermsConditions;
