import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as NoImageIcon } from '../../../../../shared/assets/noImage.svg';

const PostImage = ({ media }) => (
  <div className={classes.card}>
    {media?.length ? (
      <img
        referrerPolicy="referrer"
        className={classes.image}
        src={media[0].mediaSource}
        alt="local post media item"
        crossOrigin="anonymous"
      />
    ) : (
      <div className={classes.noImage}>
        <NoImageIcon className={classes.noImageIcon} />
      </div>
    )}
  </div>
);

export default PostImage;
