import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const NoBusinessProfiles = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('settings.noBusinessProfile')}</div>
    </div>
  );
};
export default NoBusinessProfiles;
