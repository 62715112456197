import React, { useEffect, useId, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CharCounter from '../CharCounter';
import { ReactComponent as ErrorIcon } from '../../assets/errorTriangle.svg';

const Input = ({
  onChange = () => {},
  onBlur = () => {},
  onError = () => {},
  onFocus = () => {},
  labelRised,
  label,
  className,
  value,
  validator,
  transformer,
  readOnly,
  error,
  type = 'text',
  onKeyDown,
  showErrorAfterBlur,
  placeholder,
  maxLength,
  testId,
  admin,
  Icon,
  autoComplete = 'on'
}) => {
  const { t } = useTranslation();
  const id = useId();
  const inputRef = useRef(null);

  const [afterBlur, setAfterBlur] = useState(false);
  const [innerState, setInnerState] = useState(transformer ? transformer(value) : value);
  const [innerError, setInnerError] = useState(() => (validator ? validator(value) : false));
  const [focused, setFocused] = useState(false);

  const labelIsUp = focused || innerState || labelRised;
  const showError = (!showErrorAfterBlur || afterBlur) && !readOnly && validator && (innerError || error);
  const showCounter = maxLength && Boolean(innerState?.length) && !readOnly;

  const handleError = (isValid) => {
    setInnerError(isValid);
    if (!readOnly) {
      onError(isValid);
    }
  };

  const handleChange = (event) => {
    const currValue = transformer ? transformer(event.target.value) : event.target.value;
    setInnerState(currValue);

    if (validator) {
      const isValid = validator(currValue);
      handleError(isValid);
    }
    onChange(currValue);
  };

  const handleFocus = () => {
    if (!readOnly) {
      setFocused(true);
      onFocus();
      setAfterBlur(false);
    }
  };

  const handleBlur = () => {
    setAfterBlur(true);
    setFocused(false);
    onBlur(innerState);
  };

  useEffect(() => {
    if (readOnly) {
      onError(false);
    } else {
      onError(innerError);
    }
  }, [readOnly]);

  useEffect(() => {
    const currValue = transformer ? transformer(value) : value;
    if (validator) {
      const isValid = validator(currValue);
      handleError(isValid);
    }
    setInnerState(currValue);
  }, [value]);

  useEffect(() => {
    if (validator) {
      const isValid = validator(innerState);
      handleError(isValid);
    }
  }, [innerState]);

  return (
    <div onClick={handleFocus} className={clsx(classes.wrapper, className)}>
      <div
        className={clsx(
          classes.inputWrapper,
          focused && classes.focused,
          showError && classes.error,
          readOnly && classes.disabled,
          admin && classes.admin,
        )}
      >
        <label
          htmlFor={id}
          className={clsx(
            classes.label,
            readOnly && classes.disabled,
            labelIsUp && classes.focused,
            showError && classes.error,
            showCounter && classes.withCounter,
            type === 'search' && classes.search,
          )}
        >
          {label && (
            <span className={classes.text}>
              <div>{label}</div>
            </span>
          )}
          <input
            id={id}
            onKeyDown={onKeyDown}
            ref={inputRef}
            type={type}
            disabled={readOnly}
            className={clsx(classes.input, labelIsUp && classes.focused, readOnly && classes.disabled)}
            value={innerState}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            maxLength={maxLength}
            autoComplete={autoComplete}
            data-testid={testId}
          />
        </label>
      </div>
      {Icon && <Icon className={classes.customIcon} />}
      {showError && (
        <div data-testid="errorLabel" className={classes.errorLabel}>
          <ErrorIcon className={classes.errorIcon} />
          {error || t(innerError)}
        </div>
      )}
      {showCounter && <CharCounter className={classes.counter} maxLength={maxLength} value={innerState.length || 0} />}
    </div>
  );
};

export default Input;
