export const moreHoursInit = [
  { id: 'MONDAY', name: 'Poniedziałek', value: true, hours: [{ from: '', to: '' }] },
  { id: 'TUESDAY', name: 'Wtorek', value: true, hours: [{ from: '', to: '' }] },
  { id: 'WEDNESDAY', name: 'Środa', value: true, hours: [{ from: '', to: '' }] },
  { id: 'THURSDAY', name: 'Czwartek', value: true, hours: [{ from: '', to: '' }] },
  { id: 'FRIDAY', name: 'Piątek', value: true, hours: [{ from: '', to: '' }] },
  { id: 'SATURDAY', name: 'Sobota', value: true, hours: [{ from: '', to: '' }] },
  { id: 'SUNDAY', name: 'Niedziela', value: true, hours: [{ from: '', to: '' }] },
];
export const timesFromAllDay = ['00:00', '23:59', '0:0'];
