export const ReportStatusEnum = {
  exists: 1,
  notExists: 2,
  loading: 3
};

const long = 50;
const lat = 50;

const generateMap = () => {
  const latM = 111132.92 - 559.82 * Math.cos(2 * long) + 1.175 * Math.cos(4 * long) - 0.023 * Math.cos(6 * long);
  const longM = 111412.84 * Math.cos(lat) - 93.5 * Math.cos(3 * lat) + 0.118 * Math.cos(5 * lat);
};
