export const distanceOptions = [
  { value: 1, label: '1 km', id: 1 },
  { value: 2, label: '2 km', id: 2 },
  { value: 3, label: '3 km', id: 3 },
  { value: 4, label: '4 km', id: 4 },
  { value: 5, label: '5 km', id: 5 },
  { value: 6, label: '6 km', id: 6 },
  { value: 7, label: '7 km', id: 7 },
  { value: 8, label: '8 km', id: 8 },
  { value: 9, label: '9 km', id: 9 },
  { value: 10, label: '10 km', id: 10 },
];

export const adressProvidedCheck = (values) => {
  const { addressLines, postalCode, locality } = values;
  const addressValues = Object.keys(values).filter((el) => ['addressLines', 'locality', 'postalCode'].includes(el));
  const addressProvided = addressValues
    .map((el) => values[el])
    .flat()
    .every((el) => Boolean(el.length));

  return {
    addressProvided,
    fullAddress: `${addressLines || ''}, ${postalCode || ''} ${locality || ''}`,
  };
};
