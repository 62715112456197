import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { parseStyle } from '../../../../../shared/components/List/utils';

const MobileList = ({ data, config }) => {
  const { t } = useTranslation();

  const parsedConfig = config.map((el) => {
    const style = parseStyle(el.width, el.bold);
    return {
      ...el,
      renderHeader: typeof el.header === 'function' ? el.header : () => t(el.header),
      renderCell: typeof el.render === 'function' ? el.render : (val) => val,
      style,
    };
  });
  return (
    <div>
      <div className={classes.row}>
        {data.map((row) => (
          <div className={classes.container} key={`listRow_${row.id}`}>
            {parsedConfig.map((el) => (
              <div key={row.key} className={classes.reportCard}>
                <div className={classes.cell}>
                  <div className={classes.name}>{el.renderHeader(row[el.key], row, data)}</div>
                  <div className={classes.value}>{el.renderCell(row[el.key], row, data)}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileList;
