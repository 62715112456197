import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as FilterIcon } from '../../../../../shared/assets/filter.svg';
import FiltersModal from '../FiltersModal';

const RWDFilters = ({ values, onChange, accountDetails, onGenerateReport }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modalHandler = () => setShowModal((prev) => !prev);
  return (
    <div className={classes.wrapper}>
      {showModal && (
      <FiltersModal
        handleValues={onChange}
        values={values}
        accountDetails={accountDetails}
        modalHandler={modalHandler}
        onGenerateReport={onGenerateReport}
      />
      )}
      <Button onClick={modalHandler} Icon={FilterIcon} iconStyle={classes.iconStyle} className={classes.button} label={t('generateReport.showFilters')}/>
    </div>
  );
};

export default RWDFilters;
