import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { positiveNumber } from '../../../../../shared/components/Input/transformers';
import { makeRequired, required, yearValidator } from '../../../../../shared/components/Input/validators';
import Select from '../../../../../shared/components/Select';
import { monthList } from './utils';
import { parseBackRegularHours } from '../OpenHoursForm/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import ActionBox from '../ActionBox';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const requiredYearValidator = makeRequired(yearValidator);
const OpenDateForm = ({
  className,
  businessProfileId,
  form,
  setForm,
  setState,
  onError,
  readOnlyAction,
  isEdit,
  editHandler,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [date, setDate] = useState({
    year: form.openInfo.openingDate.year || '',
    month: form.openInfo.openingDate.month || '',
    day: form.openInfo.openingDate.day || '',
  });
  const [errors, setErrors] = useState([]);

  const handleForm = (name) => (value) => {
    setDate((prev) => ({ ...prev, [name]: value }));
  };

  const dayValidator = useMemo(() => {
    const config = monthList.find((el) => el.id === date.month);
    if (!config) {
      return () => {};
    }
    const dayInMonth = config.getDaysCount(date.year);
    return (value) => {
      if (!value) {
        return 'validators.requiredField';
      }
      if (!Number(value)) {
        return 'validators.expectedNumber';
      }
      if (value > dayInMonth) {
        return 'validators.incorrectDay';
      }
      return false;
    };
  }, [date.month, date.year]);

  const handleSave = async () => {
    try {
      setState(StateEnum.loading);
      await api.post('business_profile/updateBusinessOpeningInfo', {
        businessProfileId,
        openingDate: date,
      });
      showNotification({ label: t('global.success'), message: t('global.success'), type: 'success' });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    setForm((prev) => ({
      ...prev,
      openInfo: { ...prev.openInfo, openingDate: date },
    }));
    editHandler(false);
  };

  const handleCancel = () => {
    setDate(form.openInfo.openingDate);
    editHandler(null);
  };

  const dateInputErrorHandler = (name) => (error) => {
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  useEffect(() => {
    if (!isEdit) {
      setDate(form.openInfo.openingDate);
    }
  }, [isEdit, form.openInfo.openingDate]);

  useEffect(() => onError(Object.values(errors).some((el) => Boolean(el))), [errors]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.row}>
        <Input
          label={t('businessProfileEdit.year')}
          onChange={handleForm('year')}
          validator={requiredYearValidator}
          value={date.year || ''}
          readOnly={!isEdit}
          className={classes.input}
          transformer={positiveNumber}
          maxLength={4}
          onError={dateInputErrorHandler('year')}
        />
        <Select
          label={t('businessProfileEdit.month')}
          onSelect={handleForm('month')}
          validator={required(null)}
          value={date.month || ''}
          list={monthList}
          readOnly={!isEdit}
          className={classes.input}
          onError={dateInputErrorHandler('month')}
        />
        <Input
          label={t('businessProfileEdit.day')}
          onChange={handleForm('day')}
          value={date.day || ''}
          validator={dayValidator}
          readOnly={!isEdit}
          className={classes.input}
          transformer={positiveNumber}
          maxLength={2}
          onError={dateInputErrorHandler('day')}
        />
      </div>
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} readOnlyAction={readOnlyAction} />}
    </div>
  );
};

export default OpenDateForm;
