import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';

const InformationTextBox = ({ text, customStyle, noIcon }) => (
  <div className={customStyle}>
    {!noIcon && (
      <div className={classes.iconWrapper}>
        <InfoIcon />
      </div>
    )}
    {text}
  </div>
);
export default InformationTextBox;
