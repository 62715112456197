import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import Modal from '../../../../../shared/components/Modal';

const DeleteModal = ({ modalHandler, deleteItemHandler }) => {
  const { t } = useTranslation();

  const handleCancel = () => modalHandler();
  const handleDelete = () => deleteItemHandler();

  return (
    <Modal
      onCancel={handleCancel}
      modalTitle={t('posts.deletePost')}
      contentStyle={classes.deleteModal}
      rwdBackTitle={t('global.goBack')}
    >
      <div className={classes.modalBody}>{t('posts.deletePostConfirmation')}</div>
      <div className={classes.modalActions}>
        <Button
          onClick={handleCancel}
          className={clsx(classes.modalButton, classes.cancel)}
          label={t('global.cancel')}
        />
        <Button onClick={handleDelete} className={classes.modalButton} label={t('posts.deletePost')} />
      </div>
    </Modal>
  );
};

export default DeleteModal;
