import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import ProgressBar from '../../../../../shared/components/ProgressBar';
import Tooltip from '../../../../../shared/components/Tooltip';

const BusinessKeywordsWithOpinions = ({ modalHandler, keywords = [], maxValue = 0 }) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleToolTip = (keyword) => setShowTooltip(keyword.length > 28 ? keyword : false);

  return (
    <Modal modalTitle={t('dashboard.businessKeywords')} contentStyle={classes.content} onCancel={modalHandler}>
      <div className={classes.wrapper}>
        {keywords.map(({ keyword, value, displayValue }) => (
          <div
            onMouseLeave={() => toggleToolTip(false)}
            onMouseOver={() => toggleToolTip(keyword)}
            onFocus={() => toggleToolTip(keyword)}
            className={classes.keyword}
            key={keyword}
          >
            <div className={classes.rowContainer}>
              <div className={classes.keywordTitle}>{keyword}</div>
              <div className={classes.keywordValue}>{displayValue}</div>
            </div>
            {showTooltip === keyword && (
              <Tooltip tooltipStyle={classes.tooltipStyle} title={keyword} className={classes.tooltip}>
                {keyword}
              </Tooltip>
            )}
            <div className={classes.progressBarWrapper}>
              <ProgressBar value={value} maxValue={maxValue} />
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
export default BusinessKeywordsWithOpinions;
