import { getLocalStorageProfileStatus } from '../../../../shared/helpers/helpers';
import { statusConfig } from '../../modules/Dashboard/BusinessStatus/utils';

export const fetchImagesForBusinessProfiles = async (businessProfiles, api, controller) => {
  const imagePromiseList = businessProfiles.map((el) => {
    const { fileName, fileDate } = el;

    if (!fileName || !fileDate) {
      return Promise.reject({ status: 'rejected' });
    }

    return api.get(`/assets/place/${fileDate}/${fileName}`, {
      signal: controller.signal,
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    });
  });

  const images = await Promise.allSettled(imagePromiseList);
  return images;
};

export const PROFILE_STATE_ENUM = {
  verified: 'verified',
  duplicate: 'duplicate',
  notVerified: 'notVerified',
  processing: 'processing',
  suspended: 'suspended',
};

export const parseProfileStatus = (status, t) => {
  const { duplicate, notVerified, verified, processing, suspended } = PROFILE_STATE_ENUM;
  if (status) {
    if (status.hasBusinessAuthority && status.hasVoiceOfMerchant) {
      return { label: t('settings.verified'), id: verified };
    }
    if (status.hasBusinessAuthority && status.waitForVoiceOfMerchant) {
      return { label: t('settings.processing'), id: processing };
    }
    if (status.resolveOwnershipConflict) {
      return { label: t('settings.duplicate'), id: duplicate };
    }
    if (
      status.complyWithGuidelines
      && status.complyWithGuidelines.recommendationReason === 'BUSINESS_LOCATION_SUSPENDED'
    ) {
      return { label: t('settings.suspended'), id: suspended };
    }
  }
  return { label: t('settings.notVerified'), id: notVerified };
};

export const handleProfileStatuses = async (businessProfiles, api, controller, t) => {
  const profilesWithNoStatus = businessProfiles.filter((el) => !el.status);
  const statuses = await Promise.allSettled(
    profilesWithNoStatus.map((el) => {
      if (!el.externalBusinessProfile) {
        return api.post(
          '/business_profile/getVerificationStatus',
          { businessProfileId: el.id },
          { signal: controller.signal },
        );
      }
      return Promise.resolve({
        status: 'fulfilled',
        value: null,
      });
    }),
  );

  const profilesWithStatuses = profilesWithNoStatus.map((el, index) => {
    if (statuses[index].status === 'fulfilled') {
      return {
        ...el,
        status: parseProfileStatus(statuses[index].value.data, t),
      };
    }
    return el;
  });

  return [...businessProfiles.filter((el) => el.status), ...profilesWithStatuses];
};

export const getStatusToDisplay = (businessProfileId, t) => {
  if (!businessProfileId) {
    return {
      status: '',
      statusWithLowerCase: '',
      Icon: null,
      subInfo: '',
    };
  }
  const profileStatusInStorage = getLocalStorageProfileStatus(businessProfileId);
  const translatedStatusConfig = statusConfig(t);
  return translatedStatusConfig[profileStatusInStorage];
};
