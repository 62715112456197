import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { ReactComponent as CheckIcon } from '../../../../../shared/assets/check.svg';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';
import { ReactComponent as ErrorIcon } from '../../../../../shared/assets/errorTriangle.svg';
import { keys } from '../ProfileCompletion/utils';

const ProfileCompletionRow = ({ row }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToMissingData = (path, sectionName, value) => {
    if (!value) {
      navigate(path, { state: { section: sectionName } });
    }
  };

  const renderIcon = (value) => {
    if (value === keys.error) return <ErrorIcon title={t('global.fetchFailed')} className={clsx(classes.statusIcon, classes.negativeValue)} />;
    if (value) return <CheckIcon className={clsx(classes.statusIcon, classes.positiveValue)} />;
    return <CloseIcon className={clsx(classes.statusIcon, classes.negativeValue)} />;
  };

  return (
    <div className={classes.row}>
      <div className={classes.rowTitle}>{t(row.name)}</div>
      <ul className={classes.rowValue}>
        {row.list.map((item) => (
          <div
            onClick={() => navigateToMissingData(item.navigate, item.section, item.value)}
            className={classes.rowItem}
            key={item.id}
          >
            <li className={clsx(classes.rowItemName, !item.value && classes.navigateBtn)}>{t(item.name)}</li>
            <div className={classes.rowItemValue}>{renderIcon(item.value)}</div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default ProfileCompletionRow;
