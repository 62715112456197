import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Stars from '../../../../../shared/components/Stars';
import Button from '../../../../../shared/components/Button';
import GetMoreOpinionsModal from '../GetMoreOpinionsModal';
import Pill from '../../../../../shared/components/Pill';

const OpinionsBusinessHeader = ({ businessScore, adminPreview }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modalHandler = () => setShowModal((prev) => !prev);

  return (
    <div className={clsx(classes.header, adminPreview && classes.adminPreviewHeader)}>
      {showModal && <GetMoreOpinionsModal modalHandler={modalHandler} rwdModalBackTitle={t('global.goBack')} />}
      <div className={classes.businessScore}>
        {t('opinions.customerOpinions')}
        <div className={classes.row}>
          {businessScore.totalReviewCount && (
            <>
              <Pill
                border={false}
                label={t('opinions.opinionsNumber', { number: businessScore.totalReviewCount })}
                className={classes.pill}
              />
              <div className={classes.businessScoreWrapper}>
                {businessScore.totalReviewFixedCount}
                <Stars rating={businessScore.averageRating} />
              </div>
            </>
          )}
        </div>
      </div>
      {!adminPreview && (
        <Button onClick={modalHandler} className={classes.getMoreOpinionsBtn} label={t('opinions.getMoreOpinions')} />
      )}
    </div>
  );
};
export default OpinionsBusinessHeader;
