import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useDashboard } from '../../../helpers/dashboard';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { ReactComponent as Delete } from '../../../../../shared/assets/bin.svg';
import { ReactComponent as Edit } from '../../../../../shared/assets/editPost.svg';
import { handleApiError } from '../ChangePassword/utils';
import { useModal } from '../../../../../shared/helpers/hooks';
import DeleteProfileModal from '../DeleteProfileModal';

const BusinessItemActions = ({ profile, loaderHandler, onDetachHandler }) => {
  const navigate = useNavigate();
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { selectBusinessProfile } = useDashboard();
  const [showModal, setShowModal, modalHandler] = useModal();

  const editProfileHandler = () => {
    selectBusinessProfile(profile.id);
    navigate('/dashboard/edit_business_profile');
  };

  const detachProfileHandler = async () => {
    modalHandler();
    const controller = new AbortController();
    try {
      loaderHandler();
      await api.post('/business_profile/detachBusinessProfiles', { businessProfilesId: [profile.id] });
      onDetachHandler(profile.id);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      loaderHandler();
    }
    return () => {
      controller.abort();
    };
  };

  return (
    <div className={classes.actionsWrapper}>
      {showModal && <DeleteProfileModal onCancel={modalHandler} onConfirm={detachProfileHandler} />}
      <div onClick={editProfileHandler} className={classes.iconWrapper}>
        <Edit className={classes.icon} />
      </div>
      <div onClick={modalHandler} className={classes.iconWrapper}>
        <Delete className={classes.icon} />
      </div>
    </div>
  );
};
export default BusinessItemActions;
