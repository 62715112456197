export const stepperContent = (t) => [
  { stepValue: 1, title: t('businessProfileCreate.basicInformation') },
  { stepValue: 2, title: t('businessProfileCreate.locationAndArea') },
  { stepValue: 3, title: t('businessProfileCreate.contactDetails') },
  { stepValue: 4, title: t('businessProfileCreate.verification') },
];

export const shouldColorStepper = (activeStep) => {
  const isActiveStep = (step) => step <= activeStep;
  const lineInColor = (step) => step < activeStep;

  return { isActiveStep, lineInColor };
};
