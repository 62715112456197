import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { KEYWORD_MAX_LENGTH } from '../../../helpers/constants';
import { KEYBOARD_KEYS_ENUM, PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { ReactComponent as AddIcon } from '../../../../../shared/assets/plus.svg';
import { parseStringsWithWhiteSpaces } from '../../../../../shared/helpers/strings';
import { useKeyDown } from '../../../../../shared/helpers/hooks';

const AiHelperKeywordsBox = ({
  businessKeywords,
  onKeywordClick,
  selectedKeywords,
  onUserKeywordChange,
  userTypedKeyword,
  onUserKeywordAddHandler,
  profileStatus,
}) => {
  const { t } = useTranslation();
  const { verified } = PROFILE_STATUS_ENUM;
  const [userWantToAddKeyword, setUserWantToAddKeyword] = useState(false);
  const profileVerified = profileStatus === verified;
  const keywordsTitle = profileVerified ? t('posts.selectKeywords') : t('posts.selectKeywordsNotVerified');
  const noBusinessKeywords = !businessKeywords.length;
  const renderCustomKeywordInput = noBusinessKeywords || !profileVerified || userWantToAddKeyword;
  const isAddIconVisible = profileVerified && Boolean(businessKeywords.length);
  const { enter } = KEYBOARD_KEYS_ENUM;
  const onAddKeywordClick = () => {
    setUserWantToAddKeyword((prev) => !prev);
  };
  useKeyDown({ key: enter, callback: onUserKeywordAddHandler });

  return (
    <>
      <div className={classes.selectKeywordsText}>{keywordsTitle}</div>
      <div className={classes.keywordsWrapper}>
        {businessKeywords.map(({ searchKeyword }) => (
          <div
            key={searchKeyword}
            className={clsx(classes.keyword, selectedKeywords.includes(searchKeyword) && classes.selectedStyle)}
            onClick={() => onKeywordClick(searchKeyword)}
          >
            {searchKeyword}
          </div>
        ))}
        {isAddIconVisible && (
          <div onClick={onAddKeywordClick} className={clsx(classes.addKeyword)}>
            <AddIcon onClick={onUserKeywordAddHandler} className={classes.addIcon} />
          </div>
        )}

        {renderCustomKeywordInput && (
          <div className={classes.userTypedKeywordWrapper}>
            <Input
              label={t('posts.addKeyword')}
              onChange={onUserKeywordChange}
              className={classes.addDescription}
              value={userTypedKeyword}
              type="text"
              maxLength={KEYWORD_MAX_LENGTH}
              transformer={parseStringsWithWhiteSpaces}
            />
            <AddIcon onClick={onUserKeywordAddHandler} className={classes.addKeywordIcon} />
          </div>
        )}
      </div>
    </>
  );
};
export default AiHelperKeywordsBox;
