export const SelectEnum = {
  area: 'area',
  distance: 'distance',
  net: 'net',
};

export const filtersDescription = [
  { id: 1, name: SelectEnum.area, displayName: 'Obszar wyszukiwania', description: 'Lorem ipsum dolor sit amet consectetur. Malesuada tellus aliquam vel nisi porttitor vel faucibus ultricies. ' },
  { id: 2, name: SelectEnum.distance, displayName: 'Odległość między punktami', description: 'Lorem ipsum dolor sit amet consectetur. Malesuada tellus aliquam vel nisi porttitor vel faucibus ultricies. Et lorem ullamcorper nunc nibh nunc' },
  { id: 3, name: SelectEnum.net, displayName: 'Wielkość siatki', description: 'Lorem ipsum dolor sit amet consectetur. Malesuada tellus aliquam vel nisi porttitor vel faucibus ultricies. Et lorem ullamcorper ' },
];
