import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import LinkInformation from '../LinkInformation';

const RedirectLinkList = ({ data, onLinkFormChange, admin = true }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <div>
      <div className={classes.heading}>{t('adminControlPanel.redirectLinkList', { value: data.length })}</div>
      {data.map((link, index) => (
        <LinkInformation key={link.id} link={link} onLinkFormChange={onLinkFormChange(index)} admin={admin} />
      ))}
    </div>
  );
};

export default RedirectLinkList;
