import React from 'react';
import Dashboard from '../../../../SEO/views/Dashboard';
import ReportList from '../../../../SEO/views/ReportList';
import GenerateReport from '../../../../SEO/views/GenerateReport';
import BusinessProfileEdit from '../../../../SEO/views/BusinessProfileEdit';
import AddPost from '../../../../SEO/views/AddPost';
import Opinions from '../../../../SEO/views/Opinions';
import BusinessProfileChangeHistory from '../../../../SEO/views/BusinessProfileChangeHistory';
import AnswerTemplates from '../../../../SEO/views/AnswerTemplates';

export const getTabComponent = (tabKey, props) => {
  const { businessProfileId, userId, reportParams, businessProfile, handleClickReport } = props;
  const tabComponents = {
    dashboard: <Dashboard adminPreview businessProfileId={businessProfileId} userId={userId} />,
    reports: (
      <ReportList onClick={handleClickReport} adminPreview businessProfileId={businessProfileId} userId={userId} />
    ),
    report: <GenerateReport reportParams={reportParams} adminPreview userId={userId} />,
    businessProfileEdit: (
      <BusinessProfileEdit
        businessProfileData={businessProfile}
        adminPreview
        userId={userId}
        businessProfileId={businessProfileId}
      />
    ),
    localPosts: <AddPost adminPreview userId={userId} businessProfileId={businessProfileId} />,
    reviews: <Opinions adminPreview userId={userId} businessProfileId={businessProfileId} />,
    businessProfileChangeHistory: (
      <BusinessProfileChangeHistory adminPreview userId={userId} businessProfileId={businessProfileId} />
    ),
    templates: <AnswerTemplates adminPreview userId={userId} businessProfileId={businessProfileId} />,
  };

  return tabComponents[tabKey];
};
