import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import SelectAlternative from '../../../../../shared/components/Select/SelectAlternative';
import AnswerTemplateItem from '../AnswerTemplateItem';
import AnswerTemplateModal from '../AnswerTemplateModal';
import { sortTemplateList } from '../../../views/AnswerTemplates/utils';
import Button from '../../../../../shared/components/Button';

const AnswerTemplateContent = ({
  templates,
  fetchTemplates,
  editAnswerTemplate,
  sortTemplateHandler,
  templateToChange,
  sortTemplate,
  showModal,
  createTemplate,
  userId
}) => {
  const { t } = useTranslation();

  const sortValue = sortTemplateList.find((el) => el.value === sortTemplate).id;
  const translateHandler = (value) => t(value?.name);

  return (
    <div className={classes.templatesWrapper}>
      <div className={classes.templateAction}>
        <Button
          onClick={createTemplate}
          iconWidth={20}
          className={classes.addTemplateBtn}
          label={t('opinions.createTemplate')}
        />

        <SelectAlternative
          list={sortTemplateList}
          onSelect={sortTemplateHandler}
          value={sortValue}
          showOnList={translateHandler}
        />
      </div>
      {templates.map((answer) => (
        <AnswerTemplateItem
          key={answer.id}
          className={clsx(classes.template, answer.content.length >= 700 && classes.longTemplate)}
          onEdit={editAnswerTemplate}
          answer={answer}
          fetch={fetchTemplates}
          userId={userId}
        />
      ))}
      {showModal.edit.show && (
        <AnswerTemplateModal
          templateToChange={templateToChange}
          title={t('opinions.editTemplate')}
          fetch={fetchTemplates}
          modalHandler={editAnswerTemplate}
        />
      )}
    </div>
  );
};
export default AnswerTemplateContent;
