export const formEnum = {
  serviceArea: 'serviceArea',
  shareLocation: 'shareLocation',
  regionCode: 'regionCode',
  phoneNumbers: 'phoneNumbers',
  websiteUri: 'websiteUri',
  storefrontAddress: 'storefrontAddress',
  categories: 'categories',
  languageCode: 'languageCode',
  title: 'title',
  latlng: 'latlng',
};
