import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';

const AccountInfo = ({ userData }) => {
  const { t } = useTranslation();
  const { createdAt, lastLogin, active, lastActiveStatusChangeDate } = userData;
  const activePillLabel = active ? t('adminControlPanel.active') : t('adminControlPanel.notActive');
  return (
    <div className={classes.accountInfoWrapper}>
      <div className={classes.dataTitle}>{t('adminControlPanel.accountInformation')}</div>
      <div className={classes.basicDataWrapper}>
        <div>
          <div className={classes.dataLabel}>{t('adminControlPanel.accountCreationDate')}</div>
          <div className={classes.dataValue}>{createdAt}</div>
        </div>
        <div>
          <div className={classes.dataLabel}>{t('adminControlPanel.lastLoggedIn')}</div>
          <div className={classes.dataValue}>{lastLogin}</div>
        </div>
        <div>
          <div className={classes.dataLabel}>{t('adminControlPanel.accountState')}</div>
          <div className={classes.dataValue}>
            <Pill className={clsx(classes.pill, active && classes.activeStatusPill)} label={activePillLabel} />
          </div>
        </div>
        <div>
          <div className={classes.lastStatusChangeDate}>
            {t('adminControlPanel.lastStatusChangeDate', { date: lastActiveStatusChangeDate })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
