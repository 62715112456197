import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const ProgressCircle = ({ progress, className }) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div className={clsx(classes.overlay, className)}>
      <div className={classes.progressText}>{`${progress}%`}</div>
      <svg className={classes.progressCircle} width={radius * 2} height={radius * 2} viewBox={`-5 -5 ${(radius * 2) + 10} ${(radius * 2) + 10}`}>
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="var(--secondary500)" />
            <stop offset="100%" stopColor="var(--secondary500)" />
          </linearGradient>
        </defs>
        <circle
          className={classes.backgroundCircle}
          cx={radius}
          cy={radius}
          r={radius}
          strokeLinecap="round"
        />
        <circle
          className={classes.progressArc}
          cx={radius}
          cy={radius}
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          style={{ stroke: 'url(#gradient)' }}
        />
      </svg>
    </div>
  );
};

export default ProgressCircle;
