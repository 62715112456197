import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import BusinessProfileRow from '../BusinessProfileRow';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../../../shared/helpers/auth';

const BusinessProfileSelect = ({ selected, setSelected, list, onFetch, nextPageToken, account }) => {
  const { t } = useTranslation();
  const { businessProfiles: importedProfiles } = useAuth();
  const listRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const importedProfilesId = importedProfiles.map((el) => el.googleReferenceId);

  const handleCheck = (id) => (value) => {
    const selectedReferenceIdList = selected.map((el) => el.googleReferenceId);
    if (selected.length >= 100 && value) return;
    if (selectedReferenceIdList.includes(id)) {
      setSelected((prev) => prev.filter((el) => el.googleReferenceId !== id));
    } else {
      setSelected((prev) => [...prev, { googleReferenceId: id, googleAccountName: account }]);
    }
  };
  const shouldCheck = (id) => selected.map((el) => el.googleReferenceId).includes(id) || importedProfilesId.includes(id);

  const fetchOnIntersection = async (entries) => {
    const controller = new AbortController();
    const isIntersecting = entries[0]?.isIntersecting;

    if (isIntersecting && !loading && nextPageToken) {
      setLoading(true);
      await onFetch(controller, nextPageToken);
      setLoading(false);
    }
  };

  const connectObserver = (listNode) => {
    const observerOptions = { threshold: 0.1, root: listNode };
    const observer = new IntersectionObserver(fetchOnIntersection, observerOptions);

    if (listNode?.lastChild) {
      observer.observe(listNode.lastChild);
    }

    return observer;
  };

  useEffect(() => {
    const observer = connectObserver(listRef.current);

    return () => observer.disconnect();
  }, [list, loading]);

  return (
    <div className={classes.wrapper}>
      <div ref={listRef} className={classes.list}>
        {list.length ? (
          list.map((el, index) => (
            <div key={el.id}>
              <div key={el.id} className={classes.element}>
                <BusinessProfileRow
                  name={el.name}
                  address={el.address}
                  checked={shouldCheck(el.id)}
                  onCheck={handleCheck(el.id)}
                  alreadyImported={importedProfilesId.includes(el.id)}
                />
              </div>
            </div>
          ))
        ) : (
          <div className={classes.empty}>{t('pickBusinessProfiles.noBusinessCards')}</div>
        )}
        {loading && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessProfileSelect;
