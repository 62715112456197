import { COUNTRIES } from '../../../helpers/constants';

export const updateRegionCodeOrCountries = ({ selectedAreas, onChange, onMetaDataChange }) => {
  const selectedAreasCountries = [...new Set(selectedAreas.map((area) => area.placeName?.split(',').pop().trim()))];
  if (selectedAreasCountries.length === 1) {
    const foundCountry = COUNTRIES.find((country) => country.name === selectedAreasCountries[0]);
    onChange('regionCode')(foundCountry.code.toLowerCase());
  } else {
    onMetaDataChange('multipleCountries')(selectedAreasCountries?.length > 1);
  }
};
