import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxOptionsWrapper from '../CheckboxOptionsWrapper';
import { businessTypeEnum, shareLocationCheckboxOptions } from './utils';
import { formEnum } from '../WelcomeScreenCreate/utils';
import { ReactComponent as ShareLocationSvg } from '../../../../../shared/assets/shareLocation.svg';

const FormShareLocation = ({ onChange, onMetaDataChange, form, readOnlyHandler }) => {
  const { t } = useTranslation();
  const translatedShareLocationCheckboxOptions = shareLocationCheckboxOptions(t);

  const locationCheckBoxValueHandler = (value) => {
    onChange(formEnum.shareLocation)(value);
    onMetaDataChange(formEnum.shareLocation)(value === businessTypeEnum.CUSTOMER_AND_BUSINESS_LOCATION);
  };

  useEffect(() => {
    readOnlyHandler(!form.serviceArea.businessType);
  }, [form.serviceArea.businessType]);

  return (
    <CheckboxOptionsWrapper
      title={t('businessProfileCreate.locationFormTitle')}
      subtitle={t('businessProfileCreate.locationFormSubtitle')}
      SVG={ShareLocationSvg}
      checkBoxValue={form.serviceArea.businessType}
      onClick={locationCheckBoxValueHandler}
      options={translatedShareLocationCheckboxOptions}
    />
  );
};

export default FormShareLocation;
