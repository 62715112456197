import { ReactComponent as DesktopSearch } from '../../../../../shared/assets/monitor.svg';
import { ReactComponent as DesktopMapSearch } from '../../../../../shared/assets/map.svg';
import { ReactComponent as MobileSearch } from '../../../../../shared/assets/tablet.svg';
import { ReactComponent as MobileSearchMap } from '../../../../../shared/assets/pin.svg';
import { ReactComponent as PieChart } from '../../../../../shared/assets/pieChart.svg';
import { ReactComponent as DirectionRequest } from '../../../../../shared/assets/directionRequestIcon.svg';
import { ReactComponent as CallClick } from '../../../../../shared/assets/callClick.svg';
import { ReactComponent as WebsiteClick } from '../../../../../shared/assets/website.svg';
import { ReactComponent as BusinessConversations } from '../../../../../shared/assets/chatIcon.svg';
import { ReactComponent as BusinessBookings } from '../../../../../shared/assets/bookingIcon.svg';
import { ReactComponent as BusinessFoodMenu } from '../../../../../shared/assets/foodMenuClick.svg';
import { ReactComponent as BusinessFoodOrder } from '../../../../../shared/assets/foodOrderIcon.svg';

export const filterViewsDataOptions = [
  { title: 'dashboard.desktop', Icon: DesktopSearch, type: 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH' },
  { title: 'dashboard.desktopMap', Icon: DesktopMapSearch, type: 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS' },
  { title: 'dashboard.mobile', Icon: MobileSearch, type: 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH' },
  { title: 'dashboard.mobileMap', Icon: MobileSearchMap, type: 'BUSINESS_IMPRESSIONS_MOBILE_MAPS' },
  { title: 'dashboard.dataComparison', Icon: PieChart, type: 'COMPARISON' },
];

export const filterInteractionsDataOptions = [
  { title: 'dashboard.businessDirectionRequests', Icon: DirectionRequest, type: 'BUSINESS_DIRECTION_REQUESTS' },
  { title: 'dashboard.callClicks', Icon: CallClick, type: 'CALL_CLICKS' },
  { title: 'dashboard.websiteClicks', Icon: WebsiteClick, type: 'WEBSITE_CLICKS' },
  { title: 'dashboard.businessConversations', Icon: BusinessConversations, type: 'BUSINESS_CONVERSATIONS' },
  { title: 'dashboard.businessBookings', Icon: BusinessBookings, type: 'BUSINESS_BOOKINGS' },
  { title: 'dashboard.businessFoodMenuClicks', Icon: BusinessFoodMenu, type: 'BUSINESS_FOOD_MENU_CLICKS' },
  { title: 'dashboard.businessFoodOrders', Icon: BusinessFoodOrder, type: 'BUSINESS_FOOD_ORDERS' },
];

export const chartTitleConfig = {
  BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: 'dashboard.desktop',
  BUSINESS_IMPRESSIONS_DESKTOP_MAPS: 'dashboard.desktopMap',
  BUSINESS_IMPRESSIONS_MOBILE_SEARCH: 'dashboard.mobile',
  BUSINESS_IMPRESSIONS_MOBILE_MAPS: 'dashboard.mobileMap',
  BUSINESS_DIRECTION_REQUESTS: 'dashboard.businessDirectionRequests',
  COMPARISON: 'dashboard.dataComparison',
  CALL_CLICKS: 'dashboard.callClicks',
  WEBSITE_CLICKS: 'dashboard.websiteClicks',
  BUSINESS_CONVERSATIONS: 'dashboard.businessConversations',
  BUSINESS_BOOKINGS: 'dashboard.businessBookings',
  BUSINESS_FOOD_MENU_CLICKS: 'dashboard.businessFoodMenuClicks',
  BUSINESS_FOOD_ORDERS: 'dashboard.businessFoodOrders',
};
