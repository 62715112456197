import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Select from '../../../../../shared/components/Select';
import Input from '../../../../../shared/components/Input';
import Textarea from '../../../../../shared/components/Textarea';
import Button from '../../../../../shared/components/Button';
import { priceTransformer } from '../../../../../shared/components/Input/transformers';
import { PriceEnums, Prices } from '../ServicesForm/utils';
import { priceValidator } from '../../../../../shared/components/Input/validators';

const MAX_DESCRIPTION_LENGTH = 300;
const ManageService = ({ onModalClose, service, onChange, category, onDeleteService }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [serviceDetails, setServiceDetails] = useState({ ...service });
  const readOnlyCondition = !serviceDetails.priceType || [PriceEnums.noPrice, PriceEnums.freePrice].includes(serviceDetails.priceType);
  const serviceDetailChangeHandler = (target) => (value) => {
    setServiceDetails((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const buttonClickHandler = () => {
    onModalClose();
    onChange(serviceDetails, category);
  };
  const cancelButtonClickHandler = () => onModalClose();
  return (
    <div>
      <div className={classes.body}>
        <div>
          <div className={classes.service}>{t('businessProfileEdit.service')}</div>
          <div className={classes.serviceName}>{service?.displayName}</div>
        </div>
        <div className={classes.servicePriceBox}>
          <div className={classes.rowContainer}>
            <Select
              label={t('businessProfileEdit.price')}
              value={serviceDetails.priceType ?? PriceEnums.noPrice}
              onSelect={serviceDetailChangeHandler('priceType')}
              list={Prices}
              className={classes.select}
              inputClassName={classes.selectInput}
            />
            <Input
              readOnly={readOnlyCondition}
              label={t('businessProfileEdit.enterPriceForService')}
              value={[PriceEnums.noPrice].includes(serviceDetails.priceType) ? '' : serviceDetails.price}
              onChange={serviceDetailChangeHandler('price')}
              validator={priceValidator}
              transformer={priceTransformer}
              onError={setError}
            />
          </div>
          <div>
            <Textarea
              label={t('businessProfileEdit.addServiceDescription')}
              className={classes.serviceDescription}
              maxLength={MAX_DESCRIPTION_LENGTH}
              value={service.description}
              onChange={serviceDetailChangeHandler('description')}
            />
          </div>
          <Button onClick={onDeleteService} className={classes.rwdDeleteServiceBtn} label="Usuń usługę" />
          <div className={classes.actionBox}>
            <Button
              readOnly={error}
              onClick={buttonClickHandler}
              className={classes.confirmButton}
              label={t('businessProfileEdit.confirmAndClose')}
            />
            <Button
              onClick={cancelButtonClickHandler}
              className={classes.cancelButton}
              label={t('businessProfileEdit.cancel')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageService;
