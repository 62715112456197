import {
  postalCodeTransformer,
  stringCharactersTransformer,
  capitalizeFirstLetter,
} from '../../../../../shared/components/Input/transformers';
import {
  makeRequired,
  postalCodeValidator,
  streetAndNumberValidator,
  validateCityName,
} from '../../../../../shared/components/Input/validators';
import { handleApiError } from '../../Settings/ChangePassword/utils';

export const getBusinessLocationInputs = (t) => [
  {
    label: t('businessProfileEdit.streetAndNumber'),
    name: 'addressLines',
    validator: makeRequired(streetAndNumberValidator),
    showErrorAfterBlur: true,
    transformer: capitalizeFirstLetter,
  },
  {
    label: t('businessProfileEdit.postalCode'),
    name: 'postalCode',
    validator: makeRequired(postalCodeValidator),
    showErrorAfterBlur: true,
    transformer: postalCodeTransformer,
  },
  {
    label: t('businessProfileEdit.city'),
    name: 'locality',
    validator: makeRequired(validateCityName),
    showErrorAfterBlur: true,
    transformer: stringCharactersTransformer,
  },
];

export const encodeAddress = async (address, api, setIsLoading, showNotification, t) => {
  try {
    const { data } = await api.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`,
    );
    return data;
  } catch (err) {
    handleApiError({ err, t, showNotification });
    throw err;
  } finally {
    setIsLoading(false);
  }
};

export const getLatLong = async (location, isAddressFullyProvided, handleGetAddress) => {
  const { postalCode, addressLines, locality } = location;
  const payloadAddress = `${addressLines} ${postalCode} ${locality}`;
  if (isAddressFullyProvided) {
    const [data] = await handleGetAddress({ value: payloadAddress });
    return data;
  }
  return null;
};
