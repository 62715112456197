import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../Input';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';
import { filterListFn } from './utils';

const Select = ({
  label,
  list,
  showOnList = (el) => (el ? el.name : ''),
  showErrorAfterBlur,
  value,
  onSelect = () => {},
  className,
  validator,
  inputClassName,
  readOnly,
  onError = () => {},
  filterList,
  admin = false,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const inputValue = useRef('');
  const [choice, setChoice] = useState(null);
  const [showList, setShowList] = useState();
  const [sublist, setSublist] = useState([]);
  const [error, setError] = useState(validator ? validator(value) : false);

  const hideList = useCallback((event) => {
    if (!ref.current.contains(event.target)) {
      setShowList(false);
      document.removeEventListener('click', hideList);
    }
  }, []);

  const handleChange = (val) => {
    inputValue.current = val;
    if (filterList) {
      const filtered = filterList({ list, value: val });
      setSublist(filtered);
    }
  };

  const handleFocus = () => {
    setShowList(true);
    setSublist(list);
    document.addEventListener('click', hideList);
  };

  const handleSelect = (val) => (event) => {
    event.stopPropagation();
    onSelect(val.id ?? val);
    setChoice(val);
    setShowList(false);
    setSublist(list);
    document.removeEventListener('click', hideList);
  };

  const handleKeyDown = (val) => (event) => {
    if (event.keyCode === 13) {
      onSelect(val.id ?? val);
      setShowList(false);
      document.removeEventListener('click', hideList);
    }
  };

  useEffect(() => {
    if (value) {
      setChoice(value);
    }
  }, [value]);

  useEffect(() => {
    if (!readOnly) {
      onError(error);
    }
  }, [error]);

  useEffect(() => {
    if (readOnly) {
      onError(false);
    }
  }, [readOnly]);

  useEffect(() => {
    const filtered = filterListFn({ list, value: inputValue.current });
    setSublist(filtered);
  }, [list, choice]);

  useEffect(() => {
    if (!inputValue.current) {
      setSublist(list);
    }
  }, [inputValue]);

  useEffect(() => {
    const currChoice = list.find((el) => el.id === value);
    if (validator) {
      const isValid = validator(value);
      setError(isValid);
    }
    setChoice(currChoice || value);
    inputValue.current = showOnList(currChoice);
    setSublist(list);
  }, [value]);

  useEffect(
    () => () => {
      document.removeEventListener('click', hideList);
    },
    [],
  );
  useEffect(() => {
    if (!choice) {
      setSublist(list);
    }
  }, [choice]);

  return (
    <div ref={ref} className={clsx(classes.wrapper, className)}>
      <div className={clsx(classes.selectContainer, readOnly && classes.noUserClick)}>
        <Input
          className={inputClassName}
          error={error}
          onChange={handleChange}
          onFocus={handleFocus}
          value={showOnList(choice)}
          showErrorAfterBlur={showErrorAfterBlur}
          readOnly={readOnly}
          labelRised
          label={label}
          admin={admin}
        />
        <ChevronDown
          className={clsx(
            classes.icon,
            showList && classes.invertedIcon,
            readOnly && classes.disabled,
            admin && classes.adminChevron,
          )}
        />
      </div>
      {showList && (
        <div className={classes.list}>
          {sublist.length ? (
            sublist.map((el) => (
              <div
                key={el.id}
                role="link"
                onKeyDown={handleKeyDown(el)}
                tabIndex={0}
                className={classes.element}
                onClick={handleSelect(el)}
              >
                {showOnList(el)}
              </div>
            ))
          ) : (
            <div>{t('keywordsManagement.emptyList')}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Select;
