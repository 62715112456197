import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as GoogleIcon } from '../../../../../shared/assets/googleIconColor.svg';
import { getCallToActionData } from './utils';
import { formEnum } from '../ModalForm/utils/utils';
import { getSchedule } from '../../../views/AddPost/utils';
import TermsConditions from '../OfferTermsConditions';
import OfferLink from '../OfferLink';
import OfferCouponCodeBox from '../OfferCouponCodeBox';

const ActionButton = ({ btnLabel, btnUrl }) => btnLabel && (
<div className={classes.actionBox}>
  <a href={btnUrl} target="_blank" rel="noreferrer" className={classes.button}>
    {btnLabel}
  </a>
</div>
);

const FormPreview = ({ data, subInfo, publishedAddress }) => {
  const { t } = useTranslation();
  const { localPost } = data;
  const { btnLabel, btnUrl } = getCallToActionData(data?.callToAction || data?.localPost.callToAction);
  const openOfferLinkHandler = () => window.open(localPost.offer?.redeemOnlineUrl, '_blank');
  const showPostOnGoogleHandler = () => window.open(publishedAddress, '_blank');

  return (
    <div className={classes.wrapper}>
      <div className={classes.subInfo}>{subInfo}</div>
      {Boolean(localPost.media?.length) && (
        <img src={localPost.media[0].mediaSource} alt="preview" className={classes.image} crossOrigin="anonymous" />
      )}
      <div className={classes.contentWrapper}>
        <div className={classes.rowContainer}>
          {data.type !== formEnum.STANDARD && (
            <div>
              <div className={classes.title}>{localPost.title || data?.localPost?.event?.title}</div>
              <div className={classes.schedule}>{getSchedule(data)}</div>
            </div>
          )}
          {publishedAddress && (
            <Button
              className={classes.navigateGoogleButton}
              Icon={GoogleIcon}
              label={t('posts.viewOnPostGoogle')}
              onClick={showPostOnGoogleHandler}
            />
          )}
        </div>
        <div className={classes.summary}>{localPost.summary}</div>
        <div>
          <ActionButton btnLabel={btnLabel} btnUrl={btnUrl} />
          <OfferLink data={data} localPost={localPost} openOfferLinkHandler={openOfferLinkHandler} />
          <OfferCouponCodeBox localPost={localPost} data={data} />
          <TermsConditions data={data} localPost={localPost} />
        </div>
        <div className={classes.horizontalLine} />
      </div>
    </div>
  );
};

export default FormPreview;
