import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as PreviewIcon } from '../../../../../shared/assets/eye.svg';
import { previewTypes } from '../../../views/AddPost/utils';
import { getLabelFromUrl } from './utils';

const FormContentWrapper = ({
  children,
  buttonLabel,
  submitDataHandler,
  showPreviewHandler,
  readOnlyAction,
  showPreview,
  isAIHelperOpen,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [label, setLabel] = useState(buttonLabel);
  const translatedPreviewLabes = useMemo(() => Object.values(previewTypes).map((el) => t(el)), [t(previewTypes)]);

  useLayoutEffect(() => {
    if (translatedPreviewLabes.includes(buttonLabel)) {
      const parsedLabel = getLabelFromUrl(location);
      setLabel(t(parsedLabel));
    } else {
      setLabel(buttonLabel);
    }
  }, [buttonLabel]);

  return (
    <div className={classes.container}>
      {children}
      {!showPreview && !readOnlyAction && (
        <Button
          Icon={PreviewIcon}
          onClick={showPreviewHandler}
          className={classes.RWDShowPreview}
          label={t('posts.showPreview')}
        />
      )}
      {!isAIHelperOpen && (
        <div className={classes.actionBox}>
          {!showPreview && (
            <Button
              readOnly={readOnlyAction}
              onClick={showPreviewHandler}
              className={classes.showPreview}
              label={t('posts.showPreview')}
            />
          )}
          <Button className={classes.addButton} label={label} readOnly={readOnlyAction} onClick={submitDataHandler} />
        </div>
      )}
    </div>
  );
};

export default FormContentWrapper;
