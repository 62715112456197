import { useEffect, useState } from 'react';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useApi } from './api';
import { useNotification } from './notification';
import { handleApiError } from '../../app/SEO/modules/Settings/ChangePassword/utils';

export const useModal = (initialValue = false) => {
  const [showModal, setShowModal] = useState(initialValue);
  const modalHandler = () => setShowModal((prev) => !prev);

  return [showModal, setShowModal, modalHandler];
};

export const useWindowResize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const onResizeHandler = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  return innerWidth;
};

export const useKeyDown = ({ key, callback, canInvoke = true }) => {
  useEffect(() => {
    const onKeyDownHandler = (e) => {
      const triggerCallback = key === e.key && canInvoke;
      if (triggerCallback) {
        callback();
      }
    };

    window.addEventListener('keydown', onKeyDownHandler);

    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [key, callback, canInvoke]);
};

export const useFetchData = () => {
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async (url, body = null, config = null) => {
    setIsLoading(true);
    try {
      const { data: apiData } = await api.post(url, body, config);
      setData(apiData);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchData, isLoading, data };
};

export const useCloseOnOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export const useIsMobile = (minWidth) => {
  const innerWidth = useWindowResize();
  return innerWidth <= minWidth;
};
