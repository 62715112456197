export const optionsConfig = (t, key, phoneNumber, emailDomain) => {
  const data = {
    EMAIL: {
      verificationMethod: t('businessProfileCreate.verificationMethodEmail'),
      method: 'EMAIL',
      subInfo: t('businessProfileCreate.verificationMethodEmailSubInfo', { domain: emailDomain }),
    },
    PHONE_CALL: {
      verificationMethod: t('businessProfileCreate.verificationMethodPhone'),
      method: 'PHONE',
      subInfo: t('businessProfileCreate.verificationMethodPhoneSubInfo', { phoneNumber }),
    },
    SMS: {
      verificationMethod: t('businessProfileCreate.verificationMethodSMS'),
      method: 'SMS',
      subInfo: t('businessProfileCreate.verificationMethodSMSSubInfo', { phoneNumber }),
    },
    GOOGLE: {
      verificationMethod: t('businessProfileCreate.verificationMethodGoogle'),
      method: 'GOOGLE',
      subInfo: t('businessProfileCreate.verificationMethodGoogleSubInfo'),
    },
  };
  return data[key];
};
