import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { verificationStatusConfig } from './utils';
import { SUSPENDED_PROFILE_ACTION_URL } from '../../Dashboard/BusinessStatus/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { useDashboard } from '../../../helpers/dashboard';

const VerificationStatus = ({ status }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { businessProfile } = useDashboard();
  const [placeId, setPlaceId] = useState(null);
  const { id, label, subTitle, Icon } = verificationStatusConfig(t)[status];
  const { duplicate, suspended } = PROFILE_STATUS_ENUM;
  const shouldRenderLinkForm = [duplicate, suspended].includes(status);

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      const { data } = await api.post('business_profile/getBusinessProfile', { id: businessProfile.id });
      setPlaceId(data.metadata.placeId);
    };
    if (status === duplicate) {
      fetchBusinessProfile();
    }
  }, [status]);

  const linkForm = status === 'suspended' ? SUSPENDED_PROFILE_ACTION_URL : `https://business.google.com/arc/p/${placeId}`;

  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <div className={clsx(classes.title, classes[id])}>{label}</div>
        <div className={clsx(classes.iconWrapper, classes[id])}>
          <Icon className={classes.icon} />
        </div>
        <div className={classes.subTitle}>{subTitle}</div>

        {shouldRenderLinkForm && (
          <a
            target="_blank"
            referrerPolicy="no-referrer"
            href={linkForm}
            className={classes.suspendedForm}
            rel="noreferrer"
          >
            {t('dashboard.suspendedForm')}
          </a>
        )}
      </div>
    </div>
  );
};

export default VerificationStatus;
