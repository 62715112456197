import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as CheckIcon } from '../../../../shared/assets/check.svg';
import { ReactComponent as MinusIcon } from '../../../../shared/assets/minus.svg';
import { generateKey } from '../../../../shared/helpers/helpers';

const AvailabilityMobileItem = ({ availability }) => {
  const { t } = useTranslation();

  const availabilitiesToRender = [
    availability.free,
    availability.mini,
    availability.basic,
    availability.pro,
    availability.trial,
  ];

  const renderValue = (value) => {
    if (typeof value === 'boolean') {
      return (
        <div className={classes.iconWrapper}>
          {value ? (
            <div className={classes.checkIconWrapper}>
              <CheckIcon className={classes.checkIcon} />
            </div>
          ) : (
            <div className={classes.minusIconWrapper}>
              <MinusIcon className={classes.minusIcon} />
            </div>
          )}
        </div>
      );
    }
    const isBoldText = value !== t('landingPage.pricingSection.preview');
    return <div className={clsx(classes.previewValue, isBoldText && classes.boldText)}>{value}</div>;
  };
  return availabilitiesToRender.map((availabilityItem, index) => (
    <div key={generateKey(availabilityItem, index)} className={clsx(classes.gridItem)}>
      {renderValue(availabilityItem)}
    </div>
  ));
};

export default AvailabilityMobileItem;
