import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as Checkmark } from '../../../../../shared/assets/check.svg';

const EmailSendInformation = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.iconWrapper}>
        <Checkmark className={classes.icon} />
      </div>
      <div className={classes.text}>{t('adminControlPanel.checkMailBox')}</div>
    </div>
  );
};

export default EmailSendInformation;
