import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CompletionProgressBar from '../CompletionProgressBar';
import ProfileCompletionModal from '../ProfileCompletionModal';
import ProfileCompletionRow from '../ProfileCompletionRow';
import Loader from '../../../components/Loader';
import { useModal } from '../../../../../shared/helpers/hooks';
import { completionConfig, completionData } from './utils';

const ProfileCompletion = ({ data }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState();
  const [showModal, setShowModal, modalHandler] = useModal(false);
  const { parsedData, score } = useMemo(() => (formData ? completionData(formData) : []), [formData]);
  const completionProfileData = completionConfig(score);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <div className={classes.businessProfileCompletion}>
      {showModal && <ProfileCompletionModal modalHandler={modalHandler}/>}
      {!formData ? <Loader className={classes.loader}/> : (
        <div className={classes.rowContainer}>
          <div className={classes.leftSide}>
            <div className={classes.title}>
              {t('dashboard.businessCardCompletion')}
            </div>

            <CompletionProgressBar progress={score} className={classes.progressBar}/>
            <div className={classes.completionLabel}>{t(completionProfileData.label)}</div>
            <div onClick={modalHandler} className={classes.showDetails}>
              {t('dashboard.showDetails')}
            </div>
          </div>
          <div className={classes.rightSide}>
            {parsedData.map((item) => (
              <ProfileCompletionRow row={item} key={item.id}/>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCompletion;
