export const hslToHex = ({ h, s, l }) => {
  const lightness = l / 100;
  const saturation = (s * Math.min(lightness, 1 - lightness)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = lightness - saturation * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const getHueByPosition = ({ position }) => Math.abs((position - 1) * (1 / (21 - 1)) - 1) * 110;

const generateRedColor = hslToHex({ h: 0, s: 100, l: 50 });

export const getColorByPosition = ({ position }) => {
  const parsedPositionWithPlus = Number(position.toString().split('+')[0]);
  const POSITION_ABOVE_20 = 20 || parsedPositionWithPlus;
  if (position < POSITION_ABOVE_20) {
    const hue = getHueByPosition({ position });
    return hslToHex({ h: hue, s: 70, l: 50 });
  }
  return generateRedColor;
};

export const hexToRgba = (hexColor, alpha) => {
  const hexColorValue = hexColor.replace(/^#/, '');
  const r = parseInt(hexColorValue.slice(0, 2), 16);
  const g = parseInt(hexColorValue.slice(2, 4), 16);
  const b = parseInt(hexColorValue.slice(4, 6), 16);

  if (Number.isNaN(alpha) || alpha < 0 || alpha > 1) {
    const defaultAlpha = 1;
    return `rgba(${r}, ${g}, ${b}, ${defaultAlpha})`;
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
