export const getLocalStorageProfileStatus = (id) => localStorage.getItem(`status-${id}`);

export const getObjectWithoutEmptyValues = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
  if (value) {
    acc[key] = value;
  }
  return acc;
}, {});

export const trimValues = (data) => {
  if (typeof data === 'string') {
    return data.trim();
  }

  if (Array.isArray(data)) {
    return data.map(trimValues);
  }

  if (typeof data === 'object' && data !== null) {
    return Object.entries(data).reduce((accumulator, [key, value]) => {
      const trimmedValue = trimValues(value);
      if (trimmedValue !== '') {
        accumulator[key] = trimmedValue;
      }
      return accumulator;
    }, {});
  }
  return data;
};

export const generateKey = (el, idx) => `${el}-${idx}`;
