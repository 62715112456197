import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import HeaderWithPill from '../../../../../shared/components/HeaderWithPill';
import { TAB_ENUM } from '../../../views/AddPost/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { readOnlySubList } from '../../../helpers/constants';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';

const AddPostHeading = ({ createPost, selectedTab, onTabSelect, pillNumber, adminPreview }) => {
  const { t } = useTranslation();
  const { PLANNED, PUBLISHED } = TAB_ENUM;
  const {
    businessProfile: { subscription },
  } = useDashboard();

  const isReadOnlySubPlan = readOnlySubList.includes(subscription);
  const shouldRenderButton = Boolean(pillNumber) && !adminPreview && !isReadOnlySubPlan;

  const renderButton = (tab, label) => (
    <Button
      className={clsx(classes.tabBtn, selectedTab === tab && classes.activeTabStyle)}
      label={t(label)}
      onClick={() => onTabSelect(tab)}
    />
  );

  return (
    <div className={clsx(classes.wrapper, adminPreview && classes.adminPreview)}>
      <div className={classes.rowContainer}>
        <HeaderWithPill
          className={classes.header}
          pillLabel={t('posts.postsCount', { count: pillNumber })}
          title={t('posts.yourPosts')}
        />
        {shouldRenderButton && (
          <Button
            onClick={createPost}
            className={classes.buttonSmall}
            Icon={AddCircle}
            iconStyle={classes.iconStyle}
            label={t('posts.addPost')}
          />
        )}
      </div>
      <div className={classes.headerSubtitle}>{t('posts.yourEntries')}</div>
      {!isReadOnlySubPlan && (
      <div className={classes.postType}>
        {renderButton(PUBLISHED, 'posts.published')}
        {renderButton(PLANNED, 'posts.planned')}
      </div>
      )}
    </div>
  );
};

export default AddPostHeading;
