import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import ServiceAreaSelect from '../../BusinessProfileEdit/ServiceAreaSelect';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import { ReactComponent as Step2AreaSVG } from '../../../../../shared/assets/serviceArea.svg';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';
import { updateRegionCodeOrCountries } from './utils';
import { formEnum } from '../WelcomeScreenCreate/utils';
import CreateBusinessProfileTextWrapper from '../CreateBusinessProfileTextWrapper';

const FormServiceArea = ({ onChange, form, scrollOnFocus, onMetaDataChange }) => {
  const { t } = useTranslation();
  const { placeInfos: formServiceAreaList } = form.serviceArea.places;

  const [selectedAreas, setSelectedAreas] = useState(formServiceAreaList);

  const handleArea = (area) => {
    if (area) {
      setSelectedAreas((prev) => [...prev, area]);
    }
  };
  const handleDeleteArea = (id) => setSelectedAreas((prev) => prev.filter((el) => el.placeId !== id));

  useEffect(() => {
    updateRegionCodeOrCountries({ selectedAreas, onChange, onMetaDataChange });
  }, [selectedAreas.length]);

  useEffect(() => {
    onChange(formEnum.serviceArea)(selectedAreas);
  }, [selectedAreas]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftSide}>
        <div className={classes.form}>
          <div className={classes.formTitle}>
            <CreateBusinessProfileTextWrapper
              title={t('businessProfileCreate.passServiceArea')}
              subTitle={t('businessProfileCreate.passServiceAreaSubinfo')}
            />
            <div onFocus={scrollOnFocus} className={classes.form}>
              <ServiceAreaSelect onAreaChange={handleArea} areas={selectedAreas} isEdit />
              <div className={classes.optionalInformationText}>
                {t('businessProfileCreate.optionalInformationServiceArea')}
              </div>
              <div className={classes.pickedAreasList}>
                {selectedAreas.map((el) => (
                  <PillWithIcon className={classes.pill} key={el.placeId}>
                    {el.placeName}
                    <CloseIcon onClick={() => handleDeleteArea(el.placeId)} className={classes.closeIcon} />
                  </PillWithIcon>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <Step2AreaSVG />
      </div>
    </div>
  );
};

export default FormServiceArea;
