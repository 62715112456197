import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import IconWrapper from '../../IconWrapper';
import { ReactComponent as ChevronDown } from '../../../assets/chevronv2.svg';

const SelectAlternative = ({
  list = [],
  showOnList = (el) => (el ? el.name : ''),
  value,
  onSelect = () => {},
  className,
  labelStyle,
}) => {
  const { t } = useTranslation();
  const [choice, setChoice] = useState({});
  const ref = useRef();
  const [showList, setShowList] = useState();
  const hideList = useCallback((event) => {
    if (!ref.current.contains(event.target)) {
      setShowList(false);
      document.removeEventListener('click', hideList);
    }
  }, []);

  useEffect(() => {
    const currChoice = list.find((el) => el.id === value);
    setChoice(currChoice);
  }, [value]);

  useEffect(
    () => () => {
      document.removeEventListener('click', hideList);
    },
    [],
  );

  const handleFocus = () => {
    setShowList(true);
    document.addEventListener('click', hideList);
  };

  const handleSelect = (val) => (e) => {
    onSelect(val.id);
    setShowList(false);
    document.removeEventListener('click', hideList);
    e.stopPropagation();
  };

  return (
    <div onClick={handleFocus} ref={ref} className={clsx(classes.wrapper, className)}>
      <div className={clsx(classes.selectContainer, labelStyle)}>
        {t(choice?.name, { number: choice?.value })}
        <IconWrapper className={clsx(classes.icon, showList && classes.invertedIcon)} Icon={ChevronDown} />
      </div>
      {showList && (
        <div className={classes.list}>
          {list.map((el) => (
            <div key={el.id} role="link" tabIndex={0} className={classes.element} onClick={handleSelect(el)}>
              {showOnList(el)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default SelectAlternative;
