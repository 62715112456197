import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Gallery from '../../../../../shared/components/Gallery';
import Checkbox from '../../../../../shared/components/Checkbox';
import PostponedMediaItem from '../PostponedMediaItem';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';
import VideoPlayer from '../../../../../shared/components/VideoPlayer';

const MediaItem = ({ item, onDelete, isEdit, galleryCollection, onSelect, selectedItems, onSuccess, index }) => {
  const [fullSize, setFullSize] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const {
    name,
    isImage,
    thumbnailUrl,
    googleUrl,
    locationAssociation: { category },
    publishDate,
  } = item;
  const source = category === 'PROFILE' ? googleUrl : thumbnailUrl;

  const handleOnClick = (e) => {
    e.stopPropagation();
    setFullSize((prev) => !prev);
    setCurrentImage(index);
  };

  const closeGalleryHandler = () => {
    setFullSize(false);
  };

  const onNext = (event) => {
    event.stopPropagation();
    setCurrentImage((prev) => (prev + 1) % galleryCollection.length);
  };

  const onPrev = (event) => {
    event.stopPropagation();
    setCurrentImage((prev) => (prev - 1 + galleryCollection.length) % galleryCollection.length);
  };

  useEffect(() => {
    setIsSelected(selectedItems.includes(item));
  }, [selectedItems.length]);

  return (
    <>
      <div className={classes.content}>
        {publishDate ? (
          <PostponedMediaItem
            onItemClick={handleOnClick}
            item={item}
            isSelected={isSelected}
            isEdit={isEdit}
            onSuccess={onSuccess}
          />
        ) : (
          <div onClick={handleOnClick} className={clsx(classes.userImage, isSelected && classes.borderSelected)}>
            {isImage && (
              <img
                className={clsx(classes.userImage, isSelected && classes.borderSelected)}
                key={name}
                src={source}
                alt="business profile media"
              />
            )}
            {!isImage && (
              <VideoPlayer
                videoUrl={item.sourceUrl}
                className={clsx(classes.userVideo, isSelected && classes.borderSelected)}
              />
            )}
          </div>
        )}

        {isEdit && (
          <div className={classes.itemActions}>
            <Checkbox
              value={isSelected}
              onClick={() => onSelect(item)}
              checkboxStyle={classes.checkboxStyle}
              className={classes.checkbox}
            />
            <CloseIcon onClick={(e) => onDelete(e, item)} className={classes.closeIcon} />
          </div>
        )}
      </div>
      {fullSize && (
        <Gallery
          media={galleryCollection}
          onGalleryClose={closeGalleryHandler}
          currentImage={currentImage}
          onNext={onNext}
          onPrev={onPrev}
        />
      )}
    </>
  );
};
export default MediaItem;
