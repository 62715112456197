import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as CopyIcon } from '../../../../../shared/assets/copy.svg';

const AiProposal = ({ aiReply, onSelect }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      {aiReply.map((el) => (
        <div key={el} className={classes.proposal}>
          <div className={classes.proposalText}>{el}</div>
          <Button
            onClick={() => onSelect(el)}
            className={classes.useProposalBtn}
            Icon={CopyIcon}
            label={t('global.editAndChoose')}
          />
        </div>
      ))}
    </div>
  );
};
export default AiProposal;
