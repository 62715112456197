import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Day from '../Day';
import { dayNames } from '../Calendar/utils';
import CalendarNavigation from '../MonthCalendarNavigation';

const Month = ({
  month,
  selectedStartDate,
  selectedEndDate,
  handleDayClick,
  handleMonthChange,
  isSingleMonth,
  isFirstMonth,
  futureDaysNotAvailable,
  admin,
  availableStartDate,
}) => {
  const { t } = useTranslation();
  const weekdays = useMemo(() => dayNames.map((day) => t(day)), [t]);
  const firstDayOfMonth = (month.date(1).day() + 6) % 7;
  const daysInMonth = month.daysInMonth();

  const monthDays = Array(daysInMonth + firstDayOfMonth).fill(null);
  const renderMonthDay = (_, index) => {
    const day = index + 1 - firstDayOfMonth;
    if (day < 1) return <div key={Math.random()} />;
    const date = month.date(day);
    return (
      <Day
        key={day}
        date={date}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        handleDayClick={handleDayClick}
        futureDaysNotAvailable={futureDaysNotAvailable}
        availableStartDate={availableStartDate}
        admin={admin}
      />
    );
  };

  return (
    <div className={classes.month}>
      <CalendarNavigation
        isFirstMonth={isFirstMonth}
        isSingleMonth={isSingleMonth}
        periodName={month}
        period="month"
        handleMonthChange={handleMonthChange}
      />
      <div className={classes.calendarGrid}>
        {weekdays.map((day) => (
          <div key={day} className={classes.weekday}>
            {day}
          </div>
        ))}
        {monthDays.map(renderMonthDay)}
      </div>
    </div>
  );
};
export default Month;
