import React from 'react';
import clsx from 'clsx';
import { activePillConfig, formatDate } from '../../../views/AdministratorList/utils';
import { ReactComponent as CheckIcon } from '../../../../../shared/assets/check.svg';
import { ReactComponent as MinusIcon } from '../../../../../shared/assets/minus.svg';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { ROLE_ENUM } from '../../../views/AdministratorDetails/utils';

export const SORT_CARD_COLUMNS = {
  cardCode: 'card_code',
  cardName: 'card_name',
  createdAt: 'created_at',
  email: 'email',
  nip: 'nip',
  bpName: 'bp_name',
  interaction: 'interaction',
  lastUsed: 'last_used',
  active: 'active',
  seo: 'seo',
};
const { active, bpName, cardCode, cardName, createdAt, email, interaction, lastUsed, nip, seo } = SORT_CARD_COLUMNS;

export const seoCardListTableConfig = (t) => [
  {
    header: t('adminControlPanel.cardId'),
    property: 'cardCode',
    sort: cardCode,
    minWidth: '150px',
    width: '150px',
    position: 'sticky',
    directionKey: 'left',
    directionValue: '61px',
  },
  { header: t('adminControlPanel.cardTitle'), property: 'cardName', sort: cardName, minWidth: '150px', width: '150px' },
  {
    header: t('adminControlPanel.interactionAmount'),
    property: 'interaction',
    sort: interaction,
    minWidth: '150px',
    width: '150px',
  },
  { header: t('adminControlPanel.activity'), property: 'lastUsed', sort: lastUsed, minWidth: '150px', width: '150px' },
  {
    header: t('adminControlPanel.cardCreatedAt'),
    property: 'createdAt',
    sort: createdAt,
    minWidth: '150px',
    width: '150px',
  },
  { header: t('adminControlPanel.clientMail'), property: 'email', sort: email, minWidth: '150px', width: '150px' },
  {
    header: t('adminControlPanel.businessProfile'),
    property: 'bpName',
    sort: bpName,
    minWidth: '150px',
    width: '150px',
  },
  { header: t('adminControlPanel.companyNip'), property: 'nip', sort: nip, minWidth: '150px', width: '150px' },
  { header: t('adminControlPanel.seo'), property: 'seo', sort: seo, minWidth: '150px', width: '150px' },
  { header: t('adminControlPanel.cardStatus'), property: 'active', sort: active, minWidth: '150px', width: '150px' },
];

export const seoCardDataParser = (data, t) => data.map((item) => ({
  ...item,
  key: item.id,
  cardName: item.cardName,
  interaction: item.interaction || '-',
  lastUsed: formatDate(item.lastUsed) === 'Invalid Date' ? '-' : formatDate(item.lastUsed),
  createdAt: formatDate(item.createdAt) === 'Invalid Date' ? '-' : formatDate(item.createdAt),
  email: item.email,
  bpName: item.bpName || '-',
  nip: item.nip || '-',
  lastLogin:
      formatDate(item.lastLogin) === 'Invalid Date' ? t('adminControlPanel.noData') : formatDate(item.lastLogin),
  seo: item.seo ? <CheckIcon className={classes.seoStatusIcon} /> : <MinusIcon className={classes.seoStatusIcon} />,
  active: (
    <Pill
      className={clsx(classes.pill, item.active && classes.activeStatusPill)}
      label={activePillConfig(item.active, t)}
    />
  ),
}));

export const checkIfAllEmailsOrNipsAreSame = (list) => {
  const setOfList = new Set(list.map((item) => item.email || item.nip));
  return setOfList.size === 1;
};

export const isButtonEnabled = (role, seoCardList) => {
  const { superAdmin, moderator } = ROLE_ENUM;
  if (role === superAdmin) {
    return true;
  }
  if (role === moderator) {
    return checkIfAllEmailsOrNipsAreSame(seoCardList);
  }
  return false;
};
