import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import NavigationBar from '../NavigationBar';
import classes from './styles.module.css';

const PrivacyPolicy = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div>
      <NavigationBar className={classes.navbarBackground} hideSectionList />
      <div className={clsx(classes.wrapper, className)}>
        <h2>{t('privacyPolicy.title')}</h2>
        <div>
          <h3>{t('privacyPolicy.generalProvisions')}</h3>
          <p>{t('privacyPolicy.dataController')}</p>
          {t('privacyPolicy.contactInfo', { returnObjects: true }).map((info, index) => (
            <p key={info}>{info}</p>
          ))}
          <p>{t('privacyPolicy.dataProcessing')}</p>
          <p>{t('privacyPolicy.dataProtection')}</p>
          <ul>
            {t('privacyPolicy.dataProtectionList', { returnObjects: true }).map((item, index) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          <h3>{t('privacyPolicy.automaticallyCollectedData')}</h3>
          <p>{t('privacyPolicy.automaticDataCollection')}</p>
          <p>{t('privacyPolicy.noAutomatedProcessing')}</p>
          <p>{t('privacyPolicy.automatedDecisionMaking')}</p>
          <h3>{t('privacyPolicy.dataProcessingPurpose')}</h3>
          <h4>{t('privacyPolicy.clientsAndContractors')}</h4>
          <p>{t('privacyPolicy.contractDataProcessing')}</p>
          <ul>
            {t('privacyPolicy.dataProcessingPurposeList', { returnObjects: true }).map((item, index) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          <p>{t('privacyPolicy.dataProvisionVoluntary')}</p>
          <p>{t('privacyPolicy.dataCategories')}</p>
          <p>{t('privacyPolicy.dataRetention')}</p>
          <h4>{t('privacyPolicy.correspondents')}</h4>
          <p>{t('privacyPolicy.correspondenceDataProcessing')}</p>
          <p>{t('privacyPolicy.correspondencePurpose')}</p>
          <ul>
            {t('privacyPolicy.correspondencePurposeList', { returnObjects: true }).map((item, index) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          <p>{t('privacyPolicy.correspondenceVoluntary')}</p>
          <p>{t('privacyPolicy.correspondenceDataCategories')}</p>
          <p>{t('privacyPolicy.correspondenceRetention')}</p>
          <h4>{t('privacyPolicy.usersAndVisitors')}</h4>
          <p>{t('privacyPolicy.userDataProcessing')}</p>
          <p>{t('privacyPolicy.userPurpose')}</p>
          <ul>
            {t('privacyPolicy.userPurposeList', { returnObjects: true }).map((item, index) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          <p>{t('privacyPolicy.userVoluntary')}</p>
          <p>{t('privacyPolicy.userRetention')}</p>
          <p>{t('privacyPolicy.userRetentionSecondParagraph')}</p>
          <h3>{t('privacyPolicy.dataUsage')}</h3>
          {t('privacyPolicy.dataUsageText', { returnObjects: true }).map((text, index) => (
            <p key={text}>{text}</p>
          ))}
          <h3>{t('privacyPolicy.dataRecipients')}</h3>
          {t('privacyPolicy.dataRecipientsText', { returnObjects: true }).map((text, index) => (
            <p key={text}>{text}</p>
          ))}
          <h3>{t('privacyPolicy.dataStorageAndDeletion')}</h3>
          <p>{t('privacyPolicy.dataStorageAndDeltionTitle')}</p>
          <ul>
            {t('privacyPolicy.dataStorageAndDeletionText', { returnObjects: true }).map((text, index) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
          <p>{t('privacyPolicy.dataStorageAndDeletionSubInfo')}</p>
          <h3>{t('privacyPolicy.userRights')}</h3>
          {t('privacyPolicy.userRightsText', { returnObjects: true }).map((text, index) => (
            <p key={text}>{text}</p>
          ))}
          <h3>{t('privacyPolicy.cookies')}</h3>
          {t('privacyPolicy.cookiesText', { returnObjects: true }).map((text, index) => {
            if (typeof text === 'string') {
              return <p key={text} dangerouslySetInnerHTML={{ __html: text }} />;
            }
            if (text.list) {
              return (
                <ul key={text.list.item}>
                  {text.list.map((item, subIndex) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
