import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as CircleAddIcon } from '../../../../../shared/assets/addCircle.svg';
import { ServicesTypeEnum } from '../ServicesForm/utils';
import Button from '../../../../../shared/components/Button';
import ServiceItem from '../ServiceItem';
import Tooltip from '../../../../../shared/components/Tooltip';
import { useDashboard } from '../../../helpers/dashboard';
import { readOnlySubList } from '../../../helpers/constants';

const CategoryCard = ({
  categories,
  onServiceDetailChange,
  showModal,
  onServiceDelete,
  readOnly,
  onCategoryEdit,
  categoryToEdit,
  isEdit,
  adminPreview,
  isReadOnlySubPlan,
}) => {
  const { t } = useTranslation();

  const isEditBtnRendered = !adminPreview && !isReadOnlySubPlan;
  const readOnlyAddServiceBtn = !categoryToEdit.includes(categories?.categoryReferenceId);
  const customServices = useMemo(
    () => categories?.serviceTypes?.filter(
      (service) => service.type === ServicesTypeEnum.FREE_FORM_SERVICE_ITEM && service.selected,
    ),
    [categories?.serviceTypes],
  );

  const googleServices = useMemo(
    () => categories?.serviceTypes?.filter(
      (service) => service.type !== ServicesTypeEnum.FREE_FORM_SERVICE_ITEM && service.selected,
    ),
    [categories?.serviceTypes],
  );

  const selectedServices = useMemo(
    () => categories?.serviceTypes?.filter((service) => service.selected) || [],
    [categories?.serviceTypes],
  );
  return (
    <div className={classes.categoryBox}>
      <div className={classes.header}>
        <div>
          <div className={classes.categoryType}>{categories?.type}</div>
          <div className={classes.categoryName}>{categories?.name}</div>
        </div>
        {isEditBtnRendered && (
          <Button
            onClick={() => onCategoryEdit(categories.categoryReferenceId)}
            className={classes.editBtn}
            label={t('global.edit')}
          />
        )}
      </div>
      <div className={classes.body}>
        {Boolean(googleServices?.length || customServices?.length) && (
          <div className={classes.serviceHeader}>
            <div className={classes.serviceName}>{t('businessProfileEdit.service')}</div>
            <div className={classes.servicePrice}>{t('businessProfileEdit.price')}</div>
          </div>
        )}

        {selectedServices.length ? (
          <div className={classes.servicesBox}>
            <div>
              {Boolean(googleServices.length)
                && googleServices.map((service) => (
                  <div key={service.serviceTypeId}>
                    <ServiceItem
                      readOnly={readOnlyAddServiceBtn}
                      onServiceDelete={onServiceDelete}
                      onChange={onServiceDetailChange}
                      category={categories.categoryReferenceId}
                      service={service}
                      isEdit={isEdit}
                      adminPreview={adminPreview}
                      isReadOnlySubPlan={isReadOnlySubPlan}
                    />
                  </div>
                ))}
            </div>
            {Boolean(customServices.length) && (
              <div>
                <div
                  className={clsx(classes.customServicesHeader, Boolean(googleServices.length) && classes.separation)}
                >
                  {t('businessProfileEdit.customServicesPlural')}
                </div>

                {customServices.map((service) => (
                  <ServiceItem
                    readOnly={readOnlyAddServiceBtn}
                    key={service.serviceTypeId}
                    onServiceDelete={onServiceDelete}
                    onChange={onServiceDetailChange}
                    category={categories.categoryReferenceId}
                    service={service}
                    isEdit={isEdit}
                    adminPreview={adminPreview}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className={classes.addFirstService}>{t('businessProfileEdit.addFirstService')}</div>
        )}

        {isEditBtnRendered && (
          <Tooltip
            isEdit={!readOnlyAddServiceBtn}
            text={t('businessProfileEdit.addServiceToolTip')}
            tooltipStyle={classes.tooltipStyle}
          >
            <Button
              onClick={showModal}
              readOnly={readOnlyAddServiceBtn}
              iconWidth={16}
              className={classes.addServiceButton}
              Icon={CircleAddIcon}
              iconStyle={readOnly && classes.readOnlyIcon}
              label={t('businessProfileEdit.addService')}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
export default CategoryCard;
