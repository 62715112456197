import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import mapImage from '../../../../../shared/assets/map.jpg';
import Range from '../../../../../shared/components/Range';

const renderLabel = (val) => (val ? `${val} km` : '');

const max = 10;
const min = 1;
const step = 1;

const parseToNumber = (value) => {
  const parsed = Number(value);
  return Number.isNaN(parsed) ? 0 : parsed;
};

const KeywordDistance = ({ onChange, value, rwd }) => {
  const { t } = useTranslation();
  const [distance, setDistance] = useState(parseToNumber(value));

  const handleChange = (val) => {
    setDistance(parseToNumber(val));
  };
  useEffect(() => {
    if (rwd) {
      onChange(distance);
    }
  }, [distance]);
  const handleBlur = (val) => {
    if (rwd) return;
    onChange(parseToNumber(val));
  };

  const points = useMemo(() => {
    const width = 180;
    const radius = 10;
    const pointsDistance = (distance / max) * ((width - radius * 3) - 2 * radius) + radius * 3;
    const halfDistance = pointsDistance / 2;
    const coordinates = [
      { x: halfDistance, y: halfDistance }, { x: 0, y: halfDistance }, { x: -halfDistance, y: halfDistance },
      { x: halfDistance, y: 0 }, { x: 0, y: 0 }, { x: -halfDistance, y: 0 },
      { x: halfDistance, y: -halfDistance }, { x: 0, y: -halfDistance }, { x: -halfDistance, y: -halfDistance },
    ];
    return coordinates.map((el) => ({ x: width / 2 + el.x - radius, y: width / 2 + el.y - radius, radius }));
  }, [distance]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>{t('generateReport.pointsDistance')}</div>
      {!rwd && (
      <>
        <div className={classes.subInfo}>Lorem ipsum dolor sit amet consectetur. Malesuada tellus aliquam vel nisi porttitor vel faucibus ultricies. </div>
        <div className={classes.imageWrapper}>
          <img alt="map preview" className={classes.image} src={mapImage}/>
          <div className={classes.net}>
            {points.map((el, index) => (
              <div
                key={`${el.x}-${el.y}-${distance}`}
                style={{ width: `${el.radius * 2}px`, left: `${el.x}px`, top: `${el.y}px` }}
                className={classes.point}
              />
            ))}
          </div>
        </div>
      </>
      )}
      <Range
        labelClassName={classes.rangeLabel}
        step={step}
        min={min}
        max={max}
        value={distance}
        onChange={handleChange}
        renderLabel={renderLabel}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default KeywordDistance;
