import dayjs from 'dayjs';
import {
  eventFormFieldsValidation,
  formEnum,
  handleEventForm,
  handleOfferForm,
  handleStandardForm,
  offerFormFieldsValidation,
  updateFormFieldsValidation,
} from '../ModalForm/utils/utils';
import { getPostID } from '../../../views/AddPost/utils';
import { plannedPostValidator } from '../../../../../shared/components/Input/validators';

export const removeEmptyKeys = (obj) => Object.keys(obj).reduce((acc, key) => {
  if (
    obj[key] === undefined
      || obj[key] === null
      || obj[key] === ''
      || (Array.isArray(obj[key]) && obj[key].length === 0)
  ) {
    return acc;
  }
  if (typeof obj[key] !== 'object' || Array.isArray(obj[key])) {
    return { ...acc, [key]: obj[key] };
  }
  const nestedObj = removeEmptyKeys(obj[key]);
  if (Object.keys(nestedObj).length > 0) {
    return { ...acc, [key]: nestedObj };
  }
  return acc;
}, {});

const convertToBase64 = async (mediaSource) => {
  let mediaBlob;
  if (typeof mediaSource === 'string') {
    const response = await fetch(mediaSource);
    if (!response.ok) {
      throw new Error('Failed to fetch media from URL');
    }
    mediaBlob = await response.blob();
  }

  const source = mediaSource instanceof Blob ? mediaSource : mediaBlob;

  if (!(source instanceof Blob)) {
    throw new TypeError('mediaSource must be of type Blob or File');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(source);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
const preparePostPayload = async (data, businessProfile, plannedPublishDate, isUpdate = false, editPost = null) => {
  const { localPost = { media: [] } } = data || {};
  const { media, ...rest } = localPost;
  const payload = {
    type: data.type,
    business_profile_id: businessProfile.id,
    localPost: rest,
    publishDate: plannedPublishDate,
    localPostId: null,
  };
  if (media?.length) {
    payload.media = await convertToBase64(media[0].mediaSource);
  }

  if (isUpdate) {
    payload.localPostId = editPost.id || getPostID(editPost.name);
  }

  return payload;
};

export const prepareApiData = async (data, editPost, businessProfile) => {
  const plannedPublishDate = data.publishDate || editPost?.publishDate;
  const apiEndpoint = editPost ? '/local_post/updateLocalPost' : '/local_post/createLocalPost';
  const apiPlannedEndpoint = editPost ? '/local_post/updatePostponedLocalPost' : '/local_post/createPostponedLocalPost';
  const isPlannedDateValid = !!plannedPublishDate && dayjs(plannedPublishDate).isValid();
  const finalApiEndpoint = isPlannedDateValid ? apiPlannedEndpoint : apiEndpoint;
  const finalPayload = editPost
    ? await preparePostPayload(data, businessProfile, plannedPublishDate, true, editPost)
    : await preparePostPayload(data, businessProfile, plannedPublishDate);

  return { apiPath: finalApiEndpoint, payload: finalPayload };
};

export const handleChangeFormType = (formType, name, value, setData) => {
  switch (formType) {
    case formEnum.STANDARD:
      handleStandardForm(name, value, setData);
      break;
    case formEnum.OFFER:
      handleOfferForm(name, value, setData);
      break;
    case formEnum.EVENT:
      handleEventForm(name, value, setData);
      break;
    default:
      break;
  }
};

export const validateFormFieldsType = (data) => {
  switch (data.type) {
    case formEnum.OFFER:
      return offerFormFieldsValidation(data);

    case formEnum.EVENT:
      return eventFormFieldsValidation(data);

    case formEnum.STANDARD:
      return updateFormFieldsValidation(data);

    default:
      throw new Error(`Unsupported form type: ${data.type}`);
  }
};
