import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useDashboard } from '../../helpers/dashboard';
import Header from '../../modules/BusinessProfileChangeHistory/Header';
import { config } from './utils';
import ChangeHistoryActions from '../../modules/BusinessProfileChangeHistory/ChangeHistoryActions';
import List from '../../../../shared/components/List';
import { getObjectWithoutEmptyValues } from '../../../../shared/helpers/helpers';
import Pagination from '../../../../shared/components/Pagination';
import { useIsMobile } from '../../../../shared/helpers/hooks';
import MobileList from '../../modules/BusinessProfileChangeHistory/MobileList';
import { MIN_TABLET_WIDTH, readOnlySubList } from '../../helpers/constants';
import NoAccessInformation from '../../components/NoAccessInformation';

const BusinessProfileChangeHistory = ({ userId, businessProfileId }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { businessProfile } = useDashboard();
  const { id, subscription } = businessProfile;
  const isMobileScreen = useIsMobile(MIN_TABLET_WIDTH);
  const isReadOnlySubPlan = readOnlySubList.includes(subscription);

  const [changeHistory, setChangeHistory] = useState({ profileChanges: [], profileChangesCount: 0 });
  const [filters, setFilters] = useState({ action: '', date: '', field: '', userId });
  const [apiFilters, setApiFilters] = useState({ limit: 5, offset: 0 });

  const changeFiltersHandler = (name) => (value) => {
    if (t(value) === t('businessProfileChangeHistory.all')) {
      setFilters((prev) => ({ ...prev, [name]: '' }));
      return;
    }
    setFilters((prev) => ({ ...prev, [name]: value }));
    setApiFilters((prev) => ({ ...prev, offset: 0 }));
  };

  const paginationLimitChangeHandler = (limit) => {
    setApiFilters((prev) => ({ ...prev, limit, offset: 0 }));
  };

  const pageChangeHandler = (page) => {
    setApiFilters((prev) => ({ ...prev, offset: (page - 1) * apiFilters.limit }));
  };

  useEffect(() => {
    const fetchChangeLog = async () => {
      const filtersWithValues = getObjectWithoutEmptyValues(filters);
      const { limit, offset } = apiFilters;
      const apiPath = userId
        ? '/acp/client/getBusinessProfileChangeHistory'
        : '/business_profile/getBusinessProfileChangeHistory';

      const { data } = await api.post(apiPath, {
        businessProfileId: id || businessProfileId,
        limit,
        offset,
        ...filtersWithValues,
      });
      const { profileChanges, profileChangesCount } = data;
      setChangeHistory({ profileChanges, profileChangesCount });
    };
    if ((id || userId) && !isReadOnlySubPlan) {
      fetchChangeLog();
    }
  }, [id, filters, apiFilters.offset, apiFilters.limit, userId, isReadOnlySubPlan]);

  return (
    <div className={clsx(classes.wrapper, userId && classes.adminPreview)}>
      <Header />
      {isReadOnlySubPlan ? <NoAccessInformation /> : (
        <>
          <ChangeHistoryActions onFiltersChange={changeFiltersHandler} userId={userId} />
          <List className={classes.listWrapper} config={config} data={changeHistory.profileChanges} />
          {isMobileScreen && <MobileList config={config} data={changeHistory.profileChanges} />}
          {Boolean(changeHistory.profileChanges.length) && (
            <Pagination
              page={apiFilters.offset / apiFilters.limit + 1}
              onPageChange={pageChangeHandler}
              onLimitChange={paginationLimitChangeHandler}
              limit={apiFilters.limit}
              className={classes.pagination}
              total={changeHistory.profileChangesCount}
            />
          )}
        </>
      )}
    </div>
  );
};
export default BusinessProfileChangeHistory;
