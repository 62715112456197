import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import ViewTitle from '../../components/ViewTitle';
import TabNavigator from '../../components/TabNavigator';
import { useNotification } from '../../../../shared/helpers/notification';
import { useApi } from '../../../../shared/helpers/api';
import { useAdmin } from '../../helpers/admin';
import Table from '../../../../shared/components/Table';
import {
  USER_LIST_NAVIGATION_KEY_ENUM,
  USER_LIST_TABS,
  columnsConfig,
  columnsConfigManager,
  getEmptyStateMessage,
  userDataParser,
} from './utils';
import AdminPagination from '../../components/AdminPagination';
import SearchInput from '../../../../shared/components/SearchInput';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import AdminLoader from '../../components/AdminLoader';
import { FILTERS_ENUM, MIN_SEARCH_LENGTH, SORT_COLUMNS } from '../AdministratorList/utils';
import { debounce } from '../../../../shared/helpers/debounce';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import ExpandedUserRow from '../../modules/UserList/ExpanedUserRow';
import { ROLE_ENUM } from '../AdministratorDetails/utils';
import { exportCSVDataHandler, prepareExportFilters } from '../../helpers/utils';
import { SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';
import Button from '../../../../shared/components/Button';
import { ReactComponent as AddBtnIcon } from '../../../../shared/assets/addCircle.svg';
import { useModal } from '../../../../shared/helpers/hooks';
import AddManagerModal from '../../modules/UserList/AddManagerModal';

const { clients, managers } = USER_LIST_NAVIGATION_KEY_ENUM;
const { LIMIT, OFFSET, SEARCH } = FILTERS_ENUM;
const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { login } = SORT_COLUMNS;

const UserList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { role } = useAdmin();

  const tabs = USER_LIST_TABS(t, role);

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({ limit: 10, offset: 0, search: '' });
  const [orderBy, setOrderBy] = useState({ column: login, direction: ASC });
  const [isLoading, setIsLoading] = useState(false);
  const [showAddManagerModal, setShowAddManagerModal] = useModal();

  const emptyState = getEmptyStateMessage(t, filters, activeTab);

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };

  const onUserEditClick = (row) => {
    const { id } = row;
    navigate(`/admin/users/${id}`);
  };

  const fetchList = async () => {
    setIsLoading(true);
    const endpoint = activeTab.key === clients ? '/acp/client/clientList' : '/acp/client/managerList';
    try {
      const filtersWithNoNullValue = removeNullValues(filters);
      const {
        data: { results, total },
      } = await api.post(endpoint, { filters: filtersWithNoNullValue, orderBy });
      setUserData(userDataParser(results, t));
      setTotalRecords(total);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const exportDataHandler = async () => {
    const parsedFilters = prepareExportFilters(filters);

    try {
      const response = await api.post(
        '/acp/client/clientListExport',
        { filters: parsedFilters, orderBy },
        { responseType: 'blob' },
      );
      exportCSVDataHandler({ fileName: 'clientList', response, showNotification, t });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0, search: '' }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const modalHandler = () => {
    setShowAddManagerModal((prev) => !prev);
  };

  const onExpandRowRender = (row) => <ExpandedUserRow userData={row} setLoading={setIsLoading} tab={activeTab.key} onListFetch={fetchList} />;

  useEffect(() => {
    fetchList();
  }, [activeTab, orderBy, filters]);

  return (
    <div>
      <ViewTitle title={t('adminControlPanel.users')} />
      <TabNavigator tabs={tabs} onTabClick={tabClickHandler} activeTab={activeTab.label} />
      {showAddManagerModal && <AddManagerModal onClose={modalHandler} onListFetch={fetchList} />}
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <AdminLoader />
        </div>
      ) : (
        <div className={classes.body}>
          <div className={classes.leftSide}>
            <SearchInput
              className={classes.searchInput}
              label={t('global.search')}
              onSearch={handleFiltersChange(SEARCH)}
              value={filters.search}
            />
            {activeTab.key === managers && (
            <Button
              Icon={AddBtnIcon}
              className={classes.addCardBtn}
              label={t('adminControlPanel.addManager')}
              onClick={modalHandler}
            />
            )}
          </div>
          <Table
            className={classes.tableWrapper}
            tableBodyStyle={classes.tableStyle}
            expandableRow
            data={userData}
            orderBy={orderBy}
            columns={activeTab === clients ? columnsConfig(t) : columnsConfigManager(t)}
            emptyStateTitle={emptyState}
            onSortClick={sortHandler}
            editHandler={onUserEditClick}
            onExpandRowRender={onExpandRowRender}
          />
          {Boolean(userData.length) && (
            <AdminPagination
              className={classes.pagination}
              total={totalRecords}
              offset={filters.offset}
              limit={filters.limit}
              onPageChange={handleFiltersChange(OFFSET)}
              onLimitChange={handleFiltersChange(LIMIT)}
              onExportData={exportDataHandler}
              disableExport={role !== ROLE_ENUM.superAdmin}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserList;
