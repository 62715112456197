import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import classes from './styles.module.css';
import BusinessItemImage from '../BusinessItemImage';
import BusinessItemActions from '../BusinessItemActions';
import { statusConfig } from '../../Dashboard/BusinessStatus/utils';
import { parseProfileStatus } from '../../../components/DashboardLayout/utils';
import Checkbox from '../../../../../shared/components/Checkbox';
import withLoading from '../../../../../shared/components/WithLoading';
import BusinessItemStatus from '../BusinessItemStatus';
import { handleApiError } from '../ChangePassword/utils';
import { getLocalStorageProfileStatus } from '../../../../../shared/helpers/helpers';

const ProfileStatusWithLoader = withLoading({ Component: BusinessItemStatus, className: classes.loader });
const BusinessItem = ({
  profile,
  onCheckBoxClick,
  selectedBusinessProfiles,
  loaderHandler,
  selectAllowed,
  onDetachHandler,
}) => {
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { name, address, image, externalBusinessProfile, id } = profile;
  const [isLoading, setIsLoading] = useState(false);
  const checkboxValue = selectedBusinessProfiles.includes(id);
  const profileStatus = getLocalStorageProfileStatus(id);
  const translatedStatusConfig = statusConfig(t);
  const statusToDisplay = translatedStatusConfig[profileStatus];
  const [profileStatusState, setProfileStatusState] = useState(statusToDisplay?.status);

  useEffect(() => {
    const fetchProfileStatus = async () => {
      if (!profileStatus && !profile.externalBusinessProfile) {
        setIsLoading(true);
        try {
          const { data } = await api.post('/business_profile/getVerificationStatus', { businessProfileId: id });
          const parsedStatus = parseProfileStatus(data, t);
          setProfileStatusState(parsedStatus.label);
          localStorage.setItem(`status-${id}`, parsedStatus.id);
        } catch (err) {
          handleApiError({ err, showNotification, t });
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchProfileStatus();
  }, [profileStatus, profile]);

  return (
    <div className={classes.card}>
      {selectAllowed && (
        <Checkbox value={checkboxValue} onClick={() => onCheckBoxClick(id)} className={classes.checkboxStyle} />
      )}
      <BusinessItemImage image={image} externalBusinessProfile={externalBusinessProfile} />
      <div className={classes.basicInfo}>
        <div className={classes.cardTitle}>{name}</div>
        <div className={classes.cardAddress}>{address}</div>
      </div>
      <ProfileStatusWithLoader
        profile={profile}
        isLoading={isLoading}
        profileStatus={profileStatus}
        profileStatusState={profileStatusState}
        statusToDisplay={statusToDisplay}
      />
      <BusinessItemActions profile={profile} loaderHandler={loaderHandler} onDetachHandler={onDetachHandler} />
    </div>
  );
};
export default BusinessItem;
