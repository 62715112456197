export const SelectEnum = {
  keywordId: 'keywordId',
  area: 'area',
  distance: 'distance',
  businessProfileId: 'businessProfileId',
  net: 'net',
};
export const selectList = [
  { id: SelectEnum.net, name: 'setFilters.gridSize' },
  { id: SelectEnum.distance, name: 'setFilters.pointsDistance' },
  { id: SelectEnum.area, name: 'setFilters.searchArea' },
];

export const areaMultiplierConfig = {
  3: 1,
  5: 2,
  7: 3,
  9: 4,
};
