import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as CloseIcon } from '../../../../../../shared/assets/close.svg';
import Button from '../../../../../../shared/components/Button';

const BusinessProfileModalError = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.modal}>
      <div className={classes.wrapper}>
        <div>
          <div className={classes.title}>{t('adminControlPanel.businessCardErrorTitle')}</div>
        </div>
        <CloseIcon onClick={onClose} className={classes.closeIcon} />
      </div>
      <div className={classes.info}>
        {t('adminControlPanel.businessCardErrorValue')}
      </div>
      <Button onClick={onClose} label={t('global.back')} className={classes.btn} />
    </div>
  );
};

export default BusinessProfileModalError;
