import { SORT_CARD_COLUMNS } from '../../../../Admin/modules/SeoCards/SeoCardList/utils';

const { active, bpName, cardCode, cardName, interaction, lastUsed } = SORT_CARD_COLUMNS;

export const seoCardListClientTableConfig = (t) => [
  {
    header: t('adminControlPanel.cardId'),
    property: 'cardCode',
    sort: cardCode,
    minWidth: '100px',
    width: '100px',
    position: 'sticky',
    directionKey: 'left',
    directionValue: '61px',
  },
  { header: t('adminControlPanel.cardTitle'), property: 'cardName', sort: cardName, minWidth: '150px', width: '150px' },
  {
    header: t('adminControlPanel.interactionAmount'),
    property: 'interaction',
    sort: interaction,
    minWidth: '150px',
    width: '150px',
  },
  { header: t('adminControlPanel.activity'), property: 'lastUsed', sort: lastUsed, minWidth: '150px', width: '150px' },
  {
    header: t('seoCard.connectedProfile'),
    property: 'bpName',
    sort: bpName,
    minWidth: '250px',
    width: '250px',
  },
  {
    header: t('adminControlPanel.cardStatus'),
    property: 'activePill',
    sort: active,
    minWidth: '150px',
    width: '150px',
  },
];
