export const businessTypeEnum = {
  CUSTOMER_AND_BUSINESS_LOCATION: 'CUSTOMER_AND_BUSINESS_LOCATION',
  CUSTOMER_LOCATION_ONLY: 'CUSTOMER_LOCATION_ONLY',
  BUSINESS_TYPE_UNSPECIFIED: 'BUSINESS_TYPE_UNSPECIFIED',
};

export const shareLocationCheckboxOptions = (t) => [
  {
    id: 0,
    label: t('global.yes'),
    value: businessTypeEnum.CUSTOMER_AND_BUSINESS_LOCATION,
  },
  {
    id: 1,
    label: t('global.no'),
    value: businessTypeEnum.CUSTOMER_LOCATION_ONLY,
  },
];
