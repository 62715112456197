import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classses from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { EXTERNAL_ADDRESSES } from '../../../../../shared/helpers/const';

const RedirectModal = ({ onClose, information, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal onCancel={onClose} modalTitle={t('seoCard.redirectModalTitle')} contentStyle={classses.wrapper}>
      <div>{information}</div>
      <div className={classses.actions}>
        <Button label={t('global.cancel')} className={classses.closeBtn} onClick={onClose} />
        <Button label={t('global.confirm')} className={classses.confirmBtn} onClick={onConfirm} />
      </div>
    </Modal>
  );
};

export default RedirectModal;
