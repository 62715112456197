import React from 'react';
import { useTranslation } from 'react-i18next';
import HeroSection from './modules/HeroSection';
import classes from './styles.module.css';
import About from './modules/About';
import WhyIsWorth from './modules/WhyIsWorth';
import Footer from './modules/Footer';
import CallToAction from './modules/CallToAction';
import Features from './modules/Features';
import Pricing from './modules/Pricing';

const LandingPage = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.landingPage}>
      <HeroSection />
      <About />
      <WhyIsWorth />
      <Features />
      <Pricing />
      <CallToAction />
      <Footer />
      <div className={classes.background} />
      <div className={classes.triangle} />
    </div>
  );
};

export default LandingPage;
