import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { accountDetailsConfig } from './utils';

const AccountInformation = ({ userData, userChangeHistory }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.sectionTitle}>{t('adminControlPanel.accountInformationData')}</div>
      <div className={classes.sectionDataWrapper}>
        {accountDetailsConfig(t, userData).map(({ title, key, value }) => (
          <div key={key}>
            <div className={classes.dataTitle}>{title}</div>
            <div className={classes.dataValue}>{value}</div>
          </div>
        ))}
        {userChangeHistory?.map(({ id, createdAt, comment, type }) => (
          <div key={id} className={classes.userChangeHistoryDataWrapper}>
            <div>
              <div className={classes.dataTitle}>{t('adminControlPanel.accountChangeTypeAndDate')}</div>
              <div className={classes.dataValue}>{`${type} - ${createdAt}`}</div>
            </div>
            <div className={classes.dataTitle}>{t('adminControlPanel.accountChangeReason')}</div>
            <div className={classes.dataValueComment}>{comment}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AccountInformation;
