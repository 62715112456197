import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { makeRequired } from '../../../../../shared/components/Input/validators';
import { getBusinessLocationInputs } from '../../BusinessProfileCreate/FormBusinessLocation/utils';
import { parseAddressToStorefront } from '../../../../../shared/helpers/parsers';

const AddressForm = ({ onLocationChange, keywordSelect, reportLocation, rwdFilters, onError }) => {
  const { t } = useTranslation();
  const [location, setLocation] = useState({
    postalCode: '',
    locality: '',
    addressLines: [],
  });
  const [error, setError] = useState({});

  const translatedBusinessLocationInputs = getBusinessLocationInputs(t);

  const onErrorChange = (name) => (value) => {
    setError((prev) => ({ ...prev, [name]: Boolean(value) }));
  };
  const handleLocation = (name) => (value) => {
    if (location[name] === value) {
      return;
    }

    setLocation((prev) => {
      const current = {
        ...prev,
        [name]: name === 'addressLines' ? [value] : value,
      };

      return current;
    });
  };
  useEffect(() => {
    if (onError) {
      const someErrorExist = Object.values(error).some((el) => el);
      onError(someErrorExist);
    }
    onLocationChange(location);
  }, [location]);

  useEffect(() => {
    if (reportLocation) {
      setLocation(parseAddressToStorefront(reportLocation));
    }
  }, []);

  return (
    <div className={clsx(classes.wrapper, keywordSelect && classes.keywordSelectWrapper)}>
      {!rwdFilters && <div className={classes.title}>{t('setFilters.typeAdress')}</div>}
      <div className={classes.form}>
        {translatedBusinessLocationInputs.map(({ transformer, label, name, showErrorAfterBlur, validator }) => (
          <Input
            key={name}
            transformer={transformer}
            className={clsx(classes.input, rwdFilters && classes.rwdInput)}
            value={location[name]}
            label={label}
            onChange={handleLocation(name)}
            validator={makeRequired(validator)}
            showErrorAfterBlur={showErrorAfterBlur}
            onError={onErrorChange(name)}
          />
        ))}
      </div>
      <div className={classes.horizontalLine} />
    </div>
  );
};

export default AddressForm;
