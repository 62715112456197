import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import TopProgressBar from '../../../../../shared/components/TopProgressBar';
import { getStepperDetails } from './utils';

const progressText = '0%';
const MAX_STEPPER_STEP = 4;

const StepperRWD = ({ activeStep }) => {
  const { t } = useTranslation();

  const { activeStepTitle, progress, progressInPercent, textTranslateX } = getStepperDetails(activeStep, t);

  return (
    <div className={classes.container}>
      <div className={classes.rowContainer}>
        {t(activeStepTitle)}
        <div className={classes.stepNumber}>
          {`${t('businessProfileCreate.step')} ${activeStep}/${MAX_STEPPER_STEP}`}
        </div>
      </div>
      <TopProgressBar customBarStyle={classes.barStyle} className={classes.progressBar} progress={progress} />
      <div className={clsx(classes.rowContainer, classes.progressCount)}>
        {progressText}
        <div className={classes.progressValue} style={{ left: textTranslateX }}>
          {progressInPercent}
        </div>
      </div>
    </div>
  );
};

export default StepperRWD;
