import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../shared/components/Button';
import classes from './styles.module.css';
import { useModal } from '../../../../../shared/helpers/hooks';
import AddAdminModalForm from '../AddAdminModalForm';
import SearchInput from '../../../../../shared/components/SearchInput';
import SelectAlternative from '../../../../../shared/components/Select/SelectAlternative';
import { activeListCollection } from './utils';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/addCircle.svg';

const AdministratorListTool = ({ fetchList, searchInputHandler, searchInputValue, onStatusSelect, selectValue }) => {
  const { t } = useTranslation();
  const options = useMemo(() => activeListCollection(t), []);
  const [showModal, setShowModal, modalHandler] = useModal(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const onSelectChangeHandler = (v) => {
    const foundOptionValue = options.find((option) => option.id === v).value;
    onStatusSelect(foundOptionValue);
  };

  useEffect(() => {
    if (typeof selectValue !== 'boolean') return;
    const foundId = options.find((el) => el.value === selectValue).id;
    setSelectedValue(foundId);
  }, [selectValue]);

  return (
    <div className={classes.wrapper}>
      {showModal && <AddAdminModalForm onClose={modalHandler} fetchList={fetchList} />}
      <div className={classes.leftSide}>
        <SearchInput
          value={searchInputValue}
          className={classes.searchInput}
          label={t('global.search')}
          onSearch={searchInputHandler}
        />
        <SelectAlternative
          onSelect={onSelectChangeHandler}
          value={selectedValue}
          className={classes.select}
          list={activeListCollection(t)}
          labelStyle={classes.selectLabelStyle}
        />
      </div>
      <Button
        Icon={PlusIcon}
        label={t('adminControlPanel.addAdministrator')}
        className={classes.addAdminBtn}
        onClick={modalHandler}
      />
    </div>
  );
};

export default AdministratorListTool;
