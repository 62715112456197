import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'chart.js/auto';
import clsx from 'clsx';
import classes from './styles.module.css';
import {
  chartOptions,
  getEventsChartData,
  getFilledChartData,
  prepareChartData,
  prepareChartLabels,
  prepareChartValues,
  preparePieChartComparisonData,
} from './utils';
import ViewsChartHeader from '../ViewsChartHeader';
import { PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';

const ViewsChart = ({
  data = { generalSearchData: [], delta: 0 },
  status,
  performance,
  selectedPeriod,
  chartToDisplay,
  additionalInteractions,
  userChangeHistory,
}) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [chartData, setChartData] = useState([]);
  const { generalSearchData, delta } = data;
  const { verified } = PROFILE_STATE_ENUM;
  const isViewsNumberChartToDisplay = chartToDisplay === t('dashboard.viewsNumber');
  const isComparisonSelected = selectedDataType === 'COMPARISON';
  const isBusinessVerified = status === verified;
  const pieChartData = preparePieChartComparisonData(performance, t);

  const prepareAndSetChartData = (dataCollection) => {
    if (!isBusinessVerified) {
      return;
    }
    const shouldPrepareEventsData = !selectedDataType && isViewsNumberChartToDisplay;
    const dataLabels = prepareChartLabels(dataCollection);
    const dataValues = prepareChartValues(dataCollection);

    if (shouldPrepareEventsData) {
      const eventsChartData = getEventsChartData({ userChangeHistory });
      setChartData(prepareChartData(dataLabels, dataValues, eventsChartData));
    } else {
      setChartData(prepareChartData(dataLabels, dataValues));
    }
  };

  const prepareSpecificChartData = () => {
    const { viewsAndInteractions: interactions } = performance;
    const filledChartData = getFilledChartData({
      interactions,
      selectedDataType,
      selectedPeriod,
    });
    prepareAndSetChartData(filledChartData);
  };

  const selectDataTypeHandler = (value) => {
    if (!isBusinessVerified) {
      return;
    }
    if (selectedDataType === value) {
      setSelectedDataType(null);
      prepareAndSetChartData(generalSearchData);
      return;
    }
    setSelectedDataType(value);
  };

  useEffect(() => {
    prepareAndSetChartData(isViewsNumberChartToDisplay ? generalSearchData : additionalInteractions);
  }, [
    isViewsNumberChartToDisplay,
    generalSearchData,
    additionalInteractions,
    selectedDataType,
  ]);

  useEffect(() => {
    if (selectedDataType) {
      prepareSpecificChartData();
    }
  }, [selectedDataType]);

  useEffect(() => {
    if (!isBusinessVerified || !chartRef.current) {
      return () => {};
    }
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: !isComparisonSelected ? 'line' : 'doughnut',
      data: !isComparisonSelected ? chartData : pieChartData,
      options: chartOptions(selectedDataType, t),
    });

    return () => myChart.destroy();
  }, [chartData, pieChartData]);

  return (
    <div className={clsx(classes.chartWrapper, !isBusinessVerified && classes.noData)}>
      <ViewsChartHeader
        delta={delta}
        onSelect={selectDataTypeHandler}
        selectedDataType={selectedDataType}
        chartToDisplay={chartToDisplay}
        isBusinessVerified={isBusinessVerified}
      />
      <div className={clsx(classes.body, isComparisonSelected && classes.pieChart)}>
        <canvas className={classes.chart} ref={chartRef} />
        {!isBusinessVerified && (
          <div className={classes.noDataText}>
            {t('dashboard.noChartData')}
            <div className={classes.helper}>{t('dashboard.checkProfileStatus')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewsChart;
