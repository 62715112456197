import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { rowDataParser } from './utils';
import LinkDataRow, { ColumnLabels } from '../LinkDataRow';

const ExpandedSeoCardRow = ({ rowData, admin = true }) => {
  const { t } = useTranslation();
  const parsedRowData = rowDataParser(rowData);

  return (
    <>
      <tr className={clsx(classes.redirectLinks, !admin && classes.clientLink)}>
        <td className={classes.redirectLinks}>{t('adminControlPanel.redirectLinks', { value: rowData.length })}</td>
      </tr>
      <tr className={classes.rowWrapper}>
        <td>
          <ColumnLabels admin={admin} labels={[t('adminControlPanel.baseCode'), t('adminControlPanel.redirectName')]} />
        </td>
        <td>
          <ColumnLabels labels={[t('adminControlPanel.link')]} admin={admin} />
        </td>
        <td>
          <ColumnLabels labels={[t('adminControlPanel.interactionAmount')]} admin={admin} />
        </td>
        <td>
          <ColumnLabels labels={[t('adminControlPanel.activity')]} admin={admin} />
        </td>
      </tr>
      {parsedRowData.map((link) => (
        <LinkDataRow key={link.linkCode} link={link} admin />
      ))}
    </>
  );
};

export default ExpandedSeoCardRow;
