import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';
import SubInformation from '../../../../../shared/components/SubInformation';
import { ReactComponent as Add } from '../../../../../shared/assets/addCircle.svg';

const TEMPLATE_MAX_LENGTH = 10;
const AnswerTemplateHeading = ({ createModalHandler, templates, userId }) => {
  const { t } = useTranslation();

  const templatesLimitReached = templates.length === TEMPLATE_MAX_LENGTH;

  const isButtonVisible = !templatesLimitReached && Boolean(templates.length) && !userId;

  return (
    <div className={classes.heading}>
      <div className={classes.rowContainer}>
        {t('opinions.answerTemplates')}
        <Pill className={classes.pill} label={t('opinions.templatesNumber', { number: templates.length })} />
        {isButtonVisible && (
          <Button
            className={classes.addTemplateBtn}
            iconStyle={classes.addTemplateBtnIcon}
            label={t('opinions.createTemplate')}
            onClick={createModalHandler}
            Icon={Add}
          />
        )}
      </div>
      <div className={classes.subHeading}>
        {t('opinions.answersTemplatesSubHeading')}
        {templatesLimitReached && (
          <SubInformation className={classes.templateLimitReached} label={t('opinions.answerTemplatesLimitReached')} />
        )}
      </div>
    </div>
  );
};
export default AnswerTemplateHeading;
