import React from 'react';
import BasicCardData from '../../modules/SeoCardDetails/BasicCardData';
import CardStatistic from '../../modules/SeoCardDetails/CardStatistic';

export const getTitleFromCardData = (cardData) => {
  if (!cardData) return '';
  const { lastName, firstName, email } = cardData;
  return `${firstName} ${lastName}` || email;
};

export const USER_DETAILS_NAVIGATION_KEY_ENUM = {
  basicData: 'basicData',
  cardStatistic: 'cardStatistic',
};
export const SEO_CARD_DETAIL_TABS = (t) => {
  const basicData = 'basicData';
  const cardStatistic = 'cardStatistic';

  return [
    { label: t('adminControlPanel.basicData'), key: basicData },
    { label: t('adminControlPanel.cardStatistic'), key: cardStatistic },
  ];
};

export const componentMap = (cardData, fetchData) => {
  const { basicData, cardStatistic } = USER_DETAILS_NAVIGATION_KEY_ENUM;
  return {
    [basicData]: <BasicCardData cardData={cardData} fetchData={fetchData} />,
    [cardStatistic]: <CardStatistic cardData={cardData} />,
  };
};
