import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';
import { ReactComponent as HeroImage } from '../../../../shared/assets/HeroImage.svg';
import NavigationBar from '../NavigationBar';

const HeroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={classes.background}>
      <NavigationBar />
      <section className={classes.container}>
        <div className={classes.textWrapper}>
          <h1>{t('landingPage.heroSection.title')}</h1>
          <p>{t('landingPage.heroSection.description')}</p>
          <div className={classes.actionsWrapper}>
            <Button
              label={t('global.signUp')}
              className={classes.button}
              onClick={() => navigate('register_methods')}
            />
            <p>{t('landingPage.heroSection.firstMonthForFree')}</p>
          </div>
        </div>
        <div className={classes.imageWrapper}>
          <HeroImage />
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
