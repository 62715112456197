import { Feature } from 'ol';
import { Circle, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import { Vector as VectorSource } from 'ol/source';
import { boundingExtent } from 'ol/extent';
import CircleStyle from 'ol/style/Circle';
import { fromLonLat } from 'ol/proj';
import Icon from 'ol/style/Icon';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { hexToRgba } from '../../../helpers/colors';
import { MIN_TABLET_WIDTH } from '../../../helpers/constants';

export const removeLayerIfExists = (map, layer) => {
  if (
    !map
    || !Object.keys(map).length
    || !map.getLayers().getLength()
    || !layer) { return; }

  map.removeLayer(layer);
};

export const getExtentOfCoordinates = (coordinates) => boundingExtent(coordinates);

const returnCircleColor = (numberOfPlaces) => {
  switch (true) {
    case numberOfPlaces < 10 && numberOfPlaces > 0:
      return '#E6FBD9';
    case numberOfPlaces >= 10 && numberOfPlaces < 20:
      return '#FFF19F';
    default:
      return '#FFCCB0';
  }
};
const returnCircleBorderColor = (numberOfPlaces) => {
  switch (true) {
    case numberOfPlaces < 10 && numberOfPlaces > 0:
      return '#A0EC8A';
    case numberOfPlaces >= 10 && numberOfPlaces < 20:
      return '#FFDE4B';
    default:
      return '#FFA988';
  }
};

const getColorText = (numberOfPlaces) => {
  switch (true) {
    case numberOfPlaces < 10 && numberOfPlaces > 0:
      return '#0F5B1D';
    case numberOfPlaces >= 10 && numberOfPlaces < 20:
      return '#7B5C03';
    default:
      return '#7A0B21';
  }
};
export const returnCircleStyle = (numberOfPlaces) => new Style({
  fill: returnCircleColor(numberOfPlaces, 1),
  renderer(coordinates, state) {
    const [[x, y], [x1, y1]] = coordinates;
    const ctx = state.context;
    const dx = x1 - x;
    const dy = y1 - y;
    const radius = Math.sqrt(dx * dx + dy * dy);

    const zoom = state.resolution;
    const circleSize = radius * (0.05 * zoom);

    ctx.beginPath();
    ctx.arc(x, y, circleSize, 0, 2 * Math.PI, true);
    ctx.closePath();
    ctx.fillStyle = returnCircleColor(numberOfPlaces, 1);
    ctx.fill();

    ctx.fillStyle = getColorText(numberOfPlaces);
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = '500 1.2rem Poppins';
    ctx.fillText(`${numberOfPlaces || '20+'}`, x, y);

    ctx.beginPath();
    ctx.arc(x, y, circleSize, 0, 2 * Math.PI, true);
    ctx.lineWidth = 3;
    ctx.strokeStyle = returnCircleBorderColor(numberOfPlaces);
    ctx.stroke();
  },
});

export const returnOrderNumber = (point, selectedPlace) => {
  const place = point.places?.filter((filteredPlace) => filteredPlace.placeReferenceId === selectedPlace);
  const valueToDisplay = place[0]?.order >= 0 ? place[0].order + 1 : 0;

  return valueToDisplay;
};

export const addLayerToMap = (map, layer) => {
  if (!Object.keys(map || {}).length || !layer) return;

  removeLayerIfExists(map, layer);
  map.addLayer(layer);
};

export const addFeaturesToLayer = (layer, features) => {
  if (!layer || !features.length) return;
  layer.getSource().addFeatures(features);
};

export const createLayer = (layerName) => {
  const vectorLayer = new VectorLayer({
    source: new VectorSource({}),
  });
  vectorLayer.setProperties(layerName);

  return vectorLayer;
};

export const setStyleofCircleFeature = (feature, point, selectedPlace) => {
  const valueToDisplay = returnOrderNumber(point, selectedPlace);
  feature.setStyle(returnCircleStyle(valueToDisplay));
};

export const createCircleFeature = (point, selectedPlace, radius = 500) => {
  const feature = new Feature({
    geometry: new Circle(fromLonLat([point.lng, point.lat]), radius),
    properties: {
      id: point.id
    }
  });
  setStyleofCircleFeature(feature, point, selectedPlace);

  return feature;
};
export const createMarkerFeature = (point, selectedPlace) => {
  const feature = new Feature({
    geometry: new Circle(fromLonLat([point.lng, point.lat]), 20),
    properties: {
      id: point.id
    }
  });
  setStyleofCircleFeature(feature, point, selectedPlace);

  return feature;
};

export const returnGradientCircleStyle = (color) => new Style({
  renderer(coordinates, state) {
    const screenWidth = window.innerWidth;
    const [[x, y], [x1, y1]] = coordinates;
    const circleCtx = state.context;
    const dx = x1 - x;
    const dy = y1 - y;
    const radius = Math.sqrt(dx * dx + dy * dy);
    if (screenWidth < MIN_TABLET_WIDTH) {
      const gradient = circleCtx.createRadialGradient(x, y, 0, x, y, radius * 2);
      gradient.addColorStop(1, hexToRgba(color, 0.7));
      gradient.addColorStop(0, hexToRgba(color, 0.5));
      circleCtx.fillStyle = gradient;
    } else {
      circleCtx.fillStyle = color;
      circleCtx.filter = 'opacity(0.65) blur(20px)';
    }
    circleCtx.beginPath();
    circleCtx.arc(x, y, radius, 0, 2 * Math.PI, true);

    circleCtx.globalCompositeOperation = 'destination-over';
    circleCtx.fill();
  },
});
export const getLayerExtent = (layer) => layer.getSource().getExtent();

export const fitMapToExtent = (map, extent) => {
  map.getView().fit(extent, {
    duration: 600,
    padding: [50, 50, 50, 50],
  });
};

export const createCursorFeature = (point) => {
  const pointFeature = new Feature({
    geometry: new Point(fromLonLat([point.lng, point.lat])),
    id: 'cursorFeature',
    properties: {},
  });
  const circleStyle = new Style({
    image: new CircleStyle({
      radius: 12,
      fill: new Fill({
        color: 'rgb(243, 247, 255)',
      }),
      displacement: [0, 8],
      stroke: new Stroke({
        color: 'rgb(95, 149, 251)',
        width: 2,
      }),
    }),

    zIndex: 1,
  });

  const iconStyle = new Style({
    image: new Icon({
      src: 'cursor6.svg',
      scale: 0.02,
      anchor: [0.5, 1],
    }),
    zIndex: 1,
  });

  pointFeature.setStyle([circleStyle, iconStyle]);
  pointFeature.setStyle([circleStyle, iconStyle]);
  return pointFeature;
};
