import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as ArrowIcon } from '../../../../shared/assets/paginationArrow.svg';
import Button from '../../../../shared/components/Button';

const limitList = [10, 30, 50];

const AdminPagination = ({
  total,
  onPageChange,
  onLimitChange,
  limit,
  offset,
  className,
  paginationLimitList,
  noExport,
  onExportData,
  disableExport,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const limitListPagination = paginationLimitList || limitList;
  const [showLimitList, setShowLimitList] = useState(false);
  const hideList = useCallback((event) => {
    if (!ref.current.contains(event.target)) {
      setShowLimitList(false);
      document.removeEventListener('click', hideList);
    }
  }, []);
  const handleClick = () => {
    if (showLimitList) {
      setShowLimitList(false);
      document.removeEventListener('click', hideList);
    } else {
      setShowLimitList(true);
      document.addEventListener('click', hideList);
    }
  };

  useEffect(
    () => () => {
      document.removeEventListener('click', hideList);
    },
    [],
  );

  const handleSelectLimit = (value) => () => {
    onLimitChange(value);
  };
  const page = useMemo(() => (offset === 0 ? 1 : Math.round(offset / limit) + 1), [offset, limit]);

  const handlePageChange = (value) => () => {
    if (page + value > 0 && (page - 1 + value) * limit < total) {
      onPageChange(page + value);
    }
  };
  const pageScore = Number(`${page * limit}`) > total ? total : `${(page - 1) * limit + 1}-${page * limit}`;

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.limit} ref={ref} onClick={handleClick}>
        {showLimitList && (
          <div className={classes.list}>
            {limitListPagination.map((el) => (
              <div key={`limit-option-${el}`} className={classes.option} onClick={handleSelectLimit(el)}>
                {t('adminControlPanel.rows', { value: el })}
              </div>
            ))}
          </div>
        )}
        {`${t('adminControlPanel.show')}`}
        <div className={classes.limitAmountBox}>
          {t('adminControlPanel.rows', { value: limit })}
          <ArrowIcon className={clsx(classes.arrow, classes.down, showLimitList && classes.up)} width="10px" />
        </div>
      </div>
      <div className={classes.row}>
        <ArrowIcon className={clsx(classes.arrow, classes.left)} onClick={handlePageChange(-1)} />
        <div className={classes.total}>
          {`${pageScore} ${t('adminControlPanel.paginationLabel', {
            total,
          })}`}
        </div>
        <ArrowIcon className={clsx(classes.arrow, classes.right)} onClick={handlePageChange(1)} />
      </div>
      {!noExport && (
        <div className={classes.exportBox}>
          {t('adminControlPanel.exportCSV')}
          <Button
            readOnly={disableExport}
            onClick={onExportData}
            label={t('adminControlPanel.export')}
            className={classes.exportBtn}
          />
        </div>
      )}
    </div>
  );
};

export default AdminPagination;
