import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Tooltip from '../Tooltip';
import Button from '../Button';
import classes from './styles.module.css';
import { ReactComponent as Chevron } from '../../assets/chevronDown.svg';
import { ReactComponent as EditICon } from '../../assets/pencil.svg';

const TableRow = ({
  item,
  columns,
  expandableRow,
  expandedRowId,
  onChevronClick,
  editHandler,
  index,
  onExpandRowRender,
  edit,
  admin = true,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <tr key={item.id} className={index % 2 === 0 ? classes.evenRow : ''}>
        {expandableRow && (
          <td className={classes.chevronHeader}>
            <Chevron
              className={clsx(
                classes.chevronIcon,
                expandedRowId.includes(item.id) && classes.chevronActive,
                expandedRowId.includes(item.id) && !admin && classes.clientChevron,
              )}
              onClick={() => onChevronClick(item)}
              aria-label="expand chevron"
            />
          </td>
        )}
        {columns.map((col) => (
          <td
            key={col.header}
            className={clsx(
              classes.cell,
              !admin && classes.clientCell,
              expandedRowId.includes(item.id) && classes.cellExpanded,
            )}
            style={{
              width: col.width,
              minWidth: col.minWidth,
              maxWidth: col.minWidth,
              position: col.position,
              [col.directionKey]: col.directionValue,
            }}
          >
            {item[col.property]}
          </td>
        ))}
        {edit && (
          <td className={classes.buttonCell}>
            <Tooltip text={t('global.edit')} tooltipStyle={classes.tooltipStyle}>
              <Button
                onClick={() => editHandler(item)}
                Icon={EditICon}
                className={clsx(classes.editButton, !admin && classes.clientEditButton)}
              />
            </Tooltip>
          </td>
        )}
      </tr>
      {expandedRowId.includes(item.id) && onExpandRowRender(item)}
    </>
  );
};

export default TableRow;
