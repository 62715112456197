import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';

const DashboardSidebarRWDBusinessCards = ({
  businessProfiles,
  onBusinessProfileSelect,
  navigateToCreateBusinessProfile,
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');

  const changeSearchInputHandler = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredList = businessProfiles.filter((el) => el.name.toLowerCase().includes(searchInput.toLowerCase()));

  return (
    <div className={classes.businessProfilesList}>
      <div className={classes.rowContainer}>
        <input
          onChange={changeSearchInputHandler}
          className={classes.input}
          placeholder={t('setFilters.yourBusinessCardsTitle')}
        />
        <Button
          label={t('businessProfileCreate.title')}
          className={classes.createBusinessProfileBtn}
          onClick={navigateToCreateBusinessProfile}
        />
      </div>
      <div className={classes.horizontalLine} />
      <div className={classes.listContainer}>
        {filteredList.map(({ id, name, address }) => (
          <div key={id} onClick={() => onBusinessProfileSelect(id)}>
            <div className={classes.businessProfileName}>{name}</div>
            <div className={classes.businessProfileAddress}>{address}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardSidebarRWDBusinessCards;
