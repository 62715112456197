import dayjs from 'dayjs';

export const getSelectDateLabel = (selectedPeriod, t) => {
  if (!selectedPeriod?.dateFrom) {
    return t('adminControlPanel.selectDate');
  }
  const { dateFrom, dateTo } = selectedPeriod;
  const start = dayjs(dateFrom).format('DD.MM.YY');
  const end = dayjs(dateTo).format('DD.MM.YY');

  return start === end ? start : `${start} - ${end}`;
};
