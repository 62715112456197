import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import VerificationOption from '../VerificationOption';
import Input from '../../../../../shared/components/Input';
import Button from '../../../../../shared/components/Button';
import { verificationEnum } from './utils';
import { noAtCharTransformer } from '../../../../../shared/components/Input/transformers';
import { ReactComponent as InfoIcon } from '../../../../../shared/assets/circleInfo.svg';
import { ReactComponent as VerificationSvg } from '../../../../../shared/assets/verificationSvg.svg';

const VerificationOptionsWrapper = ({
  verifyOptions,
  selectedOption,
  onOptionSelectHandler,
  alreadyVerified,
  standAlone,
  onPayloadChange,
}) => {
  const { t } = useTranslation();
  const [emailAddress, setEmailAddress] = useState('');
  const moreInformationRequired = selectedOption?.verificationMethod !== verificationEnum.EMAIL;
  const title = !moreInformationRequired
    ? t('businessProfileCreate.enterEmail')
    : t('businessProfileCreate.selectVerificationMetod');

  const onEmailChange = (val) => {
    const adressWithDomain = `${val}@${selectedOption.emailData.domain}`;
    setEmailAddress(val);
    onPayloadChange('emailAddress')(adressWithDomain);
  };

  return (
    <div className={clsx(classes.wrapper, standAlone && classes.standAloneWrapper)}>
      <div className={classes.leftSide}>
        <div className={classes.title}>{title}</div>
        <div className={classes.optionsList}>
          {moreInformationRequired ? (
            verifyOptions.map((option) => (
              <VerificationOption
                key={option.verificationMethod}
                selectedOption={selectedOption}
                option={option}
                onSelect={onOptionSelectHandler}
              />
            ))
          ) : (
            <div>
              <div className={classes.mailWrapper}>
                <Input transformer={noAtCharTransformer} value={emailAddress} onChange={onEmailChange} />
                <div className={classes.domain}>{`@${selectedOption.emailData.domain}`}</div>
              </div>
              <div className={classes.helperText}>
                <InfoIcon className={classes.infoIcon} />
                {t('businessProfileCreate.missingDataHelperText')}
              </div>
              <Button
                className={classes.goBackBtn}
                onClick={() => onOptionSelectHandler([])}
                label={t('businessProfileCreate.goBackToList')}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.rightSide}>
        <VerificationSvg error={alreadyVerified} />
      </div>
    </div>
  );
};
export default VerificationOptionsWrapper;
