import dayjs from 'dayjs';

const availablePeriodsEnum = {
  lastWeek: 'calendar.lastWeek',
  last14days: 'calendar.last14days',
  lastMonth: 'calendar.lastMonth',
  last2Months: 'calendar.last2Months',
  last3Months: 'calendar.last3Months',
  last6Months: 'calendar.last6Months',
  last12Months: 'calendar.last12Months',
};
const monthlyAgregation = [
  { label: 'calendar.sinceCreate', id: availablePeriodsEnum.lastMonth, value: 'sinceCreate' },
  { label: 'calendar.last3Months', id: availablePeriodsEnum.last3Months, value: 'last3Months' },
  { label: 'calendar.last6Months', id: availablePeriodsEnum.last6Months, value: 'last6Months' },
  { label: 'calendar.lastYear', id: availablePeriodsEnum.last12Months, value: 'last12Months' },
];
const standardAgregation = [
  { label: 'calendar.lastWeek', id: availablePeriodsEnum.lastWeek, value: 'lastWeek' },
  { label: 'calendar.last14days', id: availablePeriodsEnum.last14days, value: 'last2Weeks' },
  { label: 'calendar.lastMonth', id: availablePeriodsEnum.lastMonth, value: 'lastMonth' },
  { label: 'calendar.last2Months', id: availablePeriodsEnum.last2Months, value: 'last2Months' },
];
const periodValueEnum = {
  lastWeek: 'lastWeek',
  last2Weeks: 'last2Weeks',
  lastMonth: 'lastMonth',
  sinceCreate: 'sinceCreate',
  last2Months: 'last2Months',
  last3Months: 'last3Months',
  last6Months: 'last6Months',
  last12Months: 'last12Months',
};

export const availablePeriods = (monthlyAgregationType) => (monthlyAgregationType ? monthlyAgregation : standardAgregation);

export const dayNames = ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Nd'];

export const getStartDate = (periodValue, createdAt) => {
  let startDate;
  const createdDate = dayjs(createdAt);

  switch (periodValue) {
    case periodValueEnum.lastWeek:
      startDate = dayjs().subtract(1, 'week');
      break;
    case periodValueEnum.last2Weeks:
      startDate = dayjs().subtract(2, 'weeks');
      break;
    case periodValueEnum.lastMonth:
      startDate = dayjs().subtract(1, 'month');
      break;
    case periodValueEnum.sinceCreate:
      startDate = createdDate;
      break;
    case periodValueEnum.last2Months:
      startDate = dayjs().subtract(2, 'months');
      break;
    case periodValueEnum.last3Months:
      startDate = dayjs().subtract(2, 'months').startOf('month');
      break;
    case periodValueEnum.last6Months:
      startDate = dayjs().subtract(5, 'months').startOf('month');
      break;
    case periodValueEnum.last12Months:
      startDate = dayjs().subtract(11, 'months').startOf('month');
      break;
    default:
      startDate = dayjs();
  }

  if (Boolean(createdAt) && startDate.isBefore(createdDate)) {
    startDate = createdDate;
  }

  return startDate;
};
export const getDisplayedYear = (currMonth, nextMonth) => {
  const currentYear = currMonth.year();
  const nextMonthYear = nextMonth.year();
  return currentYear === nextMonthYear
    ? currentYear
    : `${String(currentYear).slice(2, 4)}/${String(nextMonthYear).slice(2, 4)}`;
};
