import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import FormPreview from '../FormPreview';
import ItemHeader from '../ItemHeader';
import { getPostDetails } from './utils';
import PostImage from '../PostImage';
import PostDetails from '../PostDetails';
import PostActions from '../PostActions';
import Button from '../../../../../shared/components/Button';
import { useDashboard } from '../../../helpers/dashboard';
import { getCallToActionData } from '../FormPreview/utils';
import { formEnum, initialFormDataWithEditedPostValuesParser } from '../ModalForm/utils/utils';

const PostItem = ({ post, onEdit, onDeleteIconClickHandler, adminPreview }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { name },
  } = useDashboard();

  const [showModal, setShowModal] = useState(false);
  const { previewSubInfo } = getPostDetails(post, t);
  const { media } = post;
  const { btnLabel, btnUrl } = getCallToActionData(post.callToAction);

  const openLinkHandler = () => window.open(post.callToAction.url, '_blank');
  const modalHandler = () => setShowModal((prev) => !prev);

  return (
    <div className={classes.card}>
      {showModal && (
        <Modal
          contentStyle={classes.modalContent}
          modalTitle={name}
          onCancel={modalHandler}
          rwdBackTitle={t('global.back')}
        >
          <FormPreview
            subInfo={previewSubInfo}
            data={initialFormDataWithEditedPostValuesParser(post, t)}
            media={media}
            publishedAddress={post.searchUrl ?? ''}
          />
        </Modal>
      )}
      <ItemHeader
        post={post}
        onDeleteIconClickHandler={onDeleteIconClickHandler}
        onEdit={onEdit}
        adminPreview={adminPreview}
      />
      <div className={classes.content}>
        <PostImage media={post.localPost.media} />
        <div className={classes.descriptionContainer}>
          {post.type !== formEnum.STANDARD && <PostDetails data={post} />}
          <div className={classes.description}>{post.summary}</div>
        </div>
        {Boolean(btnUrl) && <Button onClick={openLinkHandler} className={classes.callToAction} label={btnLabel} />}
      </div>
      <Button onClick={modalHandler} className={classes.showMore} label={t('posts.showFullContent')} />
      <PostActions onDelete={onDeleteIconClickHandler} onEdit={onEdit} post={post} />
    </div>
  );
};

export default PostItem;
