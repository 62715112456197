import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as AboutImage } from '../../../../shared/assets/AboutImage.svg';

const About = () => {
  const { t } = useTranslation();
  return (
    <section id="about" className={classes.sectionWrapper}>
      <div className={classes.leftSide}>
        <p className={classes.sectionTitle}>{t('landingPage.about')}</p>
        <h2 className={classes.sectionHeading}>
          {`${t('landingPage.aboutSection.title')} `}
          <span>{t('landingPage.aboutSection.seoTracking')}</span>
        </h2>
        <div className={classes.paragraphs}>
          <p className={classes.headingParagraph}>{t('landingPage.aboutSection.descriptionFristParagraph')}</p>
          <p className={classes.headingParagraph}>{t('landingPage.aboutSection.descriptionSecondParagraph')}</p>
        </div>
      </div>
      <div className={classes.rightSide}>
        <AboutImage />
      </div>
    </section>
  );
};

export default About;
