import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import classes from './styles.module.css';
import VideoPlayer from '../VideoPlayer';

const MediaPreview = ({ source, onDelete, file, isEdit }) => {
  const { extension } = file;
  const isVideo = extension === 'mp4';
  return (
    <div className={classes.container}>
      {isVideo ? (
        <div className={classes.image}>
          <VideoPlayer videoUrl={file.mediaSource} crossAnonymous />
        </div>
      ) : (
        <img className={classes.image} src={file.preview || source} alt="preview" crossOrigin="anonymous" />
      )}
      {!isEdit && <CloseIcon onClick={() => onDelete(file)} className={classes.closeIcon} />}
    </div>
  );
};
export default MediaPreview;
