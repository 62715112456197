import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { priceModelsConfig } from './utils';
import PriceModel from '../PriceModel';
import PlanComparison from '../PriceModelsFeatureMatrix';
import { generateKey } from '../../../../shared/helpers/helpers';
import { useWindowResize } from '../../../../shared/helpers/hooks';
import { MIN_TABLET_WIDTH, SMALL_MOBILE_WIDTH } from '../../../SEO/helpers/constants';
import MobilePlanComparison from '../MobilePlanComparison';

const Pricing = () => {
  const { t } = useTranslation();
  const innerWidth = useWindowResize();
  const isSmallMobile = innerWidth <= MIN_TABLET_WIDTH;
  const renderPriceModel = (model, idx) => <PriceModel key={generateKey(model, idx)} model={model} />;
  return (
    <section id="pricing" className={classes.wrapper}>
      <div className={classes.heading}>
        <h2 className={classes.title}>{t('landingPage.pricingSection.title')}</h2>
        <p className={classes.subTitle}>{t('landingPage.pricingSection.description')}</p>
      </div>
      <div className={classes.priceModelsWrapper}>{priceModelsConfig(t).map(renderPriceModel)}</div>
      <div className={classes.plansMatrix}>
        {isSmallMobile ? <MobilePlanComparison /> : <PlanComparison />}
      </div>
    </section>
  );
};

export default Pricing;
