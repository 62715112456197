import { businessTypeEnum } from '../FormShareLocation/utils';
import { formEnum } from '../WelcomeScreenCreate/utils';

export const deliveryOptions = (onClick, t) => [
  {
    value: businessTypeEnum.CUSTOMER_AND_BUSINESS_LOCATION,
    onClick: () => onClick(businessTypeEnum.CUSTOMER_AND_BUSINESS_LOCATION),
    label: t('global.yes'),
  },
  {
    value: businessTypeEnum.BUSINESS_TYPE_UNSPECIFIED,
    onClick: () => onClick(businessTypeEnum.BUSINESS_TYPE_UNSPECIFIED),
    label: t('global.no'),
  },
];

export const onCheckboxClick = ({ value, onChange, onMetaDataChange, setCheckBoxValue }) => {
  onMetaDataChange('deliveryArea')(value === businessTypeEnum.CUSTOMER_AND_BUSINESS_LOCATION);
  setCheckBoxValue(value);
  onChange(formEnum.shareLocation)(value);
};
