import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as PlusIcon } from '../../../../shared/assets/plus.svg';
import { ReactComponent as MinusIcon } from '../../../../shared/assets/minus.svg';

const FeatureWrapper = ({ feature, onFeatureClick, expandedFeature, isMobileScreen }) => {
  const { t } = useTranslation();
  const { title, description, image } = feature;
  const Icon = expandedFeature.title === feature.title ? MinusIcon : PlusIcon;

  const renderDesktopFeature = () => (
    <div onClick={() => onFeatureClick(feature)} className={classes.wrapper}>
      <h3 className={clsx(classes.featureTitle, expandedFeature.title === title && classes.selectedFeature)}>
        <Icon className={classes.icon} />
        {title}
      </h3>
      {expandedFeature.title === title && <p className={classes.featureDescription}>{description}</p>}
    </div>
  );

  const renderMobileFeature = () => (
    <div onClick={() => onFeatureClick(feature)} className={classes.mobileWrapper}>
      <h3 className={classes.featureTitle}>{title}</h3>
      <p className={classes.featureDescription}>{description}</p>
      <img src={image} alt="feature" className={classes.featureImage} loading="eager" />
    </div>
  );

  return isMobileScreen ? renderMobileFeature() : renderDesktopFeature();
};

export default FeatureWrapper;
