import clsx from 'clsx';
import React from 'react';
import classes from './styles.module.css';

const Loader = ({ className }) => (
  <div data-testid="loader" className={clsx(classes.wrapper, className)}>
    <div className={classes.spinner} />
  </div>
);

export default Loader;
