import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as LogoIcon } from '../../../../shared/assets/logo.svg';

const AdminTopbar = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <LogoIcon className={classes.logo} />
      <div className={classes.text}>{t('adminControlPanel.adminPanel')}</div>
    </div>
  );
};
export default AdminTopbar;
