import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useDashboard } from '../../../helpers/dashboard';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import Button from '../../../../../shared/components/Button';
import Loader from '../../../components/Loader';
import MediaItem from '../MediaItem';
import MediaEmptyState from '../MediaEmptyState';
import AddMediaModal from '../AddMediaModal';
import { useModal } from '../../../../../shared/helpers/hooks';
import { combineMediaItems, getOnDeletePayload } from './utils';
import { getUniqueArray } from '../AddMediaModal/utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const Media = ({ form, isEdit, editHandler, adminPreview, userId, businessProfileId }) => {
  const { businessProfile } = useDashboard();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { t } = useTranslation();
  const [showModal, setShowModal, modalHandler] = useModal();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState({ itemList: [], nextPageToken: null, totalMediaCount: null });
  const mediaProvided = Boolean(media.itemList.length);

  const itemsWithoutPublishDate = useMemo(() => media.itemList.filter((item) => !item.publishDate), [media.itemList]);

  const mediaRemaining = useMemo(
    () => media.totalMediaCount - itemsWithoutPublishDate.length,
    [media.totalMediaCount, itemsWithoutPublishDate.length],
  );
  const mediaRemainingLabel = useMemo(() => `+ ${mediaRemaining}`, [mediaRemaining]);
  const standardPayload = useMemo(() => ({ businessProfileId, limit: 19 }), [businessProfileId, media.nextPageToken]);

  const fetchMedia = async (controller = new AbortController(), withNextPageToken = false) => {
    const payload = { ...standardPayload };
    if (withNextPageToken) {
      payload.nextPageToken = media.nextPageToken;
    }
    setIsLoading(true);
    try {
      const apiPath = adminPreview ? '/acp/client/mediaList' : '/media/mediaList';
      const apiPayload = adminPreview ? { ...payload, userId, businessProfileId } : payload;
      const { data } = await api.post(apiPath, apiPayload, { signal: controller.signal });
      const newMedia = combineMediaItems(t, data.actualMedia.mediaItems, data.postponedMedia.media);
      setMedia((prev) => ({
        itemList: withNextPageToken ? getUniqueArray(prev.itemList, newMedia, (item) => item.name) : newMedia,
        nextPageToken: data.actualMedia.nextPageToken,
        totalMediaCount: data.actualMedia.totalMediaItemCount,
      }));
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImageHandler = async (e, file = { name: '', publishDate: null, id: 0 }, deleteCollection = false) => {
    const payload = getOnDeletePayload(businessProfileId, deleteCollection, selectedItems, file);
    e.stopPropagation();
    setIsLoading(true);
    try {
      await api.post('media/delete', payload);
      if (deleteCollection) {
        setSelectedItems([]);
      }
      showNotification({ type: 'success', message: t('businessProfileEdit.mediaDeleteMediaSuccess') });
      await fetchMedia();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSelectedItemHandler = (e) => {
    deleteImageHandler(e, {}, true);
  };

  const selectMediaHandler = (mediaItem) => {
    setSelectedItems((prev) => {
      if (prev.includes(mediaItem)) {
        return prev.filter((item) => item.name !== mediaItem.name);
      }
      return [...prev, mediaItem];
    });
  };

  const clearSelectionHandler = () => {
    setSelectedItems([]);
  };

  const cancelBtnHandler = () => {
    clearSelectionHandler();
    setShowModal(false);
    editHandler();
  };

  useEffect(() => {
    const controller = new AbortController();
    if (!form.media.actualMedia.mediaItems?.length) {
      fetchMedia(controller);
    }
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const newMedia = combineMediaItems(t, form.media.actualMedia.mediaItems, form.media.postponedMedia.media);
    setMedia({
      itemList: newMedia,
      nextPageToken: form.media.actualMedia.nextPageToken,
      totalMediaCount: form.media.actualMedia.totalMediaItemCount || 0,
    });
  }, [businessProfile, form.media]);

  if (!mediaProvided && !showModal) {
    return <MediaEmptyState isEdit={isEdit} cancelBtnHandler={cancelBtnHandler} modalHandler={modalHandler} />;
  }

  return (
    <div className={classes.wrapper}>
      {Boolean(selectedItems.length) && (
        <div className={classes.additonalActions}>
          <Button
            className={classes.clearAction}
            label={t('businessProfileEdit.clear')}
            onClick={clearSelectionHandler}
          />
          <Button
            className={classes.deleteAction}
            label={t('businessProfileEdit.deleteSelected')}
            onClick={deleteSelectedItemHandler}
          />
        </div>
      )}
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      {showModal && (
        <AddMediaModal busienssId={businessProfileId} handleModal={modalHandler} onSuccess={() => fetchMedia(false)} />
      )}
      <div className={clsx(classes.content)}>
        {mediaProvided
          && media.itemList.map((mediaItem, index) => (
            <MediaItem
              index={index}
              key={mediaItem.name}
              item={mediaItem}
              galleryCollection={media.itemList}
              isEdit={isEdit}
              onDelete={deleteImageHandler}
              selectedItems={selectedItems}
              onSelect={selectMediaHandler}
              totalMediaCount={media.totalMediaCount}
              onSuccess={() => fetchMedia(false)}
            />
          ))}
        {Boolean(mediaRemaining) && (
          <div className={classes.fetchMorePlaceHolder} onClick={() => fetchMedia(true)}>
            {mediaRemainingLabel}
          </div>
        )}
      </div>
      {isEdit && (
        <div className={classes.actionsWrapper}>
          <Button className={classes.cancelBtn} label={t('global.cancel')} onClick={cancelBtnHandler} />
          <Button className={classes.confirmBtn} label={t('businessProfileEdit.addMedia')} onClick={modalHandler} />
        </div>
      )}
    </div>
  );
};
export default Media;
