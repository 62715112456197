import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { makeRequired, required, urlValidator } from '../../../../../shared/components/Input/validators';
import Textarea from '../../../../../shared/components/Textarea';
import Select from '../../../../../shared/components/Select';
import { buttonActions, buttonActionsEnum } from '../ModalForm/utils/changeDataFieldHandles';
import { getValuesForInput, validateEventFormSchedule } from './utils';
import SubInformation from '../../../../../shared/components/SubInformation';
import AIHelper from '../AIHelper';
import MediaAndPublication from '../MediaAndPublication';
import { POST_STATE_ENUM } from '../ModalForm/utils/utils';

const EventForm = ({
  handleForm,
  data,
  handleMedia,
  handlePublicationDate,
  editPost,
  aiHelperHandler,
  isAIHelperOpen,
}) => {
  const { t } = useTranslation();
  const [button, setButton] = useState(buttonActions[0]);
  const [scheduleError, setScheduleError] = useState(false);
  const { localPost: post } = data;
  const { PLANNED } = POST_STATE_ENUM;
  const isValidAction = Boolean(button)
    && Boolean(![buttonActionsEnum.ACTION_TYPE_UNSPECIFIED, buttonActionsEnum.CALL].includes(button?.googleID));
  const isEditingPublishedPost = Boolean(editPost && editPost.state !== PLANNED);
  const showOnList = (el) => t(el?.name);

  const handleSelect = (value) => {
    const pickedValue = buttonActions.find((el) => el.id === value);
    setButton(pickedValue);
    handleForm('callToAction')(pickedValue.googleID);
  };

  const { inputStartDate, inputEndDate, inputStartTime, inputEndTime, isOneDayEvent } = useMemo(() => {
    if (!post?.event?.schedule) return {};
    return getValuesForInput(post.event.schedule);
  }, [post]);

  const scheduleHandler = () => {
    if (!data.localPost?.event?.schedule) return;
    const eventSchedule = data.localPost.event.schedule;
    const validSchedule = validateEventFormSchedule(eventSchedule);
    setScheduleError(validSchedule);
  };

  useEffect(() => {
    if (!data?.localPost?.callToAction?.actionType) return;
    const dataButton = buttonActions.find((el) => el.googleID === data.localPost.callToAction.actionType);
    setButton(dataButton);
  }, [data]);

  useEffect(() => {
    scheduleHandler();
  }, [inputStartDate, inputEndDate, inputStartTime, inputEndTime]);

  useEffect(() => {
    if (isOneDayEvent && !inputStartTime && !inputEndTime) {
      handleForm('oneDayWithNoTime')(isOneDayEvent);
    }
  }, [isOneDayEvent]);

  return (
    <div className={classes.container}>
      {!isAIHelperOpen && (
        <>
          <div className={classes.sectionName}>{t('posts.eventDetails')}</div>
          <Input
            onChange={handleForm('title')}
            className={classes.input}
            label={t('posts.eventTitle')}
            value={post?.event?.title || ''}
            validator={makeRequired(required(null))}
            showErrorAfterBlur
            maxLength={58}
          />
        </>
      )}
      <div className={classes.sectionBox}>
        <div className={clsx(classes.aiWrapper, isAIHelperOpen && classes.aiWrapperOpen)}>
          <AIHelper
            onApprove={handleForm('summary')}
            onClick={aiHelperHandler}
            isOpen={isAIHelperOpen}
            formType={data.type}
          />
        </div>
        {!isAIHelperOpen && (
          <Textarea
            onChange={handleForm('summary')}
            className={classes.eventDescription}
            label={t('posts.eventDescription')}
            value={post?.summary}
            maxLength={1500}
          />
        )}
      </div>
      <div className={classes.sectionName}>{t('posts.eventDateAndHour')}</div>
      {!isAIHelperOpen && (
        <div>
          <div className={classes.rowContainer}>
            <Input
              onChange={handleForm('startDate')}
              className={classes.dateInput}
              label={t('posts.startDate')}
              type="date"
              value={inputStartDate}
              validator={makeRequired(required(null))}
              showErrorAfterBlur
              labelRised
            />
            <Input
              onChange={handleForm('startTime')}
              className={classes.timeInput}
              label={t('posts.fromHour')}
              type="time"
              value={inputStartTime}
            />
          </div>
          <div className={classes.rowContainer}>
            <Input
              customStyle={classes.customInput}
              onChange={handleForm('endDate')}
              className={classes.dateInput}
              label={t('posts.endDate')}
              type="date"
              validator={makeRequired(required(null))}
              showErrorAfterBlur
              labelRised
              value={inputEndDate}
            />
            <Input
              customStyle={classes.customTimeInput}
              onChange={handleForm('endTime')}
              className={classes.timeInput}
              label={t('posts.toHour')}
              type="time"
              value={inputEndTime}
            />
          </div>
          {scheduleError && <div className={classes.error}>{t(scheduleError)}</div>}
        </div>
      )}
      <div className={classes.sectionName}>{t('posts.addOptionalButton')}</div>
      {!isAIHelperOpen && (
        <Select
          showOnList={showOnList}
          inputClassName={classes.buttonSelect}
          onSelect={handleSelect}
          value={button?.id || buttonActions[0].id}
          list={buttonActions}
          label={t('posts.addOptionalButton')}
          iconStyle={classes.chevronStyle}
        />
      )}
      {!isAIHelperOpen && (
        <div>
          {isValidAction && (
            <div className={classes.buttonDetailContainer}>
              <Input
                className={classes.input}
                onChange={handleForm('url')}
                validator={urlValidator || required(null)}
                showErrorAfterBlur
                value={post?.callToAction?.url}
                transformer={button?.transformer}
                type={button?.type}
                label={t(button?.detailLabel)}
                maxLength={75}
              />
              <SubInformation label={t(button?.subInformation)} />
            </div>
          )}
          <MediaAndPublication
            data={data}
            handleMedia={handleMedia}
            handlePublicationDate={handlePublicationDate}
            isEditingPublishedPost={isEditingPublishedPost}
            shouldRenderItems={!isAIHelperOpen}
          />
        </div>
      )}
    </div>
  );
};

export default EventForm;
