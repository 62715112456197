import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../shared/assets/trash.svg';

const PostActions = ({ onEdit, onDelete, post }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.cardHeaderActions}>
      <div className={classes.actionWrapper} onClick={() => onEdit(post)}>
        <EditIcon className={classes.actionIcon} />
        <div>{t('global.edit')}</div>
      </div>
      <div className={classes.actionWrapper} onClick={() => onDelete(post.postID)}>
        <DeleteIcon className={classes.actionIcon} />
        <div>{t('global.delete')}</div>
      </div>
    </div>
  );
};

export default PostActions;
