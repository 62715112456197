import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import Checkbox from '../../../../../shared/components/Checkbox';
import HourList from '../HourList';
import ActionBox from '../ActionBox';
import { useNotification } from '../../../../../shared/helpers/notification';

const AdditionalHoursModal = ({ modalTitle, modalHandler, openHours, setOpenHours, isEdit, moreHoursSaveHandler, additionalHoursModalCancelHandler, setErrors, errors }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const handleAdd = (index) => () => {
    setOpenHours((prev) => (
      prev.map((el, idx) => {
        if (idx === index) {
          if (el.hours.length >= 6) {
            showNotification({ message: t('businessProfileEdit.maxHours'), type: 'error' });
            return el;
          }
          return { ...el, hours: [...el.hours, { key: Math.random(), from: null, to: null }] };
        }
        return el;
      })
    ));
  };

  const handleCheckbox = (index) => () => {
    if (!isEdit) return;
    setOpenHours((prev) => (
      prev.map((el, idx) => {
        if (idx === index) {
          return { ...el, value: !el.value, hours: [{ key: Math.random(), from: null, to: null }] };
        }
        return el;
      })
    ));
  };

  const handleDelete = (index) => (hourIndex) => {
    setOpenHours((prev) => (
      prev.map((el, idx) => {
        if (idx === index) {
          return { ...el, hours: el.hours.filter((_, id) => id !== hourIndex) };
        }
        return el;
      })
    ));
  };

  const handleChange = (index) => ({ hourIndex, key, value }) => {
    setOpenHours((prev) => (
      prev.map((el, idx) => {
        if (idx === index) {
          const currentHours = el.hours.map((item, id) => {
            if (id === hourIndex) {
              return { ...item, [key]: value };
            }
            return item;
          });
          return { ...el, hours: currentHours };
        }
        return el;
      })
    ));
  };

  const handleError = (index) => (value) => {
    setErrors((prev) => {
      const iterator = new Array(Math.max(prev.length, index + 1)).fill(false);
      return iterator.map((el, id) => {
        if (index === id) {
          return value;
        }
        return el;
      });
    });
  };
  const readOnlyAction = errors.some((el) => el);

  return (
    <div className={classes.modalWrapper}>
      <Modal modalTitle={modalTitle} onCancel={modalHandler} contentStyle={classes.content} rwdBackTitle="modalMobile.goBackToBusinessProfileEdit">
        {
          openHours.map((el, index) => (
            <div className={classes.dayWrapper} key={`week-day-${el.id}`}>
              <div className={classes.dayName}>{el.name}</div>
              <div className={classes.form}>
                <Checkbox onClick={handleCheckbox(index)} checkboxStyle={clsx(classes.checkboxStyle, isEdit && classes.pointerEventsOn)} disabled={!isEdit} label={t('businessProfileEdit.closed')} value={el.value} labelPosition="right" className={clsx(classes.checkbox, !el.value && classes.lightGray)} />
                {!el.value && (
                <HourList onChange={handleChange(index)} onDelete={handleDelete(index)} onAdd={handleAdd(index)} onError={handleError(index)} isEdit={isEdit} className={classes.hours} hours={el.hours}/>
                )}
              </div>
            </div>
          ))
        }
        <ActionBox readOnlyAction={readOnlyAction} handleSave={moreHoursSaveHandler} handleCancel={additionalHoursModalCancelHandler} className={classes.actionWrapper}/>
      </Modal>
    </div>
  );
};
export default AdditionalHoursModal;
