export const getReviewerInitials = (reviewerName) => (
  reviewerName.split(' ').map((n) => n[0]).join('').toUpperCase()
);

export const generateRandomColor = (reviewerName) => {
  if (!reviewerName) return 'hsl(0, 100%, 80%)';
  const initialAsciiValue = reviewerName.charCodeAt(0);
  const hue = initialAsciiValue % 360;
  return `hsl(${hue}, 100%, 80%)`;
};
