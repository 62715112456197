import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import mapImage from '../../../../../shared/assets/map.jpg';
import Range from '../../../../../shared/components/Range';

const renderLabel = (val) => `${val || 0} km`;

const max = 50;
const step = 1;

const parseToNumber = (value) => {
  const parsed = Number(value);
  return Number.isNaN(parsed) ? 0 : parsed;
};

const KeywordsArea = ({ onChange, value, rwd, minValue }) => {
  const { t } = useTranslation();
  const [areaWidth, setAreaWidth] = useState(parseToNumber(value));

  const handleChange = (val) => {
    setAreaWidth(parseToNumber(val));
  };

  const handleBlur = (val) => {
    if (rwd) return;
    onChange(parseToNumber(val));
  };
  useEffect(() => {
    if (rwd) {
      onChange(areaWidth);
    }
  }, [areaWidth]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>{t('generateReport.pointSearchArea')}</div>
      {!rwd && (
        <>
          <div className={classes.subInfo}>
            Lorem ipsum dolor sit amet consectetur. Malesuada tellus aliquam vel nisi porttitor vel faucibus ultricies.
          </div>
          <div className={classes.imageWrapper}>
            <img alt="map preview" className={classes.image} src={mapImage} />
            <div
              style={{ width: areaWidth ? `${(180 - 20) * (areaWidth / max)}px` : '0px' }}
              className={classes.indicator}
            />
          </div>
        </>
      )}
      <Range
        labelClassName={classes.rangeLabel}
        step={step}
        min={minValue}
        max={max}
        value={areaWidth}
        onChange={handleChange}
        renderLabel={renderLabel}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default KeywordsArea;
