import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('businessProfileChangeHistory.headerTitle')}</div>
      <div className={classes.subTitle}>{t('businessProfileChangeHistory.subTitle')}</div>
    </div>
  );
};
export default Header;
