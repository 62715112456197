import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../Pill';
import { aiModels } from '../../../app/SEO/helpers/constants';

const AIModels = ({ selectModelHandler, selectedModel, className }) => {
  const handleClick = (model) => {
    selectModelHandler(model);
  };
  const renderPill = (model) => {
    const isSelected = selectedModel === model;
    return (
      <div key={model} onClick={() => handleClick(model)}>
        <Pill className={clsx(classes.pillAiModel, isSelected && classes.selectedPill)} label={model} />
      </div>
    );
  };

  return <div className={clsx(classes.aiModelsWrapper, className)}>{aiModels.map(renderPill)}</div>;
};

export default AIModels;
