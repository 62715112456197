// TODO month name and month days count should be handled by dayjs
export const monthList = [
  { id: 1, name: 'Styczeń', getDaysCount: (year) => 31 },
  { id: 2, name: 'Luty', getDaysCount: (year) => (year % 4 ? 28 : 29) },
  { id: 3, name: 'Marzec', getDaysCount: (year) => 31 },
  { id: 4, name: 'Kwiecień', getDaysCount: (year) => 30 },
  { id: 5, name: 'Maj', getDaysCount: (year) => 31 },
  { id: 6, name: 'Czerwiec', getDaysCount: (year) => 30 },
  { id: 7, name: 'Lipiec', getDaysCount: (year) => 31 },
  { id: 8, name: 'Sierpień', getDaysCount: (year) => 31 },
  { id: 9, name: 'Wrzesień', getDaysCount: (year) => 30 },
  { id: 10, name: 'Październik', getDaysCount: (year) => 31 },
  { id: 11, name: 'Listopad', getDaysCount: (year) => 30 },
  { id: 12, name: 'Grudzień', getDaysCount: (year) => 31 }
];
