import React from 'react';
import { filtersDescription } from './utils';
import classes from './styles.module.css';

const FiltersDescriptionModal = () => (
  <div className={classes.filterModalWrapper}>
    {filtersDescription.map((el) => (
      <div key={el.id} className={classes.filterWrapper}>
        <div className={classes.filterName}>
          {el.displayName}
        </div>
        <div className={classes.filterDescription}>{el.description}</div>
      </div>
    ))}
  </div>
);
export default FiltersDescriptionModal;
