export const parseTime = (timeObj) => {
  const timeRegex = /^(\d{1,2}):(\d{1,2})$/;
  const match = timeObj?.toString().match(timeRegex);

  if (match) {
    const [_, hours, minutes] = match;
    const parsedHours = hours.padStart(2, '0');
    const parsedMinutes = minutes ? minutes.padStart(2, '0') : '00';
    return `${parsedHours}:${parsedMinutes}`;
  }

  return null;
};

export const parseAddressToStorefront = (address) => {
  if (!address) {
    return null;
  }
  const [addressLine, postalCodeAndCity] = address.split(',').map((part) => part.trim());
  const [postalCode, locality] = postalCodeAndCity.split(' ');

  return {
    postalCode,
    locality,
    addressLines: [addressLine],
  };
};

export const removeNullValues = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
  if (value === null || value === '') {
    return acc;
  }
  return { ...acc, [key]: value };
}, {});
