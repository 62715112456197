import React from 'react';
import classes from './styles.module.css';

const CreateBusinessProfileTextWrapper = ({ title, subTitle }) => (
  <div>
    <div className={classes.title}>{title}</div>
    <div className={classes.subTitle}>{subTitle}</div>
  </div>
);
export default CreateBusinessProfileTextWrapper;
