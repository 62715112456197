import React from 'react';
import classes from './styles.module.css';
import { generateKey } from '../../../../shared/helpers/helpers';

const boldLastWord = (text) => {
  const words = text.split(' ');
  const lastWordIndex = words.length - 1;

  return (
    <>
      {words.map((word, index) => {
        if (index === lastWordIndex) {
          return (
            <span key={generateKey(word, index)} className={classes.featureTextBold}>
              {word}
            </span>
          );
        }
        return <React.Fragment key={generateKey(word, index)}>{`${word} `}</React.Fragment>;
      })}
    </>
  );
};

export const renderFeatureText = (el) => {
  if (el.includes('Raporty')) {
    return <p className={classes.featureTextBold}>{el}</p>;
  }
  if (el.includes('Statystyki kart SEO')) {
    return <p>{boldLastWord(el)}</p>;
  }
  return <p>{el}</p>;
};
