import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';

const HeadingComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.heading}>
      <h2 className={classes.title}>
        {t('setFilters.selectCompanyAnd')}
        <span className={classes.span}>
          {' '}
          { t('setFilters.enterKeyword') }
          {' '}
        </span>
        {t('setFilters.toGenerateReport')}
      </h2>
    </div>
  );
};
export default HeadingComponent;
