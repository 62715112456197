import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

const ErrorMessage = ({ className, message }) => (
  <div className={clsx(classes.wrapper, className)}>
    <div className={classes.icon}>
      <CloseIcon />
    </div>
    <div className={classes.message}>{message}</div>
  </div>
);

export default ErrorMessage;
