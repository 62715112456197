import React from 'react';
import styles from './styles.module.css';

const ProgressBar = ({ value, maxValue }) => {
  const progressWidth = (value / maxValue) * 100;
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar} style={{ width: `${progressWidth}%` }} />
    </div>
  );
};

export default ProgressBar;
