import React from 'react';
import dayjs from 'dayjs';
import i18next from 'i18next';
import classes from './styles.module.css';
import { parseRegularHours } from '../../modules/BusinessProfileEdit/OpenHoursForm/utils';
import { ReactComponent as Google } from '../../../../shared/assets/googleChangeHistory.svg';
import { ReactComponent as LogoIcon } from '../../../../shared/assets/seoChangeHistory.svg';

export const CHANGE_KEY_CONFIG = {
  additional_categories: 'businessProfileChangeHistory.additionalCategory',
  additional_phones: 'businessProfileChangeHistory.additionalPhone',
  service_items: 'businessProfileChangeHistory.serviceItem',
  regular_hours: 'businessProfileChangeHistory.regularHours',
  title: 'businessProfileChangeHistory.title',
  primary_phone: 'businessProfileChangeHistory.primaryPhone',
  address: 'businessProfileChangeHistory.address',
  website_uri: 'businessProfileChangeHistory.website',
  description: 'businessProfileChangeHistory.description',
  labels: 'businessProfileChangeHistory.labels',
  opening_date: 'businessProfileChangeHistory.openingDate',
  service_area: 'businessProfileChangeHistory.serviceArea',
  more_hours: 'businessProfileChangeHistory.moreHours',
};

const ACTION_ENUM = {
  ADD: 'add',
  REMOVE: 'remove',
  UPDATE: 'update',
};

const createAction = (oldValue, newValue) => {
  const { ADD, REMOVE, UPDATE } = ACTION_ENUM;
  if (oldValue === null) {
    return ADD;
  }
  if (newValue === null) {
    return REMOVE;
  }
  return UPDATE;
};

const formatHours = (el) => {
  if (el.hours.length) {
    return `\n${el.name}: ${el.hours.map((hour) => `${hour.from} - ${hour.to}`).join(', ')}`;
  }
  return '';
};

const getServiceString = (el, serviceType, serviceItemKey, serviceNameKey) => `\n${i18next.t(`businessProfileChangeHistory.${serviceType}Service`, {
  service: el[serviceItemKey][serviceNameKey],
})}`;

const getServiceStringWithPrice = (el, serviceType, serviceItemKey, serviceNameKey) => `${getServiceString(el, serviceType, serviceItemKey, serviceNameKey)} - ${i18next.t(
  'businessProfileChangeHistory.price',
  { price: el.price.units, currency: el.price.currencyCode },
)} `;

const getServicesString = (services, serviceType, serviceItemKey, serviceNameKey) => services
  .map((el) => (el.price
    ? getServiceStringWithPrice(el, serviceType, serviceItemKey, serviceNameKey)
    : getServiceString(el, serviceType, serviceItemKey, serviceNameKey)),)
  .join(', ');

const getServicesWithAndWithoutPrice = (services) => {
  const withPrice = services.filter((el) => el.price);
  const withoutPrice = services.filter((el) => !el.price);
  return { withPrice, withoutPrice };
};

const parseBusinessServices = (freeForm, structuredForm) => {
  const { withPrice: freeFormWithPrice, withoutPrice: freeFormWithoutPrice } = getServicesWithAndWithoutPrice(freeForm);
  const { withPrice: structuredFormWithPrice, withoutPrice: structuredFormWithoutPrice } = getServicesWithAndWithoutPrice(structuredForm);

  const parsedServices = [
    getServicesString(freeFormWithoutPrice, 'freeForm', 'freeFormServiceItem', 'label.displayName'),
    getServicesString(structuredFormWithoutPrice, 'structuredForm', 'structuredServiceItem', 'serviceTypeId'),
    getServicesString(structuredFormWithPrice, 'structuredForm', 'structuredServiceItem', 'serviceTypeId'),
    getServicesString(freeFormWithPrice, 'freeForm', 'freeFormServiceItem', 'label.displayName'),
  ].join('');

  return parsedServices;
};

const parseChangeValue = (val) => {
  if (Array.isArray(val)) {
    const isService = val.some((el) => el.freeFormServiceItem || el.structuredServiceItem);
    const everyValueIsString = val.every((v) => typeof v === 'string');
    const isOpenHours = val.every((v) => v.openDay && v.closeDay);

    if (everyValueIsString) {
      return `\n${val.join(', \n')}`;
    }
    if (isOpenHours) {
      const parsedRegularHours = parseRegularHours(val);
      return parsedRegularHours.map(formatHours).filter(Boolean).join(', ');
    }
    if (isService) {
      const freeFormServices = val.filter((el) => el.freeFormServiceItem);
      const structuredFormServices = val.filter((el) => !el.freeFormServiceItem);
      return parseBusinessServices(freeFormServices, structuredFormServices);
    }
  }
  return val;
};

const getActionValue = (change) => {
  const { oldValue, value } = change;
  const { ADD, REMOVE } = ACTION_ENUM;
  const parsedOldValue = parseChangeValue(oldValue);
  const parsedValue = parseChangeValue(value);
  const actionType = createAction(oldValue, value);

  if (actionType === ADD) {
    return i18next.t('businessProfileChangeHistory.added', { value: parsedValue });
  }
  if (actionType === REMOVE) {
    return i18next.t('businessProfileChangeHistory.removed', { value: parsedOldValue });
  }
  return i18next.t('businessProfileChangeHistory.updated', {
    oldValue: parsedOldValue,
    value: parsedValue,
  });
};

const getMoreHoursTranslation = (val) => {
  const [moreHours, moreHoursType] = val.split(':');
  return i18next.t('businessProfileChangeHistory.moreHours', { type: moreHoursType });
};

export const config = [
  {
    key: 'updatedBy',
    header: '',
    sort: '',
    width: 0.1,
    render: (val) => (val === 'google' ? <Google className={classes.icon} /> : <LogoIcon className={classes.icon} />),
  },
  {
    key: 'createdAt',
    header: 'businessProfileChangeHistory.date',
    sort: 'created_at',
    render: (val) => dayjs(val).format('DD/MM/YYYY HH:mm'),
    width: 0.8,
  },
  {
    key: 'key',
    header: 'businessProfileChangeHistory.editedField',
    sort: 'editedField',
    render: (val) => (i18next.t(CHANGE_KEY_CONFIG[val]).length ? i18next.t(CHANGE_KEY_CONFIG[val]) : getMoreHoursTranslation(val)),
    width: 1,
    bold: true,
  },
  {
    key: 'change',
    header: 'businessProfileChangeHistory.action',
    sort: 'action',
    render: (val) => getActionValue(val),
    width: 3,
  },
];
