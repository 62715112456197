import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';

const MediaEmptyState = ({ isEdit, cancelBtnHandler, modalHandler }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.content)}>
      <div className={classes.title}>{t('businessProfileEdit.mediaEmptyStateTitle')}</div>
      <div className={classes.subtitle}>{t('businessProfileEdit.mediaEmptyStateSubtitle')}</div>

      {isEdit && (
        <div className={classes.actionsWrapper}>
          <Button onClick={modalHandler} label={t('businessProfileEdit.addMedia')} />
          <Button className={classes.cancelBtn} onClick={cancelBtnHandler} label={t('global.cancel')} />
        </div>
      )}
    </div>
  );
};

export default MediaEmptyState;
