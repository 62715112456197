import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { chartTitleConfig, filterInteractionsDataOptions, filterViewsDataOptions } from './utils';
import { ReactComponent as Arrow } from '../../../../../shared/assets/arrowAlternative.svg';
import Tooltip from '../../../../../shared/components/Tooltip';

const ViewsChartHeader = ({ delta, onSelect, selectedDataType, chartToDisplay, isBusinessVerified }) => {
  const { t } = useTranslation();

  const negativeValue = delta < 0;
  const showDelta = Boolean(delta) && !selectedDataType;
  const chartTitle = t(chartTitleConfig[selectedDataType] || chartToDisplay);
  const isViewsNumber = chartToDisplay === t('dashboard.viewsNumber');
  const deltaAsString = `${delta}%`;
  const optionsToRender = isViewsNumber ? filterViewsDataOptions : filterInteractionsDataOptions;

  const renderIcon = ({ title, Icon, type }) => (
    <Tooltip text={t(title)} tooltipStyle={classes.tooltipStyle}>
      <div
        key={title}
        onClick={() => onSelect(type)}
        className={clsx(classes.iconWrapper, selectedDataType === type && classes.selectedIcon)}
      >
        <Icon className={classes.icon} />
      </div>
    </Tooltip>
  );

  const renderDelta = () => (
    <div className={clsx(classes.increase, negativeValue && classes.negativeValue)}>
      <Arrow className={clsx(classes.arrowIcon, negativeValue && classes.arrowNegativeValue)} />
      {deltaAsString}
    </div>
  );

  return (
    <div className={classes.header}>
      <div className={classes.rowContainer}>
        {chartTitle}
        {showDelta && renderDelta()}
      </div>
      {isBusinessVerified && <div className={classes.icons}>{optionsToRender.map(renderIcon)}</div>}
    </div>
  );
};

export default ViewsChartHeader;
