import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import { ReactComponent as Add } from '../../../../../shared/assets/addCircle.svg';

const AnswerTemplatesEmptyState = ({ createModalHandler, adminPreview }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.emptyContent}>
      <CircleIconText
        heading={t('opinions.noTemplatesYetPlural')}
        supportingText={!adminPreview && t('opinions.addFirstTemplate')}
        icon={Magnifier}
        headingStyle={classes.headingStyle}
      />
      {!adminPreview && (
      <Button
        className={classes.button}
        label={t('opinions.createTemplate')}
        onClick={createModalHandler}
        Icon={Add}
      />
      )}
    </div>
  );
};
export default AnswerTemplatesEmptyState;
