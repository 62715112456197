import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import {
  actionChangeHandlerHelper,
  getParsedUniqueTokens,
  initRequestPayload,
  limitOpinionList,
  parsingOpinionsData,
  sortOpinionList,
  getEmptyStateText,
} from './utils';
import { useApi } from '../../../../shared/helpers/api';
import { useDashboard } from '../../helpers/dashboard';
import OpinionsBusinessHeader from '../../modules/Opinions/OpinionsBusinessHeader';
import OpinionsItems from '../../modules/Opinions/OpinionItems';
import OpinionsEmptyState from '../../modules/Opinions/EmptyState';
import { StateEnum } from '../../../../shared/helpers/state';
import { useNotification } from '../../../../shared/helpers/notification';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import Pagination from '../../../../shared/components/Pagination';
import OpinionSortActions from '../../modules/Opinions/OpinionSortActions';
import Button from '../../../../shared/components/Button';
import GetMoreOpinionsModal from '../../modules/Opinions/GetMoreOpinionsModal';
import { PROFILE_STATE_ENUM } from '../../components/DashboardLayout/utils';
import { getLocalStorageProfileStatus } from '../../../../shared/helpers/helpers';
import BusinessStatus from '../../modules/Dashboard/BusinessStatus';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import Loader from '../../components/Loader';

const Opinions = ({ adminPreview, userId, businessProfileId }) => {
  const { api } = useApi();
  const { businessProfile } = useDashboard();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const controllerRef = useRef(null);
  const [state, setState] = useState(StateEnum.initializing);
  const [businessOpinions, setBusinessOpinions] = useState([]);
  const [businessScore, setBusinessScore] = useState({});
  const [pageToken, setPageToken] = useState([{ id: 1, value: '' }]);
  const [requestPayload, setRequestPayload] = useState(initRequestPayload);
  const [showModal, setShowModal] = useState(false);
  const [placeId, setPlaceId] = useState(null);

  const limitValue = limitOpinionList.find((el) => el.value === requestPayload.limit).id;
  const orderValue = Number(sortOpinionList.find((el) => el.value === requestPayload.orderBy).id);
  const pageValue = pageToken.find((el) => el.value === requestPayload.pageToken).id || 1;
  const paginationList = limitOpinionList.map((el) => el.value);
  const renderEmptyStateCondition = state === StateEnum.success && !businessOpinions?.length;
  const profileStatusInStorage = getLocalStorageProfileStatus(businessProfile.id);
  const { emptyStateText, emptySateTitle } = getEmptyStateText(adminPreview, t);
  const modalHandler = () => setShowModal((prev) => !prev);

  const [selectAllowed, setSelectedAllowed] = useState(false);
  const [selectedReviews, setSelectedReviews] = useState(null);
  const [hasMadeSelection, setHasMadeSelection] = useState(false);

  const selectAllowedHandler = () => {
    setSelectedReviews([]);
    setSelectedAllowed((prev) => !prev);
  };
  const onSelectReviewHandler = (id) => {
    setHasMadeSelection(true);
    const foundReview = selectedReviews.find((el) => el === id);
    if (foundReview) {
      setSelectedReviews((prev) => prev.filter((el) => el !== id));
    } else {
      setSelectedReviews((prev) => [...prev, id]);
    }
  };
  const fetchOpinions = async () => {
    controllerRef.current = new AbortController();
    try {
      const apiPath = adminPreview ? 'acp/client/getReviews' : '/review/getReviews';
      const adminPayload = { ...requestPayload, businessProfileId, userId };
      const apiPayload = adminPreview ? adminPayload : requestPayload;
      const { data } = await api.post(apiPath, apiPayload, { signal: controllerRef.current.signal });
      if (data.nextPageToken) {
        const parsedUniqueTokens = getParsedUniqueTokens(pageToken, data.nextPageToken);
        setPageToken(parsedUniqueTokens);
      }
      const { parsedBusinessScore, parsedBusinessOpinions } = parsingOpinionsData(data);
      setBusinessOpinions(parsedBusinessOpinions);
      setBusinessScore(parsedBusinessScore);
    } catch (err) {
      setState(StateEnum.error);
      handleApiError({ err, showNotification, t });
    } finally {
      setState(StateEnum.success);
    }
    return () => controllerRef.current.abort();
  };

  const fetchBusinessProfile = async () => {
    controllerRef.current = new AbortController();
    if (profileStatusInStorage === PROFILE_STATE_ENUM.duplicate) {
      const {
        data: { metadata },
      } = await api.post(
        '/business_profile/getBusinessProfile',
        { businessProfileId: businessProfile.id },
        { signal: controllerRef.current.signal },
      );
      setPlaceId(metadata.placeId);
    }

    return () => controllerRef.current.abort();
  };

  const actionChangeHandler = (name) => (id) => {
    setPageToken([{ id: 1, value: '' }]);
    const newPayload = actionChangeHandlerHelper(
      name,
      id,
      businessProfile.id,
      requestPayload.orderBy,
      requestPayload.limit,
    );
    setRequestPayload(newPayload);
  };

  const pageChangeHandler = (page) => {
    const pageFound = pageToken.find((el) => el.id === page).value;
    setRequestPayload((prev) => ({ ...prev, pageToken: pageFound }));
  };

  const paginationLimitChangeHandler = (limit) => {
    setPageToken([{ id: 1, value: '' }]);
    setRequestPayload((prev) => ({ ...prev, limit, pageToken: '' }));
  };

  const answerSelectedReviews = async (templateId) => {
    const controller = new AbortController();
    try {
      setState(StateEnum.loading);
      await api.post(
        '/review/postReviewsTemplate',
        { businessProfileId: businessProfile.id, templateId, reviewIds: selectedReviews },
        { signal: controller.signal },
      );
      showNotification({ label: t('global.success'), message: t('opinions.selectedAnswerSuccess'), type: 'success' });
    } catch (err) {
      setState(StateEnum.error);
      handleApiError({ err, showNotification, t });
    } finally {
      setState(StateEnum.success);
      setSelectedReviews([]);
      setSelectedAllowed(false);
    }

    return () => controller.abort();
  };

  useEffect(() => {
    setRequestPayload((prev) => ({ ...prev, businessProfileId: businessProfile.id || businessProfileId }));
  }, [businessProfile.id, businessProfileId]);

  useEffect(() => {
    const fetchData = async () => {
      if (requestPayload.businessProfileId !== '') {
        try {
          await Promise.all([fetchBusinessProfile(), fetchOpinions()]);
          setState(StateEnum.success);
        } catch (err) {
          setState(StateEnum.error);
          handleApiError({ err, showNotification, t });
        }
      }
    };
    fetchData();
  }, [requestPayload, businessProfile.id, businessProfileId, userId, adminPreview]);

  useEffect(() => {
    if (hasMadeSelection && selectedReviews.length === 0) {
      setSelectedAllowed(false);
      setHasMadeSelection(false);
    }
  }, [selectedReviews, hasMadeSelection]);

  if (state === StateEnum.error) {
    return (
      <div className={classes.center}>
        <ErrorMessage message={t('global.error')} />
      </div>
    );
  }

  if ([StateEnum.loading, StateEnum.initializing].includes(state)) {
    return (
      <div className={classes.overlay}>
        <Loader />
      </div>
    );
  }

  if (state === StateEnum.success && profileStatusInStorage !== PROFILE_STATE_ENUM.verified && !adminPreview) {
    return <BusinessStatus status={profileStatusInStorage} forDashboard={false} placeId={placeId} />;
  }

  return (
    <div className={classes.wrapper}>
      <OpinionsBusinessHeader businessScore={businessScore} adminPreview={adminPreview} />
      <div className={clsx(classes.body, adminPreview && classes.adminPreview)}>
        {showModal && (
          <GetMoreOpinionsModal
            modalHandler={modalHandler}
            rwdModalBackTitle={t('modalMobile.goBackToOpinions')}
            id={businessProfile.id}
          />
        )}
        {renderEmptyStateCondition ? (
          <div className={classes.empty}>
            <OpinionsEmptyState title={emptySateTitle} subTitle={emptyStateText} />
          </div>
        ) : (
          <>
            <OpinionSortActions
              onClick={actionChangeHandler}
              limitValue={limitValue}
              orderValue={orderValue}
              onSelect={selectAllowedHandler}
              selectedReviews={selectedReviews}
              answerSelectedReviews={answerSelectedReviews}
              adminPreview={adminPreview}
            />
            <div className={classes.opinionList}>
              <OpinionsItems
                businessProfileId={businessProfile.id}
                businessOpinions={businessOpinions}
                fetchOpinions={fetchOpinions}
                businessProfile={businessProfile}
                selectAllowed={selectAllowed}
                selectedReviews={selectedReviews}
                onSelectReviewHandler={onSelectReviewHandler}
                answerSelectedReviews={answerSelectedReviews}
                adminPreview={adminPreview}
              />
            </div>
            <Pagination
              onLimitChange={paginationLimitChangeHandler}
              className={classes.pagination}
              page={pageValue}
              onPageChange={pageChangeHandler}
              limit={requestPayload.limit}
              total={businessScore.totalReviewCount}
              paginationLimitList={paginationList}
            />
          </>
        )}
      </div>
      <div className={classes.rwdFooterAction}>
        <Button onClick={modalHandler} className={classes.rwdGetMore} label={t('opinions.getMoreOpinions')} />
      </div>
    </div>
  );
};

export default Opinions;
