import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

export const ColumnLabels = ({ labels, admin = true }) => (
  <div className={clsx(classes.columnLabel, !admin && classes.clientLabel)}>
    {labels.map((label) => (
      <div className={classes.label} key={label}>
        {label}
      </div>
    ))}
  </div>
);

const LinkDataRow = ({ link, admin }) => (
  <tr className={clsx(classes.rowWrapper, admin && classes.clientData)}>
    <td key={link.linkCode}>
      <div className={classes.linkValue}>{link.linkCode}</div>
      <div className={classes.linkValue}>{link.linkName}</div>
    </td>
    <td>
      <div className={classes.linkValue}>{link.link || '-'}</div>
    </td>
    <td>
      <div className={classes.linkInteractionsValue}>{link.linkInteractions || '-'}</div>
    </td>
    <td>
      <div className={classes.linkLastUsed}>{link.lastUsed}</div>
    </td>
  </tr>
);

export default LinkDataRow;
