import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryReferenceString } from '../CategoriesForm/utils';
import classes from './styles.module.css';
import CategoriesSelect from '../CategoriesSelect';
import { ReactComponent as BinIcon } from '../../../../../shared/assets/bin.svg';
import Tooltip from '../../../../../shared/components/Tooltip';
import { useDashboard } from '../../../helpers/dashboard';
import { readOnlySubList } from '../../../helpers/constants';

const AdditionalCategories = ({ isEdit, categories, handleDelete, handleCategory, categoriesList, adminPreview }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { subscription },
  } = useDashboard();
  const isReadOnly = readOnlySubList.includes(subscription);
  const isActionBtnDisplayed = !adminPreview && !isReadOnly;

  return categories.additionalCategories.map((el, index) => (
    <div key={el.key} className={classes.row}>
      <Tooltip
        className={classes.toolWrapper}
        tooltipStyle={classes.categoryTooltipStyle}
        isEdit={isEdit}
        text={getCategoryReferenceString(el?.categoryReferenceId)}
      >
        <CategoriesSelect
          className={classes.select}
          onCategoryChange={handleCategory(index)}
          isEdit={isEdit}
          value={el?.categoryReferenceId}
          categoriesList={categoriesList}
          label={t('businessProfileEdit.additionalCategory')}
          adminPreview={adminPreview}
          inputValue={el?.name}
        />
      </Tooltip>
      {isActionBtnDisplayed && (
        <Tooltip
          tooltipStyle={classes.tooltipStyle}
          isEdit={isEdit}
          text={t('businessProfileEdit.removeCategoryToolTip')}
        >
          <button type="button" disabled={!isEdit} onClick={handleDelete(index)} className={classes.action}>
            <BinIcon className={classes.icon} />
          </button>
        </Tooltip>
      )}
    </div>
  ));
};

export default AdditionalCategories;
