import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';
import { ReactComponent as CheckIcon } from '../../../../shared/assets/check.svg';
import { renderFeatureText } from './utils';
import { generateKey } from '../../../../shared/helpers/helpers';

const PriceModel = ({ model }) => {
  const { t } = useTranslation();
  const { isComing, popular } = model;
  const navigateToRegister = () => {
    window.location.href = '/register';
  };

  return (
    <div
      className={clsx(classes.modelWrapper, popular && classes.popularWrapper, isComing && classes.comingSoonWrapper)}
    >
      <div className={classes.row}>
        <div className={clsx(classes.modelTitle, popular && classes.popular)}>{model.title}</div>
        {model.popular && <div className={classes.popularLabel}>{t('landingPage.pricingSection.popular')}</div>}
      </div>
      <div className={classes.subTitle}>{model.subTitle}</div>
      <div className={classes.price}>
        {`${model.price} PLN`}
        <span>{t('landingPage.pricingSection.monthly')}</span>
      </div>
      <div className={classes.modelFeaturesList}>
        {model.features.map((el, idx) => (
          <div key={generateKey(el, idx)} className={classes.featureText}>
            <div className={clsx(classes.checkIconWrapper, popular && classes.popular)}>
              <CheckIcon />
            </div>
            {renderFeatureText(el)}
          </div>
        ))}
      </div>
      {isComing && <div className={classes.comingSoon}>{t('landingPage.pricingSection.comingSoon')}</div>}
      <Button
        className={clsx(classes.button, popular && classes.popularBtn)}
        label={t('landingPage.pricingSection.selectPlan')}
        onClick={navigateToRegister}
      />
    </div>
  );
};

export default PriceModel;
