import { PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';
import { ReactComponent as CheckIcon } from '../../../../../shared/assets/check.svg';
import { ReactComponent as BusinessDuplicateIcon } from '../../../../../shared/assets/duplicate.svg';
import { ReactComponent as ProcessingIcon } from '../../../../../shared/assets/clock.svg';

export const verificationStatusConfig = (t) => ({
  verified: {
    label: t('settings.verified'),
    subTitle: t('settings.verifiedSubtitle'),
    id: PROFILE_STATE_ENUM.verified,
    Icon: CheckIcon,
  },
  duplicate: {
    label: t('settings.duplicate'),
    subTitle: t('settings.duplicateSubtitle'),
    id: PROFILE_STATE_ENUM.duplicate,
    Icon: BusinessDuplicateIcon,
  },
  processing: {
    label: t('businessProfileCreate.processing'),
    subTitle: t('businessProfileCreate.processingSubtitle'),
    id: PROFILE_STATE_ENUM.processing,
    Icon: ProcessingIcon,
  },
  notVerified: {
    label: t('settings.notVerified'),
    id: PROFILE_STATE_ENUM.notVerified,
  },
  suspended: {
    label: t('settings.suspended'),
    subTitle: t('dashboard.suspendedSubInfo'),
    id: PROFILE_STATE_ENUM.suspended,
    Icon: ProcessingIcon,
  },
});
