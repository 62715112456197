import React from 'react';
import { useTranslation } from 'react-i18next';
import PlannedPost from '../PlannedPost';
import DragAndDrop from '../../../../../shared/components/DragAndDrop';

const MediaAndPublication = ({
  handleMedia,
  isEditingPublishedPost,
  handlePublicationDate,
  data,
  shouldRenderItems,
}) => {
  const { t } = useTranslation();

  return (
    shouldRenderItems && (
      <>
        <DragAndDrop
          supportingText={t('posts.learnMoreAboutPhoto')}
          files={data.localPost?.media}
          onChange={handleMedia}
          maxFilesNumber={1}
          btnLabel={t('posts.addPhotos')}
        />
        {!isEditingPublishedPost && (
          <PlannedPost
            data={data}
            onPublicationDateChange={handlePublicationDate}
          />
        )}
      </>
    )
  );
};
export default MediaAndPublication;
