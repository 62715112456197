import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { useWindowResize } from '../../../../../shared/helpers/hooks';
import { MAX_WIDTH } from '../BusinessItem/utils';
import Loader from '../../../components/Loader';

const BusinessItemStatus = ({ props }) => {
  const { t } = useTranslation();
  const innerWidth = useWindowResize();
  const { profileStatus, profileStatusState, statusToDisplay, profile } = props;
  const shouldRenderPill = innerWidth > MAX_WIDTH;

  if (profile.externalBusinessProfile) {
    return shouldRenderPill ? (
      <Pill label={t('settings.externalProfile')} className={clsx(classes.pill, classes.external)} />
    ) : null;
  }

  if (!statusToDisplay) {
    return <Loader />;
  }

  return (
    <div>
      {shouldRenderPill ? (
        <Pill label={profileStatusState} className={clsx(classes.pill, classes[profileStatus])} />
      ) : (
        <div className={clsx(classes.iconWrapper, classes[profileStatus])}>
          <statusToDisplay.Icon className={classes.icon} />
        </div>
      )}
    </div>
  );
};

export default BusinessItemStatus;
