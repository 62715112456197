import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { navLinks } from '../NavigationBar/utils';
import { useIsMobile } from '../../../../shared/helpers/hooks';
import Button from '../../../../shared/components/Button';
import { MIN_TABLET_WIDTH } from '../../../SEO/helpers/constants';

const MobileNav = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileScreen = useIsMobile(MIN_TABLET_WIDTH);

  const handleNavigation = (destination) => {
    navigate(destination);
    onClose();
  };
  return (
    <div className={classes.mobileNavWrapper}>
      <ul>
        {navLinks(t).map(({ title, url }) => (
          <li key={url}>
            <a href={url} onClick={onClose}>
              {title}
            </a>
          </li>
        ))}
        {isMobileScreen && (
          <div className={classes.actionsWrapper}>
            <Button label={t('global.signIn')} className={classes.button} onClick={() => handleNavigation('login')} />
            <Button
              label={t('landingPage.signUpForFree')}
              className={clsx(classes.button, classes.signUpBtn)}
              onClick={() => handleNavigation('register_methods')}
            />
          </div>
        )}
      </ul>
    </div>
  );
};

export default MobileNav;
