import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import ClientData from '../ClientData';
import CardInformationData from '../CardInformationData';
import RedirectLinkList from '../RedirectLinkList';
import Button from '../../../../../shared/components/Button';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import AdminLoader from '../../../components/AdminLoader';
import { urlValidator } from '../../../../../shared/components/Input/validators';

const BasicCardData = ({ cardData, fetchData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [cardDataForm, setCardDataForm] = useState({});
  const [linkDataForm, setLinkDataForm] = useState([]);
  const [error, setError] = useState(null);

  const linkDataDifferencesCollection = linkDataForm.filter((link, index) => Object.entries(link).some(([key, value]) => value !== cardData.links[index][key]),);

  const cardDataDifferencesExist = Object.entries(cardDataForm).some(([key, value]) => value !== cardData[key]);

  const linkIsValid = linkDataForm.every(({ link }) => !urlValidator(link, true));

  const noDifferencesExist = !cardDataDifferencesExist && !linkDataDifferencesCollection.length;
  const isReadOnlySaveBtn = noDifferencesExist || error || !linkIsValid;
  const handleFormInputChange = (key) => (value) => {
    setCardDataForm((prev) => ({ ...prev, [key]: value }));
  };

  const onErrorHandler = (value) => {
    setError(Boolean(value));
  };

  const handleLinkFormChange = (index) => (key) => (value) => {
    setLinkDataForm((prev) => {
      const newLinks = [...prev];
      newLinks[index] = { ...newLinks[index], [key]: value };
      return newLinks;
    });
  };
  const onUpdateCardHandler = async () => {
    const { id, email, firstName, lastName, cardName, active } = cardDataForm;
    const payload = {
      cardId: id,
      email,
      firstName,
      lastName,
      cardName,
      active,
    };
    setIsLoading(true);
    try {
      if (cardDataDifferencesExist) {
        await api.post('/acp/card/updateCardDetails', payload);
      }

      if (linkDataDifferencesCollection.length) {
        linkDataDifferencesCollection.forEach(async (link) => {
          await api.post('/acp/card/updateLink', { linkId: link.id, linkName: link.linkName, link: link.link });
        });
      }
      showNotification({ type: 'success', message: t('adminControlPanel.cardDataUpdated') });
      fetchData();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!cardData) return;

    setCardDataForm({
      id: cardData.id,
      email: cardData.email,
      firstName: cardData.firstName,
      lastName: cardData.lastName,
      cardName: cardData.cardName,
      active: cardData.active,
      bpName: cardData.bpName,
      createdAt: cardData.createdAt,
      cardCode: cardData.cardCode,
    });

    setLinkDataForm(cardData.links);
  }, [cardData]);

  if (!cardData) return null;

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <AdminLoader />
        </div>
      )}
      <div className={classes.rowContainer}>
        <ClientData clientData={cardDataForm} onFormChange={handleFormInputChange} onError={onErrorHandler} />
        <div>
          <Button
            label={t('adminControlPanel.saveChanges')}
            className={classes.saveBtn}
            readOnly={isReadOnlySaveBtn}
            onClick={onUpdateCardHandler}
          />
        </div>
      </div>
      <CardInformationData cardData={cardDataForm} fetchCardData={fetchData} onFormChange={handleFormInputChange} />
      <RedirectLinkList data={linkDataForm} onLinkFormChange={handleLinkFormChange} />
    </div>
  );
};

export default BasicCardData;
