import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import { headerTabs } from './utils';

import { ReactComponent as ArrowBack } from '../../../../../shared/assets/arrowAlternative.svg';

const ViewHeaderBar = ({ cardData = [{ cardName: '' }], onTabClick, activeTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  const cardName = cardData[0] ? cardData[0].cardName : '';
  return (
    <div className={classes.wrapper}>
      <div className={classes.rowContainer}>
        <div className={classes.iconContainer} onClick={goBackHandler}>
          <ArrowBack className={classes.arrowBackIcon} />
        </div>
        <div className={classes.cardInformation}>
          <div className={classes.seoCard}>{t('seoCard.title')}</div>
          <div className={clsx(classes.cardName, !cardName && classes.noName)}>
            {cardName || t('seoCard.cardWithoutName')}
          </div>
        </div>
      </div>
      <div className={classes.tabContainer}>
        {headerTabs.map(({ title }, index) => (
          <div
            key={title}
            className={clsx(classes.tab, activeTab === t(title) && classes.activeTab)}
            onClick={() => onTabClick(title)}
          >
            {t(title)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewHeaderBar;
