import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../../shared/components/Table';
import { seoCardListClientTableConfig } from './utils';
import classes from './styles.module.css';
import ExpandedSeoCardRow from '../../../../Admin/modules/SeoCards/ExpandedSeoCardRow';

const List = ({ data, sortHandler, orderBy }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onExpandRowRender = (row) => <ExpandedSeoCardRow rowData={row.links} admin={false} />;

  const onCardDetailsNavigate = (row) => {
    const { id } = row;
    navigate(`/dashboard/cards/${id}`);
  };

  return (
    <Table
      admin={false}
      className={classes.tableSeoCardList}
      columns={seoCardListClientTableConfig(t)}
      data={data}
      editHandler={onCardDetailsNavigate}
      emptyStateTitle={t('adminControlPanel.noData')}
      expandableRow
      stickyCells={false}
      tableBodyStyle={classes.tableBody}
      onExpandRowRender={onExpandRowRender}
      orderBy={orderBy}
      onSortClick={sortHandler}
    />
  );
};

export default List;
