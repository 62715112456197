import React from 'react';
import Input from '../../../../../shared/components/Input';
import classes from './styles.module.css';
import {
  alphabeticalValidator,
  emailValidator,
  makeRequired,
  required as requiredValidator,
} from '../../../../../shared/components/Input/validators';
import {
  capitalizeWordsAndPreserveDashesTransformer,
  nameTransformer,
} from '../../../../../shared/components/Input/transformers';

const MIN_LENGTH_3 = 3;
const MIN_LENGTH_2 = 2;
const INPUT_MAX_LENGTH = 50;

export const clientDataConfig = (t) => [
  {
    key: 'email',
    label: t('adminControlPanel.email'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      placeholder: t('adminControlPanel.passLastName'),
      validator: makeRequired(emailValidator),
    },
  },
  {
    key: 'lastName',
    label: t('adminControlPanel.lastName'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      placeholder: t('adminControlPanel.passLastName'),
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: requiredValidator(MIN_LENGTH_3),
      maxLength: INPUT_MAX_LENGTH,
    },
  },
  {
    key: 'firstName',
    label: t('adminControlPanel.name'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      placeholder: t('adminControlPanel.passName'),
      transformer: nameTransformer,
      validator: makeRequired(alphabeticalValidator, MIN_LENGTH_3),
      maxLength: INPUT_MAX_LENGTH,
    },
  },
  {
    key: 'bpName',
    readOnly: true,
    label: t('adminControlPanel.connectedBusinessProfile'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      placeholder: '',
    },
  },
];

export const renderInputComponent = ({
  field: {
    key,
    label,
    Component,
    required,
    readOnly,
    props: { admin, placeholder, labelRised, transformer, validator, showErrorAfterBlur, maxLength },
  },
  form,
  onInputChange,
  onError,
}) => (
  <div className={classes.label} key={key}>
    {label}
    {required ? '*' : ''}
    <Component
      admin={admin}
      placeholder={placeholder}
      labelRised={labelRised}
      transformer={transformer}
      validator={validator}
      showErrorAfterBlur={showErrorAfterBlur}
      value={form[key]}
      onChange={onInputChange(key)}
      maxLength={maxLength}
      readOnly={readOnly}
      onError={onError}
    />
  </div>
);
