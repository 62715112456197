import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';

const TableHeader = ({ columns, sortHandler, orderBy, expandableRow, stickyCells, edit = true, admin }) => {
  const { column, direction } = orderBy;
  const isActiveSort = (sort) => sort === column;
  const isAscDirectionSort = (sort) => sort === column && direction === 'ASC';

  return (
    <thead className={clsx(classes.columnsRow, !admin && classes.clientRow)}>
      <tr>
        {expandableRow && (
          <th aria-label="expand" className={clsx(classes.chevronHeader, stickyCells && classes.sticky)} />
        )}
        {columns.map(({ header, sort, width, minWidth, position, directionKey, directionValue }) => (
          <th
            className={clsx(classes.columnsRow, sort && classes.sortHead, !admin && classes.clientRow)}
            key={header}
            onClick={() => sortHandler(sort)}
            style={{ width, minWidth, position, [directionKey]: directionValue }}
          >
            <div
              className={clsx(
                classes.rowContainer,
                isActiveSort(sort) && classes.activeSort,
                !admin && isActiveSort(sort) && classes.clientActiveSort,
              )}
            >
              {header}
              {sort && (
                <SortIcon className={clsx(classes.sortIcon, isAscDirectionSort(sort) && classes.activeSortIcon)} />
              )}
            </div>
          </th>
        ))}
        {edit && <th aria-label="edit" className={classes.buttonCell} key="edit" />}
      </tr>
    </thead>
  );
};

export default TableHeader;
