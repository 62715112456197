import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { formActions } from './utils';

const CreateBusinessprofileActionsWrapper = ({
  onResign,
  goNextStepHandler,
  goPreviousStepHandler,
  readOnly,
  activeStepName,
  submitHandler,
}) => {
  const { t } = useTranslation();

  const actions = useMemo(
    () => formActions({
      onResign,
      activeStepName,
      goNextStepHandler,
      goPreviousStepHandler,
      readOnly,
      submitHandler,
      t,
    }),
    [onResign, activeStepName, goNextStepHandler, goPreviousStepHandler, readOnly, submitHandler, t],
  );

  return (
    <div className={classes.actionsWrapper}>
      {actions.map(({ label, onClick, className, readOnlyBtn }) => (
        <Button
          key={label}
          className={clsx(classes.button, classes[className])}
          label={label}
          onClick={onClick}
          readOnly={readOnlyBtn}
        />
      ))}
    </div>
  );
};

export default CreateBusinessprofileActionsWrapper;
