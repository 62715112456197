import clsx from 'clsx';
import React, { useMemo } from 'react';
import classes from './styles.module.css';
import { getStrokeColors } from './utils';

const CompletionProgressBar = ({ progress, className }) => {
  const strokeDashoffset = `${332 - 332 * progress}px`;
  const label = Math.round(progress * 100);
  const color = useMemo(() => getStrokeColors(progress), [progress]);
  return (
    <div className={clsx(classes.wrapper, className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="-15.75 -15.75 157.5 157.5"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: 'rotate(-90deg)' }}
      >
        <circle
          r="53"
          cx="63"
          cy="63"
          fill="transparent"
          stroke="#fff2ea"
          strokeWidth="12"
          strokeDasharray="332.84000000000003px"
          strokeDashoffset="0"
        />
        <circle
          r="53"
          cx="63"
          cy="63"
          stroke={color}
          strokeWidth="10"
          strokeLinecap="round"
          strokeDashoffset={strokeDashoffset}
          fill="transparent"
          strokeDasharray="332.84000000000003px"
        />
        <text
          x="63px"
          y="71px"
          fill="#000000"
          fontSize="24px"
          fontWeight="bold"
          textAnchor="middle"
          style={{ transform: 'rotate(90deg) translate(0px, -122px)' }}
        >
          {`${label}%`}
        </text>
      </svg>
    </div>
  );
};

export default CompletionProgressBar;
