import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as Chevron } from '../../../../../shared/assets/chevronDown.svg';

const PostOptionForm = ({ formOptions, selectOptionHandler }) => {
  const { t } = useTranslation();

  return formOptions.map(({ id, title, description }) => {
    const handleSelectOption = () => selectOptionHandler(id);

    return (
      <div key={id} onClick={handleSelectOption} className={classes.section}>
        <div>
          <div className={classes.sectionTitle}>{t(title)}</div>
          <div className={classes.sectionDescription}>{t(description)}</div>
        </div>
        <Chevron className={classes.arrowIcon} />
      </div>
    );
  });
};

export default PostOptionForm;
