export const inputFields = ({ t, showPasswordInput, confirmationPasswordBlurHandler }) => [
  {
    key: 'password',
    label: t('setPassword.password'),
    showPassword: showPasswordInput.password,
  },
  {
    key: 'passwordConfirmation',
    label: t('setPassword.confirmPassword'),
    showPassword: showPasswordInput.passwordConfirmation,
    onBlur: () => confirmationPasswordBlurHandler(true),
    onFocus: () => confirmationPasswordBlurHandler(false),
  },
];
