import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';
import KeywordsInput from '../../KeywordsManagement/KeywordsInput';
import Modal from '../../../../../shared/components/Modal';
import KeywordsModal from '../KeywordsModal';
import { useNotification } from '../../../../../shared/helpers/notification';
import SubInformation from '../../../../../shared/components/SubInformation';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { useApi } from '../../../../../shared/helpers/api';
import { getLocalStorageProfileStatus } from '../../../../../shared/helpers/helpers';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { parseProfileStatus } from '../../../components/DashboardLayout/utils';

const KEYWORD_MAX_LENGTH = 50;
const SelectBusinessKeyWord = ({ businessProfile, onKeywordChange, selectedKeyword }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [showModal, setShowModal] = useState(false);
  const { externalBusinessProfile, id } = businessProfile;
  const { verified } = PROFILE_STATUS_ENUM;
  const profileStatus = getLocalStorageProfileStatus(id);

  const modalHandler = () => {
    if (!showModal && !businessProfile) {
      showNotification({ message: t('setFilters.noBusinessProfile'), type: 'error' });
    } else {
      setShowModal((prev) => !prev);
    }
  };

  useEffect(() => {
    const shouldFetchStatus = profileStatus && id && externalBusinessProfile;
    const fetchProfileStatus = async () => {
      try {
        const { data } = await api.post('/business_profile/getVerificationStatus', {
          businessProfileId: id,
        });
        const parsedStatus = parseProfileStatus(data, t);
        localStorage.setItem(`status-${id}`, parsedStatus.id);
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
    };

    if (shouldFetchStatus) {
      fetchProfileStatus();
    }
  }, [profileStatus, businessProfile]);

  return (
    <>
      {showModal && (
        <Modal
          onCancel={modalHandler}
          modalTitle={t('setFilters.suggestedKeyWords')}
          contentStyle={classes.keywordsModalWrapper}
        >
          <KeywordsModal
            businessProfile={businessProfile}
            onKeyWordClick={onKeywordChange('keyword')}
            onClose={modalHandler}
          />
        </Modal>
      )}
      <div className={classes.wrapper}>
        <div className={classes.sectionName}>{t('setFilters.addNewKeywords')}</div>
        <div className={classes.additionalInformation}>
          {t('setFilters.youCanGenerateReport')}
          {' '}
          <span className={classes.highlightedText}>
            {t('setFilters.forOne')}
            {' '}
          </span>
          {' '}
          {t('setFilters.keyword')}
        </div>
        <div>
          <KeywordsInput
            inputLabel={t('setFilters.keywords')}
            className={classes.input}
            buttonClassName={classes.keywordButton}
            inputClassName={classes.keywordInput}
            buttonLabel={t('setFilters.addKeyWord')}
            maxLength={KEYWORD_MAX_LENGTH}
            onChange={onKeywordChange('keyword')}
            selectedKeyword={selectedKeyword}
          />
        </div>

        {profileStatus === verified && (
          <div onClick={modalHandler} className={classes.showSuggestedKeywords}>
            <SubInformation
              className={classes.showSuggestedKeywords}
              label={t('generateReport.showSuggestedKeywords')}
            />
          </div>
        )}
        <div className={classes.horizontalLine} />
      </div>
    </>
  );
};

export default SelectBusinessKeyWord;
