import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const VideoPlayer = ({ videoUrl, crossAnonymous }) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);

  return (
    <video
      className={classes.videoContainer}
      ref={videoRef}
      controls
      src={videoUrl}
      crossOrigin={crossAnonymous ? 'anonymous' : undefined}
    >
      <source src={videoUrl} type="video/mp4" />
      <track kind="captions" />
      {t('global.videoNotSupported')}
    </video>
  );
};

export default VideoPlayer;
