import React, { useState } from 'react';
import classes from './styles.module.css';
import MostPopularKeywordModal from '../MostPopularKeywordModal';
import BusinessOpinions from '../BusinessOpinions';
import BusinessKeywords from '../BusinessKeywords';

const BusinessKeywordsWithOpinions = ({
  data = { keywords: [], parsedOpinions: [], maxValue: 0 },
  status,
  adminPreview,
}) => {
  const { keywords, parsedOpinions, maxValue } = data;
  const [showKeywordsModal, setShowKeywordsModal] = useState(false);
  const keywordModalHandler = () => setShowKeywordsModal((prev) => !prev);
  const modalKeywords = keywords.length ? data.keywords.slice(0, 10) : [];

  return (
    <div className={classes.wrapper}>
      {showKeywordsModal && (
        <MostPopularKeywordModal maxValue={maxValue} keywords={modalKeywords} modalHandler={keywordModalHandler} />
      )}
      <BusinessKeywords
        keywords={keywords}
        maxValue={maxValue}
        keywordModalHandler={keywordModalHandler}
        profileStatus={status}
      />
      <BusinessOpinions data={parsedOpinions} profileStatus={status} adminPreview={adminPreview} />
    </div>
  );
};

export default BusinessKeywordsWithOpinions;
