import React, { useEffect, useRef, useState } from 'react';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat, toLonLat } from 'ol/proj';
import { ReactComponent as PinIcon } from '../../../../../shared/assets/pin.svg';
import classes from './styles.module.css';
import { isWithinMapBoundaries } from './utils';

const BusinessLocationPreviewMap = ({ latitude, longitude, onChange, form }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [latlng, setLatlng] = useState({});
  const [lastValidCenter, setLastValidCenter] = useState([parseFloat(longitude), parseFloat(latitude)]);

  useEffect(() => {
    const initMap = () => {
      if (latitude && longitude) {
        const map = new Map({
          target: mapRef.current,
          layers: [
            new TileLayer({
              source: new OSM({ attributions: '' }),
              className: 'map-layer',
            }),
          ],
          view: new View({
            center: fromLonLat([parseFloat(longitude), parseFloat(latitude)]),
            zoom: 19,
          }),
          controls: [],
        });

        map.on('moveend', () => {
          const center = map.getView().getCenter();
          const lonLat = toLonLat(center);
          const [lon, lat] = lonLat;

          if (isWithinMapBoundaries(lon, lat)) {
            setLastValidCenter(center);
            setLatlng({ lat, lng: lon });
          } else {
            map.getView().setCenter(lastValidCenter);
          }
        });

        map.on('postcompose', () => {
          const mapLayer = document.querySelector('.map-layer');
          if (mapLayer) {
            mapLayer.style.filter = 'grayscale(100%)';
          }
        });
      }
    };

    initMap();

    return () => {
      mapRef.current = null;
    };
  }, [longitude, latitude]);

  useEffect(() => {
    if (latlng.lat && latlng.lng) {
      onChange(latlng);
    }
  }, [latlng.lat, latlng.lng]);

  return (
    <div className={classes.mapContainer}>
      <div ref={mapRef} className={classes.map} style={{ width: '100%', height: '100%' }} />
      <PinIcon ref={markerRef} className={classes.marker} />
    </div>
  );
};

export default BusinessLocationPreviewMap;
