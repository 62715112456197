import React from 'react';
import classes from './styles.module.css';

const AdminLoader = ({ className }) => (
  <div className={className}>
    <div className={classes.customLoader} />
  </div>
);

export default AdminLoader;
