import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';
import { useFetchData, useModal } from '../../../../../shared/helpers/hooks';
import BusinessProfileModal from '../BusinessProfileModal';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import AdminLoader from '../../../components/AdminLoader';
import Placeholder from '../../../../../shared/assets/adminNoImage.svg';

const UserBusinessCard = ({ businessProfile, userId }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { data, fetchData } = useFetchData();
  const { data: mediaData, fetchData: fetchMediaData } = useFetchData();
  const [showModal, setShowModal, modalHandler] = useModal();
  const { showNotification } = useNotification();
  const [businessProfileImage, setBusinessProfileImage] = useState(null);
  const [businessImageLoading, setBusinessImageLoading] = useState(false);
  const [businessProfileData, setBusinessProfileData] = useState(null);
  const { id, name, address, photos, cardTotalNumber } = businessProfile;

  const onShowCardHandler = async () => {
    fetchData('/acp/client/getBusinessProfile', { userId, businessProfileId: id });
    fetchMediaData('/acp/client/mediaList', { userId, businessProfileId: id, limit: 19 });
    modalHandler();
  };

  useEffect(() => {
    const businessData = { ...data, media: mediaData };
    setBusinessProfileData(businessData);
  }, [data, mediaData]);

  useEffect(() => {
    const controller = new AbortController();
    const busienssPhotoExist = photos && photos.length;

    const fetchImage = async () => {
      setBusinessImageLoading(true);
      try {
        const { file, date } = photos[0];
        const response = await api.get(`/assets/place/${date}/${file}`, {
          signal: controller.signal,
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        });
        setBusinessProfileImage(window.URL.createObjectURL(new Blob([response.data], { type: 'image/jpg' })));
      } catch (err) {
        setBusinessProfileImage(Placeholder);
        handleApiError({ err, t, showNotification });
      } finally {
        setBusinessImageLoading(false);
      }
    };

    if (busienssPhotoExist) {
      fetchImage();
    } else {
      setBusinessProfileImage(Placeholder);
    }

    return () => {
      controller.abort();
    };
  }, [photos]);

  return (
    <div className={classes.wrapper}>
      {showModal && businessProfileData && (
        <BusinessProfileModal
          onClose={modalHandler}
          address={address}
          businessProfile={businessProfileData}
          profileImage={businessProfileImage}
          userId={userId}
          businessProfileId={id}
        />
      )}
      <div className={classes.businessCard}>
        {businessImageLoading ? (
          <AdminLoader />
        ) : (
          <img className={classes.businessImage} src={businessProfileImage} alt="User Business Card" />
        )}
        <div className={classes.businessCardText}>
          <div className={classes.businessCardName}>{name}</div>
          <div className={classes.businessCardAddress}>{address}</div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <Pill label={t('adminControlPanel.cardsSeo', { value: cardTotalNumber })} className={classes.seoCardPill} />
        <Button
          label={t('adminControlPanel.displayBusinessCard')}
          className={classes.showBusinessCardBtn}
          onClick={onShowCardHandler}
        />
      </div>
    </div>
  );
};

export default UserBusinessCard;
