import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import DragAndDrop from '../../../../../shared/components/DragAndDrop';
import Select from '../../../../../shared/components/Select';
import { useApi } from '../../../../../shared/helpers/api';
import {
  checkAspectRatioForCoverImage,
  getMediaTypeList,
  getUniqueArray,
  handleCoverImageSelection,
  MEDIA_ENUM,
  parseBackDate,
  updateMedia,
  updateMediaTypes,
} from './utils';
import Button from '../../../../../shared/components/Button';
import ProgressCircle from '../../../../../shared/components/ProgressCircle';
import Input from '../../../../../shared/components/Input';
import { useNotification } from '../../../../../shared/helpers/notification';
import SubInformation from '../../../../../shared/components/SubInformation';
import PlannedPost from '../../AddPost/PlannedPost';
import { useDashboard } from '../../../helpers/dashboard';
import { getFileExtension } from '../../../../../shared/components/DragAndDrop/utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const AddMediaModal = ({ handleModal, busienssId, onSuccess, editItem }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile } = useDashboard();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [publishDate, setPublishDate] = useState('');
  const [selectedMediaTypes, setSelectedMediaTypes] = useState({});
  const [media, setMedia] = useState([]);
  const coverIndex = Object.values(selectedMediaTypes).findIndex((type) => type === MEDIA_ENUM.COVER);
  const coverImage = media[coverIndex];
  const isPlannedBeforeToday = dayjs(publishDate).isBefore(dayjs());
  const readOnlyAddBtn = !media.length || Object.values(selectedMediaTypes).length !== media.length || isPlannedBeforeToday;
  const modalTitle = editItem ? t('businessProfileEdit.editMedia') : t('businessProfileEdit.addMedia');

  const onPublicationDateChange = (fullDate) => {
    setPublishDate(fullDate);
  };
  const showOnSelectListHandler = (value) => value?.label;

  const onSelectHandler = async (value, index) => {
    if (!(await checkAspectRatioForCoverImage(media[index])) && value === MEDIA_ENUM.COVER) {
      setSelectedMediaTypes((prev) => updateMediaTypes(prev, index, MEDIA_ENUM.ADDITIONAL));
      setMedia((prev) => updateMedia(prev, index, MEDIA_ENUM.ADDITIONAL));
      showNotification({ type: 'error', message: t('businessProfileEdit.coverImageAspectRatioError') });
      return;
    }
    if (value === MEDIA_ENUM.COVER) {
      handleCoverImageSelection(value, index, selectedMediaTypes, setSelectedMediaTypes, setMedia);
    } else {
      setSelectedMediaTypes((prev) => updateMediaTypes(prev, index, value));
      setMedia((prev) => updateMedia(prev, index, value));
    }
  };

  const uploadMedia = async (item, index) => {
    const progressArray = new Array(media.length).fill(0);
    const controller = new AbortController();
    let apiPath = '/media/createMedia';
    const category = selectedMediaTypes[index] === 'VIDEO' ? 'ADDITIONAL' : selectedMediaTypes[index];
    const payload = {
      businessProfileId: busienssId,
      media: item.mediaSource,
      category,
    };
    if (dayjs(publishDate).isValid()) {
      payload.publishDate = publishDate;
      apiPath = '/media/createPostponedMedia';
    }
    await api.post(apiPath, payload, {
      signal: controller.signal,
      onUploadProgress: (progressEvent) => {
        const progress = Number((progressEvent.loaded / progressEvent.total) * 100).toFixed(1);
        progressArray[index] = parseFloat(progress);
        const totalProgress = progressArray.reduce((a, b) => a + b, 0) / media.length;
        setUploadProgress(totalProgress.toFixed(1));
      },
    });
  };
  const editMedia = async () => {
    setIsLoading(true);
    try {
      await api.post('/media/update', {
        mediaId: media[0].id,
        businessProfileId: businessProfile.id,
        publishDate,
      });
      onSuccess(true);
      showNotification({ type: 'success', message: t('businessProfileEdit.postponedMediaDataChange') });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      handleModal();
      setIsLoading(false);
    }
  };
  const addMediaHandler = async () => {
    setIsLoading(true);
    try {
      await Promise.allSettled(media.map(uploadMedia));
      onSuccess(false);
      setPublishDate(null);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      handleModal();
      setIsLoading(false);
    }
  };

  const onMediaChangeHandler = (file) => {
    setMedia((prev) => {
      const unique = getUniqueArray(prev, file);
      return unique;
    });
  };

  const onLocaleImageRemover = (file) => {
    const foundMediaIndex = media.findIndex((el) => el.id === file.id);
    setMedia((prev) => prev.filter((el) => el.id !== file.id));
    setSelectedMediaTypes((prev) => {
      const newSelectedMediaTypes = { ...prev };
      delete newSelectedMediaTypes[foundMediaIndex];
      return newSelectedMediaTypes;
    });
  };

  useEffect(() => {
    const isVideo = media.some((el) => el.extension === 'mp4');
    if (isVideo) {
      setSelectedMediaTypes((prev) => {
        const newSelectedMediaTypes = media.map((el, index) => (el.extension === 'mp4' ? MEDIA_ENUM.VIDEO : prev[index]),);
        return newSelectedMediaTypes;
      });
    }
  }, [media]);

  useEffect(() => {
    if (editItem) {
      const parsedEditItem = [editItem].map((el) => ({
        ...el,
        mediaSource: el.link,
        filename: el.name,
        extension: getFileExtension(el.name),
      }));
      setMedia(parsedEditItem);
      setPublishDate(parseBackDate(editItem.publishDate));
      setSelectedMediaTypes({ 0: editItem.category });
    }
  }, [editItem]);

  useEffect(() => {
    const checkRationHandler = async () => {
      const coverImageCorrectAspectRatio = await checkAspectRatioForCoverImage(coverImage);
      if (!coverImageCorrectAspectRatio) {
        setSelectedMediaTypes((prev) => ({ ...prev, [coverIndex]: MEDIA_ENUM.ADDITIONAL }));
        showNotification({ type: 'error', message: t('businessProfileEdit.coverImageAspectRatioError') });
      }
    };
    checkRationHandler();
  }, [coverImage]);

  if (isLoading && !editItem) {
    return (
      <div className={classes.progressWrapper}>
        <ProgressCircle progress={uploadProgress} />
      </div>
    );
  }
  return (
    <Modal
      rwdBackTitle={t('global.goBack')}
      contentStyle={classes.modalContent}
      onCancel={handleModal}
      modalTitle={modalTitle}
    >
      <div className={classes.modalContentWrapper}>
        <div className={classes.mediaTypes}>
          {media.map((el, index) => (
            <div key={el.filename} className={classes.mediaDetailWrapper}>
              <Input className={classes.fileName} readOnly value={el.filename} />
              <Select
                className={classes.select}
                label={t('businessProfileEdit.photoType')}
                list={getMediaTypeList(t, el)}
                onSelect={(selectedValue) => onSelectHandler(selectedValue, index)}
                showOnList={showOnSelectListHandler}
                value={selectedMediaTypes[index]}
                readOnly={selectedMediaTypes[index] === 'VIDEO' || Boolean(editItem)}
              />
            </div>
          ))}
        </div>
        <DragAndDrop
          onFileRemove={onLocaleImageRemover}
          maxFilesNumber={5}
          files={media}
          onChange={onMediaChangeHandler}
          className={classes.dragAndDrop}
          supportingText={t('businessProfileEdit.learnMoreAboutMedia')}
          btnLabel={t('businessProfileEdit.addMedia')}
          withVideo
          isEdit={Boolean(editItem)}
        />
        {Boolean(media.length) && (
          <SubInformation className={classes.helperText} label={t('businessProfileEdit.mediaHelperText')} />
        )}
      </div>
      <PlannedPost plannedPublishDate={publishDate} onPublicationDateChange={onPublicationDateChange} media />
      {isPlannedBeforeToday && (
        <div className={classes.error}>{t('businessProfileEdit.postoponedMediaMustBeAfterToday')}</div>
      )}
      <div className={classes.actions}>
        <Button className={classes.cancelBtn} label={t('global.cancel')} onClick={handleModal} />
        <Button
          readOnly={readOnlyAddBtn}
          className={classes.addBtn}
          label={editItem ? t('global.edit') : t('global.add')}
          onClick={editItem ? editMedia : addMediaHandler}
        />
      </div>
    </Modal>
  );
};

export default AddMediaModal;
