import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Pill from '../../../../shared/components/Pill';
import { formatDate } from '../../../Admin/views/AdministratorList/utils';
import classes from './styles.module.css';

export const seoCardStatusLabel = (active, t) => {
  const activePillLabel = active ? t('seoCard.cardActive') : t('seoCard.cardInactive');
  return activePillLabel;
};
const getFullCreatedAt = (date) => dayjs(date).locale('pl').format('DD MMMM YYYY');
export const clinetSeoCardListParser = (data, t) => data.map((item) => ({
  ...item,
  key: item.id,
  cardName: item.cardName,
  interaction: item.interaction || '-',
  lastUsed: formatDate(item.lastUsed) === 'Invalid Date' ? '-' : formatDate(item.lastUsed),
  createdAt: formatDate(item.createdAt) === 'Invalid Date' ? '-' : formatDate(item.createdAt),
  createdAtCardDetails: formatDate(item.createdAt) === 'Invalid Date' ? '-' : getFullCreatedAt(item.createdAt),
  bpName: item.bpName || '-',
  activePill: (
    <Pill
      className={clsx(classes.pill, item.active && classes.activeStatusPill)}
      label={seoCardStatusLabel(item.active, t)}
    />
  ),
}));
