import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import Tooltip from '../../../../../shared/components/Tooltip';
import { timesFromAllDay } from '../AdditionalHours/utils';

const AdditionalHoursCards = ({ additionalHours, onSelectHandler, isEdit, adminPreview }) => {
  const { t } = useTranslation();

  const parsedAdditionalHours = additionalHours.periods.map((period) => ({ ...period }));

  return (
    <div className={classes.moreHoursWrapper}>
      <div className={classes.header}>
        <div className={classes.moreHourName}>{additionalHours.localizedDisplayName}</div>
        {!adminPreview && (
          <Tooltip tooltipStyle={classes.tooltipStyle} text={t('businessProfileEdit.inOrderToEdit')} isEdit={isEdit}>
            <Button
              label={t('businessProfileEdit.edit')}
              className={classes.editMoreHours}
              onClick={() => onSelectHandler(additionalHours.hoursTypeId)}
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.moreHours}>
        {parsedAdditionalHours.map((period) => (
          <div className={classes.moreHoursPeriod}>
            <div className={classes.periodName}>{period.name}</div>
            {period.value ? (
              <div className={classes.hour}>{t('businessProfileEdit.closed')}</div>
            ) : (
              period.hours.map((hour) => (
                <div className={classes.moreHoursHour}>
                  {!timesFromAllDay.includes(hour.from) || !timesFromAllDay.includes(hour.to) ? (
                    <div className={classes.hour}>{`${hour.from} - ${hour.to}`}</div>
                  ) : (
                    <div className={classes.hour}>{t('businessProfileEdit.allDay')}</div>
                  )}
                </div>
              ))
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default AdditionalHoursCards;
