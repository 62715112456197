import { POSTAL_CODE_REGEX_PATTERN, } from '../../../helpers/regex';

export const parseAreas = (areas) => {
  const areaWithPostlaCode = areas.some((el) => el.placeName.match(POSTAL_CODE_REGEX_PATTERN));
  if (areaWithPostlaCode) {
    const parsedAreas = areas.map((el) => {
      const placeName = el.placeName.replace(POSTAL_CODE_REGEX_PATTERN, '').trim();
      return { placeName, placeId: el.placeId };
    });
    return parsedAreas;
  }
  return areas;
};
