import React from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '../../../../../shared/components/Radio';
import classes from './styles.module.css';

const KeywordsOptions = ({ onChange, value, options, label }) => {
  const { t } = useTranslation();
  const handleClick = (id) => (optionValue) => {
    if (!optionValue) {
      onChange(id);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>{label}</div>
      {options.length ? (
        <div className={classes.list}>
          {options.map((el) => (
            <div key={el.id} onClick={() => onChange(el.id)} className={classes.optionWrapper}>
              {el.value}
              <Radio labelPosition="left" onClick={handleClick(el.id)} value={el.id === value}/>
            </div>
          ))}
        </div>
      ) : (
        <div className={classes.empty}>{t('generateReport.nothingToSelect')}</div>
      )}
    </div>
  );
};

export default KeywordsOptions;
