import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';
import {
  displayPeriodHandler,
  getSelectedMonths,
  getSelectedPeriod,
  getSixMonthsBack,
  handleMonthSelection,
} from './utils';
import { ReactComponent as Close } from '../../../../../shared/assets/close.svg';
import { useCloseOnOutsideClick } from '../../../../../shared/helpers/hooks';

const Calendar = ({ onCancel, onPeriodChange }) => {
  const { t } = useTranslation();
  const [selectedMonths, setSelectedMonths] = useState([]);
  const calendarRef = useRef();
  const allMonthsSixMonthsBack = getSixMonthsBack();

  const displayPeriod = displayPeriodHandler(selectedMonths);
  const monthNumbersList = useMemo(() => getSelectedMonths(selectedMonths), [selectedMonths]);

  const selectMonthHandler = (month) => {
    handleMonthSelection(setSelectedMonths, monthNumbersList, selectedMonths, month);
  };

  const handlePeriodChange = (useDefaultLabel = false) => {
    const data = getSelectedPeriod(selectedMonths);
    onPeriodChange(useDefaultLabel ? { displayPeriod: t('dashboard.last30Days'), data } : { displayPeriod, data });
    onCancel();
  };

  useCloseOnOutsideClick(calendarRef, onCancel);

  return (
    <div ref={calendarRef} className={classes.wrapper}>
      <div className={classes.RWDHeader}>
        <div className={classes.RWDTitle}>{t('dashboard.pickDate')}</div>
        <Close onClick={onCancel} className={clsx(classes.closeIcon)} />
      </div>
      <div className={classes.title}>{t('dashboard.calendarTitle')}</div>
      <div className={classes.monthsWrapper}>
        {allMonthsSixMonthsBack.map((month) => (
          <div
            className={clsx(
              classes.pillWrapper,
              monthNumbersList.includes(month.monthNumber) && classes.selectedPillWrapper,
            )}
            onClick={() => selectMonthHandler(month)}
            key={month.monthNumber}
          >
            <Pill
              className={clsx(classes.monthPill, monthNumbersList.includes(month.monthNumber) && classes.selectedPill)}
              label={month.label}
            />
          </div>
        ))}
      </div>
      <div className={classes.selectedMonthWrapper}>
        <div className={classes.selectedMonthsTitle}>{t('dashboard.selectedDates')}</div>
        {!selectedMonths.length ? (
          <div className={classes.emptySelectedMonths}>{t('dashboard.noSelectedDates')}</div>
        ) : (
          <div className={classes.selectedMonths}>{displayPeriod}</div>
        )}
      </div>
      <div className={classes.actionsWrapper}>
        <Button
          onClick={() => handlePeriodChange(true)}
          className={clsx(classes.btn, classes.cancel)}
          label={t('global.cancel')}
        />
        <Button
          onClick={() => handlePeriodChange(false)}
          readOnly={!selectedMonths.length}
          className={classes.btn}
          label={t('dashboard.showPeriod')}
        />
      </div>
    </div>
  );
};

export default Calendar;
