import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';

const DashboardNoProfiles = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToCreateBusinessProfile = () => {
    navigate('/createBusinessProfile');
  };
  const navigateToImportBusinessProfile = () => {
    navigate('/pick_business_profiles');
  };

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.title}>{t('dashboard.noProfilesTitle')}</div>
      <div className={classes.subtitle}>{t('dashboard.noProfilesSubtitle')}</div>
      <div className={classes.actions}>
        <Button className={classes.btn} label={t('dashboard.createBusinessProfile')} onClick={navigateToCreateBusinessProfile} />
        <Button className={classes.btn} label={t('dashboard.importBusinessProfile')} onClick={navigateToImportBusinessProfile} />
      </div>
    </div>
  );
};
export default DashboardNoProfiles;
