import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { navLinks } from './utils';
import Button from '../../../../shared/components/Button';
import { ReactComponent as LogoIcon } from '../../../../shared/assets/logoWhite.svg';
import { ReactComponent as BurgerMenu } from '../../../../shared/assets/menu.svg';
import { ReactComponent as CloseIcon } from '../../../../shared/assets/close.svg';
import MobileNav from '../MobileNav';
import BetaSign from '../../../../shared/components/BetaSign';

const NavigationBar = ({ className, hideSectionList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showMobileNav, setShowMobileNav] = useState(false);

  const handleNavigation = (destination) => {
    navigate(destination);
  };

  const toogleMobileNav = () => {
    setShowMobileNav((prev) => !prev);
  };

  return (
    <nav className={clsx(classes.navWrapper, className)}>
      <div className={classes.logoWrapper}>
        <LogoIcon className={classes.logoIcon} onClick={() => handleNavigation('/')}/>
        <BetaSign className={classes.logoSign} />
      </div>
      {!hideSectionList && (
      <ul className={classes.navLinks}>
        {navLinks(t).map(({ title, url }) => (
          <li key={url}>
            <a href={url}>{title}</a>
          </li>
        ))}
      </ul>
      )}
      <div className={classes.actionsWrapper}>
        <Button label={t('global.signIn')} className={classes.button} onClick={() => handleNavigation('login')} />
        <Button
          label={t('landingPage.signUpForFree')}
          className={clsx(classes.button, classes.signUpBtn)}
          onClick={() => handleNavigation('register_methods')}
        />
        {showMobileNav ? (
          <CloseIcon className={classes.burgerMenu} onClick={toogleMobileNav} />
        ) : (
          <BurgerMenu className={classes.burgerMenu} onClick={toogleMobileNav} />
        )}
      </div>
      {showMobileNav && <MobileNav onClose={toogleMobileNav} />}
    </nav>
  );
};

export default NavigationBar;
