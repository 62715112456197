import React from 'react';
import { useTranslation } from 'react-i18next';
import StatisticBox from '../StatisticBox';

const StatisticBoxes = ({ statisticData, status }) => {
  const { t } = useTranslation();

  return statisticData.map((el) => (
    <StatisticBox
      key={el.title}
      Icon={el.Icon}
      toolTipTitle={t(el.toolTipTitle)}
      comparison={el.comparison.toFixed(1)}
      title={t(el.title)}
      value={el.value}
      tooltipText={t(el.toolTipText)}
      status={status}
      noHighlight
    />
  ));
};

export default StatisticBoxes;
