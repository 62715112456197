import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import classes from './styles.module.css';

const Day = ({
  date,
  selectedStartDate,
  selectedEndDate,
  handleDayClick,
  futureDaysNotAvailable,
  admin,
  availableStartDate,
}) => {
  const today = dayjs();
  const isFutureDay = date.isAfter(today, 'day');
  const isPastDay = Boolean(availableStartDate) && date.isBefore(availableStartDate, 'day');
  const isSelectedDay = date.isSame(selectedStartDate, 'day') || date.isSame(selectedEndDate, 'day');
  const isBetweenDays = date.isAfter(selectedStartDate, 'day') && date.isBefore(selectedEndDate, 'day');
  const isToday = date.isSame(today, 'day');
  const displayFutureDay = (futureDaysNotAvailable && isFutureDay) || (Boolean(availableStartDate) && isPastDay);

  const dayClassName = clsx(
    classes.day,
    isSelectedDay && classes.selectedDay,
    isBetweenDays && classes.betweenDays,
    isToday && classes.today,
    displayFutureDay && classes.futureDayNotAvailable,
    admin && classes.admin,
  );

  const handleClick = () => {
    if ((!futureDaysNotAvailable || !isFutureDay) && !isPastDay) {
      handleDayClick(date);
    }
  };

  return (
    <div className={dayClassName} onClick={handleClick}>
      {date.date()}
    </div>
  );
};

export default Day;
