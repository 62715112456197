import { handleApiError } from '../ChangePassword/utils';

export const CREATE_CARD_PATH = '/dashboard/createBusinessProfile';

export const fetchNewData = async ({ api, showNotification, t }) => {
  const controller = new AbortController();
  try {
    await api.post('/account/getAccountDetails', {}, { controller: controller.signal });
  } catch (err) {
    handleApiError({ err, showNotification, t });
  }
  return () => {
    controller.abort();
  };
};

export const searchHandler = (filtredValue, setImportedProfiles, businessProfiles) => {
  if (!filtredValue.length) {
    setImportedProfiles(businessProfiles);
  } else {
    setImportedProfiles(filtredValue);
  }
};

export const onSelectFilterHandler = (
  value,
  selectList,
  setImportedProfiles,
  businessProfiles,
  setSelectFilterValue,
) => {
  const foundSelect = selectList.find((item) => item.id === value);
  setSelectFilterValue(value);
  if (foundSelect.value === 'all') {
    setImportedProfiles(businessProfiles);
    return;
  }
  setImportedProfiles(businessProfiles.filter((item) => item.status?.id === foundSelect.value));
};

export const checkboxClickHandler = (
  id,
  selectedBusinessProfiles,
  setSelectedBusinessProfiles,
  selectAllowed,
  toggleSelectAll,
) => {
  if (selectedBusinessProfiles.includes(id)) {
    setSelectedBusinessProfiles((prev) => {
      const updatedProfiles = prev.filter((item) => item !== id);
      if (updatedProfiles.length === 0) {
        toggleSelectAll();
      }
      return updatedProfiles;
    });
  } else {
    setSelectedBusinessProfiles((prev) => [...prev, id]);
    if (!selectAllowed) {
      toggleSelectAll();
    }
  }
};

export const paginationLimitList = [5, 10, 15];
