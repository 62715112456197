import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../../../../shared/components/SearchInput';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as CalendarIcon } from '../../../../../shared/assets/calendar.svg';
import classes from './styles.module.css';
import { useModal } from '../../../../../shared/helpers/hooks';
import Calendar from '../../../../../shared/components/Calendar';
import { getSelectDateLabel } from '../../../../Admin/modules/SeoCardDetails/CardStatistic/utils';
import { MIN_SEARCH_LENGTH } from '../../../../Admin/views/AdministratorList/utils';
import { debounce } from '../../../../../shared/helpers/debounce';

const FilterTools = ({ onChange, filters }) => {
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar, toggleCalendar] = useModal();
  const { dateFrom, dateTo } = filters;

  const btnLabel = getSelectDateLabel({ dateTo, dateFrom }, t);

  const handleDateChange = (date) => {
    onChange('date')(date);
    toggleCalendar();
  };

  const onCloseCalendar = () => {
    onChange('date')({});
    toggleCalendar();
  };

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload) {
        onChange('search')('');
      }
      if (payload.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      onChange('search')(payload);
      return [];
    }, 500),
    [],
  );

  return (
    <div className={classes.toolsWrapper}>
      {showCalendar && (
        <Calendar
          className={classes.calendar}
          quickSelect
          futureDaysNotAvailable
          onClose={onCloseCalendar}
          onChange={handleDateChange}
          selectedDate={filters.dateFrom}
          selectedEndPeriodDate={filters.dateTo}
          filters={filters}
        />
      )}
      <SearchInput className={classes.searchInputStyle} label={t('seoCard.searchCard')} onSearch={handleSearchbar} />
      <Button className={classes.pickDateBtn} Icon={CalendarIcon} label={btnLabel} onClick={toggleCalendar} />
    </div>
  );
};

export default FilterTools;
