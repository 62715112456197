import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { phoneNumberValidator, urlValidator } from '../../../../../shared/components/Input/validators';
import { phoneNumberTransformer, urlTransformer } from '../../../../../shared/components/Input/transformers';
import { getOptionalInformationMessage } from './utils';
import { formEnum } from '../WelcomeScreenCreate/utils';
import { POLISH_PHONE_PREFIX } from '../../../helpers/constants';
import { ReactComponent as ContactFormSvg } from '../../../../../shared/assets/businessContact.svg';

const FormContactData = ({ form, onChange, scrollOnFocus, readOnlyHandler }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);

  const { businessType } = form.serviceArea;
  const phoneNumberValueWithoutPrefix = form.phoneNumbers.primaryPhone?.slice(POLISH_PHONE_PREFIX.length);
  const { message, requierdField } = getOptionalInformationMessage(businessType);

  const errorHandler = (value) => setError(Boolean(value));

  useEffect(() => {
    const isRequiredFieldEmpty = requierdField && !(form.websiteUri?.length || phoneNumberValueWithoutPrefix?.length);
    readOnlyHandler(isRequiredFieldEmpty || error);
  }, [requierdField, error, form.websiteUri, phoneNumberValueWithoutPrefix]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftSide}>
        <div className={classes.form}>
          <div className={classes.title}>{t('businessProfileCreate.contactFormTitle')}</div>
          <div className={classes.inputs}>
            <div className={classes.phoneNumberContainer}>
              <Input
                label={t('businessProfileCreate.prefix')}
                value={POLISH_PHONE_PREFIX}
                readOnly
                className={classes.prefix}
              />
              <Input
                transformer={phoneNumberTransformer}
                className={classes.phoneValue}
                validator={phoneNumberValidator}
                onChange={onChange(formEnum.phoneNumbers)}
                label={t('businessProfileEdit.phoneNumber')}
                value={phoneNumberValueWithoutPrefix}
                onError={errorHandler}
                showErrorAfterBlur
              />
            </div>
            <div onFocus={scrollOnFocus}>
              <Input
                validator={urlValidator}
                showErrorAfterBlur
                transformer={urlTransformer}
                label={t('businessProfileEdit.companyWebsite')}
                onChange={onChange(formEnum.websiteUri)}
                value={form.websiteUri}
                onError={errorHandler}
              />
            </div>
          </div>
          <div className={classes.optionalInformation}>{t(message)}</div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <ContactFormSvg />
      </div>
    </div>
  );
};

export default FormContactData;
