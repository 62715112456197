import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import Button from '../../../../../shared/components/Button';
import { useDashboard } from '../../../helpers/dashboard';
import { readOnlySubList } from '../../../helpers/constants';
import NoAccessInformation from '../../../components/NoAccessInformation';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';

const PostsEmptyState = ({ createPost, tab, adminPreview }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { subscription }
  } = useDashboard();
  const headerText = tab === 'published' ? t('posts.addFirstPost') : t('posts.addFirstPlannedPost');
  const handleCreatePost = () => createPost();

  const isReadOnlySubPlan = readOnlySubList.includes(subscription);
  const subInfomationText = adminPreview
    ? t('adminControlPanel.noLocalPostsToDisplay')
    : t('posts.additionalInformation');

  return (
    <div className={classes.wrapper}>
      {isReadOnlySubPlan ? (
        <NoAccessInformation />
      ) : (
        <>
          <CircleIconText
            icon={Magnifier}
            heading={headerText}
            supportingText={subInfomationText}
            headingStyle={classes.headingStyle}
          />
          {!adminPreview && (
            <Button
              onClick={handleCreatePost}
              className={classes.button}
              Icon={AddCircle}
              iconStyle={classes.iconStyle}
              label={t('posts.addPost')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PostsEmptyState;
