import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import DashboardSidebar from '../DashboardSidebar';
import DashboardNoProfiles from '../DashboardNoProfiles';
import DashboardTopbar from '../DashboardTopbar';
import { parseProfileStatus } from './utils';
import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';
import { ERROR_LIST_ENUM, MAX_TABLET_WIDTH } from '../../helpers/constants';
import { getLocalStorageProfileStatus } from '../../../../shared/helpers/helpers';
import { NotificationContext } from '../../../../shared/helpers/notification';
import { DashboardContext } from '../../helpers/dashboard';
import BusinessProfileSidebar from '../BusinessProfileSidebar';
import Notification from '../../../../shared/components/Notification';
import { useWindowResize } from '../../../../shared/helpers/hooks';

const DashboardLayout = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { api } = useApi();
  const { businessProfiles } = useAuth();
  const [selectedBusinessProfile, setSelectedBusinessProfile] = useState({});
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [showBusinessCards, setShowBusinessCards] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const innerWidth = useWindowResize();
  const showOutlet = !mobileNavOpen && !showBusinessCards;
  const toggleBusinessCards = () => setShowBusinessCards((prev) => !prev);
  const toggleMobileNav = () => setMobileNavOpen((prev) => !prev);

  const showBusinessCardListHandler = () => {
    if (mobileNavOpen) {
      toggleMobileNav();
    }
    toggleBusinessCards();
  };

  const handleClose = () => {
    setNotificationQueue((prev) => {
      const [first, ...rest] = prev;
      return rest;
    });
  };

  const businessProfileSelectHandler = (businessID) => {
    setShowBusinessCards(false);
    const foundBusiness = businessProfiles.find((business) => business.id === businessID) || {};
    setSelectedBusinessProfile(foundBusiness);
  };

  const mobileMenuHandler = () => {
    if (showBusinessCards) {
      showBusinessCardListHandler();
    }
    if (innerWidth <= MAX_TABLET_WIDTH) {
      toggleMobileNav();
    }
  };
  const providerValue = useMemo(() => {
    const showNotification = (notification) => {
      setNotificationQueue((prev) => [...prev, { ...notification, key: Math.random() }]);
    };
    return { showNotification };
  }, []);

  const selectBusinessProfileHandler = (businessProfileId) => {
    const foundProfile = businessProfiles.find((el) => el.id === businessProfileId);
    const { externalBusinessProfile } = foundProfile;
    const foundProfileStatus = getLocalStorageProfileStatus(businessProfileId);
    if (!foundProfile) {
      providerValue.showNotification({
        message: t('dashboard.businessProfileNotFound'),
        type: 'error',
      });
      return;
    }
    if (externalBusinessProfile) {
      navigate('/dashboard/report_list');
    }
    setSelectedBusinessProfile({ ...foundProfile, status: foundProfileStatus });
  };

  const dashboardValue = useMemo(
    () => ({
      businessProfile: selectedBusinessProfile,
      selectBusinessProfile: selectBusinessProfileHandler,
    }),
    [businessProfiles, selectedBusinessProfile],
  );

  const setSelectedProfileInStorage = (id) => {
    localStorage.setItem('selectedProfile', id);
  };

  useEffect(() => {
    if (!businessProfiles?.length) return;
    const matchedProfile = businessProfiles?.find(
      (el) => el.id === Number(state?.businessProfileId) || el.id === selectedBusinessProfile.id,
    );
    if (matchedProfile) {
      setSelectedBusinessProfile(matchedProfile);
      setSelectedProfileInStorage(matchedProfile.id);
    } else if (businessProfiles.length) {
      setSelectedBusinessProfile(businessProfiles[0]);
      setSelectedProfileInStorage(businessProfiles[0].id);
    }
  }, [businessProfiles, state]);

  useEffect(() => {
    const profileStatusInStorage = getLocalStorageProfileStatus(selectedBusinessProfile.id);
    const shouldFetchStatus = selectedBusinessProfile.id && !profileStatusInStorage && !selectedBusinessProfile.externalBusinessProfile;
    const fetchProfileStatus = async () => {
      const controller = new AbortController();
      try {
        const { data } = await api.post(
          '/business_profile/getVerificationStatus',
          { businessProfileId: selectedBusinessProfile.id },
          { signal: controller.signal },
        );
        const parsedStatus = parseProfileStatus(data, t);
        localStorage.setItem(`status-${selectedBusinessProfile.id}`, parsedStatus.id);
        setSelectedBusinessProfile((prev) => ({ ...prev, status: parsedStatus }));
      } catch (err) {
        if (err.response.data.code === ERROR_LIST_ENUM.NOT_FOUND_REQUESTED_ENTITY) {
          providerValue.showNotification({
            message: t('dashboard.businessProfileNotFoundActionDelete'),
            type: 'error',
          });
          await api.post('/business_profile/detachBusinessProfiles', {
            businessProfilesId: [selectedBusinessProfile.id],
          });
        }
      }
    };
    if (shouldFetchStatus) {
      fetchProfileStatus();
    }
  }, [selectedBusinessProfile.id]);

  return (
    <div className={classes.wrapper}>
      <NotificationContext.Provider value={providerValue}>
        <DashboardContext.Provider value={dashboardValue}>
          <BusinessProfileSidebar />
          {Boolean(businessProfiles?.length) && (
            <DashboardSidebar
              showList={showBusinessCardListHandler}
              showBusinessCards={showBusinessCards}
              onBusinessProfileSelect={businessProfileSelectHandler}
              businessProfiles={businessProfiles}
              mobileNavVisible={mobileNavOpen}
              onMobileMenuClick={mobileMenuHandler}
            />
          )}
          <div className={classes.section}>
            <DashboardTopbar />
            {showOutlet && (
              <div className={classes.rwdBox}>
                {businessProfiles?.length ? <Outlet key={selectedBusinessProfile.id} /> : <DashboardNoProfiles />}
              </div>
            )}
          </div>
          {notificationQueue[0] && (
            <Notification
              label={notificationQueue[0].label}
              message={notificationQueue[0].message}
              type={notificationQueue[0].type}
              payload={notificationQueue[0].payload}
              key={notificationQueue[0].key}
              onClose={handleClose}
            />
          )}
        </DashboardContext.Provider>
      </NotificationContext.Provider>
    </div>
  );
};

export default DashboardLayout;
