import React from 'react';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import CreateBusinessProfileTextWrapper from '../CreateBusinessProfileTextWrapper';

const CheckboxOptionsWrapper = ({ SVG, title, subtitle, options, onClick, checkBoxValue }) => (
  <div className={classes.wrapper}>
    <div className={classes.leftSide}>
      <div className={classes.form}>
        <div className={classes.formTitle}>
          <CreateBusinessProfileTextWrapper title={title} subTitle={subtitle} />
          <div className={classes.optionsWrapper}>
            {options.map(({ value, label }) => (
              <Checkbox
                key={label}
                onClick={() => onClick(value)}
                value={value === checkBoxValue}
                checkboxStyle={classes.checkboxStyle}
                labelPosition="right"
                className={classes.checkbox}
                label={label}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className={classes.rightSide}>
      <SVG />
    </div>
  </div>
);

export default CheckboxOptionsWrapper;
