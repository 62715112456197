import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxOptionsWrapper from '../CheckboxOptionsWrapper';
import { deliveryOptions, onCheckboxClick } from './utils';
import { ReactComponent as DeliveryFormSvg } from '../../../../../shared/assets/deliveryForm.svg';

const FormBusinessDelivery = ({ onChange, onMetaDataChange, form, metadata }) => {
  const { t } = useTranslation();

  const [checkBoxValue, setCheckBoxValue] = useState(null);

  const checkBoxValueHandler = (value) => {
    onCheckboxClick({ value, onChange, onMetaDataChange, setCheckBoxValue });
  };

  const deliveryOptionsArr = useMemo(() => deliveryOptions(checkBoxValueHandler, t), [checkBoxValue, metadata]);

  useEffect(() => {
    if (metadata.deliveryArea !== undefined) {
      setCheckBoxValue(form.serviceArea.businessType);
    }
  }, [metadata.deliveryArea]);

  return (
    <CheckboxOptionsWrapper
      SVG={DeliveryFormSvg}
      checkBoxValue={checkBoxValue}
      onClick={checkBoxValueHandler}
      options={deliveryOptionsArr}
      title={t('businessProfileCreate.doYouHaveDelivery')}
      subtitle={t('businessProfileCreate.doYouHaveDeliverySubInfo')}
    />
  );
};

export default FormBusinessDelivery;
