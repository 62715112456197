import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { hoverStateHandler, options } from './utils';
import WelcomeItem from '../../modules/Welcome/WelcomeItem';

const WelcomeView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [onHover, setOnHover] = useState([
    { id: options[0].id, hover: false },
    { id: options[1].id, hover: false },
  ]);

  const handleRedirect = (route) => {
    navigate(route);
  };

  const handleHover = (state, id) => {
    setOnHover((prev) => prev.map((item) => (item.id === id ? { ...item, hover: state } : item)));
  };

  return (
    <div className={classes.wrapper}>
      <span className={classes.headerText}>{t('welcome.header')}</span>
      <div className={classes.boxes}>
        {options.map((item) => (
          <WelcomeItem
            key={item.id}
            item={item}
            handleHover={handleHover}
            handleRedirect={() => handleRedirect(item.route)}
            hoverStateHandler={hoverStateHandler}
            onHover={onHover}
          />
        ))}
      </div>
    </div>
  );
};

export default WelcomeView;
