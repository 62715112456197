import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { ReactComponent as ArrowBack } from '../../../../shared/assets/arrowAlternative.svg';

const ViewTitle = ({ title, withArrowBack }) => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };
  return (
    <div className={classes.wrapper}>
      {withArrowBack && <ArrowBack className={classes.backIcon} onClick={goBackHandler} />}
      <div className={classes.title}>{title}</div>
    </div>
  );
};

export default ViewTitle;
