import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { clientDataConfig, renderInputComponent } from './utils';

const ClientData = ({ clientData, onFormChange, onError }) => {
  const { t } = useTranslation();
  const clientDataFields = clientDataConfig(t);

  return (
    <div className={classes.wrapper}>
      <div className={classes.clientDataHeading}>{t('adminControlPanel.clientData')}</div>
      <div className={classes.clientData}>
        {clientDataFields.map((field) => renderInputComponent({ field, form: clientData, onInputChange: onFormChange, onError }),)}
      </div>
    </div>
  );
};

export default ClientData;
