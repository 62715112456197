import React from 'react';
import classes from './styles.module.css';
import LoaderSpinner from '../../../../../shared/components/LoaderSpinner';

const LoaderComponent = ({ message }) => (
  <div className={classes.wrapper}>
    <div className={classes.text}>{message}</div>
    <LoaderSpinner className={classes.loader}/>
  </div>
);
export default LoaderComponent;
