import React from 'react';
import classes from './styles.module.css';
import ReviewerBox from '../ReviewerBox';
import OpinionItemText from '../OpinionItemText';

const OpinionItem = ({
  opinion,
  onEditClick,
  deleteReplyHandler,
  activeOpinionId,
  onReplyClick,
  modalHandler,
  selectedTemplate,
  textareaVisibilityHandler,
  fetchOpinions,
  replyEditMode,
  businessProfile,
  selectAllowed,
  onSelectReviewHandler,
  selectedReviews,
  adminPreview,
}) => (
  <div key={opinion.id} className={classes.opinionWrapper}>
    <ReviewerBox
      opinion={opinion}
      selectAllowed={selectAllowed}
      onSelectReviewHandler={onSelectReviewHandler}
      selectedReviews={selectedReviews}
    />
    <OpinionItemText
      activeOpinionId={activeOpinionId}
      onReplyClick={onReplyClick}
      modalHandler={modalHandler}
      selectedTemplate={selectedTemplate}
      textareaVisibilityHandler={textareaVisibilityHandler}
      fetchOpinions={fetchOpinions}
      replyEditMode={replyEditMode}
      opinion={opinion}
      onEditClick={onEditClick}
      deleteReplyHandler={deleteReplyHandler}
      businessProfile={businessProfile}
      adminPreview={adminPreview}
    />
  </div>
);

export default OpinionItem;
