import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { ReactComponent as Logout } from '../../assets/logoutv2.svg';
import { ReactComponent as Settings } from '../../assets/settings.svg';
import Modal from '../Modal';
import { useApi } from '../../helpers/api';
import { handleApiError } from '../../../app/SEO/modules/Settings/ChangePassword/utils';
import { useAuth } from '../../helpers/auth';
import { useNotification } from '../../helpers/notification';

const SidebarUserMenu = ({ toggleUserMenu }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api, onLogout } = useApi();
  const { clearAuth } = useAuth();
  const { showNotification } = useNotification();

  const navigateToSettings = () => {
    navigate('/dashboard/settings');
    toggleUserMenu();
  };
  const handleLogout = async () => {
    try {
      await api.post('/auth/logout', {});
      clearAuth();
      onLogout();
      toggleUserMenu();
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };
  return (
    <Modal modalTitle={t('global.settings')} onCancel={toggleUserMenu}>
      <div className={classes.options}>
        <div onClick={navigateToSettings}>
          <Settings className={classes.optionIcon} />
          {t('dashboard.settings')}
        </div>
        <div onClick={handleLogout}>
          <Logout className={classes.optionIcon} />
          {t('dashboard.logout')}
        </div>
      </div>
    </Modal>
  );
};

export default SidebarUserMenu;
