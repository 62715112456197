export const getStrokeColors = (progress) => {
  if (progress < 0.25) {
    return 'var(--red500)';
  }
  if (progress < 0.7) {
    return 'var(--secondary500)';
  }
  if (progress < 0.9) {
    return 'var(--yellow500)';
  }
  return 'var(--green500)';
};
