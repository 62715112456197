import React from 'react';
import classes from './styles.module.css';
import { stateEnum } from '../PlaceElement/utils';
import Loader from '../../../components/Loader';
import { ReactComponent as ImageHolder } from '../../../../../shared/assets/image.svg';

const PlacePhoto = ({ state, photo, name }) => (
  <>
    {state === stateEnum.LOADING && <div className={classes.loader}><Loader/></div>}
    {state === stateEnum.REJECTED && <ImageHolder className={classes.noImage} />}
    {state === stateEnum.LOADED && <img src={photo} className={classes.image} alt={name} />}
  </>
);

export default PlacePhoto;
