import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { modalTabs } from './utils';
import classes from './styles.module.css';
import { ReactComponent as Chevron } from '../../../../../shared/assets/chevronDown.svg';
import Tab from '../../../components/Tab';

const BusinessProfileModalTabNavigation = ({ onTabClick, activeTab }) => {
  const { t } = useTranslation();
  const tabs = modalTabs(t);
  const isTabSelected = (tab) => tab === activeTab;
  const [showSubTabs, setShowSubTabs] = useState(null);
  const [selectedExpandableTab, setSelectedExpandableTab] = useState({});

  const toggleSubTab = (key) => {
    setShowSubTabs((prev) => (prev === key ? null : key));
  };

  const handleTabClick = (key) => {
    onTabClick(key);
    setShowSubTabs(false);
    setSelectedExpandableTab({});
  };

  const handleExpandableTabClick = (expandableKey, parentKey, label) => {
    onTabClick(expandableKey);
    setSelectedExpandableTab((prev) => ({
      ...prev,
      [parentKey]: { key: expandableKey, label },
    }));
    toggleSubTab(parentKey);
  };

  return (
    <div className={classes.navigationWrapper}>
      {tabs.map(({ key, label, expandable }) => (
        <Tab
          key={key}
          label={expandable && selectedExpandableTab[key] ? selectedExpandableTab[key].label : label}
          isSelected={isTabSelected(key)}
          onClick={(e) => {
            e.stopPropagation();
            handleTabClick(key);
            setSelectedExpandableTab({});
          }}
        >
          {expandable && (
            <Chevron
              className={clsx(classes.expandableIcon, showSubTabs === key && classes.inverted)}
              onClick={(event) => {
                event.stopPropagation();
                toggleSubTab(key);
              }}
            />
          )}
          {showSubTabs === key && expandable && (
            <div className={classes.subMenuWrapper}>
              <Tab
                subTab
                label={label}
                isSelected={isTabSelected(key)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTabClick(key);
                }}
              />
              {expandable.map(({ key: expandableKey, label: expandableLabel }) => (
                <Tab
                  subTab
                  key={expandableKey}
                  label={expandableLabel}
                  isSelected={isTabSelected(expandableKey)}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExpandableTabClick(expandableKey, key, expandableLabel);
                  }}
                />
              ))}
            </div>
          )}
        </Tab>
      ))}
    </div>
  );
};

export default BusinessProfileModalTabNavigation;
