import clsx from 'clsx';
import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

import classes from './styles.module.css';
import { notificationConfig } from './utils';

const Notification = ({ type, message, onClose }) => {
  const [show, setShow] = useState(null);

  const handleAnimationEnd = (e) => {
    if (/open/.test(e.animationName)) {
      setShow(true);
    }
    if (/display/.test(e.animationName)) {
      setShow(false);
    }
    if (/close/.test(e.animationName)) {
      onClose();
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const notificationType = notificationConfig[type];
  const { className, bodyClassName, Icon } = notificationType;

  return (
    <div
      className={clsx(classes.wrapper, className, show && classes.display, show === false && classes.close)}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={clsx(classes.body, bodyClassName)}>
        <Icon className={classes.icon} />
        {message}
      </div>
      <CloseIcon className={classes.close} onClick={handleClose} />
    </div>
  );
};

export default Notification;
