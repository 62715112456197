import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as BusinessProfileCreateSvg } from '../../../../../shared/assets/createBusiness.svg';

const WelcomeScreenCreate = ({ onBusinessCreate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };
  const createConfirmationHandler = () => onBusinessCreate();
  const navigateImportBusinessProfile = () => navigate('/pick_business_profiles');

  return (
    <div className={classes.initWrapper}>
      <div className={classes.leftSide}>
        <div className={classes.title}>{t('businessProfileCreate.title')}</div>
        <div className={classes.subTitle}>{t('businessProfileCreate.subTitle')}</div>
        <div className={clsx(classes.subTitle, classes.inNextSteps)}>{t('businessProfileCreate.inNextSteps')}</div>
        <div
          className={clsx(classes.subTitle, classes.inNextSteps, classes.navigateToImport)}
          onClick={navigateImportBusinessProfile}
        >
          {t('businessProfileCreate.youCanImportExistingOne')}
        </div>
        <div className={classes.actionsWrapper}>
          <Button
            className={classes.createBusinessProfileBtn}
            label={t('businessProfileCreate.createBusinessProfile')}
            onClick={createConfirmationHandler}
          />
          <Button
            className={classes.goDashboardBtn}
            label={t('businessProfileCreate.goBackToDashBoard')}
            onClick={navigateToDashboard}
          />
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.svgBox}>
          <BusinessProfileCreateSvg className={classes.svgImage} />
        </div>
      </div>
      <Button
        className={clsx(classes.createBusinessProfileBtn, classes.rwdBtn)}
        label={t('businessProfileCreate.createBusinessProfile')}
        onClick={createConfirmationHandler}
      />
      <Button
        className={clsx(classes.importBtn, classes.rwdBtn)}
        label={t('businessProfileCreate.importExistingBusinessProfile')}
        onClick={navigateImportBusinessProfile}
      />
    </div>
  );
};
export default WelcomeScreenCreate;
