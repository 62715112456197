export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    return new Promise((resolve, reject) => {
      const later = () => {
        timeout = null;
        return func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        resolve(later());
      }, wait);
    });
  };
};
