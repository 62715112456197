import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Tooltip from '../../../../../shared/components/Tooltip';
import { formatComparison } from './utils';
import { ReactComponent as CircleInfo } from '../../../../../shared/assets/circleInfo.svg';
import { ReactComponent as Arrow } from '../../../../../shared/assets/arrowAlternative.svg';

const StatisticBox = ({
  title,
  value,
  Icon,
  comparison,
  tooltipText,
  toolTipTitle,
  status,
  onClick = () => {},
  noHighlight,
}) => {
  const negativeValue = comparison < 0;
  const shouldRenderTooltip = Boolean(tooltipText) && status === 'verified';

  return (
    <div
      onClick={() => onClick(title)}
      className={clsx(classes.container, status !== 'verified' && classes.noData, noHighlight && classes.noHighlight)}
    >
      <Icon className={classes.icon} />
      <div className={classes.valueBox}>
        <div className={classes.title}>
          {title}
          {shouldRenderTooltip && (
            <Tooltip
              tooltipStyle={classes.tooltip}
              className={classes.tooltipWrapper}
              toolTipTitle={toolTipTitle}
              text={tooltipText}
            >
              <CircleInfo className={classes.infoIcon} />
            </Tooltip>
          )}
        </div>
        <div className={classes.value}>
          {value}
          {comparison && (
            <div className={clsx(classes.valueComparison, negativeValue && classes.red)}>
              <Arrow className={clsx(classes.arrow, negativeValue && classes.rotate)} />
              {formatComparison(comparison, negativeValue)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default StatisticBox;
