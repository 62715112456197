import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';
import { ReactComponent as Check } from '../../../../../shared/assets/check.svg';

const WelcomeItem = ({
  item: { id, Icon, name, text, route },
  handleHover,
  hoverStateHandler,
  onHover,
  handleRedirect,
}) => {
  const { t } = useTranslation();
  const handleMouseEnter = () => handleHover(true, id);
  const handleMouseLeave = () => handleHover(false, id);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classes.box}
      data-testid={id}
      onClick={handleRedirect}
    >
      <div className={classes.boxIcon}>
        <Icon />
      </div>
      <div className={classes.boxInfo}>
        <div className={classes.boxName}>{t(name)}</div>
        <div className={classes.boxText}>{t(text)}</div>
      </div>
      <div className={clsx(classes.checkIconWrapper, !hoverStateHandler(id, onHover) && classes.hideCheck)}>
        <Check className={clsx(classes.checkIcon, !hoverStateHandler(id, onHover) && classes.hideCheck)} />
      </div>
    </div>
  );
};

export default WelcomeItem;
