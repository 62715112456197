import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import {
  FILTERS_ENUM,
  MIN_SEARCH_LENGTH,
  SORT_COLUMNS,
  columns,
  parseUserDataList,
} from './utils';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import ViewTitle from '../../components/ViewTitle';
import AdministratorListTool from '../../modules/AdministratorList/AdministratorListTool';
import Table from '../../../../shared/components/Table';
import AdminPagination from '../../components/AdminPagination';
import AdminLoader from '../../components/AdminLoader';
import { debounce } from '../../../../shared/helpers/debounce';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import { exportCSVDataHandler, prepareExportFilters } from '../../helpers/utils';
import { SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';

const { LIMIT, OFFSET, SEARCH, ACTIVE } = FILTERS_ENUM;
const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { login } = SORT_COLUMNS;

const AdministratorList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filters, setFilters] = useState({ limit: 10, offset: 0, search: '', active: '' });
  const [orderBy, setOrderBy] = useState({ column: login, direction: ASC });
  const [totalNumber, setTotalNumber] = useState(0);

  const filtersApplied = filters.search !== '' || filters.active !== null;
  const emptyStateTitle = filtersApplied ? t('adminControlPanel.noResults') : t('adminControlPanel.adminListEmpty');
  const currentViewTitle = `${t('adminControlPanel.administrators')} (${totalNumber})`;

  const onRowClickHandler = useCallback(
    (row) => {
      const { id } = row;
      navigate(`/admin/administrators/${id}`);
    },
    [navigate],
  );

  const fetchUsers = async (signal = new AbortController().signal) => {
    setIsLoading(true);
    const filtersWithNoNullValue = removeNullValues(filters);
    try {
      const {
        data: { results, total },
      } = await api.post('acp/user/userList', { filters: filtersWithNoNullValue, orderBy }, { signal });
      setUserList(parseUserDataList(results, t));
      setTotalNumber(total);
    } catch (error) {
      handleApiError({ err: error, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0, search: '' }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const exportDataHandler = async () => {
    const parsedFilters = prepareExportFilters(filters);

    try {
      const response = await api.post(
        '/acp/user/userListExport',
        { filters: parsedFilters, orderBy },
        { responseType: 'blob' },
      );
      const fileName = 'acpUsers';
      exportCSVDataHandler({ response, fileName, showNotification, t });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchUsers(signal);

    return () => {
      controller.abort();
    };
  }, [orderBy, filters]);

  if (isLoading) {
    return (
      <div className={classes.loaderWrapper}>
        <AdminLoader />
      </div>
    );
  }
  return (
    <>
      <ViewTitle title={currentViewTitle} />
      <div className={classes.wrapper}>
        <div className={classes.bodyWrapper}>
          <AdministratorListTool
            searchInputValue={filters.search}
            fetchList={fetchUsers}
            searchInputHandler={handleFiltersChange(SEARCH)}
            onStatusSelect={handleFiltersChange(ACTIVE)}
            selectValue={filters.active}
          />
          <Table
            data={userList}
            columns={columns(t)}
            editHandler={onRowClickHandler}
            onSortClick={sortHandler}
            orderBy={orderBy}
            emptyStateTitle={emptyStateTitle}
            tableBodyStyle={classes.tableCustomBody}
          />
          {Boolean(totalNumber) && (
            <AdminPagination
              className={classes.pagination}
              limit={filters.limit}
              offset={filters.offset}
              total={totalNumber}
              onPageChange={handleFiltersChange(OFFSET)}
              onLimitChange={handleFiltersChange(LIMIT)}
              onExportData={exportDataHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default AdministratorList;
