import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import classes from './styles.module.css';
import CategoriesSelect from '../CategoriesSelect';
import ActionBox from '../ActionBox';
import Tooltip from '../../../../../shared/components/Tooltip';
import { StateEnum } from '../../../../../shared/helpers/state';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/plus.svg';
import { getCategoryReferenceString } from './utils';
import AdditionalCategories from '../AdditionalCategories';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const CategoriesForm = ({
  className,
  form,
  setForm,
  onError,
  businessProfileId,
  setState,
  isEdit,
  editHandler,
  adminPreview,
  isReadOnlySubPlan,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();

  const { showNotification } = useNotification();
  const [errors, setErrors] = useState([]);
  const [categories, setCategories] = useState({});
  const error = errors.some(Boolean);
  const readOnlySaveCondition = !categories.primaryCategory || categories.additionalCategories?.some((el) => !el.categoryReferenceId);
  const renderAdditionalCategories = Boolean(categories?.additionalCategories?.length);

  const isAddBtnRendered = !adminPreview && !isReadOnlySubPlan;

  const handleGetInitialCategories = () => {
    const parsedPrimaryCategory = { ...form.categories.primaryCategory, key: Math.random() };
    const parsedAdditionalCategories = form.categories?.additionalCategories?.map((el) => ({
      ...el,
      key: Math.random(),
    })) || [];
    setCategories({
      primaryCategory: parsedPrimaryCategory,
      additionalCategories: parsedAdditionalCategories,
    });
  };

  const handlePrimaryCategory = (value) => {
    if (!value) return;
    setCategories((prev) => ({ ...prev, primaryCategory: value }));
  };

  const categoriesList = Object.values(categories)
    .flat(1)
    .reduce((acc, el) => {
      if (el) {
        return [...acc, el.categoryReferenceId];
      }
      return acc;
    }, []);

  const handleCategory = (index) => async (value) => {
    if (adminPreview) return;
    try {
      if (value.categoryReferenceId) {
        const { data } = await api.post('/place_category/getPlaceCategory', {
          categoryReferenceId: value.categoryReferenceId,
          businessProfileId,
        });
        setCategories((prev) => ({
          ...prev,
          additionalCategories: prev.additionalCategories.map((el, id) => (id === index ? { categoryReferenceId: data.categoryReferenceId, name: data.name } : el),),
        }));
      } else {
        setCategories((prev) => ({
          ...prev,
          additionalCategories: prev.additionalCategories.map((el, id) => (id === index ? { key: Math.random() } : el)),
        }));
      }
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  const handleAdd = () => {
    if (categories.additionalCategories.some((el) => !el.categoryReferenceId)) {
      return;
    }
    setCategories((prev) => ({
      ...prev,
      additionalCategories: [...prev.additionalCategories, { key: Math.random() }],
    }));
    setErrors((prev) => [...prev, null]);
  };

  const handleDelete = (index) => () => {
    setCategories((prev) => ({
      ...prev,
      additionalCategories: prev.additionalCategories.filter((el, id) => index !== id),
    }));
  };

  const handleSave = async () => {
    try {
      setState(StateEnum.loading);
      const parsedCategories = {
        primaryCategory: categories.primaryCategory.categoryReferenceId,
        additionalCategories: categories.additionalCategories.map((el) => el.categoryReferenceId),
      };
      const {
        data: { categories: dataCategories },
      } = await api.post('/business_profile/updateBusinessProfileCategories', {
        businessProfileId,
        categories: parsedCategories,
      });
      setCategories(dataCategories);
      setForm((prev) => ({ ...prev, categories: dataCategories }));
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
    setState(StateEnum.success);
    editHandler();
  };

  const handleCancel = () => {
    setCategories(form.categories);
    editHandler();
  };

  useEffect(() => {
    onError(error);
  }, [error]);

  useEffect(() => {
    handleGetInitialCategories();
  }, [form.categories]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.form}>
        <div className={classes.row}>
          <Tooltip
            className={classes.toolWrapper}
            tooltipStyle={classes.categoryTooltipStyle}
            isEdit={isEdit}
            text={getCategoryReferenceString(categories.primaryCategory?.categoryReferenceId)}
          >
            <CategoriesSelect
              className={classes.select}
              onCategoryChange={handlePrimaryCategory}
              isEdit={isEdit}
              value={categories.primaryCategory?.categoryReferenceId}
              categoriesList={categoriesList}
              label={t('businessProfileEdit.primaryCategory')}
              adminPreview={adminPreview}
              inputValue={categories.primaryCategory?.name}
            />
          </Tooltip>
          {isAddBtnRendered && (
            <Tooltip
              tooltipStyle={classes.tooltipStyle}
              isEdit={isEdit}
              text={t('businessProfileEdit.addCategoryToolTip')}
            >
              <button type="button" disabled={!isEdit} onClick={handleAdd} className={classes.action} aria-label="Add">
                <PlusIcon className={classes.plusIcon} />
              </button>
            </Tooltip>
          )}
        </div>
        {renderAdditionalCategories && (
          <AdditionalCategories
            isEdit={isEdit}
            categories={categories}
            categoriesList={categoriesList}
            handleCategory={handleCategory}
            handleDelete={handleDelete}
            adminPreview={adminPreview}
          />
        )}
      </div>
      {isEdit && (
        <ActionBox readOnlyAction={readOnlySaveCondition} handleCancel={handleCancel} handleSave={handleSave} />
      )}
    </div>
  );
};

export default CategoriesForm;
