import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import PostOptionForm from '../PostOptionsForm';
import PostForm from '../PostForm';
import { formOptions, modalWithArrowBack } from '../../../views/AddPost/utils';
import { getModalTitle, initialFormDataWithEditedPostValuesParser } from './utils/utils';
import Modal from '../../../../../shared/components/Modal';
import { useWindowResize } from '../../../../../shared/helpers/hooks';
import { MIN_TABLET_WIDTH } from '../../../helpers/constants';

const ModalForm = ({ editPost, modalHandler, fetchPosts, loadingHandler }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const innerWidth = useWindowResize();

  const [modalTitle, setModalTitle] = useState(t('posts.addPost'));
  const [data, setData] = useState({});
  const [formType, setFormType] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const filteredOptions = useMemo(() => formOptions.filter((el) => el.id === formType?.toUpperCase()), [formType]);
  const showArrowBack = modalWithArrowBack.map((title) => t(title)).includes(modalTitle);

  const rwdBackTitle = location.search === '' && !editPost ? t('modalMobile.goBackToPosts') : t('modalMobile.goBackToSelectForm');
  const rwdBackTitlePreview = showPreview ? t('modalMobile.goBackToForm') : rwdBackTitle;
  const rwdBackEditTitle = editPost ? t('modalMobile.goBackToPosts') : rwdBackTitlePreview;
  const shouldModalClose = rwdBackTitlePreview === t('modalMobile.goBackToPosts');
  const showArrowBackOnMobile = innerWidth >= MIN_TABLET_WIDTH && showArrowBack;

  const handleMedia = (value) => {
    if (!value.length) {
      setData((prev) => ({
        ...prev,
        localPost: {
          ...prev.localPost,
          media: [],
        },
      }));
    }
    const valueIsFile = Boolean(value[0]?.file);
    if (valueIsFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const mediaSource = reader.result;
        setData((prev) => ({
          ...prev,
          localPost: {
            ...prev.localPost,
            media: [{ preview: mediaSource, ...value[0] }],
          },
        }));
      };
      reader.readAsDataURL(value[0].file);
    }
  };

  const togglePreview = () => {
    setShowPreview((prev) => !prev);
  };

  const moveBackHandler = () => {
    if (!showPreview) {
      navigate('/dashboard/post');
    }
    setShowPreview(false);
  };

  const onClose = async () => {
    modalHandler();
    setModalTitle(t('posts.addPost'));
    navigate('/dashboard/post');
  };

  const modalNavigationHandler = shouldModalClose ? onClose : moveBackHandler;

  const changePublicationDateHandler = (value) => {
    setData((prev) => ({
      ...prev,
      publishDate: value,
    }));
  };

  const selectOptionHandler = (option) => {
    setFormType(option);
    const idLowerCase = option.toLowerCase();
    navigate({
      pathname: '/dashboard/post',
      search: `?type=${idLowerCase}`,
      hash: '#add',
    });
  };
  useEffect(() => {
    if (editPost) {
      setFormType(editPost.topicType);
      setData(initialFormDataWithEditedPostValuesParser(editPost, t));
    }
  }, [editPost]);

  useLayoutEffect(() => {
    setModalTitle(getModalTitle(filteredOptions, showPreview, editPost, t));
  }, [filteredOptions, showPreview, editPost, t]);

  useEffect(() => {
    if (!search) {
      setFormType(null);
      setData({});
      setModalTitle(t('posts.addPost'));
    }
  }, [search]);

  useEffect(() => {
    if (formType && !editPost) {
      const { initialData } = formOptions.find((el) => el.id === formType?.toUpperCase());
      setData(initialData);
    }
  }, [formType, editPost]);

  return (
    <Modal
      showArrowBack={showArrowBackOnMobile}
      onBack={modalNavigationHandler}
      onCancel={onClose}
      modalTitle={modalTitle}
      contentStyle={classes.modalContent}
      rwdBackTitle={rwdBackEditTitle}
    >
      <div className={classes.container}>
        {!formType ? (
          <PostOptionForm formOptions={formOptions} selectOptionHandler={selectOptionHandler} />
        ) : (
          <PostForm
            buttonLabel={modalTitle}
            modalHandler={modalHandler}
            filteredOptions={filteredOptions}
            formType={formType}
            data={data}
            editPost={editPost}
            showPreview={showPreview}
            setData={setData}
            togglePreview={togglePreview}
            handleMedia={handleMedia}
            changePublicationDateHandler={changePublicationDateHandler}
            fetchPosts={fetchPosts}
            loadingHandler={loadingHandler}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalForm;
