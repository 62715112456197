export const parseStyle = (value, bold) => {
  if (!['string', 'number'].includes(typeof value)) {
    throw new Error('Wrong width value');
  }
  const number = Number(value);
  if (!Number.isNaN(number)) {
    return { flex: number, fontWeight: bold ? '500' : 'normal' };
  }
  if (!value.includes('px') && !value.includes('%')) {
    throw new Error('Wrong width value');
  }
  return { width: value, fontWeight: bold ? '500' : 'normal' };
};
