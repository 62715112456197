import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const TopProgressBar = ({ progress, className, customBarStyle }) => {
  const progressWidth = progress * 100;

  return (
    <div className={clsx(classes.wrapper, className)} data-testid="top-progress-bar-wrapper">
      <div
        className={clsx(classes.progress, customBarStyle)}
        data-testid="top-progress-bar-progress"
        style={{ width: `${progressWidth}%` }}
      />
    </div>
  );
};

export default TopProgressBar;
