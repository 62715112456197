import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { ReactComponent as LinkIcon } from '../../../../../shared/assets/link.svg';
import { urlValidator } from '../../../../../shared/components/Input/validators';

const LinkInformation = ({ link, onLinkFormChange, admin = true }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.wrapper, !admin && classes.clientWrapper)}>
      <div className={classes.inputWrapper}>
        {t('adminControlPanel.redirectName')}
        <Input
          admin={admin}
          className={classes.cardNameInput}
          value={link.linkName}
          maxLength={50}
          onChange={onLinkFormChange('linkName')}
        />
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.inputWrapper}>
          {t('adminControlPanel.baseCode')}
          <Input admin={admin} readOnly value={link.linkCode} />
        </div>
        <div className={classes.inputWrapper}>
          {t('adminControlPanel.redirectLink')}
          <div className={classes.linkInputWrapper}>
            <Input
              Icon={LinkIcon}
              admin={admin}
              className={classes.redirectLinkInput}
              validator={(v) => urlValidator(v, true)}
              showErrorAfterBlur
              value={link.link}
              onChange={onLinkFormChange('link')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkInformation;
